import * as accountStatuses from 'constants/account';
import { history } from 'stores/configureStore';
import { getQueryParams } from 'utils/queryParams';
import { getRandomInRange } from 'utils/getRandomInRange';

export const getAccountStatus = (accountOrAssociate) => {
  if (accountOrAssociate.process || accountOrAssociate.inviteStatus === 'processing') {
    return accountStatuses.STATUS_PROCESS;
  }

  if (accountOrAssociate.user) {
    return accountStatuses.STATUS_USER_EXISTS;
  }

  if (accountOrAssociate.anotherUser) {
    return accountStatuses.STATUS_WRONG_USER;
  }

  return accountStatuses.STATUS_NO_USER;
};

export const defaultPageSize = 20;

export const paginationProps = (fundId) => ({
  current: Number(getQueryParams('page') || 1),
  pageSize: defaultPageSize,
  showSizeChanger: false,
  hideOnSinglePage: true,
  defaultPageSize,
  onChange: (page) => history.push(Routes.fund_accounts_path(fundId, { page })),
});

export const generateCaptcha = () => String(getRandomInRange(1000, 9999));
