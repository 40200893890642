import { schema } from 'normalizr';

const chartSchema = new schema.Entity('charts', [], { idAttribute: 'key' });
const categorySchema = new schema.Entity('categories');
const accountSchema = new schema.Entity('accounts');
const mappingSchema = new schema.Entity('mappings');

chartSchema.define({
  categories: [categorySchema],
});

categorySchema.define({
  accounts: [accountSchema],
});

accountSchema.define({
  mappings: [mappingSchema],
});

export default [chartSchema];
