import React from 'react';
import PropTypes from 'prop-types';

import { CurrencyInput, PercentageInput } from 'components/shared';

import InvestorSeriesItem from './InvestorSeriesItem';

const propTypes = {
  investorClass: PropTypes.object.isRequired,
  fundId: PropTypes.number.isRequired,
};

function InvestorClassItem(props) {
  const { investorClass, fundId } = props;

  const InvestorSeriesItems = investorClass.investorSeries.map((is) => (
    <InvestorSeriesItem key={is.id} investorSeries={is} fundId={fundId} />
  ));

  return (
    <li className="tree__item tree__item-sub">
      <div className="row">
        <div className="col-md-3">
          <input checked type="checkbox" /> {investorClass.name}
        </div>

        <div className="col-md-3 col-md-offset-2">
          <CurrencyInput
            id={`allocated_amounts${investorClass.id}`}
            name={'allocated_amounts[amount][]'}
            required
            dataStateKey={`data.allocatedAmounts.${investorClass.id}.amount`}
            value={investorClass.amount}
            onChange={() => false}
          />
        </div>

        <div className="col-md-3">
          <PercentageInput
            id={`allocated_amounts${investorClass.id}`}
            name={'allocated_amounts[amountPercent][]'}
            required
            dataStateKey={`data.allocatedAmounts.${investorClass.id}.amountPercent`}
            value={'0'}
            onChange={() => false}
          />
        </div>
      </div>

      {InvestorSeriesItems.length > 0 && <ul className="tree tree-sub">{InvestorSeriesItems}</ul>}
    </li>
  );
}

InvestorClassItem.propTypes = propTypes;

export default InvestorClassItem;
