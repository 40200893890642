import { AccrualsApi } from 'apiAdapters';
import * as R from 'ramda';
import { FORM_ERROR } from 'final-form';

import * as actionTypes from 'constants/actionTypes';
import * as requestTypes from 'constants/requestTypes';
import initialValuesDecorator from 'decorators/funds/accruals';
import {
  getAccrualEditableKeys,
  getAccrualId,
  getAccrualPaymentItems,
  getResourceAccrual,
} from 'selectors/funds/accruals';
import { getAllocatedAmounts } from 'selectors/allocatedAmounts';
import { successNotification } from 'utils/notifications';
import { getFormStateValues } from 'selectors/forms';
import { toggleChartOfAccounts } from 'actions/chartOfAccounts';
import { getFieldForSelectAccount } from 'selectors/chartOfAccounts';
import { getFundId, getFund } from 'selectors/funds';
import { throwPaymentScheduleError, getReadableError } from 'utils/errors';
import { sortByDate } from 'utils/sort';
import { getFormatedPaymentItem, redirectAfterSubmitAccrualForm } from 'utils/accruals';
import { updateEditableKeys, setEditableKeyFalse } from 'utils/ramda';
import setRequestInProcess from 'actions/request';

export const patchPaymentItem = (paymentItemId) => (dispatch, getState) => {
  const requestType = requestTypes.ACCRUALS_PAYMENT_ITEM_PATCH;
  const state = getState();
  const scheduleValues = getFormStateValues(state, { form: 'paymentSchedule' });
  const { paymentItems } = scheduleValues;
  const paymentItem = R.find(R.propEq('id', paymentItemId))(paymentItems);
  const { id, accrualId } = paymentItem;
  const fundId = getFundId(state);
  const newPaymentItem = getFormatedPaymentItem(paymentItem);

  dispatch(setRequestInProcess(true, requestType));

  return AccrualsApi.updateFundAccrualPaymentItem(
    { paymentItem: newPaymentItem },
    { accrualId, fundId, paymentItemId: id },
  )
    .then(({ payload: { item, collection } }) => {
      const newCollection = R.map((paymentItem) => {
        if (paymentItem.id === item.id) {
          return { ...paymentItem, modified: true };
        }

        return paymentItem;
      }, collection);

      dispatch(setRequestInProcess(false, requestType));
      dispatch(updatePaymentItemsErrors({}));
      dispatch(updatePaymentItemCollection(newCollection.sort(sortByDate)));
      dispatch(setRowEditableKeysFalse(id));
    })
    .catch((res) => {
      const errors = throwPaymentScheduleError(res);
      const activePaymentItems = getAccrualPaymentItems(state);
      activePaymentItems.forEach((item) => {
        if (item.id === paymentItemId) {
          item.error = true;
        } else {
          delete item.error;
        }
      });

      dispatch(setRequestInProcess(false, requestType));
      dispatch(updatePaymentItemCollection(activePaymentItems));
      dispatch(updatePaymentItemsErrors(errors));
    });
};

export function createPaymentItem(values, accrualId) {
  return (dispatch, getState) => {
    const requestType = requestTypes.ACCRUALS_PAYMENT_ITEM_CREATE;
    const state = getState();
    const fundId = getFundId(state);
    const newItem = getFormatedPaymentItem(values);

    return AccrualsApi.createFundAccrualPaymentItem({ paymentItem: newItem }, { fundId, accrualId })
      .then(({ payload: { item, collection } }) => {
        dispatch(setRequestInProcess(false, requestType));
        const newCollection = R.map((paymentItem) => {
          if (paymentItem.id === item.id) {
            return { ...paymentItem, modified: true };
          }

          return paymentItem;
        }, collection);

        dispatch({
          type: actionTypes.ACCRUAL_PAYMENTS_ITEM_CREATE,
          payload: newCollection.sort(sortByDate),
        });
      })
      .catch((res) => {
        dispatch(setRequestInProcess(false, requestType));
        const errors = throwPaymentScheduleError(res);
        const readableErrors = getReadableError(errors);
        return { [FORM_ERROR]: readableErrors };
      });
  };
}

export function deletePaymentItem(paymentItemId) {
  return (dispatch, getState) => {
    const requestType = requestTypes.ACCRUALS_PAYMENT_ITEM_DELETE;
    const state = getState();
    const fundId = getFundId(state);
    const accrualId = getAccrualId(state);

    return AccrualsApi.destroyFundAccrualPaymentItem({ accrualId, fundId, paymentItemId })
      .then(({ payload: { collection } }) => {
        dispatch(setRequestInProcess(false, requestType));
        dispatch({
          type: actionTypes.ACCRUAL_PAYMENTS_ITEM_DELETE,
          payload: collection,
        });
        dispatch(updatePaymentItemsErrors({}));

        return successNotification('Payment item was removed');
      })
      .catch((res) => {
        const errors = throwPaymentScheduleError(res);
        dispatch(setRequestInProcess(false, requestType));
        dispatch(updatePaymentItemsErrors(errors));
      });
  };
}

export function updatePaymentItemsEditableKeys(name, key) {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.ACCRUAL_PAYMENTS_ITEMS_EDITABLE_KEYS_UPDATE,
      payload: updateEditableKeys(key, name, getAccrualEditableKeys(getState())),
    });
  };
}

export function setPaymentItemsEditableKeyFalse(name, key) {
  return (dispatch, getState) => {
    const state = getState();
    const editableKeys = getAccrualEditableKeys(state);
    const payload = setEditableKeyFalse(key, name, editableKeys);
    dispatch({
      type: actionTypes.ACCRUAL_PAYMENTS_ITEMS_EDITABLE_KEYS_UPDATE,
      payload,
    });
  };
}

export const setRowEditableKeysFalse = (rowId) => (dispatch, getState) => {
  const state = getState();
  const editableKeys = getAccrualEditableKeys(state);
  const payload = { ...editableKeys, [rowId]: undefined };
  dispatch({
    type: actionTypes.ACCRUAL_PAYMENTS_ITEMS_EDITABLE_KEYS_UPDATE,
    payload,
  });
};

export const submitAccrualForm = ({ redirectPage, ...values }, params) => (dispatch, getState) => {
  const state = getState();
  const requestType = requestTypes.ACCRUALS_FORM_SUBMIT;
  const allocatedAmounts = getAllocatedAmounts(state);
  dispatch(setRequestInProcess(true, requestType));

  return AccrualsApi.submitFundAccrual({ ...values, allocatedAmounts }, params)
    .then((result) => {
      const { payload } = result;
      dispatch(setRequestInProcess(false, requestType));
      // eslint-disable-next-line max-len
      redirectAfterSubmitAccrualForm({ redirectPage, ...values }, { ...params, accrualId: payload.accrual.id, active: true });
    })
    .catch((res) => {
      dispatch(setRequestInProcess(false, requestType));
      const errors = throwPaymentScheduleError(res);
      const readableErros = getReadableError(errors);
      if (readableErros) {
        return { [FORM_ERROR]: readableErros };
      }
    });
};

export const selectAccount = (chartAccountId) => (dispatch, getState) => {
  const state = getState();
  const fieldForSelectAccount = getFieldForSelectAccount(state);

  dispatch({
    type: actionTypes.ACCRUAL_ACCOUNT_SELECT,
    payload: {
      value: chartAccountId,
      field: fieldForSelectAccount,
    },
  });
  dispatch(toggleChartOfAccounts());
};

export const createAccrualInitialValues = () => (dispatch, getState) => {
  const state = getState();
  const fund = getFund(state);
  const accrual = getResourceAccrual(state);
  const accrualInitialValues = initialValuesDecorator(fund, accrual)();

  dispatch({
    type: actionTypes.ACCRUAL_INITIAL_VALUES_CREATE,
    payload: accrualInitialValues,
  });
};

export const updatePaymentItemCollection = (payload) => ({
  type: actionTypes.ACCRUAL_PAYMENTS_ITEM_UPDATE,
  payload,
});

function updatePaymentItemsErrors(payload) {
  return {
    type: actionTypes.ACCRUAL_PAYMENTS_ITEMS_ERRORS_UPDATE,
    payload,
  };
}
