import { filter, values, map, uniq, flatten } from 'ramda';
import { createSelector } from 'reselect';

import { getIn } from 'utils/ramda';

import { associateEntitiesSelector, accountEntitiesSelector, getAccountCollection } from '../accounts';

const directoriesSelector = (state) => getIn('fund.fundInvestorDocumentDirectories', state);

export const treeDataSelector = createSelector(directoriesSelector, (directories) => getIn('tree', directories) || []);
export const treeCollectionSelector = createSelector(directoriesSelector, (directories) => getIn('collection', directories) || []);
export const treeDocumentsSelector = createSelector(directoriesSelector, (directories) => getIn('documents', directories) || []);
export const currentPatternIdSelector = createSelector(directoriesSelector, (directories) => getIn('currentPatternId', directories));
export const getCurrentUpload = createSelector(directoriesSelector, (directories) => getIn('currentUpload', directories)) || null;
export const getFundAccountFilter = createSelector(directoriesSelector, (directories) => getIn('fundAccountFilter', directories));

const getFundAccounts = (fundId) => createSelector(accountEntitiesSelector, (accountEntities) => filter((account) => account.fundId === Number(fundId), values(accountEntities) || []));
export const getFundAccountsWithPortalAccess = (fundId) => createSelector(getFundAccounts(fundId), (collection) => filter((item) => item.webAccess, collection || []));
export const getAssociatesWithPortalAccess = createSelector(associateEntitiesSelector, (collection) => filter((item) => item.webAccess, collection)) || {};
export const getAccountsWithPrivateFolders = createSelector(treeCollectionSelector, (collection) => uniq(flatten(map((item) => item.accesses, filter((item) => item.isPrivate, collection)))))
export const getPrivateNodes = createSelector(treeCollectionSelector, treeDocumentsSelector, (collection, documents) => flatten([filter((item) => item.isPrivate, collection), filter((item) => item.isPrivate, documents)]))
export const getPrivateClasses = createSelector(getPrivateNodes, (collection) => filter((item) => !item.isDocument && !item.parentId, collection));
export const getPrivateSeries = createSelector(getPrivateNodes, getPrivateClasses, (nodes, classes) => filter((item) => !item.isDocument && map((c) => c.id, classes).includes(item.parentId), nodes));
export const getPrivateAccounts = createSelector(getPrivateNodes, getPrivateSeries, (nodes, series) => filter((item) => !item.isDocument && map((s) => s.id, series).includes(item.parentId), nodes));
export const getPrivateNodesIds = createSelector(getPrivateNodes, (nodes) => map((n) => n.id, nodes));
export const getPrivateClassesIds = createSelector(getPrivateClasses, (classes) => map((c) => c.id, classes));
export const getPrivateSeriesIds = createSelector(getPrivateSeries, (series) => map((s) => s.id, series));
export const getPrivateAccountsIds = createSelector(getPrivateAccounts, (accounts) => map((a) => a.id, accounts));

export const getFundAccountEntities = createSelector(accountEntitiesSelector, (_, fundId) => fundId, (collection, fundId) => filter((account) => account.fundId === Number(fundId), values(collection)) || []);
export const getFundAssociateEntities = createSelector(associateEntitiesSelector, (_, fundId) => fundId, (collection, fundId) => filter((account) => account.fundId === Number(fundId), collection) || []);

export const getAccessesForDirectory = createSelector(treeCollectionSelector, getAccountCollection, (_, accesses) => accesses, (collection, accounts, accesses) => {
  const accessTypes = { class: 'Fund::InvestorClass', series: 'Fund::InvestorSeries', account: 'Fund::Account' };
  return flatten(accesses.map(({ ownerId, ownerType }) => {
    if (ownerType === accessTypes.class) {
      return accounts.filter(acc => acc.investorClass.id === ownerId);
    }
    if (ownerType === accessTypes.series) {
      return accounts.filter(acc => acc.investorSeries.id === ownerId);
    }
    return accounts.filter(acc => acc.id === ownerId);
  }));
});

export const getAccessesForDocument = createSelector(treeDocumentsSelector, getAccountCollection, (_, accesses) => accesses, (collection, accounts, accesses) => {
  const accessTypes = { class: 'Fund::InvestorClass', series: 'Fund::InvestorSeries', account: 'Fund::Account' };
  return flatten(accesses.map(({ ownerId, ownerType }) => {
    if (ownerType === accessTypes.class) {
      return accounts.filter(acc => acc.investorClass.id === ownerId);
    }
    if (ownerType === accessTypes.series) {
      return accounts.filter(acc => acc.investorSeries.id === ownerId);
    }
    return accounts.filter(acc => acc.id === ownerId);
  }));
});