import set from 'lodash/fp/set';
import * as types from './actionTypes';

const initialState = {
  bands: [],
  errors: [],
  baseErrors: [],
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  const { bands } = state;

  switch (type) {
    case types.ADD_BAND:
      return {
        ...state,
        bands: [...bands, payload.band],
      };

    case types.DELETE_BAND:
      if (bands[payload.position].id === null || bands[payload.position].id === undefined) {
        return {
          ...state,
          bands: updateValueFromCollection(
            payload.position,
            'lowerBoundPercentage',
            bands[payload.position].lowerBoundPercentage,
            [...bands.slice(0, payload.position), ...bands.slice(payload.position + 1)],
            false,
          ),
        };
      }

      return {
        ...state,
        bands: updateValueFromCollection(
          payload.position + 1,
          'lowerBoundPercentage',
          bands[payload.position].lowerBoundPercentage,
          updateValueFromCollection(
            payload.position,
            '_destroy',
            true,
            bands,
          ),
          false,
        ),
      };

    case types.CHANGE_INPUT:
      return set(payload.stateKey, payload.value)(state);

    case types.CHANGE_LOWER_BOUND_PERCENTAGE:
      if (!payload.index && payload.index !== 0) {
        return state;
      }

      return {
        ...state,
        bands: updateValueFromCollection(payload.index, 'upperBoundPercentage', payload.value, bands),
      };

    case types.CHANGE_UPPER_BOUND_PERCENTAGE:
      if (!payload.index && payload.index !== 0) {
        return state;
      }

      return {
        ...state,
        bands: updateValueFromCollection(payload.index, 'lowerBoundPercentage', payload.value, bands),
      };

    default:
      return state;
  }
};

const updateValueFromCollection = (index, attribute, value, collection, immutable = true) => {
  const newCollection = immutable ? collection.slice() : collection;

  if (newCollection[index]) {
    newCollection[index][attribute] = value;
  }

  return newCollection;
};
