import React from 'react';
import { Alert, Button, Col, Form, Row } from 'antd';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { connect } from 'react-redux';
import { compose, pure } from 'recompose';

import FormGroup from 'components/shared/form/FormGroup';
import { composeValidators, validateRequired, validateDate } from 'utils/validates';
import { currencyWithoutCommaFormat } from 'utils/formats';
import { successNotification } from 'utils/notifications';

const propTypes = {
  submitError: PropTypes.string,
  handleAddBtnClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

const defaultProps = {
  submitError: undefined,
};

function AccrualsPaymentNewForm(props) {
  // eslint-disable-next-line react/prop-types
  const { submitError, handleAddBtnClick, onClose, currencySymbol } = props;

  return (
    <>
      {submitError && <Alert message={submitError} type="error" showIcon />}

      <Form className="payment_schedule__form_new">
        <Row>
          <Col>
            <Field
              name="date"
              type="datePicker"
              typeOfMask="number"
              component={FormGroup}
              label="Date"
              required
              validate={composeValidators(validateRequired(), validateDate())}
              allowClear
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Field
              component={FormGroup}
              name="amount"
              label="Accrual"
              typeOfMask="decimalNumber"
              addonBefore={currencySymbol}
              required
              validate={validateRequired()}
              parse={currencyWithoutCommaFormat}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Field
              name="paymentAmount"
              typeOfMask="decimalNumber"
              addonBefore={currencySymbol}
              component={FormGroup}
              label="Payment"
              validate={validateRequired()}
              parse={currencyWithoutCommaFormat}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Field
              name="paymentDate"
              type="datePicker"
              typeOfMask="number"
              component={FormGroup}
              label="Payment Date"
              allowClear
            />
          </Col>
        </Row>

        <div className="panel__footer panel__footer-payments_drawer">
          <Button onClick={handleAddBtnClick} type="primary">
            Add
          </Button>
          <button onClick={onClose} type="button" className="btn btn-link">
            Cancel
          </button>
        </div>
      </Form>
    </>
  );
}

const mapStateToProps = (state, ownProps) => {
  const { dirtySinceLastSubmit, form, invalid, handleSubmit, onClose } = ownProps;

  const handleAddBtnClick = async () => {
    const returnedError = await handleSubmit();
    if ((!returnedError && invalid && !dirtySinceLastSubmit) || returnedError) {
      return null;
    }
    onClose();
    form.reset();
    return successNotification('Payment item was added success');
  };

  return {
    currencySymbol: state.resourceFund.currencySymbol,
    handleAddBtnClick,
  };
};

const enhance = compose(connect(mapStateToProps, null), pure);

AccrualsPaymentNewForm.propTypes = propTypes;
AccrualsPaymentNewForm.defaultProps = defaultProps;

export default enhance(AccrualsPaymentNewForm);
