import { initialState } from 'selectors/forms';
import { FORM_UPDATE, FORM_PAYMENTS_SCHEDULE_SUBMIT_ERRORS_UPDATE } from 'constants/actionTypes';

export default function(state = initialState, action) {
  switch (action.type) {
    case FORM_UPDATE:
      return {
        ...state,
        [action.form]: action.payload,
      };

    case FORM_PAYMENTS_SCHEDULE_SUBMIT_ERRORS_UPDATE:
      return {
        ...state,
        paymentSchedule: {
          ...state.paymentSchedule,
          submitErrors: {
            paymentItems: action.payload,
          },
        },
      };

    default:
      return state;
  }
}
