import { createSelector } from 'reselect';
import { filter, includes } from 'lodash';
import shortid from 'shortid';

export const fundSelector = state => state.resourceFund;
const investorClasses = state => state.investorClasses;
const investorSeries = state => state.investorSeries;
const accounts = state => state.accounts;


export const isShareBasedTypes = createSelector(
  fundSelector,
  fund => fund.type === 'share_based' || fund.type === 'pe_share_based',
);

export const isPartnershipTypes = createSelector(
  fundSelector,
  fund => fund.type === 'partnership' || fund.type === 'pe_partnership',
);

export const isShareBased = createSelector(
  fundSelector,
  fund => fund.type === 'share_based',
);

export const isPartnership = createSelector(
  fundSelector,
  fund => fund.type === 'partnership',
);

export const isPeShareBased = createSelector(
  fundSelector,
  fund => fund.type === 'pe_share_based',
);

export const isPePartnership = createSelector(
  fundSelector,
  fund => fund.type === 'pe_partnership',
);

export const getInvestorClassesAndSeriesTree = createSelector(
  investorClasses,
  investorSeries,
  (klasses, series) =>
    klasses.map((ic) => {
      const investorSeriesForClass = filter(series, is => includes(ic.investorSeriesIds, is.id));
      return ({
        ...ic,
        _id: shortid.generate(),
        investorSeries: investorSeriesForClass,
      });
    },
  ),
);

const getInvestorSeriesSelector = createSelector(
  investorSeries,
  accounts,
  (series, accounts) =>
    series.map((is) => {
      const seriesAccounts = accounts.filter(account => includes(is.accountIds, account.id) && account);

      return ({
        ...is,
        _id: shortid.generate(),
        accounts: seriesAccounts,
      });
    },
  ),
);

export const getAllocatedAmountsTree = createSelector(
  investorClasses,
  getInvestorSeriesSelector,
  (klasses, series) =>
    klasses.map((klass) => {
      const investorClassSeries = series.filter(series => includes(klass.investorSeriesIds, series.id) && series);
      return ({
        ...klass,
        _id: shortid.generate(),
        investorSeries: investorClassSeries,
      });
    },
  ),
);
