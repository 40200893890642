import { propEq, find, map, addIndex, filter, isEmpty } from 'ramda';
import { getIn } from 'utils/ramda';

import { dateWithTimeFormat } from 'utils/formats';

const taskItemsSelector = (state) => getIn('fund.valuation.taskItems', state) || {};

export const collectionSelector = (state) => getIn('collection', taskItemsSelector(state)) || [];

export const getCompleted = (state) => filter((item) => item.completed, collectionSelector(state));

export const getIncomplete = (state) => filter((item) => !item.completed, collectionSelector(state));

export const dataSource = (collection) =>
  addIndex(map)(
    (item, index) => ({
      key: index,
      id: item.id,
      title: item.title,
      fundUserRole: item.fundUserRole,
      position: item.position,
      owner: item.owner,
      ownerId: item.ownerId,
      ownerName: item.ownerName,
      assignedAt: item.assignedAt ? dateWithTimeFormat(item.assignedAt) : null,
      canBeUncompleted: item.canBeUncompleted,
      dependentFundValuationTaskItemId: item.dependentFundValuationTaskItemId,
      dependentFundValuationTaskItemTitle: item.dependentFundValuationTaskItemTitle,
      // eslint-disable-next-line max-len
      dependentFundValuationTaskItemCompleted: item.dependentFundValuationTaskItem
        ? item.dependentFundValuationTaskItem.completed
        : true,
      // eslint-disable-next-line max-len
      dependentFundValuationTaskItemPosition: item.dependentFundValuationTaskItem
        ? item.dependentFundValuationTaskItem.position
        : null,
      completed: item.completed,
      completedByName: item.completedBy ? item.completedBy.name : null,
      completedByAt: item.completedBy ? dateWithTimeFormat(item.completedBy.at) : null,
    }),
    collection,
  );

// eslint-disable-next-line radix
export const getEditable = (id, collection) => find(propEq('id', parseInt(id)))(collection);

export const getDependentOptions = (collection, editable = null) => {
  if (editable) {
    // eslint-disable-next-line max-len
    return filter(
      (item) => item.id !== editable.id && item.dependentFundValuationTaskItemId !== editable.id,
      collection,
    );
  }

  return collection;
};

export const getCompletedCount = (state) => getCompleted(state).length;
