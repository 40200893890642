import React, { useState } from 'react';
import { Form as FinalForm } from 'react-final-form';
import PropTypes from 'prop-types';
import { Button, Drawer } from 'antd';

import AccrualsPaymentNewForm from './Form';

const propTypes = {
  handleCreate: PropTypes.func.isRequired,
};

function AccrualsPaymentNew(props) {
  const { handleCreate } = props;
  const [isVisibleDrawer, setIsVisibleDrawer] = useState(false);

  return (
    <>
      <Button onClick={() => setIsVisibleDrawer(true)} type="primary" className="table__add-accrual">
        Add a row
      </Button>
      <Drawer
        title="Add New Payment Item"
        placement="right"
        closable={false}
        width={300}
        onClose={() => setIsVisibleDrawer(false)}
        visible={isVisibleDrawer}
        destroyOnClose
      >
        <FinalForm
          component={AccrualsPaymentNewForm}
          onClose={() => setIsVisibleDrawer(false)}
          onSubmit={handleCreate}
        />
      </Drawer>
    </>
  );
}

AccrualsPaymentNew.propTypes = propTypes;

export default AccrualsPaymentNew;
