import { createSelector } from 'reselect';

export const initialState = {
  isVisible: false,
  accountQuery: '',
  fieldForSelectAccount: null,
  mode: 'tree',
  isCollapsed: true,
};

const chartOfAccounts = (state) => state.chartOfAccounts || initialState;

export const getFieldForSelectAccount = createSelector(chartOfAccounts, (chart) => chart.fieldForSelectAccount);
export const getIsVisibleChart = createSelector(chartOfAccounts, (chart) => chart.isVisible);
