import { immutableDelete, immutablePush } from 'utils/immutableFunctions';

import * as types from './actionTypes';

const initialState = {
  mode: 'table',
  status: 'pending',
  mappings: [],
  changes: null,
};

const chartsMappings = (state = initialState, action) => {
  switch (action.type) {
    case types.CHARTS_ON_MAPPING_DELETE: {
      const mappingIndex = _.findIndex(state.mappings, function(m) {
        return m.id === action.payload.id;
      });

      return {
        ...state,
        mappings: immutableDelete(state.mappings, mappingIndex),
      };
    }

    case types.CHARTS_ON_MAPPING_ADD: {
      const newMappings = immutablePush(state.mappings, action.payload.mapping);
      return {
        ...state,
        mappings: newMappings,
        changed: action.payload.mapping.id,
      };
    }

    case types.MAPPING_MODE_CHANGE:
      return {
        ...state,
        mode: action.payload.mode,
      };

    case types.SECURITY_TYPES_FILTER_CHANGE:
      return {
        ...state,
        filtered: action.payload.values,
      };

    case types.MAPPINGS_FETCH_REQUESTED:
      return {
        ...state,
        status: action.payload.status,
      };

    case types.MAPPINGS_FETCH_COMPLETED:
      return {
        ...state,
        mappings: action.payload.mappings,
        status: action.payload.status,
      };

    default:
      return state;
  }
};

export default chartsMappings;
