import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { NewScheduleItem, ScheduleItem } from './components/ScheduleItem';
import * as scheduleActions from './actions';
import { filterDestroyItems } from './selectors';
import * as scheduleItemActions from './components/ScheduleItem/actions';

const propTypes = {
  names: PropTypes.array,
  manual: PropTypes.bool,
  rawData: PropTypes.array.isRequired,
  processMethod: PropTypes.func.isRequired,
  currency: PropTypes.string,
  errors: PropTypes.object,
};

const defaultProps = {
  names: ['', 'Date', 'Amount', 'Payment', 'Payment Date', 'Receivable'],
  manual: false,
  currency: null,
  errors: null,
};

class PaymentsSchedule extends Component {
  componentDidMount() {
    this.props.onRef(this);
  }

  componentWillMount() {
    this.update();
  }

  componentWillUnmount() {
    this.props.onRef(undefined);
  }

  update() {
    const {
      props: {
        manual,
        currency,
        rawData,
        processMethod,
        scheduleActions: { updateSchedule },
      },
    } = this;

    updateSchedule(processMethod(rawData), manual);
  }

  getData() {
    const { paymentsSchedule } = this.props;

    return Object.assign({}, paymentsSchedule);
  }

  render() {
    const {
      props,
      props: {
        paymentsSchedule: { items, _new, _temp },
        errors,
        names,
        currency,
        lastLockedPeriodDate,
      },
    } = this;

    return (
      <table className="table table-condensed table-no_bordered table-form">
        <thead>
          <tr>
            {names.map((name, i) => (
              <th key={i}>{name}</th>
            ))}
            <th />
          </tr>
        </thead>
        <NewScheduleItem
          data={_new}
          currency={currency}
          disabled={!_.isEmpty(_temp)}
          actions={props.scheduleItemActions}
        />
        <tbody style={{ borderTop: 'none' }}>
          {items.map((item, index) => (
            <ScheduleItem
              key={index}
              index={index}
              data={item}
              actions={props.scheduleItemActions}
              currency={currency}
              errors={errors[item.key]}
              canDestroy={_temp.index != index && filterDestroyItems(props).length > 1}
              disabled={_.has(_temp, 'index') && _temp.index != index}
              lastLockedPeriodDate={lastLockedPeriodDate}
              edited={_temp.index == index}
            />
          ))}
        </tbody>
      </table>
    );
  }
}

const mapStateToProps = (state, props) => ({
  ...state,
  paymentsSchedule: {
    ...state.paymentsSchedule,
    _temp: state.paymentsSchedule._temp ? state.paymentsSchedule._temp : {},
    _new: state.paymentsSchedule._new
      ? state.paymentsSchedule._new
      : {
          date: null,
          amount: null,
          paymentAmount: null,
        },
    items: state.paymentsSchedule.items ? state.paymentsSchedule.items : [],
    manual: state.paymentsSchedule.manual ? state.paymentsSchedule.manual : false,
  },
});

const mapDispatchToProps = (dispatch) => ({
  scheduleActions: bindActionCreators(scheduleActions, dispatch),
  scheduleItemActions: bindActionCreators(scheduleItemActions, dispatch),
});

PaymentsSchedule.propTypes = propTypes;
PaymentsSchedule.defaultProps = defaultProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PaymentsSchedule);
