import React from 'react';
import PropTypes from 'prop-types';

import keyFuncs from './keys';

const propTypes = {
  keys: PropTypes.arrayOf(PropTypes.array).isRequired,
};

const defaultProps = {};

function FormIntegration({ keys }) {
  const [allocated, excluded] = keys;

  const normalizeTypeCamelCase = (type) => {
    return type === "class" ? 'InvestorClass' : type === 'series' ? 'InvestorSeries' : 'Account'
  }
  const normalizeTypeUnderscore = (type) => {
    return type === "class" ? 'investor_classes' : type === 'series' ? 'investor_series' : 'investor_accounts'
  }

  const inputs = (key, spec) => {
    const type = keyFuncs.getType(key);
    const id = keyFuncs.getId(key);
    return (
      <div>
        <input
          value={id}
          name={`${normalizeTypeUnderscore(type)}[${id}][target_id]`}
          id={`${normalizeTypeUnderscore(type)}_${id}_target_id`}
          type="hidden"
        />
        <input
          value={`Fund::${normalizeTypeCamelCase(type)}`}
          name={`${normalizeTypeUnderscore(type)}[${id}][target_type]`}
          id={`${normalizeTypeUnderscore(type)}_${id}_target_type`}
          type="hidden"
        />
        <input
          value={spec}
          name={`${normalizeTypeUnderscore(type)}[${id}][type]`}
          id={`${normalizeTypeUnderscore(type)}_${id}_type`}
          type="hidden"
        />
      </div>
    )
  }

  return (
    <div>
      {[
        ...allocated.map(key => inputs(key, 'allocation')),
        ...excluded.map(key => inputs(key, 'exclusion'))
      ]}
    </div>
  )
}

FormIntegration.propTypes = propTypes;
FormIntegration.defaultProps = defaultProps;

export default FormIntegration;