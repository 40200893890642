import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import FontAwesome from 'react-fontawesome';

import { Select } from 'components/shared';

const propTypes = {
  actions: PropTypes.object.isRequired,
  refs: PropTypes.shape({
    chartAccountInvestorPropertyProxyTypes: PropTypes.array.isRequired,
    investorOptions: PropTypes.array.isRequired,
  }).isRequired,
  investors: PropTypes.array,
};

const defaultProps = {
  investors: [],
};

function InvestorsFields(props) {
  const { investors, size } = props;
  const { chartAccountInvestorPropertyProxyTypes, investorOptions } = props.refs;
  const { addInvestor, removeInvestor, changeInput } = props.actions;

  return (
    <div>
      { investors && investors.map((investor, index) => {
        return (
          <div className='row' key={index}>
            <div className='col-md-6'>
              <Select
                id='investorTargetId'
                name='investor[target_id]'
                includeBlank
                dataStateKey={`data.investors.${index}.targetId`}
                value={get(investor, 'targetId')}
                onChange={changeInput}
                options={investorOptions}
              />
            </div>
            <div className='col-md-3'>
              <Select
                id='investorType'
                name='investor[type]'
                dataStateKey={`data.investors.${index}.type`}
                value={get(investor, 'type')}
                onChange={changeInput}
                options={chartAccountInvestorPropertyProxyTypes}
              />
            </div>

            { investors.length > 1 &&
              <div className='col-md-2'>
                <span className="btn btn-danger btn-width_sm" onClick={() => removeInvestor(index)}>
                  <FontAwesome name='trash' />
                </span>
              </div>
            }
          </div>
        );
      })}
      <a href='#' className="btn btn-default" onClick={(e) => { e.preventDefault(); addInvestor(); }}>Add Investor</a>
    </div>
  );
}

InvestorsFields.propTypes = propTypes;
InvestorsFields.defaultProps = defaultProps;

export default InvestorsFields;
