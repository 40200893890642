import humps from 'humps';
import axios from 'axios';
import { get, omit } from 'lodash';
import numeral from 'numeral';

import scrollToFormAlerts from 'utils/scrollToFormAlerts';
import { stringToDate } from 'utils/dateTransformations';

import { onAddAccount, onUpdateAccount, onDeleteAccount } from 'components/shared/ChartOfAccounts/actions';
import * as types from './actionTypes';

export function fillFormAccount(data) {
  return {
    type: types.CHARTS_ACCOUNT_FORM_FILL,
    payload: {
      data,
    },
  };
}

export function toggleInvestorClass(id) {
  return {
    type: types.INVESTOR_CLASS_TOGGLE,
    payload: {
      id,
    },
  };
}

export function addInvestor() {
  return { type: types.INVESTOR_ADD };
}

export function removeInvestor(index) {
  return {
    type: types.INVESTOR_REMOVE,
    payload: {
      index,
    },
  };
}

export function toggleInvestorSeries(id) {
  return {
    type: types.INVESTOR_SERIES_TOGGLE,
    payload: {
      id,
    },
  };
}

export function clearAccount() {
  return {
    type: types.CHARTS_ACCOUNT_CLEAR,
  };
}

export function changeInput(event) {
  const target = event.target ? event.target : event;
  const stateKey =
    target.getAttribute('data-statekey') ||
    target.name
      .replace(/]/g, '')
      .split('[')
      .join('.');
  const type = target.getAttribute('data-type') || target.type;
  const targetValue = target.getAttribute('data-value') || target.value;

  const value = () => {
    switch (type) {
      case 'date':
        return stringToDate(targetValue);

      case 'currency':
        return numeral(targetValue).value();

      case 'checkbox':
        return target.checked;

      case 'number':
        if (target.step === '1') {
          return isNaN(parseInt(targetValue, 10)) ? targetValue : parseInt(targetValue, 10);
        }

        return isNaN(parseFloat(targetValue)) ? targetValue : parseFloat(targetValue);

      default:
        return targetValue;
    }
  };

  return {
    type: types.CHARTS_ACCOUNT_CHANGE_INPUT,
    payload: {
      stateKey,
      value: value(),
    },
  };
}

export function changeDateInput(event) {
  const target = event.target ? event.target : event;
  const value = target.value;
  const stateKey = target.getAttribute('data-statekey');

  return changeInputAction(
    stateKey ||
      target.name
        .replace(/]/g, '')
        .split('[')
        .join('.'),
    stringToDate(value),
  );
}

const changeInputAction = (stateKey, value) => ({
  type: types.CHARTS_ACCOUNT_CHANGE_INPUT,
  payload: {
    stateKey,
    value,
  },
});

export function onSubmit(e, fundId = undefined) {
  return (dispatch, getState) => {
    const { data } = getState().chartsAccountForm;
    const { formAttributes, investorClasses, investorSeries, investors } = data;

    e.preventDefault();

    let accountData = {
      code: data.code,
      name: data.name,
      type: data.type,
      reconciliation: data.reconciliation,
      cashReconciliation: data.cashReconciliation,
      adminFee: data.adminFee,
      applyToExistingFunds: data.applyToExistingFunds,
      taxAttributeId: data.taxAttributeId,
    };

    if (fundId) {
      accountData['initialBalance'] = data.initialBalance;
    }

    const formData = {
      account: humps.decamelizeKeys(accountData),
      form_attributes: formAttributes && humps.decamelizeKeys(formAttributes),
    };

    if (formAttributes && formAttributes.classSpecific === 'true') {
      if (formAttributes.classesAndSeries === 'false') {
        formData['investors'] = humps.decamelizeKeys(investors);
      } else {
        (formData['investor_classes'] = humps.decamelizeKeys(investorClasses)),
          (formData['investor_series'] = humps.decamelizeKeys(investorSeries));
      }
    }

    dispatch(
      fundId
        ? data.id
          ? submitForm(
              formData,
              Routes.fund_charts_category_account_path(fundId, data.categoryId, data.id, { format: 'json' }),
              'put',
            )
          : submitForm(
              formData,
              Routes.fund_charts_category_accounts_path(fundId, data.categoryId, { format: 'json' }),
              'post',
            )
        : data.id
        ? submitForm(formData, Routes.charts_category_account_path(data.categoryId, data.id, { format: 'json' }), 'put')
        : submitForm(formData, Routes.charts_category_accounts_path(data.categoryId, { format: 'json' }), 'post'),
    );
  };
}

export function deleteAccount(account, url) {
  return (dispatch) => {
    axios
      .request({
        url,
        method: 'delete',
        withCredentials: true,
        xsrfHeaderName: 'X-CSRF-Token',
      })
      .then(() => {
        dispatch(onDeleteAccount(account));
        dispatch(clearAccount());
      })
      .catch((res) => {
        dispatch(submittingFormFailed(humps.camelizeKeys(get(res.response.data.payload, 'errors'))));
        scrollToFormAlerts('dock');
      });
  };
}

function submitForm(data, url, method) {
  return (dispatch) => {
    dispatch(submittingForm(true));

    axios
      .request({
        url,
        method,
        data,
        withCredentials: true,
        xsrfHeaderName: 'X-CSRF-Token',
      })
      .then((res) => {
        dispatch(submittingForm(false));
        dispatch(submittingFormSucceded(humps.camelizeKeys(get(res.data.payload, 'account'))));
      })
      .catch((res) => {
        dispatch(submittingForm(false));
        dispatch(submittingFormFailed(humps.camelizeKeys(get(res.response.data.payload, 'errors'))));
        scrollToFormAlerts('dock');
      });
  };
}

function submittingForm(bool) {
  return {
    type: types.CHARTS_ACCOUNT_SUBMITTING_FORM,
    payload: {
      submitting: bool,
    },
  };
}

function submittingFormSucceded(account = {}) {
  return (dispatch) => {
    dispatch(onAddAccount(account));

    dispatch({
      type: types.CHARTS_ACCOUNT_SUBMITTING_FORM_SUCCEEDED,
    });

    dispatch(clearAccount());
  };
}

function submittingFormFailed(data = {}) {
  return {
    type: types.CHARTS_ACCOUNT_SUBMITTING_FORM_FAILED,
    payload: {
      errors: data,
    },
  };
}
