import { createSelector } from 'reselect';

const findPolicy = (state, name) => {
  const policy = state.policies[name];

  if (policy === undefined || policy === null) {
    return false;
  }

  return policy;
};

const getPolicy = createSelector(findPolicy, (policy) => policy);

export default getPolicy;
