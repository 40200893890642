import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import withReduxProvider from 'components/hocs/withReduxProvider';

import configureStore from './store';
import * as investorTransactionActions from './actions';
import FormComponent from './components/Form';

const propTypes = {
  resourceFund: PropTypes.object.isRequired,
  investorTransactionForm: PropTypes.shape({
    data: PropTypes.object.isRequired,
    meta: PropTypes.shape({
      errors: PropTypes.object,
      submitting: PropTypes.bool,
      valuationAmount: PropTypes.number,
    }),
    actions: PropTypes.shape({
      investorTransactionActions: PropTypes.object.isRequired,
    }),
  }).isRequired,
  refs: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  resourceFund: state.resourceFund,
  investorTransactionForm: {
    data: state.investorTransactionForm.data,
    meta: {
      ...state.investorTransactionForm.meta,
      errors: state.investorTransactionForm.meta.errors || {},
      submitting: state.investorTransactionForm.meta.submitting || false,
      lastValuation: get(state.investorTransactionForm.meta, 'lastValuation'),
      amountReceived: get(state.investorTransactionForm.meta, 'amountReceived') || 0,
    },
  },
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    investorTransactionActions: bindActionCreators(investorTransactionActions, dispatch),
  },
});

FormComponent.propTypes = propTypes;

export default withReduxProvider(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(FormComponent),
  configureStore,
);
