import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import MaskedInput from 'react-text-mask';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';

import enhanceWithClickOutside from 'react-click-outside';

import DayPicker from 'react-day-picker';

import FontAwesome from 'react-fontawesome';

import moment from 'moment';
import { dateToString, stringToDate } from 'utils/dateTransformations';

import { FormGroup } from '../';

const propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  dataStateKey: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  errors: PropTypes.array,
  guide: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  placeholderChar: PropTypes.string,
  required: PropTypes.bool,
  size: PropTypes.string,
  value: PropTypes.instanceOf(Date),
};

const defaultProps = {
  className: 'form__control',
  dataStateKey: null,
  disabled: false,
  errors: null,
  guide: true,
  label: false,
  placeholderChar: '_',
  required: false,
  size: '11',
  value: null,
};

const dateMaskPattern = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
const autoCorrectedDatePipe = createAutoCorrectedDatePipe('mm/dd/yyyy');

const overlayStyle = {
  position: 'absolute',
  zIndex: '1000',
  background: 'white',
  boxShadow: '0 2px 5px rgba(0, 0, 0, .15)',
};

class DateInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stringValue: dateToString(props.value),
      showOverlay: false,
    };

    this.handleCalendarClick = this.handleCalendarClick.bind(this);
    this.handleDayClick = this.handleDayClick.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const nextPropsStringValue = dateToString(nextProps.value);
    const stringValue = nextPropsStringValue || this.input.inputElement.value;

    return this.setState({
      stringValue,
    });
  }

  handleCalendarClick() {
    this.setState({
      showOverlay: true,
    });
  }

  handleClickOutside() {
    this.setState({
      showOverlay: false,
    });
  }

  handleDayClick(day) {
    const { onChange } = this.props;
    const elem = this.input.inputElement;

    this.setState({
      stringValue: dateToString(day),
      showOverlay: false,
    });

    this.clickTimeout = setTimeout(() => onChange(elem), 0);
  }

  render() {
    const {
      id,
      dataStateKey,
      required,
      size,
      name,
      value,
      label,
      className,
      guide,
      placeholderChar,
      onChange,
      errors,
      disabled,
    } = this.props;

    const addonClass = classNames({
      form__addon_icon: true,
      'form__addon_icon-disabled': disabled,
    });

    return (
      <FormGroup label={label} required={required} id={id} errors={errors}>
        <div className="form__addon">
          <MaskedInput
            ref={(el) => (this.input = el)}
            id={id}
            data-statekey={dataStateKey}
            data-type="date"
            required={required}
            size={size}
            name={name}
            value={this.state.stringValue}
            placeholder="mm/dd/yyyy"
            mask={dateMaskPattern}
            className={className}
            guide={guide}
            pipe={autoCorrectedDatePipe}
            placeholderChar={placeholderChar}
            disabled={disabled}
            onChange={onChange}
          />

          <span className={addonClass} onClick={disabled ? null : this.handleCalendarClick}>
            <FontAwesome name="calendar" />
          </span>
        </div>

        {this.state.showOverlay && (
          <div style={{ position: 'relative' }}>
            <div style={overlayStyle}>
              <DayPicker
                initialMonth={value || moment.utc().toDate()}
                selectedDays={value || null}
                onDayClick={this.handleDayClick}
              />
            </div>
          </div>
        )}
      </FormGroup>
    );
  }
}

DateInput.propTypes = propTypes;
DateInput.defaultProps = defaultProps;

export default enhanceWithClickOutside(DateInput);
