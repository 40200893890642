import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { accountPropTypes, fundPropTypes } from 'propTypes';
import { connect } from 'react-redux';
import { compose, pure } from 'recompose';
import classNames from 'classnames';

import { getEntityDetail } from 'selectors/entities';

import Drawer from '../../Drawer';
import Charts from '../../../Charts';
import FundCharts from '../../../Funds/Charts';

const propTypes = {
  fund: fundPropTypes,
  isError: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  selectedAccount: accountPropTypes,
};

const defaultProps = {
  fund: undefined,
  isError: false,
  selectedAccount: undefined,
};

function AccountSelect(props) {
  const { fund, isError, selectedAccount, onChange } = props;

  const btnClasses = classNames({
    btn: true,
    'btn-default': !selectedAccount,
    'btn-primary': selectedAccount,
    'btn-danger': isError,
  });

  return (
    <Fragment>
      <Drawer
        title="Select Account"
        render={({ onShow }) => (
          <button className={btnClasses} onClick={onShow} type="button">
            {selectedAccount ? selectedAccount.displayTitle : 'Select Account'}
          </button>
        )}
      >
        {({ onClose }) => {
          return fund ? (
            <FundCharts
              fundId={fund.id}
              onSelectAccount={(value) => {
                onChange(value);
                onClose();
              }}
            />
          ) : (
            <Charts
              onSelectAccount={(value) => {
                onChange(value);
                onClose();
              }}
            />
          );
        }}
      </Drawer>
    </Fragment>
  );
}

AccountSelect.propTypes = propTypes;
AccountSelect.defaultProps = defaultProps;

const mapStateToProps = (state, props) => {
  const { error, submitError, value, fund } = props;

  return {
    isError: error || submitError,
    selectedAccount: getEntityDetail(state, {
      entityName: fund ? 'accounts' : 'accountPatterns',
      entityId: value,
    }),
  };
};

const enhance = compose(
  connect(mapStateToProps),
  pure,
);

export default enhance(AccountSelect);
