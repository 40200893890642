import { Card, Button, Popconfirm, Tag, message } from 'antd';
import React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { useDispatch } from 'react-redux';

import {
  editDocumentsPattern,
  deleteDocumentsPattern,
  changeDocumentsPatternPosition,
} from 'actions/investorDocumentPatterns';
import { useAsyncFetchDefer } from 'utils/useAsyncFetch';

import styles from './documents.module.css';

function PatternCard(props) {
  const { index, id, title, position, fundType } = props;

  const ref = React.useRef(null);
  const dispatch = useDispatch();

  const [asyncChangePosition] = useAsyncFetchDefer(changeDocumentsPatternPosition);
  const [asyncDeletePattern] = useAsyncFetchDefer(deleteDocumentsPattern);

  const [{ isActive }, drop] = useDrop({
    accept: 'TaskListPatternCard',
    collect: (monitor) => {
      const { position: dragPosition } = monitor.getItem() || {};

      if (position === dragPosition) {
        return {};
      }

      return {
        isActive: monitor.canDrop() && monitor.isOver(),
      };
    },
    drop: (item) => (item.position !== position ? asyncChangePosition({ id: item.id, moveTo: position }) : null),
  });

  const [{ isDragging }, drag] = useDrag({
    item: { type: 'TaskListPatternCard', id, index, position },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drag(drop(ref));

  return (
    <div className={styles.pattern} ref={ref} style={{ opacity: isDragging ? 0.5 : 1 }}>
      <Card
        className={styles.card}
        style={{
          top: isActive ? 20 : 0,
          transform: isActive ? 'scale(0.9)' : 'none',
          opacity: isActive ? 0.7 : 1,
        }}
        hoverable
      >
        <span className={styles.cardTitle}>{position ? `#${position} - ${title}` : title}</span>
        <Tag color={fundType ? 'volcano' : null} key={fundType}>{fundType || 'No Fund Type'}</Tag>
        <div className={styles.cardFooter}>
          <Button type='link' icon="edit" onClick={() => dispatch(editDocumentsPattern({ id }))} />
          <Popconfirm
            placement="top"
            title='Delete Documents Pattern?'
            onConfirm={() => {
              asyncDeletePattern({ id });
              return message.info('Deleted.');
            }}
            okText="Confirm"
            cancelText="Close"
          >
            <Button type='link' icon="delete" />
          </Popconfirm>
        </div>
      </Card>
    </div>
  );
}

export default PatternCard;
