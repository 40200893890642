import { createSelector } from 'reselect';

const bandsSelector = (state) => state.bands;

export const isDisabled = createSelector(bandsSelector, (bands) => {
  const lastBand = bands[bands.length - 1];

  return lastBand && (
    isNaN(parseFloat(lastBand.lowerBoundPercentage))
    || isNaN(parseFloat(lastBand.upperBoundPercentage))
    || isNaN(parseFloat(lastBand.rate))
  );
});
