import { forEach, map, filter } from 'ramda';

import * as actionTypes from 'constants/actionTypes';
import { camelizeKeys } from 'utils/humps';

import AccountsApi from '../../../apiAdapters/funds/accounts';
import { normalizeAccounts, normalizeAssociates } from '../../../normalizrs/accounts';
import { deleteEntities, mergeEntities } from '../../entities';

export const deleteAccount = (fundId, id) => (dispatch) => {
  AccountsApi.delete(fundId, id)
    .then(() => {
      dispatch({ type: 'DELETE_ACCOUNT' });
      dispatch(deleteEntities(id, 'accounts'));
    })
    .catch((error) => console.log(error));
};

export const inviteAccounts = (values) => (dispatch, getState) => {
  const { fund: { details: { id: fundId } }, entities: { accounts, associates } } = getState();
  const accountIds = [];
  const associateIds = [];

  forEach(
    (value) => {
      const [type, id] = value.split('-');

      if (type === 'investor') {
        if (!accounts[id].process) {
          accountIds.push(id);
        }
      } else if (type === 'associate') {
        if (!associates[id].process) {
          associateIds.push(id);
        }
      }
    },
    values,
  );

  AccountsApi.invite(fundId, { accounts: accountIds, associates: associateIds })
    .then(() => {
      dispatch({ type: 'START_INVITE_ACCOUNTS' });

      dispatch(mergeEntities(normalizeAccounts(map(
        (accountId) => ({
          ...accounts[accountId],
          process: true,
        }),
        accountIds,
      )).entities));

      dispatch(mergeEntities(normalizeAssociates(map(
        (associateId) => ({
          ...associates[associateId],
          process: true,
        }),
        associateIds,
      )).entities));
    })
    .catch((error) => console.log(error));
};

export const inviteAccountDone = (data) => (dispatch, getState) => {
  const { fund: { details: { id: fundId } } } = getState();

  dispatch({ type: 'INVITE_ACCOUNT_DONE' });

  dispatch(mergeEntities(normalizeAccounts(map(
    (account) => ({ ...account, process: false }),
    filter((account) => account.fundId === fundId, camelizeKeys(data.accounts || [])),
  )).entities));

  dispatch(mergeEntities(normalizeAssociates(map(
    (associate) => ({ ...associate, process: false }),
    filter((associate) => associate.fundId === fundId, camelizeKeys(data.associates || [])),
  )).entities));
};

export function fetchFundAccounts({ fundId }) {
  return (dispatch) => AccountsApi.get(fundId).then((accounts) => {
    dispatch({ type: actionTypes.FUND_ACCOUNTS_FETCH_SUCCESS, payload: { accounts } });
  }).catch((error) => console.log(error));
}
