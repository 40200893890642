import React, { Component } from 'react';
import { connect } from 'react-redux';

import getPolicy from './selectors';

class PolicyComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { apply: false };
  }

  componentDidMount() {
    this.setState((state, props) => ({ apply: !props.policy }));
  }

  render() {
    const { type, children } = this.props;
    const { apply } = this.state;

    if (apply && type === 'hide') {
      return null;
    }

    return children;
  }
}

const mapStateToProps = (state, props) => ({
  policy: getPolicy(state, props.name),
});

export default connect(mapStateToProps)(PolicyComponent);
