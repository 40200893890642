import React from 'react';
import PropTypes from 'prop-types';
import { Form as FinalForm } from 'react-final-form';
import { connect } from 'react-redux';

import { getAccrualPaymentItems } from 'selectors/funds/accruals';
import * as actions from 'actions/funds/accruals';
import { paymentsItemsinitialValues } from 'propTypes';
import EditableTable from './shared/Table';

const propTypes = {
  handleCreate: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({ paymentItems: paymentsItemsinitialValues }).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

function FundsAccrualsPaymentItemsContainer(props) {
  const { onSubmit, initialValues, handleCreate } = props;

  return (
    <FinalForm
      onSubmit={onSubmit}
      render={({ dirtySinceLastSubmit, form, handleSubmit }) => {
        return (
          <form onSubmit={handleSubmit}>
            <EditableTable
              handleCreate={handleCreate}
              handleSubmit={onSubmit}
              dirtySinceLastSubmit={dirtySinceLastSubmit}
            />
          </form>
        );
      }}
      initialValues={initialValues}
      handleCreate={handleCreate}
    />
  );
}

const mapStateToProps = (state) => {
  const accrualPaymentsItems = getAccrualPaymentItems(state);

  return {
    initialValues: { paymentItems: accrualPaymentsItems },
    accrualPaymentsItems,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { accrualId } = ownProps.match.params;

  return {
    onSubmit: (id) => dispatch(actions.patchPaymentItem(id)),
    handleCreate: (values) => dispatch(actions.createPaymentItem(values, accrualId)),
  };
};

FundsAccrualsPaymentItemsContainer.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(FundsAccrualsPaymentItemsContainer);
