import set from 'lodash/fp/set';
import * as types from './actionTypes';

const initialState = {
  resourceFund: {},
  tradeExerciseForm: {
    data: {},
    meta: {
      errors: {},
      submitting: false,
    },
  },
};

const tradeExercise = (state = initialState, action) => {
  switch (action.type) {
    case types.SECURITY_UPDATE:
      return {
        ...state,
        tradeExerciseForm: {
          ...state.tradeExerciseForm,
          data: {
            ...state.tradeExerciseForm.data,
            securityId: action.payload.securityId,
          },
        },
        security: {
          ...state.security,
          ...action.payload.security,
        },
      };

    case types.TRADE_DATE_UPDATE:
      return {
        ...state,
        tradeExerciseForm: {
          ...state.tradeExerciseForm,
          data: {
            ...state.tradeExerciseForm.data,
            tradeDate: action.payload.value,
            signedQuantity: undefined,
          },
        },
      };

    case types.CLEAR_REALIZATIONS_DATA:
      return {
        ...state,
        tradeExerciseForm: {
          ...state.tradeExerciseForm,
          data: {
            ...state.tradeExerciseForm.data,
            exercisePrice: undefined,
            realization_taxlot_realizations: undefined,
          },
        },
        realizations: undefined,
      };

    case types.CLEAR_SECURITY_DATA:
      return {
        ...state,
        tradeExerciseForm: {
          ...state.tradeExerciseForm,
          data: {
            ...state.tradeExerciseForm.data,
            securityId: undefined,
          },
        },
        security: undefined,
      };

    case types.CHANGE_INPUT:
      return set(action.payload.stateKey, action.payload.value)(state);

    case types.CURRENT_HOLDINGS_FOR_SECURITY_FETCH_REQUESTED:
      return {
        ...state,
        security: {
          ...state.security,
          currentHoldingsLoading: true,
        },
      };

    case types.CURRENT_HOLDINGS_FOR_SECURITY_FETCH_COMPLETED: {
      return {
        ...state,
        tradeExerciseForm: {
          ...state.tradeExerciseForm,
          data: {
            ...state.tradeExerciseForm.data,
            type: fetchExerciseType(action.payload.currentHoldings),
          },
        },
        security: {
          ...state.security,
          currentHoldings: action.payload.currentHoldings,
          currentHoldingsLoading: undefined,
          totalBuys: action.payload.totalBuys,
          totalSells: action.payload.totalSells,
          pricingDate: action.payload.pricingDate,
          withSecurityPrice: action.payload.withSecurityPrice,
          securityPrice: action.payload.securityPrice,
        },
      };
    }

    case types.CURRENT_HOLDINGS_FOR_UNDERLYING_INVESTMENT_FETCH_REQUESTED:
      return {
        ...state,
        security: {
          ...state.security,
          underlyingInvestment: {
            ...state.security.underlyingInvestment,
            currentHoldingsLoading: true,
          },
        },
      };

    case types.CURRENT_HOLDINGS_FOR_UNDERLYING_INVESTMENT_FETCH_COMPLETED:
      return {
        ...state,
        security: {
          ...state.security,
          underlyingInvestment: {
            ...state.security.underlyingInvestment,
            currentHoldings: action.payload.currentHoldings,
            currentHoldingsLoading: undefined,
          },
        },
      };

    case types.SECURITIES_FETCH_REQUESTED:
      return {
        ...state,
        securities: {
          loading: true,
        },
      };

    case types.SECURITIES_FETCH_SUCCEEDED:
      return {
        ...state,
        securities: {
          data: action.payload.securities,
        },
      };

    case types.SECURITIES_FETCH_FAILED:
      return {
        ...state,
        securities: {
          error: action.payload.errorText,
        },
      };

    case types.REALIZATIONS_FETCH_REQUESTED:
      return {
        ...state,
        realizations: {
          loading: true,
        },
      };

    case types.REALIZATIONS_FETCH_SUCCEEDED:
      return {
        ...state,
        realizations: action.payload.realizations,
        tradeExerciseForm: {
          ...state.tradeExerciseForm,
          data: {
            ...state.tradeExerciseForm.data,
            realizationTaxlotRealizations: action.payload.realizations.trades.map((el) => ({ realizedTradeId: el.realizedTradeId, type: el.type, realizedQuantity: el.realizedQuantity, securityId: el.securityId })),
            exercisePrice: action.payload.realizations.exercisePrice,
            additionalPrice: action.payload.realizations.additionalPrice,
          },
        },
      };

    case types.REALIZATIONS_FETCH_FAILED:
      return {
        ...state,
        realizations: {
          ...state.realizations,
          error: action.payload.errorText,
        },
      };

    case types.SUBMITTING_FORM:
      return {
        ...state,
        submitting: action.payload.submitting,
        success: undefined,
      };

    case types.SUBMITTING_FORM_SUCCEEDED:
      return {
        ...state,
        errors: undefined,
        success: action.payload.success,
      };

    case types.SUBMITTING_FORM_FAILED:
      return {
        ...state,
        errors: action.payload.errors,
        success: undefined,
      };

    case types.CLEAR_FORM:
      return {
        ...action.payload.state,
      };

    case types.MAKE_SETTLE_FX_RATE_EQUAL_TO_TRADE_FX_RATE:
      return {
        ...state,
        tradeExerciseForm: {
          ...state.tradeExerciseForm,
          data: {
            ...state.tradeExerciseForm.data,
            settleFxRate: state.tradeExerciseForm.data.tradeFxRate,
          },
        },
      }

    case types.SET_FX_RATE:
      return {
        ...state,
        tradeExerciseForm: {
          ...state.tradeExerciseForm,
          data: {
            ...state.tradeExerciseForm.data,
            tradeFxRate: action.payload.fxRate
          }
        }
      }

    case types.CONVERT_FEES_BY_FX_RATE:
      return {
        ...state,
        tradeExerciseForm: {
          ...state.tradeExerciseForm,
          data: {
            ...state.tradeExerciseForm.data,
            commissionFee: action.payload.commissionFee,
            brokerFee: action.payload.brokerFee,
            secFee: action.payload.secFee,
            otherFee: action.payload.otherFee,
            taxes: action.payload.taxes
          }
        }
      }

    default:
      return state;
  }
};

function fetchExerciseType(currentHoldings) {
  if (currentHoldings > 0) {
    return 'exercise';
  }

  if (currentHoldings < 0) {
    return 'assign';
  }

  return undefined;
}

export default tradeExercise;
