import * as types from './actionTypes';

const initialState = {};

export default function(state = initialState, action) {
  switch (action.type) {
    case types.ACCOUNTS_FETCH_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case types.INVESTOR_SERIES_FETCH_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case types.INVESTOR_CLASSES_FETCH_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case types.ACCOUNTS_FETCH_COMPLETED:
      return {
        ...state,
        accounts: action.payload.accounts,
        loading: undefined,
      };

    case types.INVESTOR_SERIES_FETCH_COMPLETED:
      return {
        ...state,
        investorSeries: action.payload,
        loading: undefined,
      };

    case types.INVESTOR_CLASSES_FETCH_COMPLETED:
      return {
        ...state,
        investorClasses: action.payload,
        loading: undefined,
      };

    default:
      return state;
  }
}
