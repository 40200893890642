export * from 'constants/actionTypes';

export const ACCOUNT_SELECT = 'ACCOUNT_SELECT';
export const ACCOUNTING_DATE_INPUT_CHANGE = 'ACCOUNTING_DATE_INPUT_CHANGE';
export const ALLOCATED_AMOUNTS_TOGGLE = 'ALLOCATED_AMOUNTS_TOGGLE';
export const ALLOCATED_AMOUNTS_SET_TYPE = 'ALLOCATED_AMOUNTS_SET_TYPE';
export const AMOUNT_CHANGE = 'AMOUNT_CHANGE';

export const CURRENCY_INPUT_CHANGE = 'CURRENCY_INPUT_CHANGE';

export const ENTRY_CHANGE = 'ENTRY_CHANGE';
export const ENTRY_SET = 'ENTRY_SET';
export const ENTRY_NEED_BE_UPDATED = 'ENTRY_NEED_BE_UPDATED';

export const DESCRIPTION_CHANGE = 'DESCRIPTION_CHANGE';

export const LINE_ITEM_ADD = 'LINE_ITEM_ADD';
export const LINE_ITEM_SPECIAL_ALLOCATION_SET = 'LINE_ITEM_SPECIAL_ALLOCATION_SET';
export const LINE_ITEM_SPECIAL_ALLOCATION_TOTAL_SET = 'LINE_ITEM_SPECIAL_ALLOCATION_TOTAL_SET';
export const LINE_ITEM_SPECIAL_ALLOCATION_TOTAL_ERROR_SET = 'LINE_ITEM_SPECIAL_ALLOCATION_TOTAL_ERROR_SET';
export const LINE_ITEM_SPECIAL_ALLOCATION_TOTAL_ERROR_CLEAR = 'LINE_ITEM_SPECIAL_ALLOCATION_TOTAL_ERROR_CLEAR';
export const LINE_ITEM_REMOVE = 'LINE_ITEM_REMOVE';
