import * as R from 'ramda';

import { accountNormalizr, chartNormalizr } from 'normalizrs';
import { history } from 'stores/configureStore';

import { throwSubmissionError } from 'utils/errors';
import { FundsApi } from 'apiAdapters';

import * as actionTypes from 'constants/actionTypes';
import * as requestTypes from 'constants/requestTypes';
import { CHART_OF_ACCOUNTS_CHARTS_FETCH_SUCCESS } from 'constants/actionTypes/chartOfAccounts';

import { getFundChartsCollapsedKeys, getFundChartsCategoryAndAccountKeys } from 'selectors/funds';

import setRequestInProcess from '../request';
import { deleteEntities, mergeEntities } from '../entities';

export function fetchFundCharts(params) {
  const requestType = requestTypes.FUND_CHARTS_FETCH;

  return (dispatch) => {
    dispatch(setRequestInProcess(true, requestType));

    return FundsApi.getFundCharts(params).then((data) => {
      const { entities } = chartNormalizr(data);

      dispatch(fetchChartOfAccountsSuccess(entities));
      dispatch(setRequestInProcess(false, requestType));
    });
  };
}

export function fetchFundChartsCategory(params) {
  const requestType = requestTypes.FUND_CHARTS_CATEGORY_FETCH;

  return (dispatch) => {
    dispatch(setRequestInProcess(true, requestType));

    return FundsApi.getFundChartsCategory(params).then((category) => {
      dispatch(setRequestInProcess(false, requestType));
      return category;
    });
  };
}

export function createFundChartsCategory(values, params) {
  const { fundId } = params;

  return FundsApi.submitFundChartsCategory(values, params)
    .then(() => history.push(Routes.fund_charts_path(fundId)))
    .catch((res) => throwSubmissionError(res));
}

export function updateFundChartsCategory(values, params) {
  const { fundId } = params;

  return FundsApi.submitFundChartsCategory(values, params)
    .then(() => history.push(Routes.fund_charts_path(fundId)))
    .catch((res) => throwSubmissionError(res));
}

export function destroyFundChartsCategory(params) {
  const { fundId } = params;

  return FundsApi.deleteFundChartsCategory(params)
    .then(() => history.push(Routes.fund_charts_path(fundId)))
    .catch((res) => throwSubmissionError(res));
}

function prepareKeysForUpdate(keys, state) {
  if (R.isNil(keys)) {
    if (R.isEmpty(getFundChartsCollapsedKeys(state))) {
      return getFundChartsCategoryAndAccountKeys(state);
    }

    return [];
  }

  return keys;
}

export function updateFundChartsExpandedKeys(keys) {
  return (dispatch, getState) => {
    const state = getState();

    dispatch({
      type: actionTypes.FUND_CHARTS_EXPANDED_KEYS_UPDATE,
      payload: prepareKeysForUpdate(keys, state),
    });
  };
}

export function fetchFundChartsCategoryAccount(params) {
  const requestType = requestTypes.FUND_CHARTS_CATEGORY_ACCOUNT_FETCH;

  return (dispatch) => {
    dispatch(setRequestInProcess(true, requestType));

    return FundsApi.getFundChartsCategoryAccount(params).then((account) => {
      dispatch(setRequestInProcess(false, requestType));
      return account;
    });
  };
}

export function createFundChartsCategoryAccount(values, params) {
  const { fundId } = params;

  return FundsApi.submitFundChartsCategoryAccount(values, params)
    .then(() => history.push(Routes.fund_charts_path(fundId)))
    .catch((res) => throwSubmissionError(res));
}

export function updateFundChartsCategoryAccount(values, params) {
  const { fundId } = params;

  return FundsApi.submitFundChartsCategoryAccount(values, params)
    .then(() => history.push(Routes.fund_charts_path(fundId)))
    .catch((res) => throwSubmissionError(res));
}

export function destroyFundChartsCategoryAccount(params) {
  const { fundId } = params;

  return FundsApi.deleteFundChartsCategoryAccount(params)
    .then(() => history.push(Routes.fund_charts_path(fundId)))
    .catch((res) => throwSubmissionError(res));
}

export function createFundChartsMapping(values, params) {
  const { fundId } = params;

  return FundsApi.submitFundChartsMapping(values, params)
    .then(() => history.push(Routes.fund_charts_mappings_path(fundId)))
    .catch((res) => throwSubmissionError(res));
}

export function destroyFundChartsMapping(params) {
  return (dispatch) => {
    return FundsApi.deleteFundChartsMapping(params).then((data) => {
      const { id } = params;
      const { entities } = accountNormalizr(data);

      dispatch(mergeEntities(entities));
      dispatch(deleteEntities(id, 'mappings'));
    });
  };
}

export function updateFundChartsSearchValue(value) {
  return {
    type: actionTypes.FUND_CHARTS_SEARCH_VALUE_UPDATE,
    payload: {
      searchValue: value,
    },
  };
}

export function updateFundChartsSecurityFilters(values) {
  return {
    type: actionTypes.FUND_CHARTS_SECURITY_FILTERS_UPDATE,
    payload: {
      values,
    },
  };
}

function fetchFundChartsSuccess(payload) {
  return {
    type: actionTypes.FUND_CHARTS_FETCH_SUCCESS,
    payload: {
      types: payload,
    },
  };
}

function fetchChartOfAccountsSuccess(payload) {
  return {
    type: CHART_OF_ACCOUNTS_CHARTS_FETCH_SUCCESS,
    payload: {
      types: payload,
    },
  };
}
