import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

const defaultProps = {};

const BlankLink = ({ title, link }) => (
  <a
    className='ant-link'
    target='_blank'
    rel='noopener noreferrer'
    href={link}
  >
    {title}
  </a>
);

BlankLink.propTypes = propTypes;
BlankLink.defaultProps = defaultProps;

export default BlankLink;
