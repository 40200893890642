import React from 'react';
import PropTypes from 'prop-types';

import { FormGroup } from '../';

const propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['text', 'number']).isRequired,
  step: PropTypes.string,
  name: PropTypes.string.isRequired,
  dataStateKey: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  required: PropTypes.bool,
  autofocus: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  errors: PropTypes.array,
};

const defaultProps = {
  type: 'text',
  disabled: false,
  value: '',
  autofocus: false,
  onChange: undefined,
  required: false,
  placeholder: undefined,
  label: undefined,
  dataStateKey: undefined,
  step: undefined,
};

const Input = (props) => {
  const {
    id,
    step,
    type,
    name,
    dataStateKey,
    value,
    label,
    required,
    placeholder,
    onChange,
    errors,
    autofocus,
    checked,
    disabled,
  } = props;

  return (
    <FormGroup label={label} required={required} id={id} errors={errors}>
      <input
        id={id}
        type={type}
        step={step}
        required={required}
        name={name}
        value={value}
        data-statekey={dataStateKey}
        placeholder={placeholder}
        className="form__control"
        onChange={onChange}
        checked={checked}
        autoFocus={autofocus}
        disabled={disabled}
      />
    </FormGroup>
  );
};

Input.propTypes = propTypes;
Input.defaultProps = defaultProps;

export default Input;
