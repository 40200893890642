import numeral from 'numeral';
import { dateToString } from 'utils/dateTransformations';

export const equalCreditAndDebitAmounts = (lineItems) => {
  return creditAmount(lineItems).toFixed(5) === debitAmount(lineItems).toFixed(5);
};

export const creditAmount = (lineItems) => {
  return lineItems.reduce((total, lineItem) => total + numeral(lineItem.creditAmount).value(), 0);
};

export const debitAmount = (lineItems) => {
  return lineItems.reduce((total, lineItem) => total + numeral(lineItem.debitAmount).value(), 0);
};

export const buildEntry = (lineItems, currency) => {
  const entryArray = lineItems.reduce((entryArr, lineItem) => {
    if (!lineItem.accountName || !(lineItem.debitAmount || lineItem.creditAmount)) {
      return entryArr;
    }

    const str = {
      accountName: lineItem.accountName,
      amount: lineItem.debitAmount || lineItem.creditAmount,
      type: lineItem.debitAmount ? 'DR' : 'CR',
    };

    if (lineItem.specialAllocation) {
      str.specialAllocationType = lineItem.specialAllocationType
        ? lineItem.specialAllocationType.toUpperCase()
        : 'AUTO';
    }

    entryArr.push(str);

    return entryArr;
  }, []);

  return entryArray
    .map((item) => {
      const resultArr = [item.type, item.accountName, currency + ' ' + numeral(item.amount).format('0,0.00')];

      if (item.specialAllocationType) {
        resultArr.push(item.specialAllocationType);
      }

      return resultArr.join(' - ');
    })
    .join('\n');
};

export const fetchPrice = (fundId, priceType, currencyIso, fundBaseCurrencyIso, accountingDate) => {
  let result = {
    rate: 1,
    createdAt: '-',
  };

  if (currencyIso === fundBaseCurrencyIso) {
    result.rate = 1;
  } else {
    $.ajax({
      url: Routes.fx_rate_path({
        search: {
          fund_id: fundId,
          buy_currency_iso: fundBaseCurrencyIso,
          sell_currency_iso: currencyIso,
          date: dateToString(accountingDate),
          price_type: priceType || 'close',
        },
      }),
      type: 'GET',
      dataType: 'json',
      async: false,
      error: function(callback) {
        return callback;
      },
      success: function(res) {
        if (res !== undefined) {
          result.rate = res.rate;
          result.createdAt = res.date;
        }
      },
    });
  }
  return result;
};
