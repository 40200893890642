import React from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { configureStore, history } from 'stores/configureStore';
import CableApp from '../services/CableApp';
import RootRoutes from './Routes';

const cableApp = new CableApp(process.env.RAILS_ENV);

// eslint-disable-next-line react/prop-types
const App = ({ state, refs }) => (
  <DndProvider backend={HTML5Backend}>
    <Provider store={configureStore(state)}>
      <ConnectedRouter history={history}>
        <RootRoutes refs={refs} cableApp={cableApp} />
      </ConnectedRouter>
    </Provider>
  </DndProvider>
);

export default App;
