import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  errors: PropTypes.array,
};

const FormError = (props) => {
  const { errors } = props;

  return (
    errors ?
    <p className='form__error'>
      { errors[0] }
    </p> : null
  );
};

FormError.propTypes = propTypes;

export default FormError;
