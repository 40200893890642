import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { accounting } from 'accounting';
import { get, map, forEach } from 'lodash';
import cx from 'classnames';

import LocaleProvider from 'antd/lib/locale-provider';
import Table from 'antd/lib/table';
import Modal from 'antd/lib/modal';
import enUS from 'antd/lib/locale-provider/en_US';

import { CurrencyInput, Input, RadioGroup } from 'components/shared';

import { actions, selectors } from './data';

const propTypes = {
  parentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  actions: PropTypes.object.isRequired,
  amount: PropTypes.number.isRequired,
  allocatedAmountsTree: PropTypes.array,
  allocatedAmounts: PropTypes.array,
  investorClasses: PropTypes.array,
  investorSeries: PropTypes.array,
  accounts: PropTypes.array,
  currencySymbol: PropTypes.string,
  date: PropTypes.instanceOf(Date),
  specialAllocationType: PropTypes.string,
};

const defaultProps = {
  allocatedAmountsTree: [],
  allocatedAmounts: [],
  currencySymbol: '$',
  investorClasses: [],
  investorSeries: [],
  accounts: [],
  date: undefined,
  specialAllocationType: 'auto',
};

class AllocatedAmounts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allocatedAmountType: props.specialAllocationType || 'auto',
      searchText: '',
      selectAllModal: false,
      massSelectable: null,
      data: props.allocatedAmountsTree,
    };

    this.getFilteredTree = this.getFilteredTree.bind(this);
    this.addHighlightToName = this.addHighlightToName.bind(this);
    this.showSelectAllModal = this.showSelectAllModal.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.clearSearchText = this.clearSearchText.bind(this);
    this.setMassSelectable = this.setMassSelectable.bind(this);
    this.setAllocatedAmountType = this.setAllocatedAmountType.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.allocatedAmountsTree !== nextProps.allocatedAmountsTree) {
      this.setState({
        data: nextProps.allocatedAmountsTree,
      });
    }
  }

  onInputChange = (e) => {
    const { value } = e.target;

    this.setState({
      searchText: value,
      data: this.getFilteredTree(this.props.allocatedAmountsTree, value),
    });
  };

  getFilteredTree = (tree, value) => {
    const reg = new RegExp(value, 'gi');

    return tree
      .map((record) => {
        const match = String(record.name).match(reg);

        if (!match) {
          if (record.selected) {
            return record;
          }

          if (record.children && record.children.length > 0) {
            if (this.getFilteredTree(record.children, value).length > 0) {
              return {
                ...record,
                children: this.getFilteredTree(record.children, value),
                name: this.addHighlightToName(record.name, reg, match),
              };
            }
          }

          return null;
        }

        return {
          ...record,
          name: this.addHighlightToName(record.name, reg, match),
        };
      })
      .filter((record) => record);
  };

  clearSearchText = () => {
    this.setState({
      searchText: '',
    });
  };

  setMassSelectable = (type) => {
    if (this.state.massSelectable !== null) {
      this.props.actions.unSelectAllAllocatedAmount(this.props.parentId);
    }

    let array = [];

    if (type === 'Fund::InvestorClass') {
      array = this.props.investorClasses;
    }

    if (type === 'Fund::InvestorSeries') {
      array = this.props.investorSeries;
    }

    if (type === 'Fund::Account') {
      array = this.props.accounts;
    }

    forEach(array, (allocatedAmount) => {
      this.props.actions.toggleAllocatedAmount({
        ...allocatedAmount,
        parentId: this.props.parentId,
      });
    });

    this.setState({
      massSelectable: type,
    });

    this.clearSearchText();
    this.handleCancel();
  };

  showSelectAllModal = (targetType, targetId) => {
    this.setState({
      selectAllModal: true,
      selectAllModalTargetType: targetType,
      selectAllModalTargetId: targetId,
    });
  };

  handleCancel = (e) => {
    this.setState({
      selectAllModal: false,
    });
  };

  addHighlightToName = (name, reg, match) => (
    <span>
      {String(name)
        .split(reg)
        .map((text, i) => (i > 0 ? [<span style={{ color: '#f50', background: '#FFFFD8' }}>{match[0]}</span>, text] : text))}
    </span>
  );

  setAllocatedAmountType = (type) => {
    this.setState({
      allocatedAmountType: type,
    });
  };

  render() {
    const {
      allocatedAmountsTotal,
      allocatedAmounts,
      parentId,
      currencySymbol,
      amount,
      actions,
      onChangeType,
      accounts,
    } = this.props;

    const { allocatedAmountType } = this.state;

    const { changeAllocationAmount, toggleAllocatedAmount } = actions;

    const allocatedAmountIds = map(allocatedAmounts, '_id');

    const columns = [
      {
        title: 'Name',
        key: 'name',
        dataIndex: 'name',
        width: '30%',
        render: (text) => ({
          props: {
            style: { whiteSpace: 'nowrap' },
          },
          children: text,
        }),
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
        width: '30%',
        render: (text, record) => record.selected && (
          <CurrencyInput
            id={`allocated_amounts${record.indexOfRecord}`}
            name="allocated_amounts[amount][]"
            required
            currency={currencySymbol}
            value={record.amount}
            onChange={(e) => changeAllocationAmount(e, record.indexOfRecord)}
            disabled={onChangeType && allocatedAmountType === 'auto'}
          />
        ),
      },
      {
        title: 'Special',
        dataIndex: 'special',
        key: 'special',
        render: (text, record) => record.selected
          && record.special && (
            <input
              id={`allocated_amounts_${record.indexOfRecord}_special`}
              name="allocated_amounts[special][]"
              checked={record.special}
              type="checkbox"
              disabled
            />
          ),
      },
    ];

    const rowSelection = {
      selectedRowKeys: allocatedAmountIds,
      onSelect: (record, selected, selectedRows) => {
        const allocatedAmount = {
          ...record,
          selected,
          parentId,
        };
        toggleAllocatedAmount(
          {
            ...allocatedAmount,
            parentId,
          },
          allocatedAmountType,
        );
        this.clearSearchText();
      },
      onSelectAll: (selected, selectedRows, changeRows) => {
        this.showSelectAllModal(selected.targetType, selected.targetId);
      },
    };

    const amountsTotal = accounting.formatNumber(amount, 2);
    const formatAllocatedAmountsTotal = accounting.formatNumber(allocatedAmountsTotal, 2);

    const alertSuccess = amountsTotal == formatAllocatedAmountsTotal
      || (
        this.state.allocatedAmountType === 'auto'
        && accounts.filter((item) => item.selected).length > 1
      );
    const alertClasses = cx('alert', 'alert-small',
      { 'alert-success': alertSuccess },
      { 'alert-danger': !alertSuccess },
    );

    return (
      <div>
        <Input
          id="allocatedAmount_search"
          name="allocatedAmount[search]"
          autofocus
          value={this.state.searchText}
          placeholder="Find by name …"
          onChange={this.onInputChange}
        />
        <br />

        {onChangeType && (
          <div style={{ paddingBottom: 15 }}>
            <RadioGroup
              id="allocatedAmount_type"
              dataStateKey="allocatedAmount_type"
              name="allocatedAmountType"
              value={this.state.allocatedAmountType}
              onChange={(e) => {
                this.setAllocatedAmountType(e.target.value);
                onChangeType(parentId, e.target.value);
              }}
              options={[
                { label: 'Auto Allocation based on Ownership %', value: 'auto' },
                { label: 'Manual Allocation', value: 'manual' },
              ]}
            />
          </div>
        )}

        <LocaleProvider locale={enUS}>
          <div>
            <Table
              columns={columns}
              rowSelection={rowSelection}
              rowKey="_id"
              indentSize={20}
              dataSource={this.state.data}
              pagination={false}
              defaultExpandAllRows
              size="small"
            />
            <Modal
              title="What do you want to select?"
              footer={false}
              visible={this.state.selectAllModal}
              onCancel={this.handleCancel}
            >
              <RadioGroup
                id="select_all_type"
                dataStateKey="select_all_type"
                name="selectAllType"
                value={this.state.massSelectable}
                onChange={(e) => {
                  e.preventDefault();
                  this.setMassSelectable(e.target.value);
                }}
                options={[
                  { label: 'All Investor Classes', value: 'Fund::InvestorClass' },
                  { label: 'All Investor Series', value: 'Fund::InvestorSeries' },
                  { label: 'All Accounts', value: 'Fund::Account' },
                ]}
              />
            </Modal>
            <br />
            <div className={alertClasses}>
              <table className="table table-auto table-centered">
                <tbody>
                  <tr>
                    <td>
                      Total To Allocate -
                      {accounting.formatMoney(amountsTotal, currencySymbol, 2, ',', '.')}
                    </td>
                    <td>
                      Allocated -
                      {accounting.formatMoney(formatAllocatedAmountsTotal, currencySymbol, 2, ',', '.')}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </LocaleProvider>
      </div>
    );
  }
}

const makeMapStateToProps = () => {
  const getAllocatedAmountsTree = selectors.makeGetAllocatedAmountsTree();
  const getAllocatedAmounts = selectors.makeGetAllocatedAmounts();

  const mapStateToProps = (state, props) => ({
    allocatedAmounts: get(getAllocatedAmounts(state, props), 'selected'),
    investorClasses: selectors.getInvestorClassesSelector(state, props),
    investorSeries: selectors.getInvestorSeriesSelector(state, props),
    accounts: selectors.getAccountsWithPercentageSelector(state, props),
    allocatedAmountsTree: getAllocatedAmountsTree(state, props),
    allocatedAmountsTotal: selectors.getAllocatedAmountsSum(state, { parentId: props.parentId }),
  });

  return mapStateToProps;
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

AllocatedAmounts.propTypes = propTypes;
AllocatedAmounts.defaultProps = defaultProps;

export { actionTypes, actions, reducer } from './data';

export default connect(makeMapStateToProps, mapDispatchToProps)(AllocatedAmounts);
