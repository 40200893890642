import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { FormError, FormLabel } from './';

const propTypes = {
  // children: PropTypes.element.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  errors: PropTypes.array,
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  required: PropTypes.bool,
};

const defaultProps = {
  errors: null,
  id: null,
  label: false,
  required: false,
};

const FormGroup = (props) => {
  const { children, errors, id, label, required } = props;

  const classes = classNames({
    form__group: true,
    'form__group-errored': errors,
  });

  return (
    <div className={classes}>
      <FormLabel label={label} required={required} htmlFor={id} />
      {children}
      <FormError errors={errors} />
    </div>
  );
};

FormGroup.propTypes = propTypes;
FormGroup.defaultProps = defaultProps;

export default FormGroup;
