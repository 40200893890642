import React, { useState } from 'react';
import PropTypes from 'prop-types';
import humps from 'humps';
import { selectOptionsPropTypes, selectOptionsNumbersPropTypes } from 'propTypes';
import { Field } from 'react-final-form';
import { Alert, Button, Col, Form, Row } from 'antd';
import { connect } from 'react-redux';

import SelectChartOfAccount from 'components/shared/SelectChartOfAccount';
import FormGroup from 'components/shared/form/FormGroup';
import FormStateToRedux from 'components/shared/FormStateToRedux';
import { getSubmitBtnText } from 'utils/accruals';
import distributionOptions from 'constants/options';
import formShape from 'constants/propTypes/form';
import { accrualPropTypes } from 'constants/propTypes/funds/accruals';
import accrualAccounts from 'constants/funds/accruals';
import AllocatedAmounts from 'components/shared/AllocatedAmounts';
import { getFormStateValues } from 'selectors/forms';
import { getFundCurrencySymbol } from 'selectors/funds';
import { getIsEmptyAllocatedAmounts } from 'selectors/allocatedAmounts';
import { getResourceAccrual, getAccrualHasPaymentItemsWithLockedPeriods } from 'selectors/funds/accruals';
import { validateRequired, validateMoreThanNull } from 'utils/validates';
import { dateFormat, currencyWithoutCommaFormat } from 'utils/formats';
import { ChartOfAccounts, Dock } from 'components/shared';
import { getFieldForSelectAccount } from 'selectors/chartOfAccounts';

const propTypes = {
  accrual: accrualPropTypes,
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  accrualCategoryOptions: selectOptionsNumbersPropTypes.isRequired,
  additionalSubmitBtnText: PropTypes.string.isRequired,
  cancelUrl: PropTypes.string.isRequired,
  currencySymbol: PropTypes.string.isRequired,
  form: PropTypes.shape(formShape),
  frequencyOptions: selectOptionsPropTypes.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleAdditionalSubmit: PropTypes.func.isRequired,
  handleSelectAccount: PropTypes.func.isRequired,
  toggleChart: PropTypes.func.isRequired,
  hasSubmitErrors: PropTypes.bool,
  specialAllocation: PropTypes.bool,
  submitError: PropTypes.string,
  submitting: PropTypes.bool,
  submitBtnText: PropTypes.string.isRequired,
  isEvenlyOverPeriod: PropTypes.bool,
  isEmptyAllocatedAmounts: PropTypes.bool,
  isVisibleChart: PropTypes.bool,
  isEditable: PropTypes.bool,
  refs: PropTypes.shape({
    accrualCategoryOptions: selectOptionsNumbersPropTypes,
    frequencyOptions: selectOptionsPropTypes,
  }).isRequired,
};

const defaultProps = {
  accrual: {},
  amount: null,
  form: {},
  hasSubmitErrors: false,
  submitError: undefined,
  specialAllocation: false,
  submitting: false,
  isEvenlyOverPeriod: false,
  isVisibleChart: false,
  isEmptyAllocatedAmounts: true,
  isEditable: true,
};

const FundsAccrualForm = (props) => {
  const {
    accrual,
    accrualCategoryOptions,
    additionalSubmitBtnText,
    amount,
    cancelUrl,
    currencySymbol,
    form,
    frequencyOptions,
    handleSubmit,
    hasSubmitErrors,
    handleAdditionalSubmit,
    handleSelectAccount,
    submitError,
    specialAllocation,
    submitting,
    submitBtnText,
    isEvenlyOverPeriod,
    isEmptyAllocatedAmounts,
    refs,
    isVisibleChart,
    toggleChart,
  } = props;

  const [isVisibleSpecilaAllocation, setIsVisibleSpecialAllocation] = useState(false);
  const isNeedShowSpecilaAllocation = specialAllocation && isVisibleSpecilaAllocation;

  const onVisibleSpecialAllocationChange = () => {
    setIsVisibleSpecialAllocation(!isVisibleSpecilaAllocation);
    if (isEmptyAllocatedAmounts) {
      form.mutators.changeSpecialAllocation();
    }
  };

  return (
    <Form layout="vertical">
      <FormStateToRedux form="accrual" />

      {hasSubmitErrors && <Alert style={{ marginTop: 15 }} message={submitError} type="error" showIcon />}

      <Row>
        <Col sm={20} md={10} lg={8} xl={6}>
          <Field
            component={FormGroup}
            label={I18n.t('activerecord.attributes.fund/accrual.accrual_category')}
            name="accrualCategoryId"
            options={accrualCategoryOptions}
            required
            type="select"
            validate={validateRequired()}
          />
        </Col>
      </Row>

      <Row>
        <Col sm={20} md={10} lg={8} xl={6}>
          <Field
            component={FormGroup}
            label={I18n.t('activerecord.attributes.fund/accrual.amount')}
            name="amount"
            required
            typeOfMask="decimalNumber"
            addonBefore="$"
            parse={currencyWithoutCommaFormat}
            validate={validateMoreThanNull()}
          />
        </Col>
      </Row>

      {amount && (
        <>
          <Row>
            <Col span={4}>
              <Field
                component={FormGroup}
                label={false}
                labelText="Special Allocation?"
                name="specialAllocation"
                type="checkbox"
                onClick={(e) => setIsVisibleSpecialAllocation(e.target.value)}
              />
            </Col>
            {specialAllocation && (
              <Col sm={20} md={10} lg={8} xl={6}>
                <Button type="dashed" onClick={() => setIsVisibleSpecialAllocation(true)} style={{ fontSize: 12 }}>
                  View special allocated amounts
                </Button>
              </Col>
            )}
          </Row>
          <Dock isVisible={isNeedShowSpecilaAllocation} onVisibleChange={onVisibleSpecialAllocationChange}>
            {specialAllocation && (
              <AllocatedAmounts
                amount={amount}
                currencySymbol={currencySymbol}
                date={accrual.startDate}
                parentId="accrualForm"
              />
            )}
          </Dock>
        </>
      )}

      <Row>
        <Col sm={20} md={10} lg={8} xl={6}>
          <Field
            component={FormGroup}
            label="Distribution"
            name="distribution"
            options={distributionOptions}
            required
            type="select"
            validate={validateRequired()}
          />
        </Col>
      </Row>

      {isEvenlyOverPeriod && (
        <Row>
          <Col sm={20} md={10} lg={8} xl={6}>
            <Field
              component={FormGroup}
              label={I18n.t('activerecord.attributes.fund/accrual.frequency')}
              name="frequency"
              options={frequencyOptions}
              required
              type="select"
              style={{ marginBottom: 5 }}
              validate={validateRequired()}
            />
          </Col>
        </Row>
      )}

      <Row gutter={16}>
        <Col span={5}>
          <Field
            component={FormGroup}
            label={I18n.t('activerecord.attributes.fund/accrual.start_date')}
            name="startDate"
            required
            type="datePicker"
            parse={dateFormat}
            validate={validateRequired()}
          />
        </Col>
        <Col span={5}>
          <Field
            component={FormGroup}
            label={I18n.t('activerecord.attributes.fund/accrual.end_date')}
            name="endDate"
            required
            type="datePicker"
            style={{ marginLeft: 10 }}
            parse={dateFormat}
            validate={validateRequired()}
          />
        </Col>
      </Row>

      {accrualAccounts.map((field) => {
        const camelizeField = humps.camelize(field);
        return (
          <dl className="dl-horizontal" key={field}>
            <dt className="dt">
              {I18n.t(`activerecord.attributes.fund/accrual.${field}`)}
              :
              {' '}
            </dt>

            <dd className="dd">
              <SelectChartOfAccount
                field={camelizeField}
                selectedAccountId={accrual[camelizeField]}
                btnClass="btn btn-sm"
              />
            </dd>
          </dl>
        );
      })}

      <Dock id="dock" isVisible={isVisibleChart} onVisibleChange={toggleChart}>
        <ChartOfAccounts fundId={accrual.fundId} refs={refs} onSelectAccount={handleSelectAccount} />
      </Dock>

      <div className="panel__footer">
        <Button loading={submitting} onClick={handleSubmit} type="primary" style={{ marginTop: 10 }}>
          {submitBtnText}
        </Button>
        <a className="btn btn-link" href={cancelUrl}>
          Cancel
        </a>

        <Button
          className="pull-right"
          type="primary"
          loading={submitting}
          onClick={handleAdditionalSubmit}
          style={{ marginTop: 10 }}
        >
          {additionalSubmitBtnText}
        </Button>
      </div>
    </Form>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { form, submitting, handleSubmit, selectChartAccount } = ownProps;
  const accrual = getResourceAccrual(state);
  const formValues = getFormStateValues(state, { form: 'accrual' });
  const amount = formValues && formValues.amount;
  const currencySymbol = getFundCurrencySymbol(state);
  const specialAllocation = formValues && formValues.specialAllocation;
  const distribution = formValues && formValues.distribution;
  const isEvenlyOverPeriod = distribution && distribution === 'evenly_over_period';
  const isEmptyAllocatedAmounts = getIsEmptyAllocatedAmounts(state);
  const submitBtnText = getSubmitBtnText(submitting);
  const additionalSubmitBtnText = getSubmitBtnText(submitting, 'additional');
  const fieldForSelectAccount = getFieldForSelectAccount(state);

  const handleSelectAccount = (accountId) => {
    form.change(fieldForSelectAccount, accountId);
    selectChartAccount(accountId);
  };

  const handleAdditionalSubmit = (values, params) => {
    form.change('redirectPage', 'paymentSchedule');
    handleSubmit(values, params);
  };

  return {
    accrual,
    additionalSubmitBtnText,
    amount,
    currencySymbol,
    isEvenlyOverPeriod,
    isEmptyAllocatedAmounts,
    submitBtnText,
    specialAllocation,
    handleAdditionalSubmit,
    handleSelectAccount,
  };
};

FundsAccrualForm.propTypes = propTypes;
FundsAccrualForm.defaultProps = defaultProps;

export default connect(mapStateToProps, null)(FundsAccrualForm);
