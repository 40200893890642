import { omit, mergeDeepRight } from 'ramda';

import * as actionTypes from 'constants/actionTypes';
import { normalizeAccounts } from 'normalizrs/accounts';


const initialState = {};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case actionTypes.ENTITIES_DELETE:
      return deleteEntities(state, payload);
    case actionTypes.ENTITIES_MERGE:
      return mergeDeepRight(state, payload);
    case actionTypes.ENTITIES_SYNC:
      return syncEntities(state, payload);
    case actionTypes.FUND_ACCOUNTS_FETCH_SUCCESS:
      return { ...state, ...normalizeAccounts(payload.accounts).entities }
    default:
      return state;
  }
}

function syncEntities(state, { entity, key }) {
  return { ...state, [key]: { ...state[key], [entity.id]: entity } };
}

function deleteEntities(state, { entityId, key }) {
  const newEntities = omit([entityId], state[key]);
  return { ...state, [key]: newEntities };
}
