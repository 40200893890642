import React from 'react';
import PropTypes from 'prop-types';
import { capitalize } from 'lodash';

import { accounting } from 'accounting';
import { dateToString } from 'utils/dateTransformations';

import { Stat } from 'components/shared';

const propTypes = {
  fund: PropTypes.object.isRequired,
  realizationsSummary: PropTypes.object,
  underlyingInvestmentType: PropTypes.string,
  underlyingInvestmentSecondType: PropTypes.string,
  security: PropTypes.object,
};

const defaultProps = {
  realizationsSummary: undefined,
  underlyingInvestmentType: undefined,
  underlyingInvestmentSecondType: undefined,
  security: undefined,
};

const Summary = (props) => {
  const { underlyingInvestmentType, underlyingInvestmentSecondType, fx } = props;
  const {
    displayTotalFeesTradeAmount,
    displayTotalFees,
    displayGrossTradeAmount,
    displayGrossSettleAmount,
    displayBaseNetTradeAmount,
    displayBaseNetSettleAmount,
    displayNetTradeAmount,
    displayNetSettleAmount,
    displayTradeDate,
    displayGrossAmount,
    displayBaseNetAmount,
    displayNetAmount,
  } = props.realizationsSummary;

  const security = props.security;
  const currencySymbol =
    security.type == 'currency' && security.holdingsDetails && security.holdingsDetails.sellCurrency
      ? security.holdingsDetails.sellCurrency.symbol
      : security.currencySymbol;

  const peOrPartnershipSecurity =
    security.type === 'partnership_investment' || security.type === 'private_equity_investment';
  // const displayCurrentHoldings = peOrPartnershipSecurity ? numeral(security.currentHoldings).format('$0,0.00') : security.currentHoldings;

  const displayUnderlyingSecurityCurrentHoldings = peOrPartnershipSecurity
    ? accounting.formatMoney(security.underlyingInvestment.currentHoldings, currencySymbol, 2, ',', '.')
    : security.underlyingInvestment.currentHoldings;
  const displayTotalSells = accounting.formatMoney(security.totalSells, currencySymbol, 2, ',', '.');
  const displayTotalBuys = accounting.formatMoney(security.totalBuys, currencySymbol, 2, ',', '.');

  const { withSecurityPrice, underlyingInvestment } = security;
  const pricingDate = dateToString(security.pricingDate);
  const displaySecurityPrice = accounting.formatMoney(security.securityPrice, currencySymbol, 2, ',', '.');

  return (
    <div>
      <div className="panel">
        <div className="panel__body panel__body-padded">
          <ul className="list list-unstyled">
            {peOrPartnershipSecurity &&
              withSecurityPrice && (
                <li>
                  Security Price on {pricingDate}:<strong> {displaySecurityPrice}</strong>
                </li>
              )}

            <li className="text-centered">
              <h4 className="m-t-none">
                For Underlying Investment
                <br />
                <a href={Routes.security_path(underlyingInvestment.id)} rel="noopener noreferrer" target="_blank">
                  {underlyingInvestment.displayTitle}
                </a>
              </h4>
            </li>

            <li>
              Holdings on {displayTradeDate}: <strong>{displayUnderlyingSecurityCurrentHoldings}</strong>
            </li>

            {peOrPartnershipSecurity &&
              withSecurityPrice && (
                <li>
                  Security Price on {pricingDate}:<strong> {displaySecurityPrice}</strong>
                </li>
              )}

            {peOrPartnershipSecurity &&
              !withSecurityPrice && (
                <li>
                  Total Sells:
                  <strong> {displayTotalSells}</strong>
                </li>
              )}

            {peOrPartnershipSecurity &&
              !withSecurityPrice && (
                <li>
                  Total Buys:
                  <strong> {displayTotalBuys}</strong>
                </li>
              )}

            {underlyingInvestmentType && (
              <li>
                Type:
                <strong> {capitalize(underlyingInvestmentType.split('_').join(' '))}</strong>
              </li>
            )}

            {underlyingInvestmentSecondType && (
              <li>
                <span>
                  Second Trade:
                  <strong> {underlyingInvestmentSecondType}</strong>
                </span>
              </li>
            )}
          </ul>
        </div>
      </div>

      <div className="row">
        {fx ? (
          <div>
            <div className="col-md-12">
              <Stat hint="Gross Trade Amount" value={displayGrossTradeAmount} />
            </div>
            <div className="col-md-12">
              <Stat hint="Gross Settle Amount" value={displayGrossSettleAmount} />
            </div>
            <div className="col-md-12">
              <Stat hint="Total Fees & Taxes" value={displayTotalFeesTradeAmount} />
            </div>
            <div className="col-md-12">
              <Stat hint="Net Trade Amount" value={displayNetTradeAmount} />
            </div>
            <div className="col-md-12">
              <Stat hint="Net Settle Amount" value={displayNetSettleAmount} />
            </div>
          </div>
        ) : (
          <div>
            <div className="col-md-12">
              <Stat hint="Gross Amount" value={displayGrossAmount} />
            </div>
            <div className="col-md-12">
              <Stat hint="Total Fees & Taxes" value={displayTotalFees} />
            </div>
            <div className="col-md-12">
              <Stat hint="Net Amount" value={displayNetAmount} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

Summary.propTypes = propTypes;
Summary.defaultProps = defaultProps;

export default Summary;
