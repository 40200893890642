import { Button, Divider, Empty } from 'antd';
import { isEmpty } from 'ramda';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { openDrawer } from 'actions/investorDocumentPatterns';
import { getSortedPatternsCollection } from 'selectors/investorDocumentPatterns';


import Drawer from './Drawer';
import PatternCard from './PatternCard';
import styles from './documents.module.css';

function InvestorDocumentPatterns(props) {
  const dispatch = useDispatch();
  const patterns = useSelector(getSortedPatternsCollection);
  const { match } = props;

  return (
    <>
      <Drawer match={match} />
      <div className={styles.container}>
        <div className={styles.addPattern}>
          <Button
            type='default'
            onClick={() => dispatch(openDrawer())}
            size='large'
          >
            Add a Structure Pattern
          </Button>
        </div>
        <div className={styles.newPatterns}>
          <Divider orientation='left'>Document Structure Patterns</Divider>
          {
            isEmpty(patterns)
              ? (<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>No patterns</span>} />)
              : (
                <div className={styles.patterns}>
                  {patterns.map((pattern, index) => <PatternCard key={pattern.id} index={index} {...pattern} />)}
                </div>
              )
          }
        </div>
      </div>
    </>
  );
}

export default InvestorDocumentPatterns;
