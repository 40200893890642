import React from 'react';
import FontAwesome from 'react-fontawesome';
import { animateScroll } from 'react-scroll';

import { forIn, filter, groupBy, snakeCase } from 'lodash';

import MappingItem from './MappingItem';

class ChartsMappingsTable extends React.Component {
  componentDidMount() {
    const position = $('.scroll-to-this').offset();

    if (position) {
      animateScroll.scrollTo(position.top - window.innerHeight / 7, {
        smooth: true,
      });
    }
  }

  render() {
    const { namespace, events, mappings, status, actions, changed } = this.props;
    const selectMappings = (fullTransactionKey) => (mappings ? filter(mappings, (m) => m.fullTransactionKey === fullTransactionKey) : []);

    const transactionList = (() => {
      const result = [];

      forIn(events, (transactionKeys, event) => {
        result.push(
          <tr>
            <td className="text-bold">{I18n.t(`mappings.events.${snakeCase(namespace)}.${snakeCase(event)}`)}</td>
            <td />
            <td />
            {namespace === 'trade' && <td />}
          </tr>,
        );

        if (namespace === 'trade') {
          forIn(
            groupBy(transactionKeys, (transactionKey) => transactionKey.eventType),
            (eventTypeTransactionKeys, eventType) => {
              result.push(
                <tr>
                  <td />
                  <td>{I18n.t(`mappings.trade.event_types.${snakeCase(eventType)}`)}</td>
                  <td />
                  <td />
                </tr>,
              );

              eventTypeTransactionKeys.map((eventTypeTransactionKey) => {
                const transactionKey = _.findKey(transactionKeys, {
                  fullTransactionKey: eventTypeTransactionKey.fullTransactionKey,
                });
                const selectedMappings = selectMappings(eventTypeTransactionKey.fullTransactionKey);

                result.push(
                  <tr>
                    <td />
                    <td />
                    <td>
                      {I18n.t(
                        `mappings.transaction_keys.${snakeCase(namespace)}.${snakeCase(event)}.${snakeCase(
                          transactionKey,
                        )}`,
                      )}
                    </td>
                    {eventTypeTransactionKey.accessible ? (
                      <td className="text-centered">
                        {status != 'complete' ? (
                          <FontAwesome name="spinner" spin />
                        ) : (
                          <MappingItem
                            mappings={selectedMappings}
                            namespace={namespace}
                            event={event}
                            changed={changed}
                            transactionKey={transactionKey}
                            actions={actions}
                            adding={namespace == 'trade' || selectedMappings.length == 0}
                          />
                        )}
                      </td>
                    ) : (
                      <td className="text-centered">
                        {I18n.t(
                          `mappings.transaction_keys.${snakeCase(namespace)}.${snakeCase(
                            event,
                          )}.inaccessible.${snakeCase(transactionKey)}`,
                        )}
                      </td>
                    )}
                  </tr>,
                );
              });
            },
          );
        } else {
          forIn(transactionKeys, (value, transactionKey) => {
            const selectedMappings = selectMappings(value.fullTransactionKey);

            result.push(
              <tr>
                <td />
                <td>
                  {I18n.t(
                    `mappings.transaction_keys.${snakeCase(namespace)}.${snakeCase(event)}.${snakeCase(
                      transactionKey,
                    )}`,
                  )}
                  {value.special && (
                    <span>
                      {' '}
                      <span className="label label-default">{I18n.t(`fund.types.${value.special}`)}</span>
                    </span>
                  )}
                </td>
                {value.accessible ? (
                  <td className="text-centered">
                    {status != 'complete' ? (
                      <FontAwesome name="spinner" spin />
                    ) : (
                      <MappingItem
                        mappings={selectedMappings}
                        namespace={namespace}
                        event={event}
                        changed={changed}
                        transactionKey={transactionKey}
                        actions={actions}
                        adding={namespace === 'trade' || selectedMappings.length === 0}
                      />
                    )}
                  </td>
                ) : (
                  <td className="text-centered">
                    {I18n.t(
                      `mappings.transaction_keys.${snakeCase(namespace)}.${snakeCase(event)}.inaccessible.${snakeCase(
                        transactionKey,
                      )}`,
                    )}
                  </td>
                )}
              </tr>,
            );
          });
        }
      });

      return result;
    })();

    return (
      <table className="table">
        <tbody>{transactionList}</tbody>
      </table>
    );
  }
}

export default ChartsMappingsTable;
