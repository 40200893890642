import axios from 'axios';
import humps from 'humps';
import { dateToString, stringToDate } from 'utils/dateTransformations';

import { changeDateInput, changeInput } from 'actions/changeInput';
import { isShareBasedTypes } from 'components/Funds/selectors';
import * as types from './actionTypes';

export * from 'actions/changeInput';
export * from 'actions/submitForm';

export function selectValueTypeRadioInput(e) {
  return (dispatch) => {
    const target = e.target ? e.target : e;
    const valueTypeStateKey = target.getAttribute('data-value-type-state-key');
    const valueType = target.getAttribute('data-value-type');

    dispatch(clearValues());

    dispatch({
      type: types.CHANGE_INPUT,
      payload: {
        value: valueType,
        stateKey: valueTypeStateKey,
      },
    });
  };
}

export function customRedemptionHoldbackChange(e) {
  return (dispatch) => {
    dispatch(clearRedemptionHoldbackValues());

    dispatch(changeInput(e));
  };
}

export function selectHoldbackTypeRadioInput(e) {
  return (dispatch) => {
    const target = e.target ? e.target : e;
    const valueTypeStateKey = target.getAttribute('data-value-type-state-key');
    const valueType = target.getAttribute('data-value-type');

    dispatch(clearRedemptionHoldbackValues());

    dispatch({
      type: types.CHANGE_INPUT,
      payload: {
        value: valueType,
        stateKey: valueTypeStateKey,
      },
    });
  };
}

export function changeDealingDate(e) {
  return (dispatch) => {
    const target = e.target ? e.target : e;
    const { value } = target;
    const dateValue = value && Date.parse(value) ? value : null;

    dispatch({
      type: types.CHANGE_DEALING_DATE,
      payload: {
        value: stringToDate(dateValue),
      },
    });

    if (dateValue === null) {
      dispatch(updateLastValuation(null));
    } else {
      dispatch(fetchLastValuationRequest());
    }
  };
}

export function getFundAccountsOptions(searchValue) {
  if (searchValue.length <= 2) {
    return () => Promise.resolve({ options: [] });
  }

  return (dispatch, getState) => {
    const {
      resourceFund,
      investorTransactionForm: { data },
    } = getState();
    dispatch(startFetchFundAccountsOptions(searchValue));

    // eslint-disable-next-line no-undef
    return fetch(
      Routes.fund_accounts_path(resourceFund.id, {
        s: {
          name: searchValue,
          sort: 'name.asc',
          excluded:
            data.type === 'Fund::InvestorTransaction::Transfer' ? [data.fromFundAccountId || data.toFundAccountId] : [],
        },
        format: 'json',
      }),
      {
        credentials: 'include',
      },
    )
      .then((response) => response.json())
      .then((json) => {
        const options = humps.camelizeKeys(json);
        dispatch(finishedSuccessFetchFundAccountsOptions(options));
        return { options };
      });
  };
}

function finishedSuccessFetchFundAccountsOptions(options) {
  return {
    type: types.FINISHED_SUCCESS_FETCH_FUND_ACCOUNTS_OPTIONS,
    payload: {
      options,
    },
  };
}

function startFetchFundAccountsOptions(searchValue) {
  return {
    type: types.START_FETCH_FUND_ACCOUNTS_OPTIONS,
    payload: {
      searchValue,
    },
  };
}

export function changeFundAccount(fundAccount) {
  return (dispatch) => {
    dispatch(updateFundAccount(fundAccount));

    setTimeout(dispatch, 300, {
      type: types.UPDATE_ACCOUNT_DATA,
      payload: {
        investorClass: fundAccount ? fundAccount.investorClass : undefined,
        investorSeries: fundAccount ? fundAccount.investorSeries : undefined,
        investor: fundAccount ? fundAccount.investor : undefined,
        openDate: fundAccount ? fundAccount.openDate : undefined,
        closeDate: fundAccount ? fundAccount.closeDate : undefined,
      },
    });
  };
}

export function changeFromFundAccount(fundAccount) {
  return (dispatch) => {
    dispatch({
      type: types.UPDATE_FROM_ACCOUNT,
      payload: {
        fundAccount,
      },
    });

    setTimeout(dispatch, 300, {
      type: types.UPDATE_FROM_ACCOUNT_DATA,
      payload: {
        investorClass: fundAccount ? fundAccount.investorClass : undefined,
        investorSeries: fundAccount ? fundAccount.investorSeries : undefined,
        investor: fundAccount ? fundAccount.investor : undefined,
        openDate: fundAccount ? fundAccount.openDate : undefined,
        closeDate: fundAccount ? fundAccount.closeDate : undefined,
      },
    });
  };
}

export function changeToFundAccount(fundAccount) {
  return (dispatch) => {
    dispatch({
      type: types.UPDATE_TO_ACCOUNT,
      payload: {
        fundAccount,
      },
    });
  };
}

function updateFundAccount(fundAccount) {
  return {
    type: types.UPDATE_ACCOUNT,
    payload: {
      fundAccount,
    },
  };
}

export function getFundContactsOptions() {
  return (dispatch, getState) => {
    const { investorTransactionForm } = getState();
    const { fundId } = investorTransactionForm.data;

    // eslint-disable-next-line no-undef
    return fetch(Routes.fund_contacts_path(fundId, { s: { role: 'agent' }, format: 'json' }), {
      credentials: 'include',
    })
      .then((response) => response.json())
      .then((json) => ({ options: humps.camelizeKeys(json) }));
  };
}

export function changeThirdParty(option) {
  return (dispatch, getState) => {
    const { investorTransactionForm } = getState();
    const { thirdPartyId } = investorTransactionForm.data;

    if (option == null) {
      dispatch(updateThirdParty(null));
    } else if (option.id !== thirdPartyId) {
      dispatch(updateThirdParty(option.id));
    }
  };
}

function clearValues() {
  return {
    type: types.CLEAR_VALUES,
  };
}

function clearRedemptionHoldbackValues() {
  return {
    type: types.CLEAR_REDEMPTION_HOLDBACK_VALUES,
  };
}

function updateThirdParty(value) {
  return {
    type: types.UPDATE_THIRD_PARTY,
    payload: {
      value,
    },
  };
}

function updateLastValuation(value) {
  return {
    type: types.UPDATE_LAST_VALUATION,
    payload: {
      value,
    },
  };
}

function fetchLastValuationRequest() {
  return (dispatch, getState) => {
    const { investorTransactionForm } = getState();
    const { fundId, dealingDate } = investorTransactionForm.data;

    dispatch({
      type: types.START_FETCH_VALUATION_DATA,
    });

    axios
      .get(
        Routes.fund_valuations_path(fundId, {
          format: 'json',
          s: {
            fund_id: fundId,
            on_date: dateToString(dealingDate),
          },
        }),
        {
          withCredentials: true,
        },
      )
      .then((res) => {
        dispatch(updateLastValuation(humps.camelizeKeys(res.data[0])));

        setTimeout(dispatch, 300, { type: types.FINISH_FETCH_VALUATION_DATA });
      });
  };
}
