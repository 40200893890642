import { FORM_ERROR } from 'final-form';
import { isEmpty, keys, is } from 'ramda';

import { clearSpecSymbols, makeReadableName } from 'utils/formats';
import { getIn } from 'utils/ramda';
import { camelizeKeys } from 'utils/humps';

export function throwSubmissionError(response) {
  const { message, source } = getIn('errors', response) || {};
  let errors = {};

  if (message && typeof source !== 'string') {
    errors = { ...errors, [FORM_ERROR]: message };
  }

  if (source && typeof source === 'string') {
    errors = { ...errors, [FORM_ERROR]: source };
  }

  if (source && typeof source !== 'string') {
    const camelizedSource = camelizeKeys(source);
    errors = { ...errors, ...camelizedSource };
  }

  return errors;
}

export const throwPaymentScheduleError = (response) => {
  const payload = getIn('payload', response) || {};
  const errors = getIn('errors', payload) || {};

  return errors;
};

export const getReadableError = (errors) => {
  if (!isEmpty(errors)) {
    const errorKeys = keys(errors);
    const firstKey = errorKeys[0];
    const firstKeyErrors = getIn(firstKey, errors);

    if (firstKeyErrors && !isEmpty(firstKeyErrors)) {
      const fieldName = makeReadableName(firstKey);

      if (is(Object, firstKeyErrors[0])) {
        const errorStr = JSON.stringify(firstKeyErrors[0]);
        const clearedStr = clearSpecSymbols(errorStr);

        return `${fieldName}: ${clearedStr}`;
      }

      if (Array.isArray(firstKeyErrors)) {
        return `${fieldName}: ${firstKeyErrors[0]}`;
      }

      return `${fieldName}: ${firstKeyErrors}`;
    }
  }

  return null;
};
