import React from 'react';
import PropTypes from 'prop-types';
import { Form as FinalForm } from 'react-final-form';
import moment from 'moment';
import { Button, Popconfirm, message, Avatar, Form, Input, List, Comment, Tooltip } from 'antd';

import EditNoteForm from './EditNoteForm';

const propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  onEdit: PropTypes.func,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
  onSubmit: PropTypes.func,
  isAllowed: PropTypes.func,
  pagination: PropTypes.bool,
  touchable: PropTypes.bool,
};

const defaultProps = {
  data: [],
  onEdit: undefined,
  onCancel: undefined,
  onDelete: undefined,
  onSubmit: undefined,
  isAllowed: () => false,
  pagination: true,
  touchable: true,
};

const Notes = ({
  data,
  onEdit,
  onCancel,
  onDelete,
  onSubmit,
  isAllowed = () => false,
  pagination = true,
  touchable = true,
}) => {
  return (
    <List
      className="comment-list"
      itemLayout="horizontal"
      dataSource={data}
      size="small"
      pagination={
        pagination
          ? {
            pageSize: 5,
            hideOnSinglePage: true,
          }
          : false
      }
      renderItem={(item) => (
        <li>
          {item.editable ? (
            <FinalForm
              component={EditNoteForm}
              onSubmit={onSubmit}
              onCancel={onCancel}
              initialValues={item}
              subscription={{
                pristine: true,
                hasSubmitErrors: true,
                submitting: true,
                submitError: true,
                initialValues: true,
              }}
            />
          ) : (
              <Comment
                actions={
                  touchable &&
                  isAllowed(item.authorId) && [
                    <Button type="link" size="small" onClick={() => onEdit(item.id)}>
                      Edit
                  </Button>,
                    <Popconfirm
                      placement="top"
                      title="Delete Note?"
                      onConfirm={() => {
                        onDelete(item.id);
                        return message.info('Deleted.');
                      }}
                      okText="Confirm"
                      cancelText="Cancel"
                    >
                      <Button type="link" size="small">
                        Delete
                    </Button>
                    </Popconfirm>,
                  ]
                }
                author={item.authorName}
                avatar={
                  <Avatar style={{ backgroundColor: '#ea763b', verticalAlign: 'middle' }}>{item.authorInitials}</Avatar>
                }
                content={
                  item.editable ? (
                    <>
                      <Form.Item>
                        <Input.TextArea autoSize={{ minRows: 2 }} value={item.content} allowClear autoFocus />
                      </Form.Item>
                      <Button htmlType="submit" loading={false} type="link" size="small">
                        Save
                    </Button>
                      <Button type="link" size="small" onClick={onCancel}>
                        Cancel
                    </Button>
                    </>
                  ) : (
                      <p>
                        {item.content}
                        {item.edited && (
                          <Tooltip title={moment(item.updatedAt).format('YYYY-MM-DD HH:mm:ss')}>
                            <span className="text-muted text-italic small">{' (edited)'}</span>
                          </Tooltip>
                        )}
                      </p>
                    )
                }
                datetime={
                  /* eslint-disable react/jsx-wrap-multilines */
                  <Tooltip title={moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss')}>
                    <span>{moment(item.createdAt).fromNow()}</span>
                  </Tooltip>
                }
              />
            )}
        </li>
      )}
    />
  );
};

Notes.propTypes = propTypes;
Notes.defaultProps = defaultProps;

export default Notes;
