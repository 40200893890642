import { createSelector } from 'reselect';
import { isEmpty } from 'ramda';

export const initialState = [];

const allocatedAmountsSelector = (state) => state.allocatedAmounts || initialState;

export const getAllocatedAmounts = createSelector(
  allocatedAmountsSelector,
  (allocatedAmounts) => allocatedAmounts,
);

export const getIsEmptyAllocatedAmounts = createSelector(
  allocatedAmountsSelector,
  (allocatedAmounts) => {
    const filteredAllocatedAmounts = allocatedAmounts.filter((amount) => amount.selected === true);
    return isEmpty(filteredAllocatedAmounts);
  },
);
