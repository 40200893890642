import { createStore, applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk';
import humps from 'humps';

import { saveState } from 'utils/localStorage';

import tradeExerciseReducer from './reducer';

const createStoreWithThunk = applyMiddleware(ReduxThunk)(createStore);

export default function configureStore(initialState) {
  const camelizeState = humps.camelizeKeys(initialState);

  const state = {
    ...camelizeState,
  };

  const store = createStoreWithThunk(tradeExerciseReducer,
    state,
    window.__REDUX_DEVTOOLS_EXTENSION__ &&
    window.__REDUX_DEVTOOLS_EXTENSION__()
  );

  saveState(store.getState());

  return store;
}
