import { sort, find } from 'ramda';
import { createSelector } from 'reselect';

import { getIn } from 'utils/ramda';

function patternsSelector(state) {
  return getIn('investorDocumentPatterns', state) || [];
}
function directoriesSelector(state) {
  return getIn('investorDocumentPatternDirectories', state) || [];
}

export const getPatternsCollection = createSelector(patternsSelector, (patterns) => getIn('collection', patterns) || []);
export const getDrawerState = createSelector(patternsSelector, (patterns) => getIn('drawer', patterns) || false);
export const getDirectoriesCollection = createSelector(directoriesSelector, (directories) => getIn('collection', directories) || []);
export const getDirectoriesTree = createSelector(directoriesSelector, (directories) => getIn('tree', directories) || []);

export const getSortedPatternsCollection = createSelector(getPatternsCollection, (patterns) => sort((a, b) => a.position - b.position, patterns));
export const getPatternDetails = createSelector(getPatternsCollection, (_, id) => Number(id), (patterns, id) => id ? find((p) => p.id === id, patterns) : patterns);


// export const getPatterns = createSelector(
//   patternsSelector,
//   (collection) => sort((a, b) => a.position - b.position, collection),
// );

// export const getPatternDetails = (id) => createSelector(patternsSelector, (state) => {
//   if (id) {
//     return state.filter((item) => item.id === Number(id));
//   }
//   return state;
// });

// export const getPatternDirTree = () => createSelector(
//   directoriesTreeSelector,
//   (state) => state
// );

// export const getIsDrawerOpen = (state) => getIn('drawer', getIn('InvestorDocumentDirectories', state) || []) || false;
