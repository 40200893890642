import * as types from 'constants/actionTypes';
import { initialState } from 'selectors/charts';

import { is } from 'ramda';

import { toggleValueFromArray } from 'utils/ramda';
import { getActionTypeWithPrefix } from 'utils/actions';

function connectCharts(key) {
  if (key && !is(String, key)) {
    throw new Error('Charts key must  be a string!');
  }

  return (state = initialState, action) => {
    switch (action.type) {
      case getActionTypeWithPrefix(key, types.CHARTS_FETCH_SUCCESS):
        return { ...state, ...action.payload };
      case getActionTypeWithPrefix(key, types.CHARTS_SEARCH_VALUE_UPDATE):
        return { ...state, ...action.payload };
      case getActionTypeWithPrefix(key, types.CHARTS_EXPANDED_KEYS_UPDATE): {
        return {
          ...state,
          collapsedKeys: prepareCollapsedKeys(action.payload, state.collapsedKeys),
        };
      }
      case getActionTypeWithPrefix(key, types.CHARTS_SECURITY_FILTERS_UPDATE):
        return {
          ...state,
          securityFilters: action.payload.values,
        };
      default:
        return state;
    }
  };
}

function prepareCollapsedKeys(payload, collapsedKeys) {
  if (is(String, payload)) {
    return toggleValueFromArray(payload, collapsedKeys);
  }

  return payload;
}

export default connectCharts;
