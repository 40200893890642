import React from 'react';
import PropTypes from 'prop-types';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import classNames from 'classnames';
import FontAwesome from 'react-fontawesome';
import { FormGroup } from 'components/shared';

import * as actions from './actions';
import getSelectedAccountTitle from './selectors';

const propTypes = {
  actions: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired,
  selectedAccountId: PropTypes.number,
  selectedAccountTitle: PropTypes.string,
  errors: PropTypes.array,
  btnClass: PropTypes.string,
  name: PropTypes.string,
};

const defaultProps = {
  selectedAccountId: null,
  selectedAccountTitle: null,
  errors: null,
  btnClass: 'btn',
  name: 'Select Account',
};

function SelectChartOfAccount(props) {
  const { field, btnClass, selectedAccountId, selectedAccountTitle, name, actions, errors } = props;

  const classes = classNames({
    [btnClass]: true,
    'btn-default': !selectedAccountId,
    'btn-primary': selectedAccountId,
    'btn-danger': errors,
  });

  return (
    <FormGroup id={`accountID${selectedAccountId}`} errors={errors}>
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
          actions.setFieldForSelectAccount(field);
        }}
        className={classes}
      >
        {selectedAccountId ? selectedAccountTitle || <FontAwesome name="spinner" spin /> : name}
      </a>
    </FormGroup>
  );
}

const mapStateToProps = (state, props) => ({
  selectedAccountTitle: getSelectedAccountTitle(state, props.selectedAccountId),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

SelectChartOfAccount.propTypes = propTypes;
SelectChartOfAccount.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(SelectChartOfAccount);
