export * from 'constants/actionTypes';

export const UPDATE_SECURITY = 'UPDATE_SECURITY';
export const REMEMBER_SECURITY = 'REMEMBER_SECURITY';
export const REMOVE_SECURITY = 'REMOVE_SECURITY';
export const CLEAR_PREVIOUS_SECURITY = 'CLEAR_PREVIOUS_SECURITY';
export const RESTORE_PREVIOUS_SECURITY = 'RESTORE_PREVIOUS_SECURITY';
export const UPDATE_TRADE_DATE = 'UPDATE_TRADE_DATE';
export const CHANGE_HISTORIC_TRADE_CHECKBOX = 'CHANGE_HISTORIC_TRADE_CHECKBOX';
export const UPDATE_HISTORIC_TRADE_DATE = 'UPDATE_HISTORIC_TRADE_DATE';
export const FETCH_CURRENT_HOLDINGS = 'FETCH_CURRENT_HOLDINGS';
export const MAKE_SETTLE_FX_RATE_EQUAL_TO_TRADE_FX_RATE = 'MAKE_SETTLE_FX_RATE_EQUAL_TO_TRADE_FX_RATE';
export const SET_FX_RATE = 'SET_FX_RATE';
export const CONVERT_FEES_BY_FX_RATE = 'CONVERT_FEES_BY_FX_RATE';
export const UPDATE_TRADE_FX = 'UPDATE_TRADE_FX';

export const SELECT_SEARCH_SECURITY_TYPE = 'SELECT_SEARCH_SECURITY_TYPE';
export const CHANGE_SEARCH_EXPIRED_SECURITY = 'CHANGE_SEARCH_EXPIRED_SECURITY';
