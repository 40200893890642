import { pipe } from 'ramda';

function initialValuesDecorator(fund, accrual) {
  function makeInitialValues() {
    const distribution = accrual.distribution || 'evenly_over_period';
    const frequency = accrual.frequency || fund.valuationFrequency;
    const amount = accrual.amount && Number(accrual.amount);
    return { ...accrual, distribution, frequency, amount };
  }

  return pipe(makeInitialValues);
}

export default initialValuesDecorator;
