import set from 'lodash/fp/set';
import { has, omit, last } from 'lodash';

import { immutablePush, immutableDelete, immutableSplice } from 'utils/immutableFunctions';
import * as types from './actionTypes';

const initialState = {
  data: {
    formAttributes: {
      classSpecific: 'false',
      classesAndSeries: 'true',
    },
    investors: [
      {
        targetId: undefined,
        targetType: 'Fund::Account',
        type: 'Allocation',
      },
    ],
  },
  meta: {
    errors: {},
    submitting: false,
  },
};

export default function(state = initialState, action) {
  switch (action.type) {
    case types.CHARTS_ACCOUNT_CLEAR:
      return {
        ...state,
        ...initialState,
      };

    case types.INVESTOR_CLASS_TOGGLE: {
      if (state.data.investorClasses && has(state.data.investorClasses, action.payload.id)) {
        return {
          ...state,
          data: {
            ...state.data,
            investorClasses: omit(state.data.investorClasses, action.payload.id),
          },
        };
      }

      return {
        ...state,
        data: {
          ...state.data,
          investorClasses: {
            ...state.data.investorClasses,
            [action.payload.id]: {
              targetId: action.payload.id,
              targetType: 'Fund::InvestorClass',
              type: 'allocation',
            },
          },
        },
      };
    }

    case types.INVESTOR_SERIES_TOGGLE: {
      if (state.data.investorSeries && has(state.data.investorSeries, action.payload.id)) {
        return {
          ...state,
          data: {
            ...state.data,
            investorSeries: omit(state.data.investorSeries, action.payload.id),
          },
        };
      }

      return {
        ...state,
        data: {
          ...state.data,
          investorSeries: {
            ...state.data.investorSeries,
            [action.payload.id]: {
              targetId: action.payload.id,
              targetType: 'Fund::InvestorSeries',
              type: 'allocation',
            },
          },
        },
      };
    }

    case types.INVESTOR_ADD: {
      const newInvestor = Object.assign({}, last(state.data.investors));
      newInvestor.targetId = undefined;
      newInvestor.type = 'allocation';

      const investors = immutablePush(state.data.investors, newInvestor);

      return {
        ...state,
        data: {
          ...state.data,
          investors,
        },
      };
    }

    case types.INVESTOR_REMOVE:
      return {
        ...state,
        data: {
          ...state.data,
          investors: immutableDelete(state.data.investors, action.payload.index),
        },
      };

    case types.CHARTS_ACCOUNT_FORM_FILL:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload.data,
        },
      };

    case types.CHARTS_ACCOUNT_CHANGE_INPUT:
      return set(action.payload.stateKey, action.payload.value)(state);

    case types.CHARTS_ACCOUNT_SUBMITTING_FORM:
      return {
        ...state,
        meta: {
          submitting: action.payload.submitting,
        },
      };

    case types.CHARTS_ACCOUNT_SUBMITTING_FORM_SUCCEEDED:
      return {
        ...state,
        meta: {
          errors: null,
        },
      };

    case types.CHARTS_ACCOUNT_SUBMITTING_FORM_FAILED:
      return {
        ...state,
        meta: {
          errors: action.payload.errors,
        },
      };

    default:
      return state;
  }
}
