import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { includes, toString } from 'lodash';

import { FormError } from '../';

const propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  dataStateKey: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  checked: PropTypes.bool,
  label: PropTypes.string,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  inline: PropTypes.bool,
};

const defaultProps = {
  inline: false,
};

const Checkbox = (props) => {
  const {
    type,
    name,
    dataStateKey,
    value,
    checked,
    label,
    required,
    placeholder,
    inline,
    onChange,
    errors,
    disabled,
  } = props;

  const formGroupClass = classNames({
    form__group: true,
    'form__group-errored': errors,
  });

  const checkboxClasses = classNames({
    form__checkbox: true,
    'form__checkbox-inline': inline,
  });

  return (
    <div className={formGroupClass}>
      <div className={checkboxClasses}>
        <label>
          <input
            name={name}
            onChange={onChange}
            value={value}
            checked={checked}
            type="checkbox"
            data-statekey={dataStateKey}
            disabled={disabled}
          />
          {required && <abbr title="required">* </abbr>}
          {label}
        </label>
      </div>
      <FormError errors={errors} />
    </div>
  );
};

Checkbox.propTypes = propTypes;
Checkbox.defaultProps = defaultProps;

export default Checkbox;
