import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Field } from 'react-final-form';
import { Button, Avatar, Form, Input, Comment, Tooltip } from 'antd';

import { validateRequired } from 'utils/validates';

const propTypes = {
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  initialValues: PropTypes.shape(PropTypes.object),
  pristine: PropTypes.bool,
};

const defaultProps = {
  submitting: false,
  handleSubmit: undefined,
  onCancel: undefined,
  initialValues: {},
  pristine: false,
};

const EditNoteForm = ({ submitting, handleSubmit, pristine, initialValues, onCancel }) => {
  const { authorName, authorInitials, createdAt } = initialValues;

  return (
    <Form layout="vertical" onSubmit={handleSubmit}>
      <Comment
        actions={[
          <Button htmlType="submit" loading={submitting} disabled={pristine} type="link" size="small">
            Save
          </Button>,
          <Button type="link" size="small" onClick={onCancel}>
            Cancel
          </Button>,
        ]}
        author={authorName}
        avatar={<Avatar style={{ backgroundColor: '#ea763b', verticalAlign: 'middle' }}>{authorInitials}</Avatar>}
        content={
          /* eslint-disable react/jsx-wrap-multilines */
          <Field name="content" validate={validateRequired()}>
            {({ input, meta }) => (
              <Form.Item
                validateStatus={meta.error && meta.touched ? 'error' : 'success'}
                help={meta.error && meta.touched ? meta.error : undefined}
                required
              >
                <Input.TextArea rows={2} autoSize={{ minRows: 2 }} {...input} allowClear autoFocus />
              </Form.Item>
            )}
          </Field>
        }
        datetime={
          <Tooltip title={moment(createdAt).format('YYYY-MM-DD HH:mm:ss')}>
            <span>{moment(createdAt).fromNow()}</span>
          </Tooltip>
        }
      />
    </Form>
  );
};

EditNoteForm.propTypes = propTypes;
EditNoteForm.defaultProps = defaultProps;

export default EditNoteForm;
