import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, Table, Popconfirm, Popover, message, Avatar, Typography, Menu, Dropdown } from 'antd';

import { tableItemShape, ownerOptionsShape } from 'constants/propTypes/funds/tasks';

const propTypes = {
  currentUserId: PropTypes.number.isRequired,
  dataSource: PropTypes.arrayOf(tableItemShape),
  DraggableBodyRow: PropTypes.elementType,
  onComplete: PropTypes.func,
  onChangeOwner: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onMoveRow: PropTypes.func,
  ownerOptions: PropTypes.arrayOf(ownerOptionsShape),
};

const defaultProps = {
  dataSource: [],
  DraggableBodyRow: undefined,
  onComplete: undefined,
  onChangeOwner: undefined,
  onEdit: undefined,
  onDelete: undefined,
  onMoveRow: undefined,
  ownerOptions: [],
};

function TaskTable(props) {
  const {
    currentUserId,
    dataSource,
    DraggableBodyRow,
    onComplete,
    onChangeOwner,
    onEdit,
    onDelete,
    onMoveRow,
    ownerOptions,
  } = props;

  return (
    <Table
      className="task_table"
      dataSource={dataSource}
      bordered={false}
      onRow={(record, index) => ({
        index,
        id: record.id,
        position: record.position,
        moveRow: onMoveRow,
        completed: record.completed,
      })}
      components={{
        body: {
          row: DraggableBodyRow,
        },
      }}
      columns={[
        {
          title: '#',
          dataIndex: 'position',
          key: 'position',
          width: 40,
          sorter: (a, b) => a.key - b.key,
          ellipsis: true,
          align: 'right',
        },
        {
          title: undefined,
          dataIndex: 'completed',
          key: 'completed',
          width: 45,
          align: 'left',
          render: (completed, item) => {
            return (
              <>
                {completed ? (
                  <Popover content={`Completed by ${item.completedByName} ${item.completedByAt}`} trigger="hover">
                    <Button
                      shape="circle"
                      type="success"
                      icon="check"
                      onClick={() => onComplete({ completed: false }, item.id)}
                      disabled={!item.canBeUncompleted}
                    />
                  </Popover>
                ) : item.ownerId !== currentUserId ? (
                  <Popconfirm
                    title="This task is not assigned to you, are you sure you want to complete this task?"
                    onConfirm={() => onComplete({ completed: true }, item.id)}
                    disabled={!item.dependentFundValuationTaskItemCompleted}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button
                      shape="circle"
                      type="default"
                      icon="check"
                      disabled={!item.dependentFundValuationTaskItemCompleted}
                    />
                  </Popconfirm>
                ) : (
                      <Button
                        shape="circle"
                        type="default"
                        icon="check"
                        onClick={() => onComplete({ completed: true }, item.id)}
                        disabled={!item.dependentFundValuationTaskItemCompleted}
                      />
                    )}
              </>
            );
          },
        },
        {
          title: 'Title',
          dataIndex: 'title',
          key: 'title',
          render: (title, item) => (
            <>
              <Typography.Text delete={item.completed}>{title}</Typography.Text>
              {item.dependentFundValuationTaskItemId && (
                <>
                  {' '}
                  <Popover
                    content={
                      // eslint-disable-next-line react/jsx-wrap-multilines
                      <Button type="link" onClick={() => onEdit(item.dependentFundValuationTaskItemId)}>
                        {`Dependent on task #${item.dependentFundValuationTaskItemPosition} - ${item.dependentFundValuationTaskItemTitle}`}
                      </Button>
                    }
                    trigger="hover"
                  >
                    <Icon type="info-circle" theme="twoTone" twoToneColor="#ff4d4f" />
                  </Popover>
                </>
              )}
            </>
          ),
        },
        {
          title: 'Owner',
          dataIndex: 'owner',
          key: 'owner',
          width: 70,
          sorter: (a, b) => {
            if (a.ownerName === b.ownerName) {
              return 0;
            }
            if (a.ownerId === null) {
              return 1;
            }
            if (b.ownerId === null) {
              return -1;
            }

            return a.ownerName.length < b.ownerName.length ? 1 : -1;
          },
          ellipsis: true,
          align: 'center',
          render: (owner, item) => (
            <Dropdown
              trigger="click"
              overlay={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Menu>
                  {ownerOptions.map(({ id, name }) => (
                    <Menu.Item>
                      <Typography.Text
                        type={id === null || id === '' ? 'secondary' : null}
                        mark={id === item.ownerId}
                        onClick={() => onChangeOwner({ ownerId: id }, item.id)}
                      >
                        {name}
                      </Typography.Text>
                    </Menu.Item>
                  ))}
                </Menu>
              }
            >
              {owner ? (
                <Popover content={item.ownerName} trigger="hover">
                  <Avatar
                    style={{
                      cursor: 'pointer',
                      backgroundColor: '#ea763b',
                      verticalAlign: 'middle',
                    }}
                    size="small"
                  >
                    {item.owner}
                  </Avatar>
                </Popover>
              ) : (
                  <Avatar style={{ cursor: 'pointer' }} icon="user" size="small" />
                )}
            </Dropdown>
          ),
        },
        {
          title: 'Date Assigned',
          dataIndex: 'assignedAt',
          key: 'assignedAt',
          align: 'right',
          width: 100,
        },
        {
          title: undefined,
          key: 'actions',
          width: 80,
          align: 'right',
          render: (item) => (
            <>
              <Button type="link" icon="edit" onClick={() => onEdit(item.id)} />
              <Popconfirm
                placement="left"
                title="Delete Task?"
                onConfirm={() => {
                  onDelete(item.id);
                  return message.info('Deleted.');
                }}
                okText="Confirm"
                cancelText="Cancel"
              >
                <Button type="link" icon="delete" />
              </Popconfirm>
            </>
          ),
        },
      ]}
      size="small"
      // scroll={{ x: 1300 }}
      pagination={false}
    />
  );
}

TaskTable.propTypes = propTypes;
TaskTable.defaultProps = defaultProps;

export default TaskTable;
