const initialState = {
  collection: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'CREATE_TASK_ITEM':
    case 'UPDATE_TASK_ITEM':
    case 'DESTROY_TASK_ITEM':
    case 'CHANGE_TASK_ITEM_OWNER':
    case 'COMPLETE_TASK_ITEM':
    case 'CHANGE_TASK_ITEM_POSITION':
      return {
        ...state,
        collection: action.payload,
      };
    case 'NEW_TASK_ITEM':
    case 'EDIT_TASK_ITEM':
    case 'CLOSE_TASK_ITEM':
      return state;
    default:
      return state;
  }
};
