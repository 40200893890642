import fetch from '../../fetch';
import { postRequest } from '../../requests';

export default class FundInvestorDocumentPatternsApi {
  static import(patternId, fundId) {
    return fetch(Routes.import_fund_investor_document_directories_path(fundId), postRequest({
      body: {
        import_investor_document_directories: {
          investor_document_pattern_id: patternId
        }
      }
    }));
  }

  static setPrivate(fundId, accesses) {
    return fetch(Routes.create_for_fund_account_fund_investor_document_directories_path(fundId), postRequest({
      body: {
        fund_investor_document_directory_params: {
          fund_account_ids: accesses
        }
      }
    }));
  }
}