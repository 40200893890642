import actionCable from 'actioncable';

const wsUrl = {
  development: 'http://localhost:3334/cable',
  staging: 'wss://staging.bastionsolutions.technology/cable',
  integration: 'wss://integration.bastionsolutions.technology/cable',
  production: 'wss://bastionsolutions.technology/cable',
};

export default class {
  constructor(env) {
    this.cable = actionCable.createConsumer(wsUrl[env] || 'development');
  }

  createSubscription(channelName, callback) {
    this.cable.subscriptions.create(
      { channel: channelName },
      {
        received(data) {
          callback(data);
        },
      },
    );
  }
}
