import { map, sort, filter, flatten, without, update, insert, findIndex } from 'ramda';

import * as types from 'constants/actionTypes';
import buildATree from 'utils/trees/buildATree';

const initialState = {
  collection: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case 'PARSE_FUND_DOCUMENTS': {
      return {
        ...state,
        documents: state.collection.reduce((acc, item) => {
          if (item.documents) {
            return [...acc, ...map((doc) => ({ ...doc, isPrivate: item.isPrivate }), item.documents)]
          }
          return [...acc];
        }, [])
      }
    }
    case 'BUILD_FUND_DIRECTORY_TREE': { // MOVE TO SELECTORS
      const { collection: folders, documents } = state;
      const normalizedDocuments = map((document) => ({ ...document, parentId: document.fundInvestorDocumentDirectoryId, isDocument: true, isLeaf: true }), documents || []);
      const foldersWithDocuments = [...folders, ...normalizedDocuments];
      const idsToKeys = map(({ id, ...item }) => ({ key: item.isLeaf ? `${id}-document` : `${id}`, ...item }), foldersWithDocuments);
      const tree = buildATree(idsToKeys, { idName: 'key', parentIdName: 'parentId', withAccesses: true });
      return { ...state, tree }
    }
    case types.INVESTOR_DOCUMENTS_SET_COLLECTION:
      return { ...state, collection: payload }

    case types.INVESTOR_DOCUMENTS_CREATE_DIRECTORY: {
      const firstIsPrivateElementIdx = findIndex((x) => x.isPrivate, state.collection);
      return {
        ...state,
        collection: insert(firstIsPrivateElementIdx, payload, state.collection),
      }
    }
    case types.INVESTOR_DOCUMENTS_UPDATE_DIRECTORY: {
      const { id, props } = payload;
      const directory = state.collection.find((item) => item.id === id);
      const changes = filter((val) => !!val, props);
      const dirIdx = findIndex((x) => x.id === directory.id, state.collection);

      return {
        ...state,
        collection: update(dirIdx, { ...directory, ...changes }, state.collection),
      }
    }

    case types.INVESTOR_DOCUMENTS_REMOVE_DIRECTORY: {
      const getIds = (arr) => arr.map((x) => x.id);
      const getChildren = (parent) => state.collection.filter((item) => item.parentId === parent.id);
      const getAllChildren = (item) => {
        const children = getChildren(item)
        return flatten([...children, ...children.map((child) => getAllChildren(child))])
      };

      const target = state.collection.find((item) => item.id === payload.id);
      const directoriesToDelete = [target, ...getAllChildren(target)];
      const documentsToDelete = (state.documents || []).filter((document) => getIds(directoriesToDelete).includes(document.parentId)) || [];
      return {
        ...state,
        collection: without(directoriesToDelete, state.collection),
        documents: without(documentsToDelete, state.documents || []),
      }
    }
    case 'FUND_DOCUMENT_ITEM_DIRECTORIES_ADD_DOCUMENTS': {
      const { decorator } = payload;
      return { ...state, tree: decorator(state.tree) };
    }
    case 'SET_NEW_PATTERN': {
      const { patterns, currentPatternId } = payload;
      return { ...state, collection: patterns, currentPatternId }
    }
    case 'OPEN_DOCUMENT_ACCOUNTS_DRAWER':
      return {
        ...state,
        currentUpload: payload,
      };
    case 'CLOSE_DOCUMENT_ACCOUNTS_DRAWER':
      return {
        ...state,
        currentUpload: null,
      };
    case types.INVESTOR_DOCUMENTS_CREATE_DOCUMENT: {
      const { parentId } = payload;
      const folder = state.collection.find((item) => item.id === parentId);
      const folIdx = findIndex((x) => x.id === folder.id, state.collection);
      const updFolder = { ...folder, documents: [...(folder.documents || []), payload] };

      return {
        ...state,
        collection: update(folIdx, updFolder, state.collection),
      }
    }
    case types.INVESTOR_DOCUMENTS_UPDATE_DOCUMENT: {
      const { id, parentId, props } = payload;
      const folder = state.collection.find((item) => item.id === parentId);
      const document = folder.documents.find((item) => item.id === id);
      const docIdx = findIndex((x) => x.id === id, folder.documents);
      const folIdx = findIndex((x) => x.id === folder.id, state.collection);
      const updDocument = { ...document, ...props };
      const updFolder = { ...folder, documents: update(docIdx, updDocument, (folder.documents || [])) }

      return {
        ...state,
        collection: update(folIdx, updFolder, state.collection),
      }
    }
    case types.INVESTOR_DOCUMENTS_REMOVE_DOCUMENT:
      return {
        ...state,
        collection: state.collection.map(
          (folder) => folder.documents.map((x) => x.id).includes(payload.id)
            ? { ...folder, documents: folder.documents.filter((doc) => doc.id !== payload.id) }
            : folder
        ),
      }
    case types.INVESTOR_DOCUMENTS_CHILDREN_ACCESSES: {
      const item = state.collection.find((x) => x.id === payload.id);
      const recursion = (item, f, modifyItem = true) => {
        const newItem = { ...item, documents: item.documents.map((doc) => f(doc)) };
        return [modifyItem ? f(newItem) : newItem, ...state.collection.filter((x) => x.parentId === newItem.id).map((x) => recursion(x, f))];
      };
      const changes = flatten(recursion(item, (x) => ({ ...x, accesses: without([...payload.accesses, ...payload.accessesToRemove], x.accesses) }), false));
      const idxs = changes.map((c) => ({ idx: findIndex((x) => x.id === c.id, state.collection), item: c }));
      return {
        ...state,
        collection: idxs.reduce((acc, cur) => update(cur.idx, cur.item, acc), state.collection),
      }
    }
    case types.INVESTOR_DOCUMENTS_SET_FILTER:
      return { ...state, fundAccountFilter: payload.fundAccountId }
    default:
      return state;
  }
}
