const initialState = {
  collection: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SHOW_NOTES':
      return state;
    case 'HIDE_NOTES':
    case 'EDIT_NOTE':
    case 'CANCEL_EDIT_NOTE':
    case 'CREATE_NOTE':
    case 'UPDATE_NOTE':
    case 'DELETE_NOTE':
      return {
        ...state,
        collection: action.payload,
      };
    default:
      return state;
  }
};
