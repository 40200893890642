import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

const propTypes = {
  children: PropTypes.node.isRequired,
  is: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
  reverse: PropTypes.bool,
  when: PropTypes.string.isRequired,
};

const defaultProps = {
  reverse: false,
};

function FormCondition({ when, is, children, reverse }) {
  return (
    <Field name={when} subscription={{ value: true }}>
      {({ input: { value } }) => {
        let check;

        if (Array.isArray(is)) {
          check = is.some((item) => value.toString() === item.toString()) ? children : null;
        } else {
          check = is.toString() === value.toString();
        }

        if (reverse) check = !check;

        return check ? children : null;
      }}
    </Field>
  );
}

FormCondition.propTypes = propTypes;
FormCondition.defaultProps = defaultProps;

export default FormCondition;
