import React from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import { Input as AntInput } from 'antd';

import { getMask, getPlaceholder } from 'utils/forms';

const propTypes = {
  addonBefore: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  id: PropTypes.string,
  name: PropTypes.string,
  mask: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.func, PropTypes.string]),
  guide: PropTypes.bool,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  typeOfMask: PropTypes.oneOf(['phone', 'currency', 'decimalCurrency', 'percent', 'number', 'date', 'ssn', 'cvv']),
};

const defaultProps = {
  addonBefore: undefined,
  id: null,
  name: null,
  guide: true,
  mask: null,
  placeholder: null,
  typeOfMask: null,
  type: 'text',
};

function TextInput({ addonBefore, mask, typeOfMask, guide, ...props }) {
  const { placeholder } = props;
  const resultMask = getMask(mask, typeOfMask);
  const maskPlaceholder = getPlaceholder(placeholder, typeOfMask);

  const maskedProps = {
    guide,
    mask: resultMask,
    ...props,
    placeholder: maskPlaceholder,
  };

  if (resultMask) {
    if (addonBefore) {
      return (
        <MaskedInput
          {...maskedProps}
          render={(ref, ownProps) => (
            <span className="ant-input-group-wrapper">
              <span className="ant-input-wrapper ant-input-group">
                <span className="ant-input-group-addon">{addonBefore}</span>
                <input className="ant-input" ref={ref} {...ownProps} />
              </span>
            </span>
          )}
        />
      );
    }

    return <MaskedInput className="ant-input" {...maskedProps} />;
  }
  return <AntInput {...props} />;
}

TextInput.propTypes = propTypes;
TextInput.defaultProps = defaultProps;

export default TextInput;
