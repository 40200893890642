import { propEq, find, filter, addIndex, map } from 'ramda';
import { getIn } from 'utils/ramda';

const taskItemPatternsSelector = (state) => getIn('taskItemPatterns', state) || {};

export const collectionSelector = (state) => getIn('collection', taskItemPatternsSelector(state)) || [];

// eslint-disable-next-line radix
export const currentList = (listId, state) => filter((item) => item.taskListPatternId === parseInt(listId), state);

export const dataSource = (listId, state) => addIndex(map)((item, index) => ({
  key: index,
  id: item.id,
  title: item.title,
  role: item.fundUserRole,
  position: item.position,
  dependentTaskItemPatternId: item.dependentTaskItemPatternId,
  dependentTaskItemPatternTitle: item.dependentTaskItemPatternTitle,
  dependentTaskItemPatternPosition: item.dependentTaskItemPattern ? item.dependentTaskItemPattern.position : null,
}), currentList(listId, state));

// eslint-disable-next-line radix
export const getEditable = (id, collection) => find(propEq('id', parseInt(id)))(collection);

export const getDependentOptions = (collection, editable = null) => {
  if (editable) {
    return filter((item) => item.id !== editable.id && item.dependentTaskItemPatternId !== editable.id, collection);
  }

  return collection;
};
