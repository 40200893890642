import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import withReduxProvider from 'components/hocs/withReduxProvider';

import configureStore from './store';
import FundsBatchesTradeExercisesForm from './components/Form';
import { getTradeExerciseWithType, getRealizationsSummary } from './selectors';

import * as actions from './actions';

const mapStateToProps = (state) => ({
  resourceFund: state.resourceFund,
  tradeExerciseForm: {
    data: {
      ...getTradeExerciseWithType(state),
    },
  },
  realizations: state.realizations,
  security: state.security,
  realizationsSummary: getRealizationsSummary(state),
  securities: {
    data: state.securities ? state.securities.data : undefined,
  },
  submitting: state.submitting,
  errors: state.errors || null,
  success: state.success || null,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default withReduxProvider(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(FundsBatchesTradeExercisesForm),
  configureStore,
);
