export const MAPPING_CHANGE_INPUT = 'MAPPING_CHANGE_INPUT';

export const MAPPING_FORM_FILL = 'MAPPING_FORM_FILL';

export const MAPPING_SUBMITTING_FORM = 'MAPPING_SUBMITTING_FORM';
export const MAPPING_SUBMITTING_FORM_SUCCEEDED = 'MAPPING_SUBMITTING_FORM_SUCCEEDED';
export const MAPPING_SUBMITTING_FORM_FAILED = 'MAPPING_SUBMITTING_FORM_FAILED';

export const MAPPING_TRANSACTION_TYPE_UPDATE = 'MAPPING_TRANSACTION_TYPE_UPDATE';

export const MAPPING_CLEAR = 'MAPPING_CLEAR';
export const CHARTS_ACCOUNT_SELECT = 'CHARTS_ACCOUNT_SELECT';
export const UPDATE_SECURITY_KLASS = 'UPDATE_SECURITY_KLASS';

export const CLEAR_EVENT_AND_TRANSACTION_KEY = 'CLEAR_EVENT_AND_TRANSACTION_KEY';
export const CLEAR_TRANSACTION_KEY = 'CLEAR_TRANSACTION_KEY';
