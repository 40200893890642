import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form as FinalForm } from 'react-final-form';
import { Button, Icon } from 'antd';

import overrideFilterInitialValuesShape from 'constants/propTypes/funds/valuations';
import FilterForm from './Form';

const propTypes = {
  clearInitialValues: PropTypes.func,
  initialValues: PropTypes.shape(overrideFilterInitialValuesShape),
  handleSubmit: PropTypes.func,
};

const defaultProps = {
  clearInitialValues: undefined,
  initialValues: {},
  handleSubmit: undefined,
};

function ValuationOverrideFilter(props) {
  const { clearInitialValues, handleSubmit, initialValues } = props;
  const [visible, changeVisible] = useState(false);

  const showDrawer = () => changeVisible(true);
  const onClose = () => changeVisible(false);

  return (
    <div>
      <Button onClick={showDrawer}>
        <Icon type="filter" />
        <span>Filters</span>
      </Button>

      <FinalForm
        component={FilterForm}
        onSubmit={handleSubmit}
        mutators={{
          resetSeriesId: (name, state, { changeValue }) => {
            changeValue(state, 'seriesId', () => null);
          },
        }}
        onClose={onClose}
        initialValues={initialValues}
        visible={visible}
        clearInitialValues={clearInitialValues}
      />
    </div>
  );
}

ValuationOverrideFilter.propTypes = propTypes;
ValuationOverrideFilter.defaultProps = defaultProps;

export default ValuationOverrideFilter;
