import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Table, Icon, Tooltip, Drawer, Tree, Input, Typography, message } from 'antd';
import { map, isEmpty, filter, flatten, values, forEach, uniq, toLower } from 'ramda';

import { deleteAccount, inviteAccounts, inviteAccountDone } from 'actions/funds/accounts';
import * as accountStatuses from 'constants/account';
import { getAccountStatus, paginationProps, generateCaptcha } from 'utils/accounts';
import { getAccountCollection, associateEntitiesSelector } from 'selectors/funds/accounts';
import StatusIcon from './shared/StatusIcon';
import Explanation from './shared/Explanation';
import columns from './shared/columns';

// eslint-disable-next-line react/prop-types
const Accounts = ({ accountCollection, associates, currencySymbol, fundId, fundType, ...props }) => {
  // eslint-disable-next-line react/prop-types
  const { onDeleteAccount, onInviteAccounts, onInviteAccountDone, cableApp } = props;

  const [captcha, setCaptcha] = useState(() => generateCaptcha());
  const [compareCaptcha, setCompareCaptcha] = useState('');
  const [visible, setVisible] = useState(false);
  const [opened, setOpened] = useState(false);
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  const allKeysById = useMemo(() => {
    const result = {};

    forEach((account) => {
      result[account.id] = [];

      if (account.investor.email && account.webAccess && !account.user && !account.anotherUser) {
        result[account.id].push(`investor-${account.id}`);
      }

      forEach((associateId) => {
        const associate = associates[associateId];

        // eslint-disable-next-line react/prop-types
        if (associate.email && associate.webAccess && !associate.user && !associate.anotherUser) {
          // eslint-disable-next-line react/prop-types
          result[account.id].push(`associate-${associate.id}`);
        }
      }, account.associates);
    }, accountCollection);

    return result;
  }, [accountCollection, associates]);

  const allKeys = useMemo(() => flatten(values(allKeysById)), [accountCollection, associates]);

  const allExpandedKeys = useMemo(() => {
    const result = [];

    forEach((account) => {
      if (account.investor.email && account.webAccess && !account.anotherUser) {
        result.push(`account-${account.code}`);
      }

      forEach((associateId) => {
        const associate = associates[associateId];

        // eslint-disable-next-line react/prop-types
        if (associate.email && associate.webAccess && !associate.anotherUser) {
          result.push(`account-${account.code}`);
        }
      }, account.associates);
    }, accountCollection);

    return uniq(result);
  }, [accountCollection, associates]);

  const resetCaptcha = useCallback(() => {
    setCompareCaptcha('');
    setCaptcha(() => generateCaptcha());
  }, [captcha]);

  const openInviteModal = useCallback(
    (account = null) => {
      resetCaptcha();
      setSearchValue('');
      setExpandedKeys(() => (account ? [`account-${account.code}`] : [...allExpandedKeys]));
      setCheckedKeys(() => (account ? allKeysById[account.id] : allKeys));
      setVisible(true);
    },
    [accountCollection, associates],
  );

  const onInvite = useCallback(() => {
    resetCaptcha();
    onInviteAccounts([...checkedKeys]);
    setCheckedKeys([]);
  }, [checkedKeys]);

  const onExpand = useCallback((keys) => setExpandedKeys([...keys]), [expandedKeys]);

  const onCheck = useCallback(
    (keys, e) => {
      const [type] = e.node.props.eventKey.split('-');

      // eslint-disable-next-line max-len
      if (
        e.checked &&
        e.node.props.status !== accountStatuses.STATUS_NO_USER &&
        e.node.props.status !== accountStatuses.STATUS_PROCESS
      ) {
        if (type === 'account') {
          // eslint-disable-next-line max-len
          message.warning(
            'Users will be invited or re-invited. If the user already exists, a new password will be set.',
          );
        } else {
          message.warning('User will be re-invited. A new password will be set for this user.');
        }
      }

      setCheckedKeys(
        map(
          (checkedNode) => checkedNode.key,
          filter((checkedNode) => !checkedNode.props.disabled && !checkedNode.props.disableCheckbox, e.checkedNodes),
        ),
      );
    },
    [checkedKeys],
  );

  const onSearch = useCallback(
    (e) => {
      const { value } = e.target;
      const lowerValue = toLower(value);
      const data = [];

      forEach((account) => {
        const accountKey = `account-${account.code}`;

        if (toLower(account.name).indexOf(lowerValue) > -1) {
          data.push(accountKey);
        }

        forEach((associateId) => {
          // eslint-disable-next-line react/prop-types
          if (toLower(associates[associateId].name).indexOf(lowerValue) > -1) {
            data.push(accountKey);
          }
        }, account.associates);
      }, accountCollection);

      setExpandedKeys(uniq(data));
      setSearchValue(lowerValue);
    },
    [accountCollection, associates],
  );

  // eslint-disable-next-line react/prop-types
  const Name = ({ item, associate = false }) => {
    // eslint-disable-next-line react/prop-types
    const { name, role } = item;
    // eslint-disable-next-line react/prop-types
    const index = toLower(name).indexOf(searchValue);
    // eslint-disable-next-line react/prop-types
    const beforeStr = name.substr(0, index);
    // eslint-disable-next-line react/prop-types
    const searchStr = name.substr(beforeStr.length, searchValue.length);
    // eslint-disable-next-line react/prop-types
    const afterStr = name.substr(index + searchValue.length);
    const type = associate ? role : 'Investor';

    return index > -1 ? (
      <span className="found-item">
        {beforeStr}
        <span style={{ color: '#f50' }}>{searchStr}</span>
        {afterStr}
        {` - ${type}`}
      </span>
    ) : (
        <span>{`${name} - ${type}`}</span>
      );
  };

  useEffect(() => {
    if (opened) {
      const element = document.getElementsByClassName('ant-tree-treenode-switcher-open')[0];

      if (element) {
        element.scrollIntoView({
          block: 'center',
          behavior: 'smooth',
        });
      }
    }
  }, [opened]);

  useEffect(() => {
    // eslint-disable-next-line react/prop-types
    cableApp.createSubscription('WebPortalInviteNotificationsChannel', (data) => onInviteAccountDone(data));
  }, []);

  useEffect(() => {
    if (!isEmpty(searchValue)) {
      const element = document.getElementsByClassName('found-item')[0];

      if (element) {
        setTimeout(() => element.scrollIntoView({ block: 'center', behavior: 'smooth' }), 1);
      }
    }
  }, [searchValue]);

  return (
    <>
      <div className="panel__body">
        <Table
          dataSource={accountCollection}
          rowKey="id"
          columns={columns(accountCollection, fundType, currencySymbol, onDeleteAccount, openInviteModal)}
          size="small"
          pagination={paginationProps(fundId)}
        />
      </div>
      <Drawer
        title="Invite to Portal"
        placement="left"
        width="55%"
        closable={false}
        onClose={() => setVisible(false)}
        afterVisibleChange={() => setOpened(visible)}
        visible={visible}
        bodyStyle={{ paddingBottom: 80 }}
        destroyOnClose
      >
        <div style={{ display: 'flex', marginBottom: 8, width: '80%', position: 'sticky', top: 0, zIndex: 99999 }}>
          <Input.Search placeholder="Search" onChange={onSearch} allowClear />
        </div>
        <Tree
          onExpand={onExpand}
          onCheck={onCheck}
          checkedKeys={[...checkedKeys]}
          expandedKeys={[...expandedKeys]}
          switcherIcon={<Icon type="down" />}
          selectable={false}
          showIcon
          autoExpandParent
          checkable
        >
          {/* eslint-disable-next-line react/prop-types */}
          {accountCollection.map((account) => (
            <Tree.TreeNode title={account.displayTitle} key={`account-${account.code}`}>
              <>
                <Tree.TreeNode
                  status={getAccountStatus(account)}
                  icon={<StatusIcon accountOrAssociate={account} />}
                  title={
                    !account.webAccess ? (
                      <>
                        <Tooltip placement="right" title="No Web Access">
                          <Name item={account} />
                        </Tooltip>
                        <Explanation receiveReports={account.receiveReports} />
                      </>
                    ) : (
                      <>
                        <Name item={account} />{' '}
                        {account.investor.email ? (
                          // eslint-disable-next-line jsx-a11y/anchor-is-valid
                          <a className="ant-btn-link">{account.emails.join(', ')}</a>
                        ) : (
                          <Typography.Text type="danger">No Main Email</Typography.Text>
                        )}
                      </>
                    )
                  }
                  key={`investor-${account.id}`}
                  disabled={!account.webAccess}
                  disableCheckbox={
                    !account.investor.email ||
                    account.anotherUser ||
                    getAccountStatus(account) === accountStatuses.STATUS_PROCESS
                  }
                />
              </>
              {account.associates.map((associateId) => (
                <Tree.TreeNode
                  status={getAccountStatus(associates[associateId])}
                  icon={<StatusIcon accountOrAssociate={associates[associateId]} />}
                  title={
                    !associates[associateId].webAccess ? (
                      <>
                        <Tooltip placement="right" title="No Web Access">
                          <Name item={associates[associateId]} associate />
                        </Tooltip>
                        <Explanation receiveReports={associates[associateId].receiveReports} />
                      </>
                    ) : (
                        <>
                          <Name item={associates[associateId]} associate />{' '}
                          {associates[associateId].email ? (
                            // eslint-disable-next-line jsx-a11y/anchor-is-valid
                            <a className="ant-btn-link">{associates[associateId].email}</a>
                          ) : (
                              <Typography.Text type="danger">No Email</Typography.Text>
                            )}
                        </>
                      )
                  }
                  key={`associate-${associates[associateId].id}`}
                  disabled={!associates[associateId].webAccess}
                  disableCheckbox={
                    !associates[associateId].email ||
                    associates[associateId].anotherUser ||
                    getAccountStatus(associates[associateId]) === accountStatuses.STATUS_PROCESS
                  }
                />
              ))}
            </Tree.TreeNode>
          ))}
        </Tree>
        <div
          style={{
            position: 'absolute',
            zIndex: 999999,
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            display: 'flex',
          }}
        >
          <Input.Group style={{ margin: '0 8px' }} compact>
            <Button className="captcha" onClick={resetCaptcha}>
              {captcha}
            </Button>
            <Input
              style={{
                width: '35%',
                textAlign: 'left',
              }}
              onChange={(e) => setCompareCaptcha(e.target.value)}
              value={compareCaptcha}
              placeholder="Please, enter the captcha..."
            />
            <Button onClick={onInvite} type="primary" disabled={captcha !== compareCaptcha || isEmpty(checkedKeys)}>
              Invite
            </Button>
          </Input.Group>
          <Button onClick={() => setVisible(false)}>Close</Button>
        </div>
      </Drawer>
    </>
  );
};

const mapStateToProps = (state) => ({
  accountCollection: getAccountCollection(state),
  associates: associateEntitiesSelector(state),
  currencySymbol: state.fund.details.currencySymbol,
  fundId: state.fund.details.id,
  fundType: state.fund.details.type,
});

const mapDispatchToProps = (dispatch, { match: { params } }) => {
  const { fundId } = params;

  return {
    onDeleteAccount: bindActionCreators((id) => deleteAccount(fundId, id), dispatch),
    onInviteAccounts: bindActionCreators(inviteAccounts, dispatch),
    onInviteAccountDone: bindActionCreators((data) => inviteAccountDone(data), dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Accounts);
