import { ACCRUAL_ACCOUNT_SELECT, ACCRUAL_INITIAL_VALUES_CREATE } from 'constants/actionTypes';

const initialState = {};

export default function(state = initialState, action) {
  switch (action.type) {
    case ACCRUAL_ACCOUNT_SELECT:
      return {
        ...state,
        details: {
          ...state.details,
          [action.payload.field]: action.payload.value,
        },
      };

    case ACCRUAL_INITIAL_VALUES_CREATE:
      return {
        ...state,
        initialValues: action.payload,
      };

    default:
      return state;
  }
}
