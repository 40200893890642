import React, { Component } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { debounce } from 'lodash';
import FontAwesome from 'react-fontawesome';

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  fluid: PropTypes.bool,
  id: PropTypes.string,
  size: PropTypes.number,
  defaultSize: PropTypes.number,
  isVisible: PropTypes.bool,
  onVisibleChange: PropTypes.func,
  onSizeChange: PropTypes.func,
  dimStyle: PropTypes.object,
  duration: PropTypes.number,
};

const defaultProps = {
  id: null,
  fluid: true,
  defaultSize: 0.7,
  duration: 200,
};

class Dock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      size: props.size || props.defaultSize,
      isDimOpacity: !props.isVisible,
      fullWidth: typeof window !== 'undefined' && window.innerWidth,
      fullHeight: typeof window !== 'undefined' && window.innerHeight,
      isTransitionStarted: false,
      isWindowResizing: false,
    };

    const body = document.querySelector(`#body`); // eslint-disable-line
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);

    if (!window.fullWidth) {
      this.updateWindowSize();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.isVisible !== nextProps.isVisible) {
      nextProps.isVisible ? body.classList.add('body-no_overflowed') : body.classList.remove('body-no_overflowed');
      this.setState({
        isTransitionStarted: true,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.isVisible !== prevProps.isVisible) {
      if (!this.props.isVisible) {
        this.hideDim();
      } else {
        this.setState({ isDimOpacity: false });
      }

      window.setTimeout(() => this.setState({ isTransitionStarted: false }), 0);
    }
  }

  transitionEnd = () => {
    this.setState({ isTransitionStarted: false });
  };

  hideDim = () => {
    if (!this.props.isVisible) {
      this.setState({ isDimOpacity: true });
    }
  };

  render() {
    const { id, children, isVisible } = this.props;
    const { size, isDimOpacity } = this.state;

    const dimStyles = Object.assign({}, ...getDimStyles(this.props, this.state));
    const dockStyles = Object.assign({}, ...getDockStyles(this.props, this.state));

    const dockClasses = classNames({
      dock: true,
      'dock-hidden': !isVisible,
    });

    return (
      <div className="dock_wrapper">
        {!isDimOpacity && <div className="dock_dim" style={dimStyles} onClick={this.handleDimClick} />}
        <div className={dockClasses} id={id} style={dockStyles}>
          <a
            onClick={(e) => {
              e.preventDefault();
              this.handleDimClick();
            }}
            href="#"
            className="dock__close"
          >
            <FontAwesome name="close" />
          </a>
          <div className="dock__content">
            {typeof children === 'function'
              ? children({
                  size,
                  isVisible,
                })
              : children}
          </div>
        </div>
      </div>
    );
  }

  handleDimClick = () => {
    this.props.onVisibleChange && this.props.onVisibleChange(false);
  };

  handleResize = () => {
    if (window.requestAnimationFrame) {
      window.requestAnimationFrame(this.updateWindowSize.bind(this, true));
    } else {
      this.updateWindowSize(true);
    }
  };

  updateWindowSize = (windowResize) => {
    const sizeState = {
      fullWidth: window.innerWidth,
      fullHeight: window.innerHeight,
    };

    if (windowResize) {
      this.setState({
        ...sizeState,
        isWindowResizing: windowResize,
      });

      this.debouncedUpdateWindowSizeEnd();
    } else {
      this.setState(sizeState);
    }
  };

  updateWindowSizeEnd = () => {
    this.setState({
      isWindowResizing: false,
    });
  };

  debouncedUpdateWindowSizeEnd = debounce(this.updateWindowSizeEnd, 30);
}

const styles = {
  dimOpacity: {
    opacity: 0,
  },

  dockOpacity: {
    opacity: 0,
  },
};

function getDockStyles({ fluid, isVisible }, { size, fullWidth }) {
  const absSize = fluid ? `${fullWidth - 300}px` : `${size}px`;

  function getRestSize(fullSize) {
    return fluid ? 300 : `${fullSize - size}px`;
  }

  const posStyle = {
    left: isVisible ? getRestSize(fullWidth) : fullWidth,
    width: isVisible ? absSize : 0,
  };

  return [posStyle, !isVisible && styles.dockOpacity];
}

function getDimStyles({ isVisible }, { isTransitionStarted }) {
  return [!isVisible && styles.dimOpacity, isTransitionStarted && isVisible && styles.dimOpacity];
}

Dock.propTypes = propTypes;
Dock.defaultProps = defaultProps;

export default Dock;
