import { applyMiddleware, combineReducers, createStore, compose } from 'redux';
import thunk from 'redux-thunk';
import humps from 'humps';

import * as reducers from './reducer';

const policies = (state = {}) => state;

const reducer = combineReducers({ ...reducers, policies });

export default function configureStore(initialState) {
  const camelizeState = humps.camelizeKeys(initialState);

  const state = {
    ...camelizeState,
  };

  const enhancers = [
    applyMiddleware(thunk),
  ];

  if (process.env.NODE_ENV !== 'production') {
    window.devToolsExtension && enhancers.push(window.devToolsExtension());
  }

  const store = createStore(reducer, state, compose(...enhancers));

  return store;
}
