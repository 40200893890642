import { Tree as AntdTree, Icon, Button, Tooltip } from 'antd';
import { uniq, without, flatten } from 'ramda';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import deepMap from 'utils/trees/deepMap';

import { parseDocuments, buildDirectoryTree } from 'actions/funds/investorDocumentDirectories';
import { enableCreateNewDirectory, disableCreateNewDirectory } from 'actions/funds/investorDocumentDirectories/directories'
import { treeDataSelector, treeCollectionSelector, treeDocumentsSelector, getFundAccountFilter } from 'selectors/funds/investorDocumentDirectories';
import renderTree from 'utils/trees/renderTree';

import TreeNode from './TreeNode';
import styles from './tree.module.css';

const { DirectoryTree } = AntdTree;

function Tree(props) {
  const { match, currentUpload, currentPattern, currentFundAccount, isLoadingSetPattern, isSettingPrivateFolders, isBindingDirectory, isBindingDocument, asyncRemoveDirectory,
    isRemovingDirectory,
    asyncRemoveDocument,
    isRemovingDocument } = props;
  const { params: { fundId } } = match;
  const dispatch = useDispatch();
  const treeData = useSelector(treeDataSelector);
  const treeCollection = useSelector(treeCollectionSelector);
  const filterAccount = useSelector(getFundAccountFilter);
  const treeDocuments = useSelector((state) => treeDocumentsSelector(state, currentFundAccount));
  const [expandedKeys, setExpandedKeys] = useState([])
  const [renamingKey, setRenamingKey] = useState(-1);
  const isUploading = !!currentUpload;

  if (treeCollection.length > 0 && treeData.length === 0) {
    dispatch(parseDocuments());
    dispatch(buildDirectoryTree());
    setExpandedKeys(uniq(treeCollection.filter((item) => item.parentId).map(({ parentId }) => `${parentId}`)));
  }

  useEffect(() => {
    setExpandedKeys(uniq([
      ...treeCollection.filter((item) => item.parentId).map(({ parentId }) => `${parentId}`),
      ...treeDocuments.map(({ fundInvestorDocumentDirectoryId: parentId }) => `${parentId}`)
    ]));
  }, [(treeDocuments || []).length]);

  const treeNodeIcon = (node) => {
    const { isPrivate, isDocument, accesses, inheritedAccesses, children, isCreating } = node;
    if (isCreating) {
      return ({ expanded }) => expanded ? <Icon type="folder-open" theme="filled" /> : <Icon type="folder" theme="filled" />
    }

    const notAccessedDocuments = isDocument || isPrivate || accesses.length > 0 || inheritedAccesses.length > 0 ? false : flatten(deepMap((child) => ([!!child.isDocument && child.accesses.length === 0 && child.inheritedAccesses.length === 0, child.children]), children));
    const containsNotAccessedDocuments = notAccessedDocuments && notAccessedDocuments.some(bool => bool);
    const containsAccessedDocuments = notAccessedDocuments && notAccessedDocuments.some(bool => !bool);
    const isAccessed = accesses.length > 0 || inheritedAccesses.length > 0;
    const style = {
      style: {
        color: isPrivate
          ? '#5b5b5b'
          : isDocument
            ? isAccessed
              ? '#1790ff'
              : '#f84d4f'
            : containsNotAccessedDocuments
              ? '#f8a94f'
              : '#1790ff',
      }
    };
    const PrivateDecorator = ({ children }) => isPrivate ? (
      <Tooltip title={`Private ${isDocument ? 'document' : 'folder'}`}>
        {React.cloneElement(children, { ...style })}
      </Tooltip>
    ) : (
        <Tooltip title={
          isDocument && !isAccessed
            ? 'Unshared document'
            : !isDocument && containsNotAccessedDocuments
              ? `${containsAccessedDocuments ? 'Shared' : 'Unshared'} folder, contains unshared documents`
              : `Shared ${isDocument ? 'document' : 'folder'}`}>
          {React.cloneElement(children, { ...style })}
        </Tooltip>
      );
    if (isDocument) {
      return (
        <PrivateDecorator>
          <Icon type="file" theme="filled" />
        </PrivateDecorator>
      )
    }
    return ({ expanded }) => {
      if (expanded) {
        return (
          <PrivateDecorator>
            <Icon type="folder-open" theme="filled" />
          </PrivateDecorator>
        )
      }
      return (
        <PrivateDecorator>
          <Icon type="folder" theme="filled" />
        </PrivateDecorator>
      )
    }
  }

  return (
    <>
      {treeData && treeData.length > 0 && (
        <DirectoryTree
          className={styles.tree}
          selectable={false}
          expandedKeys={expandedKeys}
          onExpand={(arr) => setExpandedKeys(arr)}
          blockNode
        >
          {renderTree(treeData, TreeNode, ({ key, parentId, isCreating }) => ({
            id: Number(key.split('-')[0]),
            fundId,
            isEditing: renamingKey === key,
            setIsEditing: (bool) => { if (!isCreating) { dispatch(disableCreateNewDirectory()); } setRenamingKey(bool ? key : -1) },
            setIsOpen: (bool) => { setExpandedKeys(bool ? uniq([...expandedKeys, key]) : without(key, expandedKeys)) },
            isUploading,
            isLoadingSetPattern,
            isSettingPrivateFolders,
            isBindingDirectory,
            isBindingDocument,
            asyncRemoveDirectory,
            isRemovingDirectory,
            asyncRemoveDocument,
            isRemovingDocument
          }), (node) => ({
            icon: treeNodeIcon(node),
          }))}
        </DirectoryTree>
      )}
      {!(treeData || []).some((x) => x.key === 'null-folder') && !filterAccount && <Button type="link" icon="plus" onClick={() => dispatch(enableCreateNewDirectory(null, fundId))}>Add folder</Button>}
      {filterAccount && (!treeData || treeData.length === 0) ? 'No directories or documents are assigned to this fund account' : null}
    </>
  )
}

export default Tree