import numeral from 'numeral';
import { reject, forEach } from 'lodash';
import humps from 'humps';
import { findIndex, propEq } from 'ramda';

import { accounting } from 'accounting';
import { stringToDate, isDate } from 'utils/dateTransformations';
import { getAllocatedAmountsSum, getSelectedAccounts } from 'components/shared/AllocatedAmounts/data/selectors';
import { toggleChartOfAccounts } from 'components/shared/ChartOfAccounts/actions';
import { submitForm } from 'actions/submitForm';
import { toggleAllocatedAmount } from 'components/shared/AllocatedAmounts/data/actions';
import { getAllocatedAmounts } from 'selectors/allocatedAmounts';

import axios from 'axios';
import { getSubmitingFormData } from './selectors';
import { buildEntry } from './calculateAmounts';
import { fetchAccounts, fetchInvestorSeries, fetchInvestorClasses } from '../actions';
import { fundSelector } from '../selectors';

import * as types from './actionTypes';

export * from 'actions/changeInput';

export function onSubmit(e) {
  return (dispatch, getState) => {
    e.preventDefault();

    const formData = getSubmitingFormData(getState());

    return dispatch(
      formData.id
        ? submitForm(
          humps.decamelizeKeys(formData),
          Routes.fund_gl_entry_path(formData.fundId, formData.id, { format: 'json' }),
          'put',
        )
        : submitForm(
          humps.decamelizeKeys(formData),
          Routes.fund_gl_entries_path(formData.fundId, { format: 'json' }),
          'post',
        ),
    );
  };
}

export function accountingDateInputChange(event) {
  const date = event.value || event.target.value;

  return async (dispatch, getState) => {
    const {
      glEntryForm,
      resourceFund: { baseCurrencyIso, pricingMethodology },
    } = getState();
    const target = event.target ? event.target : event;
    const { currencyIso } = glEntryForm.data;
    const { fundId } = glEntryForm.data;

    dispatch({
      type: types.ACCOUNTING_DATE_INPUT_CHANGE,
      payload: {
        fundId,
        currencyIso,
        fundBaseCurrencyIso: baseCurrencyIso,
        accountingDate: stringToDate(target.value),
        priceType: pricingMethodology,
      },
    });

    if (isDate(date)) {
      await dispatch(fetchAccounts(fundId, date, true));
      dispatch(fetchInvestorSeries(fundId, date));
      dispatch(fetchInvestorClasses(fundId, date));
    }
    const state = getState();
    const { accounts } = fundSelector(state);
    const allocatedAmounts = getAllocatedAmounts(state);

    allocatedAmounts.forEach((amount) => {
      const accountIndex = findIndex(propEq('id', amount.targetId))(accounts);
      if (accountIndex === -1) {
        dispatch(toggleAllocatedAmount(amount));
      }
    });

    forEach(glEntryForm.data.lineItems, (lineItem, index) => {
      dispatch(checkLineItemSpecialAllocationTotal(index));
    });

    dispatch(setEntry());
  };
}

export function currencyInputChange(event, fundBaseCurrencyIso, accountingDate) {
  return (dispatch, getState) => {
    const {
      glEntryForm,
      resourceFund: { pricingMethodology },
    } = getState();
    const { fundId } = glEntryForm.data;

    dispatch({
      type: types.CURRENCY_INPUT_CHANGE,
      payload: {
        fundId,
        currencyIso: event.target.value,
        fundBaseCurrencyIso,
        accountingDate,
        priceType: pricingMethodology,
      },
    });

    dispatch(setEntry());
  };
}

export function addLineItem() {
  return { type: types.LINE_ITEM_ADD };
}

export function removeLineItem(index) {
  return (dispatch) => {
    dispatch({
      type: types.LINE_ITEM_REMOVE,
      payload: {
        index,
      },
    });

    dispatch(setEntry());
  };
}

export function selectAccount(chartAccountId) {
  return (dispatch, getState) => {
    const { fieldForSelectAccount, entities } = getState().chartOfAccounts;
    const fieldId = Number(fieldForSelectAccount.split('.')[2]);

    dispatch({
      type: types.ACCOUNT_SELECT,
      payload: {
        value: chartAccountId,
        field: fieldForSelectAccount,
        name: entities.accounts[chartAccountId].displayTitle,
      },
    });
    dispatch(checkLineItemSpecialAllocationTotal(fieldId));
    dispatch(toggleChartOfAccounts());
    dispatch(setEntry());
  };
}

export function changeAmount(event, index, type) {
  return (dispatch) => {
    dispatch({
      type: types.AMOUNT_CHANGE,
      payload: {
        amount: numeral(event.target.value).value(),
        type,
        index,
      },
    });

    dispatch(setEntry());
    dispatch(setLineItemSpecialAllocation(index, false));
    dispatch(checkLineItemSpecialAllocationTotal(index));
  };
}

export function toggleLineItemAllocatedAmounts(lineItemIndex) {
  return (dispatch, getState) => {
    const { showAllocatedAmounts } = getState().glEntryForm.data.lineItems[`${lineItemIndex}`];

    dispatch({
      type: types.ALLOCATED_AMOUNTS_TOGGLE,
      payload: {
        lineItemIndex,
      },
    });

    if (showAllocatedAmounts) {
      dispatch(setLineItemSpecialAllocationTotal(lineItemIndex));
      const selectedAccounts = getSelectedAccounts(getState(), { parentId: lineItemIndex });
      if (getState().glEntryForm.data.lineItems[`${lineItemIndex}`].specialAllocationTotal === 0 && selectedAccounts === 1) {
        dispatch(setLineItemSpecialAllocation(lineItemIndex, false));
      }

      dispatch(checkLineItemSpecialAllocationTotal(lineItemIndex));
    }
  };
}

export function setAllocatedAmountsType(lineItemIndex, value) {
  return (dispatch, getState) => {
    dispatch({
      type: types.ALLOCATED_AMOUNTS_SET_TYPE,
      payload: {
        lineItemIndex,
        value,
      },
    });

    if (value === 'manual') {
      const state = getState();
      const allocatedAmounts = getAllocatedAmounts(state);

      allocatedAmounts.forEach((i, idx) => {
        if (i.parentId === lineItemIndex) {
          dispatch({
            type: types.ALLOCATED_AMOUNT_SET_SPECIAL,
            payload: {
              stateKey: `${idx}.special`,
            },
          });
        }
      });
    }

    dispatch(setEntry());
  };
}

function setLineItemSpecialAllocationTotal(lineItemIndex) {
  return (dispatch, getState) => {
    const lineItemSpecialAllocationTotal = getAllocatedAmountsSum(getState(), { parentId: lineItemIndex });

    dispatch({
      type: types.LINE_ITEM_SPECIAL_ALLOCATION_TOTAL_SET,
      payload: {
        lineItemIndex,
        total: lineItemSpecialAllocationTotal,
      },
    });
  };
}

export function checkLineItemSpecialAllocationTotal(lineItemIndex) {
  return (dispatch, getState) => {
    const lineItem = getState().glEntryForm.data.lineItems[`${lineItemIndex}`];
    const special = lineItem.specialAllocation;
    const specialTypeManual = lineItem.specialAllocationType === 'manual';

    if (special) {
      const amount = accounting.formatNumber(lineItem.debitAmount || lineItem.creditAmount, 2);
      const lineItemSpecialAllocationTotal = accounting.formatNumber(lineItem.specialAllocationTotal, 2);
      const selectedAccounts = getSelectedAccounts(getState(), { parentId: lineItemIndex });

      if ((specialTypeManual || selectedAccounts.length === 1) && lineItemSpecialAllocationTotal !== amount) {
        dispatch(setLineItemSpecialAllocationTotalError(lineItemIndex));
      } else {
        dispatch(clearLineItemSpecialAllocationTotalError(lineItemIndex));
      }
    } else {
      dispatch(clearLineItemSpecialAllocationTotalError(lineItemIndex));
    }
  };
}

export function setLineItemSpecialAllocation(lineItemIndex, toggle = true) {
  return (dispatch, getState) => {
    const special = toggle ? !getState().glEntryForm.data.lineItems[`${lineItemIndex}`].specialAllocation : false;

    dispatch({
      type: types.LINE_ITEM_SPECIAL_ALLOCATION_SET,
      payload: {
        lineItemIndex,
        special,
      },
    });

    dispatch(setEntry());

    if (special) {
      dispatch(toggleLineItemAllocatedAmounts(lineItemIndex));
    }
    dispatch(checkLineItemSpecialAllocationTotal(lineItemIndex));
  };
}

export function changeEntry(event) {
  const target = event.target ? event.target : event;
  const targetValue = target.getAttribute('data-value') || target.value;

  return {
    type: types.ENTRY_CHANGE,
    payload: {
      value: targetValue,
      changed: true,
    },
  };
}

export function changeDescription(event) {
  const target = event.target ? event.target : event;
  const targetValue = target.getAttribute('data-value') || target.value;

  return {
    type: types.DESCRIPTION_CHANGE,
    payload: {
      value: targetValue,
      changed: true,
    },
  };
}

function setLineItemSpecialAllocationTotalError(lineItemIndex) {
  return {
    type: types.LINE_ITEM_SPECIAL_ALLOCATION_TOTAL_ERROR_SET,
    payload: {
      lineItemIndex,
      errorText: 'Line Item amount and sum of allocated amounts must be equal.',
    },
  };
}

function clearLineItemSpecialAllocationTotalError(lineItemIndex) {
  return {
    type: types.LINE_ITEM_SPECIAL_ALLOCATION_TOTAL_ERROR_CLEAR,
    payload: {
      lineItemIndex,
    },
  };
}

function setEntry() {
  return (dispatch, getState) => {
    const { glEntryForm } = getState();
    const { entryChanged, entryNeedBeUpdated } = glEntryForm.meta;

    if (entryChanged) {
      if (!entryNeedBeUpdated) {
        dispatch({
          type: types.ENTRY_NEED_BE_UPDATED,
        });
      }

      return false;
    }

    const { currencyIso } = glEntryForm.data;
    const activeLineItems = reject(glEntryForm.data.lineItems, (a) => a._destroy);
    const entry = buildEntry(activeLineItems, currencyIso);

    dispatch({
      type: types.ENTRY_SET,
      payload: {
        entry,
      },
    });
  };
}
