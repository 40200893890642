import { createSelector } from 'reselect';
import { accounting } from 'accounting';
import { get, merge } from 'lodash';

const resourceFundSelector = (state) => state.resourceFund;
const investorTransactionFormSelector = (state) => state.investorTransactionForm;

export const getAccountInfo = createSelector(
  resourceFundSelector,
  investorTransactionFormSelector,
  (resourceFund, investorTransaction) => {
    const { investorClass, investorSeries, openDate, closeDate } = investorTransaction.meta;
    const data = {};

    merge(data, {
      ofAllowableShares: { value: investorClass ? investorClass.ofAllowableShares : null, type: 'units' },
      dates: { label: 'Open/Close', value: openDate ? `${openDate}${closeDate ? ` - ${closeDate}` : ''}` : null, type: 'date' },
    });

    merge(data, {
      class: { value: get(investorClass, 'name'), type: 'text' },
      series: { value: get(investorSeries, 'name'), type: 'text' },
    });

    data.currencySymbol = resourceFund.currencySymbol;

    return data;
  },
);

export const getValuationInfo = createSelector(
  resourceFundSelector,
  investorTransactionFormSelector,
  (resourceFund, investorTransaction) => {
    const { lastValuation } = investorTransaction.meta;
    const data = {};

    if (lastValuation) {
      merge(data, {
        valuationAmount: { value: lastValuation.amount, type: 'currency' },
      });
    }

    merge(data, {
      valuationFrequency: { value: resourceFund.displayValuationFrequency, type: 'text' },
    });

    if (lastValuation) {
      merge(data, {
        valuationPeriod: { value: `${lastValuation.startDate} - ${lastValuation.endDate}`, type: 'date' },
      });
    }

    data.currencySymbol = resourceFund.currencySymbol;

    return data;
  },
);

export const getInfo = createSelector(
  resourceFundSelector,
  investorTransactionFormSelector,
  (resourceFund, investorTransaction) => {
    const { ofAllowableShares, firstInvestableDate } = resourceFund;
    const { data: { type } } = investorTransaction;
    const data = {};

    merge(data, {
      fundFirstInvestableDate: { value: firstInvestableDate, type: 'date' },
      ofAllowableShares: { value: ofAllowableShares, type: 'units' },
    });

    if (resourceFund.redemptionHoldbackPercentage && type === 'Fund::InvestorTransaction::Redemption') {
      merge(data, {
        redemptionHoldbackPercentage: {
          label: 'Redemption Holdback Percentage',
          value: resourceFund.redemptionHoldbackPercentage,
          type: 'percent',
        },
        minimumRedemptionPeriod: {
          label: 'Minimum Redemption Period',
          value: resourceFund.displayMinimumInvestmentPeriod,
          type: 'text',
        },
        redemptionFrequency: {
          label: 'Redemption Frequency',
          value: resourceFund.displayRedemptionFrequency,
          type: 'text',
        },
        redemptionPaymentTime: {
          label: 'Redemption Payment Time',
          value: resourceFund.displayRedemptionPaymentTime,
          type: 'text',
        },
      });
    }

    data.currencySymbol = resourceFund.currencySymbol;
    data.unitsDecimal = resourceFund.shareDecimalPrecision;

    return data;
  },
);

export const getNotice = createSelector(
  resourceFundSelector,
  investorTransactionFormSelector,
  (resourceFund, investorTransactionForm) => {
    const { redemptionHoldbackPercentage } = resourceFund;
    const { data: { percentage, type } } = investorTransactionForm;
    const result = { messages: [] };

    if (type === 'Fund::InvestorTransaction::Redemption' && percentage === 100) {
      result.messages.push(
        `This fund has Redemption Holdback Percentage of ${accounting.formatNumber(redemptionHoldbackPercentage, 2)}%.`,
      );
    }

    return result;
  },
);
