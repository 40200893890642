import React from 'react';
import { accounting } from 'accounting';
import { startCase } from 'lodash';

import { dateToString } from 'utils/dateTransformations';
import { Block, Stat } from 'components/shared';

const Investment = (props) => {
  const { resourceFund, trade, summary, security, submitting, errors, success, refs: { pePartnershipSecurityTradeTypes } } = props;
  const { getSecurityOptions, changeSecurity, removeSecurity, updateTradeDate, changeDateInput, changeInput, submitForm, changeFxRate, changeFeesCurrency } = props.actions;

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <Block
            title={'Summary'}
            fields={
              <div>
                <ul className='list list-unstyled'>
                  <li>
                    {'Type:'}
                    <strong>
                      {' '}
                      { trade.type ? startCase(trade.type.split('_').join(' ')) : '?' }
                    </strong>
                  </li>

                  {security.withSecurityPrice &&
                    <li>
                      Security Price on {dateToString(security.pricingDate)}:
                      <strong>
                        {' '}
                        {accounting.formatMoney(security.securityPrice, security.currencySymbol, 2, ',', '.')}
                      </strong>
                    </li>
                  }

                  <li>
                    Holdings on {summary.displayTradeDate}:
                    <strong>
                      {' '}
                      {accounting.formatMoney(security.currentHoldings, security.currencySymbol, 2, ',', '.')}
                    </strong>
                  </li>

                  <li>
                    Total Sells:
                    <strong>
                      {' '}
                      {accounting.formatMoney(security.totalSells, security.currencySymbol, 2, ',', '.')}
                    </strong>
                  </li>

                  <li>
                    Total Buys:
                    <strong>
                      {' '}
                      {accounting.formatMoney(security.totalBuys, security.currencySymbol, 2, ',', '.')}
                    </strong>
                  </li>

                  { trade.secondTradeType &&
                    <li>
                      <span>
                        Second Trade:
                        <strong>
                          {' '}
                          {trade.secondTradeType}
                        </strong>
                      </span>
                    </li>
                  }
                </ul>
              </div>
            }
          />
        </div>
      </div>
      <div className="row">
        { trade.fx ? (
          <div>
            <div className="col-md-12">
              <Stat hint='Gross Trade Amount' value={summary.displayGrossTradeAmount} />
            </div>
            <div className="col-md-12">
              <Stat hint='Gross Settle Amount' value={summary.displayGrossSettleAmount} />
            </div>
            <div className="col-md-12">
              <Stat hint='Total Fees & Taxes' value={summary.displayTotalFeesTradeAmount} />
            </div>
            <div className="col-md-12">
              <Stat hint='Net Trade Amount' value={summary.displayNetTradeAmount} />
            </div>
            <div className="col-md-12">
              <Stat hint='Net Settle Amount' value={summary.displayNetSettleAmount} />
            </div>
          </div>
        ) : (
          <div>
            <div className="col-md-12">
              <Stat hint='Gross Amount' value={summary.displayGrossAmount} />
            </div>
            <div className="col-md-12">
              <Stat hint='Total Fees & Taxes' value={summary.displayTotalFees} />
            </div>
            <div className="col-md-12">
              <Stat hint='Net Amount' value={summary.displayNetAmount} />
            </div>
          </div>
        ) }
      </div>
    </div>
  );
};

export default Investment;
