import { schema, normalize } from 'normalizr';

const mapping = new schema.Entity('mappings');

const mappingPattern = new schema.Entity('mappingPatterns');

const account = new schema.Entity('accounts', {
  mappings: [mapping],
});

const accountPattern = new schema.Entity('accountPatterns', {
  mappingPatterns: [mappingPattern],
});

const category = new schema.Entity('categories', {
  accounts: [account],
});

const categoryPattern = new schema.Entity('categoryPatterns', {
  accountPatterns: [accountPattern],
});

const chart = new schema.Entity(
  'charts',
  {
    categories: [category],
    categoryPatterns: [categoryPattern],
  },
  { idAttribute: 'key' },
);

export const accountNormalizr = (data) => normalize(Array.isArray(data) ? data : [data], [account]);
export const accountPatternNormalizr = (data) => normalize(Array.isArray(data) ? data : [data], [accountPattern]);
export const chartNormalizr = (data) => normalize(Array.isArray(data) ? data : [data], [chart]);
