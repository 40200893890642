import { Button, Select, Popconfirm, Input } from 'antd';
import { uniq, map, filter, sort } from 'ramda';
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { generateCaptcha } from 'utils/accounts';
import { setPattern, fetchSetFundAccountFilter, openDrawer } from 'actions/funds/investorDocumentDirectories';
import { getFundAccountEntities, getAccountsWithPrivateFolders, getFundAccountFilter } from 'selectors/funds/investorDocumentDirectories';
import { getSortedPatternsCollection } from 'selectors/investorDocumentPatterns';
import { useAsyncFetchDefer } from 'utils/useAsyncFetch';


import styles from './fundDocumentPatterns.module.css';

const { Option, OptGroup } = Select;

function SelectPattern(props) {
  const { match, currentPattern, isFetchingFundAccounts, asyncSetPattern, isLoadingSetPattern } = props;
  const { path, params: { fundId } } = match;
  const dispatch = useDispatch();
  const patterns = useSelector(getSortedPatternsCollection);
  const accountWithPrivateFolders = useSelector(getAccountsWithPrivateFolders);
  const filterAccount = useSelector(getFundAccountFilter);
  const fundAccounts = useSelector((state) => getFundAccountEntities(state, fundId));
  const fundSeries = uniq(map((acc) => ({ id: acc.investorSeries.id, name: acc.investorSeries.name, classId: acc.investorClass.id, className: acc.investorClass.name }), fundAccounts));
  const fundClasses = uniq(map(({ classId, className }) => ({ id: classId, name: className }), fundSeries));
  const [asyncSetFilter, isSettingFilter] = useAsyncFetchDefer(fetchSetFundAccountFilter);
  const [selected, setSelected] = useState(currentPattern);
  const [captcha, setCaptcha] = useState(() => generateCaptcha());
  const [compareCaptcha, setCompareCaptcha] = useState('');

  const resetCaptcha = useCallback(() => {
    setCompareCaptcha('');
    setCaptcha(() => generateCaptcha());
  }, [captcha]);

  const onChange = () => { resetCaptcha(); asyncSetPattern({ values: { pattern: selected }, fundId }) }

  const onChangeAccount = (fundAccountId) => { asyncSetFilter({ fundId, fundAccountId }) }

  return (
    <>
      <div className={styles.sides}>
        <div style={{ width: '100%' }}>
          <div className={styles.select}>
            <div className={styles.text}>Show documents for:</div>
            <Select
              showSearch
              style={{ width: 301 }}
              placeholder="Select a fund account"
              onChange={(i, item) => onChangeAccount(Number(item.key.split('-')[1]))}
              disabled={isFetchingFundAccounts || isSettingFilter}
              loading={isFetchingFundAccounts || isSettingFilter}
              dropdownMatchSelectWidth={false}
            >
              <Option key={`fundAccount-${0}`} value="No filter">No filter</Option>
              {map((series) => (
                <OptGroup key={`srs-${series.id}`} label={`${series.className}, ${series.name}`}>
                  {map(({ id, displayName, code }) => (
                    <Option key={`fundAccount-${id}`} value={`${displayName} / ${code}`}>
                      {`${displayName} / ${code}`}
                    </Option>
                  ), filter((a) => a.investorSeriesId === series.id, fundAccounts))}
                </OptGroup>
              ), sort((a, b) => a.classId - b.classId, sort((a, b) => a.id - b.id, fundSeries)))}
            </Select>
          </div>
          <div className={styles.select}>
            <div className={styles.text}>Manage private folders:</div>
            <Button disabled={isFetchingFundAccounts || isSettingFilter || !!filterAccount} onClick={() => dispatch(openDrawer({ accesses: accountWithPrivateFolders, type: 'PRIVATE_FOLDERS' }))}>
              {isSettingFilter || !!filterAccount ? 'Remove the filter to manage' : 'Select fund accounts'}
            </Button>
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.select}>
            <div style={{ display: 'flex' }}>
              <div className={styles.text}>Structure pattern:</div>
              <Select
                showSearch
                style={{ width: 200 }}
                placeholder="Select a pattern"
                onChange={(i, item) => setSelected(Number(item.key))}
                defaultValue={(patterns.find((item) => item.id === selected) || {}).title}
              >
                {patterns.map(({ id, title }) => (
                  <Option key={id} value={title}>
                    {title}
                  </Option>
                ))}
              </Select>
            </div>
            <Input.Group style={{ marginTop: '10px' }} compact>
              {currentPattern && (
                <>
                  <Button
                    className="captcha"
                    onClick={resetCaptcha}
                    disabled={currentPattern && selected === currentPattern}
                  >
                    {captcha}
                  </Button>
                  <Input
                    style={{
                      width: '200px',
                      textAlign: 'left',
                    }}
                    disabled={currentPattern && selected === currentPattern}
                    onChange={(e) => setCompareCaptcha(e.target.value)}
                    value={compareCaptcha}
                    placeholder="Please, enter the captcha..."
                  />
                </>
              )}
              <Button onClick={onChange} type="primary" disabled={currentPattern && captcha !== compareCaptcha} loading={isLoadingSetPattern}>
                Change pattern
            </Button>
            </Input.Group>
          </div></div>
      </div>
    </>
  )
}

export default SelectPattern;