import React, { Component } from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import classNames from 'classnames';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import { FormGroup } from '../';

const propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  dataStateKey: PropTypes.string,
  dataValueType: PropTypes.string,
  dataValueTypeStateKey: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  radioValue: PropTypes.bool,
  label: PropTypes.string,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onChecked: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
};

const defaultProps = {
  type: 'string',
  className: 'form__control',
  disabled: false,
  dataStateKey: undefined,
  dataValueType: undefined,
  dataValueTypeStateKey: undefined,
  value: '',
  radioValue: true,
  placeholder: '',
  label: undefined,
  required: false,
  checked: false,
  options: {},
};

class RadioSelectInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stringValue: props.value,
    };

    this.toogleRadio = this.toogleRadio.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const inputValue = this.input.inputElement.value;
    const stringValue = nextProps.value !== numeral(inputValue).value() ? nextProps.value : inputValue;

    return this.setState({
      stringValue,
    });
  }

  toogleRadio(e) {
    this.props.onChecked(e);

    setTimeout(() => this.input.inputElement.focus(), 0);
  }

  handleChange(e) {
    this.props.onChange(e);
  }

  clearDashedValue() {
    // Костыль для избавления от черточки
    setTimeout(
      () =>
        this.input &&
        (this.input.inputElement.value === '_'
          ? (this.input.inputElement.value = null)
          : this.input.inputElement.value),
      10,
    );
  }

  render() {
    const {
      id,
      type,
      name,
      dataStateKey,
      value,
      label,
      required,
      placeholder,
      className,
      errors,
      onChecked,
      unit,
      radioValue,
      checked,
      dataValueTypeStateKey,
      disabled,
      dataValueType,
      options
    } = this.props;
    const { stringValue } = this.state;
    const addonClass = classNames({
      form__addon_icon: true,
      'form__addon_icon-disabled': !checked,
    });

    const selectMask = (dataValueType, options = {}) => {
      switch (dataValueType) {
        case 'shares':
          return createNumberMask({
            allowDecimal: true,
            allowNegative: false,
            prefix: '',
            decimalLimit: options.decimalLimit,
          });
        case 'percentage':
          return createNumberMask({
            allowDecimal: true,
            allowNegative: false,
            includeThousandsSeparator: false,
            prefix: '',
          });
        default:
          return createNumberMask({
            allowDecimal: true,
            allowNegative: false,
            prefix: '',
          });
      }
    };

    let icon = null;

    if (dataValueType) {
      if (dataValueType == 'amount') {
        icon = <span className={addonClass}>{unit || '$'}</span>;
      }
      if (dataValueType == 'percentage') {
        icon = <span className={addonClass}>{unit || '%'}</span>;
      }
      if (dataValueType == 'shares') {
        icon = <span className={addonClass}>{unit || '#'}</span>;
      }
    }

    this.clearDashedValue();

    return (
      <FormGroup label={label} required={required} id={id} errors={errors}>
        <div className="form__addon form__addon-left">
          <span className="form__addon_icon">
            <input
              ref={(el) => (this.radio = el)}
              type="radio"
              value={radioValue}
              onChange={this.toogleRadio}
              checked={checked === true}
              data-value-type-state-key={dataValueTypeStateKey}
              data-value-type={dataValueType}
            />
          </span>
          {icon}
          <MaskedInput
            ref={(el) => (this.input = el)}
            id={id}
            type={type}
            required={required}
            name={name}
            data-statekey={dataStateKey}
            data-type="currency"
            value={stringValue}
            placeholder={placeholder}
            mask={selectMask(dataValueType, options)}
            className={className}
            onChange={this.handleChange}
            disabled={disabled || checked === false}
          />
        </div>
      </FormGroup>
    );
  }
}

RadioSelectInput.propTypes = propTypes;
RadioSelectInput.defaultProps = defaultProps;

export default RadioSelectInput;
