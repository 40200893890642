import API from 'apiAdapters/funds/investorDocumentDirectories/documents';
import * as types from 'constants/actionTypes';
import { treeDocumentsSelector } from 'selectors/funds/investorDocumentDirectories';
import { catcher } from 'utils/actions';
import { getIn } from 'utils/ramda';

import { closeDrawer, buildDirectoryTree, parseDocuments } from './index';



function create(payload, isPrivate) {
  return (dispatch) => {
    dispatch({
      type: types.INVESTOR_DOCUMENTS_CREATE_DOCUMENT, payload: {
        ...payload,
        parentId: payload.fundInvestorDocumentDirectoryId,
        isDocument: true,
        isLeaf: true,
        isPrivate
      }
    });
    dispatch(parseDocuments());
    dispatch(buildDirectoryTree());
  }
}

function update(id, props) {
  return (dispatch, getState) => {
    const parentId = getIn('fundInvestorDocumentDirectoryId', treeDocumentsSelector(getState()).find((item) => item.id === id));
    dispatch({ type: types.INVESTOR_DOCUMENTS_UPDATE_DOCUMENT, payload: { id, parentId, props } });
    dispatch(parseDocuments());
    dispatch(buildDirectoryTree());
  }
}

function remove(id) {
  return (dispatch) => {
    dispatch({ type: types.INVESTOR_DOCUMENTS_REMOVE_DOCUMENT, payload: { id } });
    dispatch(parseDocuments());
    dispatch(buildDirectoryTree());
  }
}

export function fetchBindDocument({ fundId, id, accesses }) {
  return catcher(async (dispatch) => {
    const { payload } = await API.bind({ fundId, id, accesses });
    dispatch(update(id, { accesses: payload }));
    dispatch(closeDrawer());
  })
}

export function fetchUploadDocuments({ fundId, folderId, files, accesses, isPrivate }) {
  return catcher(async (dispatch) => {
    const { payload } = await API.add({ fundId, folderId, files, isPrivate });
    payload.map((document) => dispatch(create(document, isPrivate)))

    if (!isPrivate) {
      payload.map(({ id }) => dispatch(fetchBindDocument({ fundId, id, accesses })));
    }
  })
}

export function fetchRenameDocument({ fundId, id, parentId, title }) {
  return catcher(async (dispatch) => {
    await API.rename({ fundId, id, parentId, title });
    dispatch(update(id, { title }));
  })
}

export function fetchRemoveDocument({ fundId, parentId, id }) {
  return catcher(async (dispatch) => {
    await API.remove({ fundId, parentId, id });
    dispatch(remove(id));
  });
}

export function fetchDownloadDocument(payload) {
  const { title, ...restPayload } = payload;
  return catcher(async () => {
    const resp = await API.download(restPayload);
    const header = resp.headers.get('content-disposition') || '';

    resp.blob().then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = (header.split('attachment; filename="')[1] || '').slice(0, -1) || 'document';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    });
  });
}