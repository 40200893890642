import * as types from 'constants/actionTypes';

export function deleteEntities(entityId, key) {
  return {
    type: types.ENTITIES_DELETE,
    payload: {
      entityId,
      key,
    },
  };
}

export function mergeEntities(entities) {
  return {
    type: types.ENTITIES_MERGE,
    payload: entities,
  };
}

export function syncEntities(entity, key) {
  return {
    type: types.ENTITIES_SYNC,
    payload: {
      entity,
      key,
    },
  };
}
