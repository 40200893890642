export * from 'constants/actionTypes';

export const MODE_CHANGE = 'MODE_CHANGE';

export const CHART_OF_ACCOUNTS_FETCH_REQUESTED = 'CHART_OF_ACCOUNTS_FETCH_REQUESTED';
export const CHART_OF_ACCOUNTS_FETCH_COMPLETED = 'CHART_OF_ACCOUNTS_FETCH_COMPLETED';
export const CHART_OF_ACCOUNTS_MODE_CHANGE = 'CHART_OF_ACCOUNTS_MODE_CHANGE';

export const CHART_OF_ACCOUNTS_TOGGLE = 'CHART_OF_ACCOUNTS_TOGGLE';
export const FIELD_FOR_SELECT_ACCOUNT_SET = 'FIELD_FOR_SELECT_ACCOUNT_SET';

export const ON_ACCOUNT_ADD = 'ON_ACCOUNT_ADD';
export const ON_ACCOUNT_UPDATE = 'ON_ACCOUNT_UPDATE';
export const ON_ACCOUNT_DELETE = 'ON_ACCOUNT_DELETE';

export const ON_CATEGORY_ADD = 'ON_CATEGORY_ADD';
export const ON_CATEGORY_UPDATE = 'ON_CATEGORY_UPDATE';
export const ON_CATEGORY_DELETE = 'ON_CATEGORY_DELETE';

export const ON_MAPPING_ADD = 'ON_MAPPING_ADD';
export const ON_MAPPING_DELETE = 'ON_MAPPING_DELETE';

export const ACCOUNT_QUERY_CHANGE = 'ACCOUNT_QUERY_CHANGE';

export const ACCOUNTS_TOGGLE = 'ACCOUNTS_TOGGLE';
export const MAPPINGS_TOGGLE = 'MAPPINGS_TOGGLE';
export const ALL_TOGGLE = 'ALL_TOGGLE';
