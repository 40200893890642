import fetch from '../fetch';
import { deleteRequest, postRequest, putRequest } from '../requests';

export default class InvestorDocumentPatternsAPI {
  static create(values) {
    return fetch(Routes.investor_document_patterns_path(), postRequest({ body: values }));
  }

  static update(values, id) {
    return fetch(Routes.investor_document_pattern_path(id), putRequest({ body: values }))
  }

  static delete(id) {
    return fetch(Routes.investor_document_pattern_path(id), deleteRequest());
  }
}
