export const dueDates = {
  t_plus_7: 'T+7',
  t_plus_10: 'T+10',
  t_plus_12: 'T+12',
  t_plus_15: 'T+15',
  t_plus_16_plus: 'T+16+',
};

export const statuses = {
  active: 'Active',
  startup: 'Startup',
  closed: 'Closed',
  hold: 'Hold',
  no_response: 'No Response',
  non_regular_work: 'Non Regular Work',
  feeder: 'Feeder',
  test: 'Test',
};

export const defaultStatuses = [
  'active',
  'test',
];

export const defaultMyFundsStatuses = [
  'active',
];
