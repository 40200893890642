import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, get, concat, omit } from 'lodash';
import classNames from 'classnames';

const propTypes = {
  messages: PropTypes.array,
};

const Warnings = (props) => {
  const { messages } = props;

  return (
    <div>
      {messages &&
        messages.map((message, k) => (
          <div key={k} className="alert alert-form alert-warning">
            {message}
          </div>
        ))}
    </div>
  );
};

Warnings.propTypes = propTypes;

export default Warnings;
