import React from 'react';
import PropTypes from 'prop-types';
import FontAwesome from 'react-fontawesome';
import { get } from 'lodash';

import { AllocatedAmounts, CurrencyInput, Checkbox, Dock, SelectChartOfAccount } from 'components/shared';

const propTypes = {
  glEntry: PropTypes.object.isRequired,
  lineItem: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  size: PropTypes.number.isRequired,
  currencySymbol: PropTypes.string.isRequired,
  fundBaseCurrencyIso: PropTypes.string.isRequired,
  actions: PropTypes.object.isRequired,
  errors: PropTypes.object,
};

const defaultProps = {
  errors: {},
};

function glEntriesLineItem(props) {
  const { glEntry, lineItem, index, size, currencySymbol, fundBaseCurrencyIso, actions, errors } = props;
  const isGlEntryInBaseCurrency = fundBaseCurrencyIso === glEntry.currencyIso;

  return lineItem._destroy !== true ? (
    <tr>
      <td>
        <SelectChartOfAccount
          field={`data.lineItems.${index}`}
          selectedAccountId={lineItem.accountId}
          errors={get(errors, `${index}.account`)}
          btnClass="btn btn-default btn-block"
        />
      </td>

      <td>
        {!lineItem.creditAmount && (
          <CurrencyInput
            id={`debit_amount${index}`}
            name={`gl_entry[line_items][][debit_amount]`}
            required
            dataStateKey={`data.lineItems.${index}.debitAmount`}
            value={lineItem.debitAmount}
            onChange={(arg) => actions.changeAmount(arg, index, 'debitAmount')}
            errors={get(errors, `${index}.amount`)}
            currency={currencySymbol}
          />
        )}
      </td>

      <td>
        {!lineItem.debitAmount && (
          <CurrencyInput
            id={`credit_amount${index}`}
            name={`gl_entry[line_items][][credit_amount]`}
            required
            dataStateKey={`data.lineItems.${index}.creditAmount`}
            value={lineItem.creditAmount}
            onChange={(arg) => actions.changeAmount(arg, index, 'creditAmount')}
            errors={get(errors, `${index}.amount`)}
            currency={currencySymbol}
          />
        )}
      </td>

      <td>
        {(lineItem.debitAmount || lineItem.creditAmount) && isGlEntryInBaseCurrency && (
          <div>
            <Checkbox
              id={`special_allocation${index}`}
              dataStateKey={`data.lineItems.${index}.specialAllocation`}
              name={`gl_entry[line_items][][special_allocation]`}
              checked={lineItem.specialAllocation}
              onChange={() => actions.setLineItemSpecialAllocation(index)}
              label="Special Allocation?"
              errors={get(errors, `${index}.specialAllocation`)}
            />

            <Dock
              isVisible={lineItem.showAllocatedAmounts}
              onVisibleChange={() => actions.toggleLineItemAllocatedAmounts(index)}
            >
              {lineItem.showAllocatedAmounts ? (
                <AllocatedAmounts
                  amount={lineItem.debitAmount || lineItem.creditAmount}
                  currencySymbol={currencySymbol}
                  date={glEntry.accountingDate}
                  parentId={index}
                  onChangeType={actions.setAllocatedAmountsType}
                  specialAllocationType={lineItem.specialAllocationType}
                />
              ) : (
                <FontAwesome name="spinner" spin />
              )}
            </Dock>
          </div>
        )}
      </td>
      <td>
        {lineItem.specialAllocation && (
          <div
            className="btn btn-default btn-xs"
            title="Show Special Allocations"
            onClick={() => actions.toggleLineItemAllocatedAmounts(index)}
          >
            <FontAwesome name="binoculars" />
          </div>
        )}
      </td>

      <td>
        {size > 2 && (
          <span className="btn btn-danger btn-width_sm" onClick={() => actions.removeLineItem(index)}>
            <i className="fa fa-trash" />
          </span>
        )}
      </td>
    </tr>
  ) : null;
}

glEntriesLineItem.propTypes = propTypes;
glEntriesLineItem.defaultProps = defaultProps;

export default glEntriesLineItem;
