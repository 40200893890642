import * as R from 'ramda';

import { dateIsValid } from 'utils/formats';

const regEmail = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const validateRequired = ({ message = 'must be filled' } = {}) => (value) => {
  return R.isNil(value) ? message : undefined;
};

export const validateEmpty = ({ message = 'must be filled' } = {}) => (value) => {
  return R.isEmpty(value) ? message : undefined;
};

export const validateRequiredNotStrict = ({ message = 'must be filled' } = {}) => (value) => {
  return value === undefined ? message : undefined;
};

export function validateAccepted({ message = 'must be accepted' } = {}) {
  return validateRequired.call(this, { message });
}

export const validateEquality = ({ message, base = true } = {}) => (value) => {
  const mess = message || `must be equal ${base.toString()}`;
  return value === base ? undefined : mess;
};

export const validateEmail = ({ message = 'is invalid', reg = regEmail } = {}) => (email) => {
  return email && !reg.test(String(email).toLowerCase()) ? message : undefined;
};

export const validatePhone = ({ message = 'is invalid' } = {}) => (phone) => {
  const onlyNums = phone ? phone.replace(/[^\d]/g, '') : undefined;
  return onlyNums && onlyNums.length !== 10 && message;
};

export const validateDate = ({ message = 'is invalid' } = {}) => (date) => {
  if (dateIsValid(date)) return undefined;
  return message;
};

export const validateMoreThanNull = ({ message = 'must be more than 0' } = {}) => (value) => {
  if (!value) {
    return 'must be filled';
  }

  if (value <= 0) {
    return message;
  }

  return undefined;
};

export const composeValidators = (...validators) => (...args) => {
  return validators.reduce((error, validator) => error || validator(...args), undefined);
};
