import { animateScroll } from 'react-scroll';

export default function(containerId = 'body') {
  const container = document.querySelector(`#${containerId} .alert-form`); // eslint-disable-line

  const elem = container || document.querySelector('#body .alert-form'); // eslint-disable-line

  animateScroll.scrollTo(calculateScrollOffset(elem, -15), {
    // duration: 1500,
    // delay: 50,
    smooth: true,
    // isDynamic: true,
    containerId,
  });
}

function calculateScrollOffset(elem, additionalOffset = 0) {
  const body = document.body; // eslint-disable-line
  const html = document.documentElement; // eslint-disable-line

  const elemRect = elem.getBoundingClientRect();
  const { clientHeight } = html;
  const documentHeight = Math.max(
    body.scrollHeight,
    body.offsetHeight,
    html.clientHeight,
    html.scrollHeight,
    html.offsetHeight,
  );

  const scrollPosition = elemRect.top;

  const maxScrollPosition = documentHeight - clientHeight;
  return Math.min(scrollPosition + additionalOffset + window.pageYOffset, maxScrollPosition); // eslint-disable-line
}

export const scrollToTop = () => {
  animateScroll.scrollTo(0, 0);
};
