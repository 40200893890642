import React from 'react';
import { Tag } from 'antd';
import { includes } from 'ramda';
import { isAfter, isEqual } from 'date-fns';

import BlankLink from '../shared/BlankLink';
import { dueDates, statuses as fundStatuses } from '../../constants/fund';
import { types } from '../../constants/user';
import { dateFormat, dateWithTimeFormat } from '../../utils/formats';
import { getQueryParams } from '../../utils/queryParams';

import ColumnFilter from './ColumnFilter';

const sortOrder = (field) => {
  const sortParam = getQueryParams('sort');

  if (sortParam && includes(sortParam, [`${field}.asc`, `${field}.desc`])) {
    return sortParam === `${field}.asc` ? 'ascend' : 'descend';
  }

  return false;
};

const dateSorter = (a, b, field, reserveField = 'user') => {
  const aDate = new Date(a[field]);
  const bDate = new Date(b[field]);

  if (!aDate) { return -1; }
  if (!bDate) { return 1; }

  if (isEqual(aDate, bDate)) { return a[reserveField].localeCompare(b[reserveField]); }

  return isAfter(new Date(a[field]), new Date(b[field])) ? 1 : -1
};

const filteredValue = (field) => getQueryParams(field);

export default (users, roles, statuses, funds) => ([
  {
    title: 'User',
    dataIndex: 'user_ids',
    ellipsis: true,
    sorter: true,
    width: 170,
    sortOrder: sortOrder('user_ids'),
    filteredValue: (filteredValue('user_ids') === 'all'
      ? users.map(({ id }) => `${id}`)
      : filteredValue('user_ids')
    ) || null,
    filterDropdown: (props) => <ColumnFilter name="user_ids" items={users} {...props} />,
    render: (_, item) => item.user,
  },
  {
    title: 'Role',
    dataIndex: 'roles',
    sorter: (a, b) => a.role.localeCompare(b.role),
    width: 140,
    sortOrder: sortOrder('roles'),
    filteredValue: (filteredValue('roles') === 'all'
      ? roles
      : filteredValue('roles')
    ) || null,
    filterDropdown: (props) => <ColumnFilter name="roles" items={roles} {...props} />,
    render: (_, { role }) => <Tag color='volcano' key={role}>{types[role]}</Tag>,
  },
  {
    title: 'Valuation Date',
    dataIndex: 'valuationDate',
    width: 140,
    sorter: (a, b) => dateSorter(a, b, 'valuationDate'),
    sortOrder: sortOrder('valuationDate'),
    render: (valuationDate, item) => (
      <BlankLink
        title={dateFormat(valuationDate)}
        link={Routes.fund_valuation_task_items_path(item.fundId, item.fundValuationId)}
      />
    ),
  },
  {
    title: 'Status',
    dataIndex: 'fund_statuses',
    sorter: true,
    width: 150,
    sortOrder: sortOrder('fund_statuses'),
    filteredValue: (filteredValue('fund_statuses') === 'all'
      ? statuses
      : filteredValue('fund_statuses')
    ) || null,
    filterDropdown: (props) => <ColumnFilter name="fund_statuses" items={statuses} {...props} />,
    render: (_, { status }) => fundStatuses[status],
  },
  {
    title: 'Fund',
    dataIndex: 'fund_ids',
    ellipsis: true,
    sorter: true,
    sortOrder: sortOrder('fund_ids'),
    filteredValue: (filteredValue('fund_ids') === 'all'
      ? funds.map(({ id }) => `${id}`)
      : filteredValue('fund_ids')
    ) || null,
    filterDropdown: (props) => <ColumnFilter name="fund_ids" items={funds} {...props} />,
    render: (_, { fund, fundId }) => (
      <BlankLink
        title={fund}
        link={Routes.fund_path(fundId)}
      />
    ),
  },
  {
    title: 'Task',
    dataIndex: 'title',
    ellipsis: true,
    render: (title, item) => (
      <BlankLink
        title={title}
        link={Routes.fund_valuation_task_items_path(item.fundId, item.fundValuationId)}
      />
    ),
  },
  {
    title: 'Date Assigned',
    dataIndex: 'assignedAt',
    width: 170,
    sorter: (a, b) => dateSorter(a, b, 'assignedAt'),
    sortOrder: sortOrder('assignedAt'),
    render: (assignedAt) => dateWithTimeFormat(assignedAt),
  },
  {
    title: 'Due Date',
    dataIndex: 'dueDate',
    width: 90,
    render: (dueDate) => dueDates[dueDate],
  },
]);