import { deleteRequest, postRequest, putRequest } from '../../requests';
import fetch from '../../fetch';

export default class TaskItemPatternsApi {
  static submit(body, { fundId, id = null } = {}) {
    const url = id
      ? Routes.fund_task_item_pattern_path(fundId, id)
      : Routes.fund_task_item_patterns_path(fundId);
    const request = id ? putRequest({ body }) : postRequest({ body });

    return fetch(url, request);
  }

  static import(body, { fundId }) {
    return fetch(Routes.import_fund_task_item_patterns_path(fundId), postRequest({ body }));
  }

  static delete({ fundId, id }) {
    return fetch(Routes.fund_task_item_pattern_path(fundId, id), deleteRequest());
  }
}
