import humps from 'humps';
import axios from 'axios';
import { stringToDate } from 'utils/dateTransformations';

import * as types from './actionTypes';

export function fetchAccounts(fundId, onDate = null, onlyActive = false) {
  return async (dispatch) => {
    dispatch(accountsFetchRequested());

    try {
      const res = await axios.get(
        Routes.fund_accounts_path(fundId, {
          format: 'json',
          search: {
            sort: 'name.asc',
            on_date: onDate,
            only_active: onlyActive,
          },
        }),
        {
          withCredentials: true,
        },
      );

      const accounts = humps.camelizeKeys(res.data).map((account) => ({
        ...account,
        openDate: stringToDate(account.openDate),
        closeDate: stringToDate(account.closeDate),
      }));

      dispatch(accountsFetchCompleted(accounts));
    } catch (error) {
      console.error(error);
    }
  };
}

export function fetchInvestorSeries(fundId, onDate = null) {
  return (dispatch) => {
    dispatch(seriesFetchRequested());

    axios
      .get(
        Routes.fund_investor_series_index_path(fundId, {
          format: 'json',
          search: {
            sort: 'name.asc',
            on_date: onDate,
          },
        }),
        {
          withCredentials: true,
        },
      )
      .then((res) => {
        dispatch(seriesFetchCompleted(humps.camelizeKeys(res.data)));
      });
  };
}

export function fetchInvestorClasses(fundId, onDate = null) {
  return (dispatch) => {
    dispatch(classesFetchRequested());

    axios
      .get(
        Routes.fund_investor_classes_path(fundId, {
          format: 'json',
          search: {
            sort: 'name.asc',
            on_date: onDate,
          },
        }),
        {
          withCredentials: true,
        },
      )
      .then((res) => {
        dispatch(classesFetchCompleted(humps.camelizeKeys(res.data)));
      });
  };
}

function accountsFetchRequested() {
  return {
    type: types.ACCOUNTS_FETCH_REQUESTED,
  };
}

function seriesFetchRequested() {
  return {
    type: types.INVESTOR_SERIES_FETCH_REQUESTED,
  };
}

function classesFetchRequested() {
  return {
    type: types.INVESTOR_CLASSES_FETCH_REQUESTED,
  };
}

function accountsFetchCompleted(accounts) {
  return {
    type: types.ACCOUNTS_FETCH_COMPLETED,
    payload: {
      accounts,
    },
  };
}

function seriesFetchCompleted(payload) {
  return {
    type: types.INVESTOR_SERIES_FETCH_COMPLETED,
    payload,
  };
}

function classesFetchCompleted(payload) {
  return {
    type: types.INVESTOR_CLASSES_FETCH_COMPLETED,
    payload,
  };
}
