import fetch from '../../fetch';
import { getRequest, postRequest, deleteRequest } from '../../requests';

export default class AccountsApi {
  static invite(fundId, { accounts, associates }) {
    const url = Routes.fund_accounts_invite_path(fundId);
    const body = { accounts, associates };
    return fetch(url, postRequest({ body }));
  }

  static get(fundId) {
    return fetch(Routes.fund_accounts_path(fundId), getRequest());
  }

  static delete(fundId, id) {
    return fetch(Routes.fund_account_path(fundId, id), deleteRequest());
  }
}
