import React from 'react';
import { Provider } from 'react-redux';

import { ChartOfAccounts } from 'components/shared';
import configureStore from './store';

function ChartsContainer(props) {
  const { refs, state } = props;

  return (
    <Provider store={configureStore(state)}>
      <ChartOfAccounts refs={refs} />
    </Provider>
  );
}

export default ChartsContainer;
