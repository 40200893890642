import React from 'react';
import PropTypes from 'prop-types';

import { Radio } from 'antd';

const propTypes = {
  buttonStyle: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

const defaultProps = {
  buttonStyle: 'solid',
  value: null,
};

function YesOrNoInput({ value, onChange, ...rest }) {
  return (
    <Radio.Group value={value} onChange={onChange} {...rest}>
      <Radio.Button value>Yes</Radio.Button>
      <Radio.Button value={false}>No</Radio.Button>
    </Radio.Group>
  );
}

YesOrNoInput.propTypes = propTypes;
YesOrNoInput.defaultProps = defaultProps;

export default YesOrNoInput;
