import set from 'lodash/fp/set';
import { get } from 'lodash';
import * as types from './actionTypes';

const initialState = {
  data: {},
  meta: {
    errors: {},
    submitting: false,
  },
  info: {},
};

export default function(state = initialState, action) {
  switch (action.type) {
    case types.CHANGE_INPUT:
      return set(action.payload.stateKey, action.payload.value)(state);

    case types.SUBMITTING_FORM:
      return {
        ...state,
        meta: {
          ...state.meta,
          submitting: action.payload.submitting,
        },
      };

    case types.SUBMITTING_FORM_SUCCEEDED:
      return {
        ...state,
        meta: {
          ...state.meta,
          errors: null,
          success: action.payload.data,
        },
      };

    case types.SUBMITTING_FORM_FAILED:
      return {
        ...state,
        meta: {
          ...state.meta,
          errors: action.payload.errors,
        },
      };

    case types.CHANGE_DEALING_DATE:
      return {
        ...state,
        data: {
          ...state.data,
          dealingDate: action.payload.value,
        },
      };

    case types.UPDATE_ACCOUNT:
      return {
        ...state,
        data: {
          ...state.data,
          fundAccountId: action.payload.fundAccount ? action.payload.fundAccount.id : undefined,
        },
        meta: {
          ...state.meta,
          fundAccount: action.payload.fundAccount ? action.payload.fundAccount : undefined,
          accountFetchingInProgress: true,
        },
      };

    case types.UPDATE_FROM_ACCOUNT:
      return {
        ...state,
        data: {
          ...state.data,
          fromFundAccountId: action.payload.fundAccount ? action.payload.fundAccount.id : undefined,
        },
        meta: {
          ...state.meta,
          fromFundAccount: action.payload.fundAccount ? action.payload.fundAccount : undefined,
          accountFetchingInProgress: true,
        },
      };

    case types.UPDATE_TO_ACCOUNT:
      return {
        ...state,
        data: {
          ...state.data,
          toFundAccountId: action.payload.fundAccount ? action.payload.fundAccount.id : undefined,
        },
        meta: {
          ...state.meta,
          toFundAccount: action.payload.fundAccount ? action.payload.fundAccount : undefined,
        },
      };

    case types.UPDATE_ACCOUNT_DATA:
      return {
        ...state,
        meta: {
          ...state.meta,
          investorClass: action.payload.investorClass,
          investorSeries: action.payload.investorSeries,
          investor: action.payload.investor,
          openDate: action.payload.openDate,
          closeDate: action.payload.closeDate,
          accountFetchingInProgress: false,
        },
      };

    case types.UPDATE_FROM_ACCOUNT_DATA:
      return {
        ...state,
        meta: {
          ...state.meta,
          investorClass: action.payload.investorClass,
          investorSeries: action.payload.investorSeries,
          investor: action.payload.investor,
          openDate: action.payload.openDate,
          accountFetchingInProgress: false,
        },
      };

    case types.UPDATE_LAST_VALUATION:
      return {
        ...state,
        meta: {
          ...state.meta,
          lastValuation: action.payload.value,
        },
      };

    case types.START_FETCH_VALUATION_DATA:
      return {
        ...state,
        meta: {
          ...state.meta,
          valuationFetchingInProgress: true,
        },
      };

    case types.FINISH_FETCH_VALUATION_DATA:
      return {
        ...state,
        meta: {
          ...state.meta,
          valuationFetchingInProgress: false,
        },
      };

    case types.SELECT_RADIO_INPUT:
      return set(action.payload.selectedStateKey, action.payload.selected)(state);

    case types.UPDATE_THIRD_PARTY:
      return {
        ...state,
        data: {
          ...state.data,
          thirdPartyId: action.payload.value,
        },
      };

    case types.CLEAR_VALUES:
      return {
        ...state,
        data: {
          ...state.data,
          percentage: null,
          amount: null,
          shares: null,
        },
      };

    case types.CLEAR_REDEMPTION_HOLDBACK_VALUES:
      return {
        ...state,
        data: {
          ...state.data,
          redemptionHoldbackAmount: null,
          redemptionHoldbackPercentage: null,
        },
      };

    default:
      return state;
  }
}
