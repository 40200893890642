import PropTypes from 'prop-types';

export const mappingPropTypes = PropTypes.shape({
  accountId: PropTypes.number,
  displayAccountTitle: PropTypes.string,
  displayEvent: PropTypes.string,
  displayNamespace: PropTypes.string,
  displaySecurityKlass: PropTypes.string,
  displayTransactionKey: PropTypes.string,
  fullTransactionKey: PropTypes.string,
  id: PropTypes.number,
  securityKlass: PropTypes.string,
});

export const mappingsConfigurationTypes = PropTypes.shape({
  namespaces: PropTypes.arrayOf(PropTypes.object),
  events: PropTypes.arrayOf(PropTypes.object),
  transactions: PropTypes.arrayOf(PropTypes.object),
});

export const mappingDefaultProps = {};
