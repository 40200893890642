import { flattenDeep, isArray } from 'lodash';

import {
  currencyMask,
  decimalCurrencyMask,
  decimalNumberMask,
  numberMask,
  percentMask,
  phoneMask,
  dateMask,
  ssnMask,
  cvvMask,
} from './masks';

export function getFirstError(errors) {
  if (isArray(errors)) {
    return flattenDeep(errors)[0].toString();
  }

  return errors;
}

export const getMask = (mask, typeOfMask) => {
  if (mask) {
    if (typeof mask === 'string') {
      const arrMask = mask.split('').map((char) => {
        if (char === '9') return /\d/;
        if (char === 'a') return /[a-zA-Z]/;
        return char;
      });
      return arrMask;
    }
    return mask;
  }

  switch (typeOfMask) {
    case 'currency':
      return currencyMask;
    case 'decimalCurrency':
      return decimalCurrencyMask;
    case 'decimalNumber':
      return decimalNumberMask;
    case 'number':
      return numberMask;
    case 'percent':
      return percentMask;
    case 'phone':
      return phoneMask;
    case 'date':
      return dateMask;
    case 'ssn':
      return ssnMask;
    case 'cvv':
      return cvvMask;
    default:
      return undefined;
  }
};

export const getPlaceholder = (placeholder, typeOfMask) => {
  if (placeholder) return placeholder;
  switch (typeOfMask) {
    case 'phone':
      return '(123)999-9999';
    case 'currency':
      return '';
    case 'decimalCurrency':
      return '';
    case 'percent':
      return '0%';
    case 'date':
      return '__/__/____';
    case 'ssn':
      return '__-__-____';

    default:
      return undefined;
  }
};
