import React from 'react';

import { default as Currency } from './Currency';
import { default as Investment } from './Investment';
import { default as FixedIncome } from './FixedIncome';
import { default as All } from './All';

const Fields = (props) => {
  const { security, previousSecurity, trade } = props;
  const currentSecurity = security || (trade.id ? previousSecurity : undefined);

  const fields = (type) => {
    switch(type) {
      case 'currency':
        return <Currency {...props} />;
      case 'partnership_investment':
      case 'private_equity_investment':
        return <Investment {...props} />;
      case 'fixed_income':
      return <FixedIncome {...props} />;
      default:
        return <All {...props} />;
    }
  };

  return currentSecurity ? fields(currentSecurity.type) : null;
}

export default Fields;
