import setRequestInProcess from 'actions/request';
import DocumentsPatternsApi from 'apiAdapters/investorDocumentPatterns';
import DocumentDirectoriesApi from 'apiAdapters/investorDocumentPatterns/directories';
import * as types from 'constants/requestTypes';
import { history } from 'stores/configureStore';
import { catcher } from 'utils/actions';

function buildDirectoryTree({ id }) {
  return (dispatch) => {
    dispatch({ type: 'BUILD_DIRECTORY_TREE', payload: { id } });
  }
}

export function openDrawer(props = { id: null }) {
  const { id } = props;
  return (dispatch) => {
    if (id) { dispatch(buildDirectoryTree({ id })); }
    dispatch({ type: 'OPEN_DOCUMENT_PATTERN_DRAWER' });
    if (id) {
      history.push(Routes.edit_investor_document_pattern_path(id));
      document.title = 'Document Pattern | Edit';
    } else {
      history.push(Routes.new_investor_document_pattern_path());
      document.title = 'Document Pattern | New';
    }
  };
}

export const closeDrawer = () => (dispatch) => {
  history.push(Routes.investor_document_patterns_path());
  document.title = 'Document Patterns';
  dispatch({ type: 'CLOSE_DOCUMENT_PATTERN_DRAWER' });
};

export function createDocumentsPattern({ values }) {
  return catcher(async (dispatch) => {
    const { payload } = await DocumentsPatternsApi.create(values);
    dispatch({ type: 'CREATE_DOCUMENT_PATTERN', payload });
    dispatch(closeDrawer());
  })
}

// eslint-disable-next-line max-len
export function updateDocumentsPattern({ values, id }) {
  return catcher(async (dispatch) => {
    const { payload } = await DocumentsPatternsApi.update(values, id);
    dispatch({ type: 'UPDATE_DOCUMENT_PATTERN', payload });
    dispatch(closeDrawer());
  })
}

export function editDocumentsPattern({ id }) {
  return (dispatch) => {
    dispatch(openDrawer({ id }));
  };
}

export function deleteDocumentsPattern({ id }) {
  return catcher(async (dispatch) => {
    await DocumentsPatternsApi.delete(id);
    dispatch({ type: 'DESTROY_DOCUMENT_PATTERN', payload: { id } });
    history.push(Routes.investor_document_patterns_path());
    document.title = 'Document Patterns';
  });
}

// eslint-disable-next-line max-len
export function changeDocumentsPatternPosition({ id, moveTo }) {
  return catcher(async (dispatch, getState) => {
    const { investorDocumentPatterns: { collection } } = getState();
    const currentPattern = collection.find((item) => item.id === id)
    const movedFrom = currentPattern.position;
    const { payload } = await DocumentsPatternsApi.update({ position: moveTo }, id);
    dispatch({ type: 'CHANGE_DOCUMENT_PATTERN_POSITION', payload: { ...payload, movedFrom } })
  });
}

export function createDirectory({ title, parentId, patternId }) {
  return (dispatch) => {
    dispatch(setRequestInProcess(true, types.DOCUMENTS_DIRECTORY_CREATE));
    return DocumentDirectoriesApi.submit({ body: { title, parentId }, patternId })
      .then(({ payload }) => {
        const { id } = payload;
        const directory = { id, title, parentId, patternId };
        dispatch(setRequestInProcess(false, types.DOCUMENTS_DIRECTORY_CREATE));
        dispatch({ type: 'CREATE_DOCUMENTS_PATTERN_DIRECTORY', payload: directory });
        dispatch(buildDirectoryTree({ id: patternId }));
        return payload;
      })
      .catch((error) => console.log(error));
  }
}

export function renameDirectory({ title, id, parentId, patternId }) {
  return (dispatch) => DocumentDirectoriesApi.submit({ body: { title }, patternId, id })
    .then(({ payload }) => {
      const { parentId, patternId } = payload;
      const directory = { id, title, parentId, patternId };
      dispatch({ type: 'RENAME_DOCUMENTS_PATTERN_DIRECTORY', payload: directory });
      dispatch(buildDirectoryTree({ id: patternId }));
      return payload;
    })
}

export function deleteDirectory({ patternId, id }) {
  return (dispatch) => DocumentDirectoriesApi.delete({ patternId, id })
    .then(({ payload }) => {
      dispatch({ type: 'REMOVE_DOCUMENTS_PATTERN_DIRECTORY', payload: { id } });
      dispatch(buildDirectoryTree({ id: patternId }));
      return payload;
    })
    .catch((error) => console.log(error))
}
