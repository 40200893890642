import set from 'lodash/fp/set';

import * as types from './actionTypes';

const initialState = {
  data: {},
  meta: {
    errors: {},
    submitting: false,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.CHARTS_CATEGORY_CLEAR:
      return {
        ...state,
        ...initialState,
      };

    case types.CHARTS_CATEGORY_FORM_FILL:
      return {
        ...state,
        data: action.payload.data,
      };

    case types.CHARTS_CATEGORY_CHANGE_INPUT:
      return set(action.payload.stateKey, action.payload.value)(state);

    case types.CHARTS_CATEGORY_SUBMITTING_FORM:
      return {
        ...state,
        meta: {
          submitting: action.payload.submitting,
        },
      };

    case types.CHARTS_CATEGORY_SUBMITTING_FORM_SUCCEEDED:
      return {
        ...state,
        meta: {
          errors: null,
        },
      };

    case types.CHARTS_CATEGORY_SUBMITTING_FORM_FAILED:
      return {
        ...state,
        meta: {
          errors: action.payload.errors,
        },
      };

    default:
      return state;
  }
}
