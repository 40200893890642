import { filter, values } from 'ramda';
import { createSelector } from 'reselect';

import { getIn } from 'utils/ramda';

const fundIdSelector = (state) => getIn('fund.details.id', state);
export const accountEntitiesSelector = (state) => getIn('entities.accounts', state) || {};
export const associateEntitiesSelector = (state) => getIn('entities.associates', state) || {};

export const getAccountCollection = createSelector(
  accountEntitiesSelector,
  fundIdSelector,
  (accountEntities, fundId) => filter((account) => account.fundId === fundId, values(accountEntities)) || [],
);

export const getAssociatesCollection = createSelector(
  associateEntitiesSelector,
  fundIdSelector,
  (associateEntities, fundId) => filter((associate) => associate.fundId === fundId, values(associateEntities)) || [],
)
