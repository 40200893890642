export const MAPPING_MODE_CHANGE = 'MAPPING_MODE_CHANGE';

export const CHARTS_ON_MAPPING_ADD = 'CHARTS_ON_MAPPING_ADD';
export const CHARTS_ON_MAPPING_DELETE = 'CHARTS_ON_MAPPING_DELETE';

export const SECURITY_TYPES_FILTER_CHANGE = 'SECURITY_TYPES_FILTER_CHANGE';
export const MAPPINGS_BY_SECURITY_TYPES_FILTER = 'MAPPINGS_BY_SECURITY_TYPES_FILTER';

export const MAPPINGS_FETCH_REQUESTED = 'MAPPINGS_FETCH_REQUESTED';
export const MAPPINGS_FETCH_COMPLETED = 'MAPPINGS_FETCH_COMPLETED';
