import fetch from '../../fetch';
import { putRequest, getRequest, deleteRequest, postDataRequest } from '../../requests';

export default class FundDocumentsApi {
  static add({ fundId, folderId, files, isPrivate }) {
    const data = new FormData();
    files.map((file) => data.append('investor_documents[files][]', file.originFileObj));
    // data.append('investor_documents[is_private]', !!isPrivate);

    return fetch(Routes.fund_investor_document_directory_documents_path(fundId, folderId), postDataRequest({ body: data }))
  }

  static rename({ fundId, id, parentId, title }) {
    return fetch(Routes.fund_investor_document_directory_document_path(fundId, parentId, id), putRequest({ body: { investorDocuments: { title } } }))
  }

  static bind({ fundId, id, accesses }) {
    const payload = {
      body: {
        fund_investor_document_access: {
          targetId: id,
          targetType: 'Fund::InvestorDocument',
          owners: accesses.map((key) => ({
            ownerId: Number(key.split('-')[1]),
            ownerType: key.split('-')[0],
          }))
        }
      }
    }
    return fetch(Routes.bind_fund_investor_document_accesses_path(fundId), putRequest(payload));
  }

  static remove({ fundId, id, parentId }) {
    return fetch(Routes.fund_investor_document_directory_document_path(fundId, parentId, id), deleteRequest());
  }

  static download({ fundId, parentId, id }) {
    return fetch(`${Routes.download_fund_investor_document_directory_document_path(fundId, parentId, id)}`, getRequest())
  }
}
