import PropTypes from 'prop-types';

export const accrualFormMutatorsShape = {
  changeSpecialAllocation: PropTypes.func,
};

export const accrualPropTypes = PropTypes.shape({
  id: PropTypes.number,
  fundId: PropTypes.number,
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  accrualCategoryId: PropTypes.number,
  frequency: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  accrualDebitAccountId: PropTypes.number,
  accrualCreditAccountId: PropTypes.number,
  paymentDebitAccountId: PropTypes.number,
  paymentCreditAccountId: PropTypes.number,
  specialAllocation: PropTypes.bool,
});
