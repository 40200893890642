import { map, filter } from 'ramda';

import { history } from 'stores/configureStore';
import TaskNotesApi from '../../../../apiAdapters/funds/valuations/taskNotes';

export const showNotes = (fundId, valuationId) => (dispatch) => {
  dispatch({ type: 'SHOW_NOTES' });
  history.push(Routes.fund_valuation_task_items_task_notes_path(fundId, valuationId));
  document.title = 'Fund | Valuation | Task Items | Task Notes';
};

export const hideNotes = (fundId, valuationId) => (dispatch, getState) => {
  const { fund: { valuation: { taskNotes: { collection } } } } = getState();
  const newCollection = setEditable(collection);

  dispatch({ type: 'HIDE_NOTES', payload: newCollection });
  history.push(Routes.fund_valuation_task_items_path(fundId, valuationId));
  document.title = 'Fund | Valuation | Task Items';
};

// eslint-disable-next-line max-len
export const submitTaskNote = (values, { fundId, valuationId }) => (dispatch, getState) => TaskNotesApi.submit(values, { fundId, valuationId })
  .then(({ payload }) => {
    const { fund: { valuation: { taskNotes: { collection } } } } = getState();

    if (values.id) {
      const newCollection = map((note) => {
        if (values.id && note.id === values.id) {
          return { ...payload, editable: false };
        }

        return { ...note, editable: false };
      }, collection);

      dispatch({ type: 'UPDATE_NOTE', payload: newCollection });
    } else {
      const newCollection = [{ ...payload }, ...setEditable(collection)];

      dispatch({ type: 'CREATE_NOTE', payload: newCollection });
    }
  })
  .catch((error) => console.log(error));

export const editNote = (id) => (dispatch, getState) => {
  const { fund: { valuation: { taskNotes: { collection } } } } = getState();
  const newCollection = setEditable(collection, id);

  return dispatch({ type: 'EDIT_NOTE', payload: newCollection });
};

export const cancelEditNote = () => (dispatch, getState) => {
  const { fund: { valuation: { taskNotes: { collection } } } } = getState();
  const newCollection = setEditable(collection);

  return dispatch({ type: 'CANCEL_EDIT_NOTE', payload: newCollection });
};

// eslint-disable-next-line max-len
export const deleteNote = (fundId, valuationId, id) => (dispatch, getState) => TaskNotesApi.delete({ fundId, valuationId, id })
  .then(() => {
    const { fund: { valuation: { taskNotes: { collection } } } } = getState();
    const newCollection = filter((n) => n.id !== id, setEditable(collection));

    return dispatch({ type: 'DELETE_NOTE', payload: newCollection });
  })
  .catch((error) => console.log(error));

const setEditable = (collection, currentId = null) => map((note) => {
  if (currentId && note.id === currentId) {
    return { ...note, editable: true };
  }

  return { ...note, editable: false };
}, collection);
