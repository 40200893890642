import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { uniq, sort, map, toString, mapObjIndexed } from 'ramda';
import { Table } from 'antd';


import { history } from '../../stores/configureStore';
import { getQueryParamsFromCurrent, getQueryParams } from '../../utils/queryParams';
import { getTasks, getUsers, getStatuses, getFunds, getRoles } from '../../selectors/tasks';
import { defaultStatuses, defaultMyFundsStatuses } from '../../constants/fund';

import columns from './columns';
import Tabs from './Tabs';

const defaultPageSize = 100;

const paginationProps = () => {
  const limit = Number(getQueryParams('limit') || defaultPageSize);

  return {
    current: Number(getQueryParams('page') || 1),
    pageSize: limit,
    defaultPageSize,
    pageSizeOptions: map((n) => toString(n), sort((a, b) => a - b, uniq([20, 50, 100, limit]))),
    showSizeChanger: true,
    hideOnSinglePage: true,
  };
};

const onChangeTable = (pagination, filters, sorter, filterables) => {
  history.push(
    Routes.tasks_path(
      {
        // eslint-disable-next-line no-nested-ternary,max-len
        sort: sorter.order ? sorter.order === 'ascend' ? `${sorter.field}.asc` : `${sorter.field}.desc` : undefined,
        page: pagination.current,
        limit: pagination.pageSize || pagination.defaultPageSize,
        ...mapObjIndexed((values, key) => (values && values.length === filterables[key].length) ? 'all' : values, filters),
      },
    ),
  );
};

function MyTasksPage() {
  const params = getQueryParamsFromCurrent();
  const tasks = useSelector((state) => getTasks(state, params));
  const users = useSelector(getUsers);
  const statuses = useSelector(getStatuses);
  const funds = useSelector(getFunds);
  const roles = useSelector(getRoles);
  const currentUser = useSelector((state) => state.user);

  return (
    <div className='panel'>
      {useMemo(() => (
        <Tabs
          data={[
            // eslint-disable-next-line max-len
            { name: 'All', link: Routes.funds_path({ s: { sort: 'name.asc', statuses: defaultStatuses } }), active: false },
            // eslint-disable-next-line max-len
            { name: 'My Funds', link: Routes.funds_path({ s: { sort: 'name.asc', statuses: defaultMyFundsStatuses, user: true } }), active: false },
            // eslint-disable-next-line react/prop-types,max-len,no-undef
            { name: 'My Tasks', link: Routes.tasks_path({ user_ids: [currentUser.id], roles: currentUser.types, fund_statuses: defaultMyFundsStatuses }), active: true },
          ]}
        />
        // eslint-disable-next-line react/prop-types
      ), [currentUser.name])}
      <Table
        dataSource={tasks}
        onChange={(pagination, filters, sorter) => onChangeTable(pagination, filters, sorter, { user_ids: users, fund_statuses: statuses, fund_ids: funds, roles })}
        rowKey='id'
        columns={columns(users, roles, statuses, funds)}
        size='small'
        pagination={paginationProps()}
      />
    </div>
  )
};

export default MyTasksPage;
