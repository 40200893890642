import API from 'apiAdapters/tasks';
import * as types from 'constants/actionTypes';

export const fetchTasks = (filters) => async (dispatch) => {
  try {
    const { state: { tasks } } = await API.get(filters);

    dispatch({ type: types.MY_TASKS_ADD_TASKS, payload: tasks })
  } catch (err) {
    console.error(err);
  }
};