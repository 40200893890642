import React from 'react';
import { startCase } from 'lodash';

import { Stat, Block } from 'components/shared';

const All = (props) => {
  const { notionalAmounts, resourceFund, trade, summary, security, previousSecurity } = props;
  const currentSecurity = security || (trade.id ? previousSecurity : undefined);

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <Block
            title="Summary"
            fields={
              <div>
                <ul className="list list-unstyled">
                  <li>
                    {'Type:'}
                    <strong> {trade.type ? startCase(trade.type.split('_').join(' ')) : '?'}</strong>
                  </li>
                  <li>
                    Holdings on {summary.displayTradeDate}:<strong> {currentSecurity.currentHoldings}</strong>
                  </li>

                  {trade.secondTradeType && (
                    <li>
                      <span>
                        Second Trade:
                        <strong> {trade.secondTradeType}</strong>
                      </span>
                    </li>
                  )}
                </ul>
              </div>
            }
          />
        </div>
      </div>

      {currentSecurity && (
        <div className="text-centered">
          {currentSecurity.currencyIso === resourceFund.baseCurrencyIso ? (
            <div style={{ display: 'inline-block', width: '100%' }}>
              <h3 className="m-t-none">{resourceFund.baseCurrencyIso}</h3>
            </div>
          ) : (
            <div>
              <div style={{ display: 'inline-block', width: '50%' }}>
                <h3 className="m-t-none">{currentSecurity.currencyIso}</h3>
              </div>
              <div style={{ display: 'inline-block', width: '50%' }}>
                <h3 className="m-t-none">{resourceFund.baseCurrencyIso}</h3>
              </div>
            </div>
          )}
        </div>
      )}

      <div className="row">
        {trade.fx ? (
          <div>
            <div className="col-md-12">
              <Stat hint="Total Fees & Taxes" value={summary.displayTotalFeesTradeAmount} />
            </div>
          </div>
        ) : (
          <div>
            <div className="col-md-12">
              <Stat hint="Total Fees & Taxes" value={summary.displayTotalFees} />
            </div>
          </div>
        )}
      </div>

      {notionalAmounts && (
        <div className="row">
          <div>
            <div className="col-md-12">
              <Stat hint="Notional amounts" value={notionalAmounts} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default All;
