import { stringify } from 'qs';
import { pipe, then, includes } from 'ramda';

import { camelizeKeys } from '../utils/humps';
import { getIn, setIn } from '../utils/ramda';
import { internalServerErrorNotification } from '../utils/notifications';

function parseJSON(res) {
  return res.json();
}

function toJSON(res) {
  return pipe(
    parseJSON,
    then(camelizeKeys),
  )(res);
}

function checkStatus(res) {
  const statusCode = res.status;
  const isOk = getIn('ok', res);

  const throwError = pipe(
    toJSON,
    then(setIn(statusCode, 'statusCode')),
    then((error) => {
      throw error;
    }),
  );

  if (res.status === 500) internalServerErrorNotification();

  if (isOk) return res;

  return throwError(res);
}

function checkType(res) {
  const contentType = res.headers.get('content-type');
  const isJSON = contentType && includes('application/json', contentType);

  if (isJSON) return toJSON(res);
  return res;
}

function responseHandler(res) {
  return pipe(
    then(checkStatus),
    then(checkType),
  )(res);
}

function getQuerystring(params) {
  const queryString = stringify(params, { encode: false, arrayFormat: 'brackets', skipNulls: true });
  return queryString ? `?${queryString}` : '';
}

function parseEndpoint(path, params) {
  return `${path}${getQuerystring(params)}`;
}

function cfetch(endpoint, request, params) {
  const response = fetch(parseEndpoint(endpoint, params), request);
  return responseHandler(response);
}

export default cfetch;
