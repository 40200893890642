import PropTypes from 'prop-types';

export const fundPropTypes = PropTypes.shape({
  accounts: PropTypes.arrayOf(PropTypes.object),
  baseCurrencyIso: PropTypes.string,
  currencySymbol: PropTypes.string,
  id: PropTypes.number,
  investorClasses: PropTypes.arrayOf(PropTypes.object),
  investorSeries: PropTypes.arrayOf(PropTypes.object),
  name: PropTypes.string,
  navDecimalPrecision: PropTypes.number,
  pricingMethodology: PropTypes.string,
  pricingPrecision: PropTypes.number,
  shareDecimalPrecision: PropTypes.number,
  type: PropTypes.string,
  valuationFrequency: PropTypes.string,
});

export const fundDefaultProps = {};
