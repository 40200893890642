import React from 'react';
import PropTypes from 'prop-types';
import FontAwesome from 'react-fontawesome';
import numeral from 'numeral';

import { Input, CurrencyInput } from 'components/shared';

const propTypes = {
  resourceFund: PropTypes.object.isRequired,
  tradeExercise: PropTypes.object.isRequired,
  security: PropTypes.object.isRequired,
  realizations: PropTypes.object,
};

const defaultProps = {
  realizations: undefined,
};

function Details(props) {
  const { resourceFund, tradeExercise, security, realizations } = props;
  const quantityToExercise = numeral(tradeExercise.signedQuantity).value() * numeral(security.pricingFactor).value();
  const currencySymbol = security.type == 'currency' && security.holdingsDetails && security.holdingsDetails.sellCurrency ? security.holdingsDetails.sellCurrency.symbol : security.currencySymbol;

  if (tradeExercise.signedQuantity && realizations.availableQuantity < tradeExercise.signedQuantity) {
    return <div className='alert alert-warning'>This option is not available</div>;
  }

  if (realizations.loading) {
    return <FontAwesome name='spinner' spin />;
  }

  if (realizations.error) {
    return <div className='alert alert-danger'>{realizations.error}</div>;
  }

  return (
    resourceFund.portfolioTaxlotReliefMethod === 'specific_allocation' ? (
      <div>There will be a trade select</div>
    ) : (
      <div>
        <div className="row">
          <div className="col-md-6">
            <Input
              id={'trade_type'}
              name={'trade[type]'}
              value={tradeExercise.type}
              label='Type'
              disabled
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <Input
              id={'trade_quantity_to_exercise'}
              name={'trade[quantity_to_exercise]'}
              value={quantityToExercise}
              label='Quantity'
              disabled
            />
          </div>
          <div className="col-md-6">
            <CurrencyInput
              id='trade_price'
              name='trade[price]'
              value={numeral(realizations.exercisePrice).value()}
              label='Price'
              disabled
              currency={currencySymbol}
              decimalLimit={8}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <CurrencyInput
              id='additional_price'
              name='trade[additional_price]'
              value={numeral(tradeExercise.additionalPrice).value()}
              label='Additional Price'
              disabled
              currency={currencySymbol}
            />
          </div>
        </div>
        { security.type === 'fixed_income' &&
          <div className="row">
            <div className="col-md-6">
              <CurrencyInput
                id='accrued_interest'
                name='trade[accrued_interest]'
                value={numeral(tradeExercise.accruedInterest).value()}
                label='Accrued Interest'
                disabled
                currency={currencySymbol}
              />
            </div>
          </div>
        }
      </div>
    )
  );
}

Details.propTypes = propTypes;
Details.defaultProps = defaultProps;

export default Details;
