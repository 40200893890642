import React from 'react';
import PropTypes from 'prop-types';

import { FormGroup } from '../';

const propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  dataStateKey: PropTypes.string.isRequired,
  value: PropTypes.number,
  label: PropTypes.string,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onDecrement: PropTypes.func.isRequired,
  onIncrement: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

const defaultProps = {
  type: 'number',
  step: '1',
  className: 'form__control',
  disabled: false,
};

const IncrementInput = (props) => {
  const {
    id,
    type,
    name,
    dataStateKey,
    value,
    label,
    required,
    step,
    placeholder,
    className,
    onChange,
    onDecrement,
    onIncrement,
    errors,
    disabled,
  } = props;

  return (
    <FormGroup label={label} required={required} id={id} errors={errors}>
      <div className="form__inline">
        <span
          className="btn btn-default btn-width_sm"
          onClick={disabled ? null : onDecrement}
          disabled={disabled ? 'disabled' : ''}
        >
          -
        </span>
        &nbsp;
        <input
          id={id}
          type={type}
          required={required}
          name={name}
          placeholder={placeholder}
          data-statekey={dataStateKey}
          value={value}
          className={className}
          step={step}
          onChange={onChange}
          disabled={disabled ? 'disabled' : ''}
        />
        &nbsp;
        <span
          className="btn btn-default btn-width_sm"
          onClick={disabled ? null : onIncrement}
          disabled={disabled ? 'disabled' : ''}
        >
          +
        </span>
      </div>
    </FormGroup>
  );
};

IncrementInput.propTypes = propTypes;
IncrementInput.defaultProps = defaultProps;

export default IncrementInput;
