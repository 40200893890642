import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Tooltip } from 'antd';

import { getAccountStatus } from 'utils/accounts';
import * as accountStatuses from 'constants/account';

const propTypes = {
  accountOrAssociate: PropTypes.objectOf(PropTypes.object).isRequired,
};

const defaultProps = {};

// eslint-disable-next-line consistent-return
const StatusIcon = ({ accountOrAssociate }) => {
  // eslint-disable-next-line default-case
  switch (getAccountStatus(accountOrAssociate)) {
    case accountStatuses.STATUS_PROCESS:
      return <Icon type="loading" />;

    case accountStatuses.STATUS_USER_EXISTS:
      return (
        <Tooltip placement="top" title="User Exists">
          <Icon type="smile" theme="twoTone" twoToneColor="#52c41a" />
        </Tooltip>
      );

    case accountStatuses.STATUS_WRONG_USER:
      return (
        <Tooltip placement="top" title="Wrong User">
          <Icon type="frown" theme="twoTone" twoToneColor="#f5222d" />
        </Tooltip>
      );

    case accountStatuses.STATUS_NO_USER:
      return (
        <Tooltip placement="top" title="No User">
          <Icon type="meh" theme="twoTone" twoToneColor="#bfbfbf" />
        </Tooltip>
      );
  }
};

StatusIcon.propTypes = propTypes;
StatusIcon.defaultProps = defaultProps;

export default StatusIcon;
