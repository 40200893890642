import { initialState } from 'selectors/funds/accruals';
import {
  FUND_ACCRUAL_FORM_UPDATE,
  ACCRUAL_PAYMENTS_ITEMS_POST_FAILURE,
  ACCRUAL_PAYMENTS_ITEMS_CLEAR,
  ACCRUAL_PAYMENTS_ITEM_CREATE,
  ACCRUAL_PAYMENTS_ITEM_UPDATE,
  ACCRUAL_PAYMENTS_ITEM_DELETE,
  ACCRUAL_PAYMENTS_ITEMS_EDITABLE_KEYS_UPDATE,
  ACCRUAL_PAYMENTS_ITEMS_EDITABLE_KEYS_CLEAR,
  ACCRUAL_PAYMENTS_ITEMS_ERRORS_UPDATE,
} from 'constants/actionTypes';

export default function(state = initialState, action) {
  switch (action.type) {
    case FUND_ACCRUAL_FORM_UPDATE:
      return {
        ...state,
        formData: action.payload.values,
      };

    case ACCRUAL_PAYMENTS_ITEMS_CLEAR:
      return {
        ...state,
        paymentItems: {
          ...state.paymentItems,
          collection: action.payload,
        },
      };

    case ACCRUAL_PAYMENTS_ITEM_CREATE:
      return {
        ...state,
        paymentItems: {
          ...state.paymentItems,
          collection: action.payload,
        },
      };

    case ACCRUAL_PAYMENTS_ITEM_UPDATE:
      return {
        ...state,
        paymentItems: {
          ...state.paymentItems,
          collection: action.payload,
        },
      };

    case ACCRUAL_PAYMENTS_ITEM_DELETE:
      return {
        ...state,
        paymentItems: {
          ...state.paymentItems,
          collection: action.payload,
        },
      };

    case ACCRUAL_PAYMENTS_ITEMS_EDITABLE_KEYS_UPDATE:
      return {
        ...state,
        paymentItems: {
          ...state.paymentItems,
          editableKeys: action.payload,
        },
      };

    case ACCRUAL_PAYMENTS_ITEMS_EDITABLE_KEYS_CLEAR:
      return {
        ...state,
        paymentItems: {
          ...state.paymentItems,
          editableKeys: action.payload,
        },
      };

    case ACCRUAL_PAYMENTS_ITEMS_POST_FAILURE:
      return {
        ...state,
        paymentItems: {
          ...state.paymentItems,
          collection: action.payload,
        },
      };

    case ACCRUAL_PAYMENTS_ITEMS_ERRORS_UPDATE:
      return {
        ...state,
        paymentItems: {
          ...state.paymentItems,
          errors: action.payload,
        },
      };

    default:
      return state;
  }
}
