import React, { useState } from 'react';
import { Input } from 'antd';

import Tree from './Tree';
import styles from './portfolios.module.css';

function Portfolios() {
  const [searchValue, setSearchValue] = useState('');

  return (
    <div className={styles.investors}>
      <Input placeholder="Search..." value={searchValue} onChange={(e) => setSearchValue(e.target.value)} className={styles.searchField} />
      <div className={styles.treeContainer}>
        <Tree searchValue={searchValue} />
      </div>
    </div>
  );
}

export default Portfolios;