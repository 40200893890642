import { uniq, without, flatten } from 'ramda';
import keyFuncs from './keys';

class TreeHelper {
  constructor({ classes, series, accounts }, isNewRecord) {
    this.isNewRecord = isNewRecord;
    this.classes = classes;
    this.series = series;
    this.accounts = accounts;
  }

  isClass(item) {
    return this.classes.includes(item);
  }

  isKeyClass(key) {
    return keyFuncs.isClassKey(key);
  }

  isSeries(item) {
    return this.series.includes(item);
  }

  isKeySeries(key) {
    return keyFuncs.isSeriesKey(key);
  }

  isAccount(item) {
    return this.accounts.includes(item);
  }

  isKeyAccount(key) {
    return keyFuncs.isAccountKey(key);
  }

  hasParent(key) {
    return !this.isKeyClass(key);
  }

  getChildren(key) {
    if (!keyFuncs.isKey(key)) { return [] }
    const id = keyFuncs.getId(key);
    const keys = [];
    if (this.isKeyClass(key)) {
      const cSeries = this.series.filter((s) => s.investor_class_id === id);
      const cAccounts = this.accounts.filter((a) => a.investorClassId === id);
      keys.push(...cSeries, ...cAccounts);
    }
    if (this.isKeySeries(key)) {
      const sAccounts = this.accounts.filter((a) => a.investorSeriesId === id);
      keys.push(...sAccounts);
    }
    return keys;
  }

  getParents(key) {
    if (!keyFuncs.isKey(key)) { return [] };
    const id = keyFuncs.getId(key);
    const keys = [];
    if (this.isKeySeries(key)) {
      const item = this.series.find((s) => s.id === id);
      const klass = this.classes.find((c) => c.id === item.investor_class_id);
      keys.push(klass);
    }
    if (this.isKeyAccount(key)) {
      const item = this.accounts.find((a) => a.id === id);
      const series = this.series.find((s) => s.id === item.investorSeriesId);
      const klass = this.classes.find((c) => c.id === item.investorClassId);
      keys.push(series, klass);
    }
    return keys;
  }

  getKey(item) {
    if (this.isClass(item)) { return keyFuncs.classKey(item.id) }
    if (this.isSeries(item)) { return keyFuncs.seriesKey(item.id) };
    if (this.isAccount(item)) { return keyFuncs.accountKey(item.id) };
    return null;
  }

  getKeys(array) {
    return array.map((item) => this.getKey(item));
  }

  getChildrenKeys(key) {
    return this.getKeys(this.getChildren(key));
  }

  getParentsKeys(key) {
    return this.getKeys(this.getParents(key));
  }

  getDirectParent(key) {
    const allParents = this.getParents(key);
    if (this.isKeySeries(key)) {
      return allParents.find((parent) => this.classes.includes(parent));
    }
    if (this.isKeyAccount(key)) {
      return allParents.find((parent) => this.series.includes(parent));
    }
    return null;
  }

  getDirectParentKey(key) {
    const directParent = this.getDirectParent(key);
    return this.getKey(directParent);
  }

  getDirectChildren(key) {
    const allChildren = this.getChildren(key);
    if (this.isKeyClass(key)) {
      return allChildren.filter((child) => this.series.includes(child));
    }
    if (this.isKeySeries(key)) {
      return allChildren.filter((child) => this.accounts.includes(child));
    }
    return [];
  }

  getDirectChildrenKeys(key) {
    const directChildren = this.getDirectChildren(key);
    return directChildren.map((child) => this.getKey(child));
  }

  getAllKeys() {
    return this.getKeys([...this.classes, ...this.series, ...this.accounts]);
  }


  getDefaultKeys(defAllocated = [], defExcluded = []) {
    if (this.isNewRecord) {
      return [this.getAllKeys(), []];
    }

    const allChildrenAlloc = uniq(flatten(defAllocated.map((key) => this.getChildrenKeys(key))));
    const allChildrenExcl = uniq(flatten(defExcluded.map((key) => this.getChildrenKeys(key))));

    const allocated = uniq([...without([...allChildrenExcl, ...defExcluded], allChildrenAlloc), ...defAllocated])
    const excluded = without(defAllocated, [...allChildrenExcl, ...defExcluded]);
    return [allocated, excluded]
  }

  getParentsThatNeedAllocation(allocatedKeys) {
    const parentsToAllocate = [];
    this.getKeys([...this.classes, ...this.series]).forEach((key) => {
      const isKeyAllocated = allocatedKeys.includes(key);
      if (isKeyAllocated) { return; }

      const allChildren = this.getChildrenKeys(key);
      const isAllChildrenAllocated = allChildren
        .every((childKey) => [...allocatedKeys, ...parentsToAllocate].includes(childKey));
      if (isAllChildrenAllocated) {
        parentsToAllocate.push(key);
      }
    })
    return parentsToAllocate;
  }

  getExcessiveExcludedItems(excludedKeys) {
    const items = [];
    excludedKeys.forEach((key) => {
      const parent = this.getDirectParentKey(key);
      if (excludedKeys.includes(parent)) {
        items.push(key);
      }
    });
    return items;
  }

  getHalfCheckedKeys(allocatedKeys) {
    return this.getKeys([...this.classes, ...this.series]).filter((key) => {
      const isAllocated = allocatedKeys.includes(key);
      if (isAllocated) { return false; }

      const allChildren = this.getChildrenKeys(key);
      return allChildren.some((childKey) => allocatedKeys.includes(childKey));
    })
  }

  getKeysForSubmittion(allocatedKeys, excludedKeys) {
    const filterChildren = (keys) => keys.filter((key) =>
      !keys.includes(this.getDirectParentKey(key))
    );

    return [filterChildren(allocatedKeys), filterChildren(excludedKeys)]
  }
}

export default TreeHelper;