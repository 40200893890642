export const CHANGE_INPUT = 'CHANGE_INPUT';

export const CLEAR_FORM = 'CLEAR_FORM';
export const SUBMITTING_FORM = 'SUBMITTING_FORM';
export const SUBMITTING_FORM_SUCCEEDED = 'SUBMITTING_FORM_SUCCEEDED';
export const SUBMITTING_FORM_FAILED = 'SUBMITTING_FORM_FAILED';

// Entities
export const ENTITIES_DELETE = 'ENTITIES_DELETE';
export const ENTITIES_MERGE = 'ENTITIES_MERGE';
export const ENTITIES_SYNC = 'ENTITIES_SYNC';

// Charts
export const CHARTS_FETCH_SUCCESS = 'CHARTS_FETCH_SUCCESS';
export const CHARTS_SEARCH_VALUE_UPDATE = 'CHARTS_SEARCH_VALUE_UPDATE';
export const CHARTS_EXPANDED_KEYS_UPDATE = 'CHARTS_EXPANDED_KEYS_UPDATE';
export const CHARTS_SECURITY_FILTERS_UPDATE = 'CHARTS_SECURITY_FILTERS_UPDATE';
export const CHARTS_MAPPING_DESTROY_SUCCESS = 'CHARTS_MAPPING_DESTROY_SUCCESS';

// Fund
export const FUND_CHARTS_FETCH_SUCCESS = 'FUND_CHARTS_FETCH_SUCCESS';
export const FUND_CHARTS_SEARCH_VALUE_UPDATE = 'FUND_CHARTS_SEARCH_VALUE_UPDATE';
export const FUND_CHARTS_SECURITY_FILTERS_UPDATE = 'FUND_CHARTS_SECURITY_FILTERS_UPDATE';
export const FUND_CHARTS_EXPANDED_KEYS_UPDATE = 'FUND_CHARTS_EXPANDED_KEYS_UPDATE';
export const FUND_ACCOUNTS_FETCH_SUCCESS = 'FUND_ACCOUNTS_FETCH_SUCCESS';

export const FUND_ACCRUAL_FORM_UPDATE = 'FUND_ACCRUAL_FORM_UPDATE';

// Accruals
export const ACCRUAL_PAYMENTS_ITEMS_CLEAR = 'ACCRUAL_PAYMENTS_ITEMS_CLEAR';
export const ACCRUAL_PAYMENTS_ITEMS_POST_FAILURE = 'ACCRUAL_PAYMENTS_ITEMS_POST_FAILURE';
export const ACCRUAL_PAYMENTS_ITEMS_EDITABLE_KEYS_UPDATE = 'ACCRUAL_PAYMENTS_ITEMS_EDITABLE_KEYS_UPDATE';
export const ACCRUAL_PAYMENTS_ITEMS_EDITABLE_KEYS_CLEAR = 'ACCRUAL_PAYMENTS_ITEMS_EDITABLE_KEYS_CLEAR';
export const ACCRUAL_PAYMENTS_ITEMS_ERRORS_UPDATE = 'ACCRUAL_PAYMENTS_ITEMS_ERRORS_UPDATE';
export const ACCRUAL_PAYMENTS_ITEM_CREATE = 'ACCRUAL_PAYMENTS_ITEM_CREATE';
export const ACCRUAL_PAYMENTS_ITEM_UPDATE = 'ACCRUAL_PAYMENTS_ITEM_UPDATE';
export const ACCRUAL_PAYMENTS_ITEM_DELETE = 'ACCRUAL_PAYMENTS_ITEM_DELETE';
export const ACCRUAL_ACCOUNT_SELECT = 'ACCRUAL_ACCOUNT_SELECT';
export const ACCRUAL_INITIAL_VALUES_CREATE = 'ACCRUAL_INITIAL_VALUES_CREATE';

// Form
export const FORM_UPDATE = 'FORM_UPDATE';
export const FORM_PAYMENTS_SCHEDULE_SUBMIT_ERRORS_UPDATE = 'FORM_PAYMENTS_SCHEDULE_SUBMIT_ERRORS_UPDATE';

// Request in progress
export const REQUEST_IN_PROCESS_SET = 'REQUEST_IN_PROCESS_SET';

// Allocated Amounts
export const ALLOCATED_AMOUNT_CHANGE = 'ALLOCATED_AMOUNT_CHANGE';
export const ALLOCATED_AMOUNT_SET_SPECIAL = 'ALLOCATED_AMOUNT_SET_SPECIAL';
export const ALLOCATED_AMOUNT_ADD = 'ALLOCATED_AMOUNT_ADD';
export const ALLOCATED_AMOUNT_TOGGLE = 'ALLOCATED_AMOUNT_TOGGLE';

// Investor Documents
export const INVESTOR_DOCUMENTS_SET_COLLECTION = 'INVESTOR_DOCUMENTS_SET_COLLECTION';
export const INVESTOR_DOCUMENTS_CREATE_DIRECTORY = 'INVESTOR_DOCUMENTS_CREATE_DIRECTORY';
export const INVESTOR_DOCUMENTS_UPDATE_DIRECTORY = 'INVESTOR_DOCUMENTS_UPDATE_DIRECTORY';
export const INVESTOR_DOCUMENTS_REMOVE_DIRECTORY = 'INVESTOR_DOCUMENTS_REMOVE_DIRECTORY';
export const INVESTOR_DOCUMENTS_CREATE_DOCUMENT = 'INVESTOR_DOCUMENTS_CREATE_DOCUMENT';
export const INVESTOR_DOCUMENTS_UPDATE_DOCUMENT = 'INVESTOR_DOCUMENTS_UPDATE_DOCUMENT';
export const INVESTOR_DOCUMENTS_REMOVE_DOCUMENT = 'INVESTOR_DOCUMENTS_REMOVE_DOCUMENT';
export const INVESTOR_DOCUMENTS_CHILDREN_ACCESSES = 'INVESTOR_DOCUMENTS_CHILDREN_ACCESSES';
export const INVESTOR_DOCUMENTS_SET_FILTER = 'INVESTOR_DOCUMENTS_SET_FILTER';

// My Tasks
export const MY_TASKS_ADD_TASKS = 'MY_TASKS_ADD_TASKS';