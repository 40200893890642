import { toUpper, pipe, reject, isNil, join, map, trim, is } from 'ramda';

import { argsToArray } from '../ramda';

export function catcher(func) {
  return async (...props) => {
    try {
      await func(...props)
    } catch (err) {
      console.log(err);
    }
    return 200;
  }
}

export function getActionTypeWithPrefix(prefix, actionType) {
  if (isNil(actionType)) {
    throw new Error('Action Type must not be nil');
  }

  if (prefix && !is(String, prefix)) {
    throw new Error('Prefix must not be a string');
  }

  if (actionType && !is(String, actionType)) {
    throw new Error('Action Type must not be a string');
  }

  return pipe(
    argsToArray,
    reject(isNil),
    map(trim),
    join('_'),
    toUpper,
  )(prefix, actionType);
}

export default getActionTypeWithPrefix;
