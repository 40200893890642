import { createStore, applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk';
import humps from 'humps';

import reducer from './reducer';

const createStoreWithThunk = applyMiddleware(ReduxThunk)(createStore);

export default function configureStore(initialState) {
  const camelizeState = humps.camelizeKeys(initialState);
  const store = createStoreWithThunk(reducer,
    camelizeState,
    window.__REDUX_DEVTOOLS_EXTENSION__ &&
    window.__REDUX_DEVTOOLS_EXTENSION__()
  );

  return store;
}
