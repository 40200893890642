import moment from 'moment';

export function isDate(value) {
  return moment.utc(Date.parse(value)).isValid();
}

export function stringToDate(stringValue, stringFormat = 'MM/DD/YYYY') {
  return stringValue && isDate(stringValue) ? moment.utc(stringValue, stringFormat).toDate() : undefined;
}

export function dateToString(value, format = 'MM/DD/YYYY') {
  return isDate(value) ? moment.utc(value).format(format) : undefined;
}
