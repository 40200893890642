import { getQueryParamsFromCurrent } from 'utils/queryParams';

const overrideItemsFilter = (values, overrideItems) => {
  const { classId, seriesId } = values;

  if (!classId) {
    return overrideItems;
  }

  const filteredOverrideItems = overrideItems.filter((item) => item.source.classId === classId);

  if (seriesId) {
    const filteredBySeries = filteredOverrideItems.filter((item) => item.source.seriesId === seriesId);

    return filteredBySeries;
  }

  return filteredOverrideItems;
};

export const getDecoratedValuesFromUrl = () => {
  const params = getQueryParamsFromCurrent();
  const result = {};

  for (let key in params) {
    if (params[key]) {
      result[key] = Number(params[key]);
    }
  }

  return result;
};

export default overrideItemsFilter;
