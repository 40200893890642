import { createSelector } from 'reselect';
import { identity } from 'ramda';
import { getIn } from 'utils/ramda';

export const initialState = {};

// Form
const formSelector = (state) => state.forms || initialState;

const getProps = (state, props) => props;

export const formValuesSelector = (state, { name } = '') => getIn(`forms.${name}.values`, state) || false;

export const formSubmitErrorSelector = (state, { name } = '') => getIn(`forms.${name}.submitError`, state) || '';

export const formDirtySinceLastSubmitSelector = (state, { name } = '') =>
  getIn(`forms.${name}.dirtySinceLastSubmit`, state) || false;

// Payment Schedule
const paymentsScheduleSubmitErrorsSelector = (state) =>
  getIn('forms.paymentSchedule.submitErrors.paymentItems', state) || [];

// Reselect
export const getFormState = createSelector(formSelector, getProps, (forms, props) => forms[props.form]);

export const getFormStateValues = createSelector(getFormState, (form) => form && form.values);

export const getFormStateInitialValues = createSelector(getFormState, (form) => form && form.initialValues);

export const getPaymentsScheduleSubmitErrors = createSelector(paymentsScheduleSubmitErrorsSelector, identity);
