import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';

import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import { FormGroup } from '../';

const percentageMask = createNumberMask({
  prefix: '',
  suffix: '%',
  allowDecimal: true,
  decimalLimit: 2,
});

const propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  dataStateKey: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  size: PropTypes.number,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

const defaultProps = {
  type: 'string',
  disabled: false,
  // size: 5,
  className: 'form__control',
};

const PercentageInput = (props) => {
  const {
    id,
    type,
    size,
    name,
    dataStateKey,
    value,
    label,
    required,
    placeholder,
    className,
    onChange,
    disabled,
    errors,
  } = props;

  return (
    <FormGroup label={label} required={required} id={id} errors={errors}>
      <MaskedInput
        id={id}
        type={type}
        required={required}
        name={name}
        size={size}
        data-type="number"
        data-statekey={dataStateKey}
        value={numeral(value).value()}
        placeholder={placeholder}
        mask={percentageMask}
        className={className}
        onChange={onChange}
        disabled={disabled}
      />
    </FormGroup>
  );
};

PercentageInput.propTypes = propTypes;
PercentageInput.defaultProps = defaultProps;

export default PercentageInput;
