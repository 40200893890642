import React from 'react';
import PropTypes from 'prop-types';
import { compose, mapProps, pure } from 'recompose';

import { dateIsValid } from 'utils/formats';
import TextInput from './TextInput';

const propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  guide: PropTypes.bool,
  type: PropTypes.string,
};

const defaultProps = {
  id: null,
  name: null,
  guide: true,
  type: 'text',
};

function DateInput({ value, ...props }) {
  return <TextInput {...props} typeOfMask="date" value={value} />;
}

const enhance = compose(
  mapProps(({ setFocused, open, value, onFocus, ...props }) => ({
    ...props,
    value: dateIsValid(value) ? value : value,
  })),
  pure,
);

DateInput.propTypes = propTypes;
DateInput.defaultProps = defaultProps;

export default enhance(DateInput);
