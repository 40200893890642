import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, get, concat, omit, pickBy, identity } from 'lodash';
import classNames from 'classnames';

const propTypes = {
  errors: PropTypes.object,
  success: PropTypes.object,
};

const FormAlerts = (props) => {
  const { errors, success } = props;
  const compactErrors = pickBy(errors, identity);
  const classes = classNames({
    'alert alert-form': true,
    'alert-danger': !isEmpty(compactErrors),
    'alert-success': isEmpty(compactErrors) && success && !isEmpty(success.message),
  });

  const defaultError = [I18n.t('shared.messages.errors')];

  const messages = (() => {
    if (isEmpty(compactErrors)) {
      return success ? [success.message] : null;
    } else {
      return isEmpty(get(compactErrors, 'base'))
        ? defaultError
        : isEmpty(omit(compactErrors, 'base'))
          ? errors.base
          : concat(defaultError, compactErrors.base);
    }
  })();

  return (
    <div>
      {messages
        && messages.map((message, k) => (
          <div key={k} className={classes}>
            {message}
          </div>
        ))}
    </div>
  );
};

FormAlerts.propTypes = propTypes;

export default FormAlerts;
