import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose, pure, withHandlers, withState } from 'recompose';
import { isNil } from 'ramda';

import { Drawer as AntDrawer } from 'antd';

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  onShow: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  visible: PropTypes.bool,
  width: PropTypes.number,
  title: PropTypes.string,
  closable: PropTypes.bool,
  placement: PropTypes.string,
  destroyOnClose: PropTypes.bool,
  render: PropTypes.func.isRequired,
};

const defaultProps = {
  width: 600,
  closable: true,
  placement: 'right',
  destroyOnClose: true,
  title: undefined,
  visible: false,
};

function Drawer({
  render,
  onShow,
  onClose,
  visible,
  children,
  width,
  closable,
  placement,
  destroyOnClose,
  title,
  ...props
}) {
  const drawerProps = {
    width,
    title,
    placement,
    closable,
    onClose,
    destroyOnClose,
    visible,
    ...props,
  };
  return (
    <Fragment>
      {render({ onShow, onClose, visible })}
      <AntDrawer {...drawerProps} width={width}>
        {typeof children === 'function' && children({ onShow, onClose, visible })}
        {typeof children !== 'function' && children}
      </AntDrawer>
    </Fragment>
  );
}

Drawer.propTypes = propTypes;
Drawer.defaultProps = defaultProps;

const enhance = compose(
  withState('visible', 'setVisible', ({ defaultVisible }) => {
    if (isNil(defaultVisible)) return false;
    return defaultVisible;
  }),
  withHandlers({
    onShow: ({ setVisible, onShow }) => () => {
      if (onShow) onShow();
      setVisible(true);
    },
    onClose: ({ setVisible, onClose }) => () => {
      if (onClose) onClose();
      setVisible(false);
    },
  }),
  pure,
);

export default enhance(Drawer);
