import set from 'lodash/fp/set';

import * as types from './actionTypes';

const initialState = {
  items: [],
  manual: false,
  _temp: {},
  _new: {
    date: null,
    amount: null,
    paymentAmount: null
  }
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.UPDATE_SCHEDULE:
      return {
        ...state,
        items: action.payload.items
      }

    case types.CHANGE_INPUT:
      return set(action.payload.stateKey, action.payload.value)(state);

    case types.UPDATE_RECEIVABLE_AMOUNTS:
      return {
        ...state,
        items: action.payload.items,
      }

    case types.ADD_LINE_ITEM:
      return {
        ...state,
        items: action.payload.items,
        _new: action.payload.new,
      }

    case types.UNDO_LINE_ITEM:
      return {
        ...state,
        items: action.payload.items
      }

    case types.EDIT_LINE_ITEM_AND_CHANGE_INPUT_AND_CREATE_TEMP_ITEM:
      state = set(action.payload.stateKey, action.payload.value)(state);

      return {
        ...state,
        _temp: action.payload.temp
      }

    case types.DESTROY_TEMP_ITEM:
      return {
        ...state,
        _temp: action.payload.value
      }

    case types.DONE_LINE_ITEM:
      return {
        ...state,
        items: action.payload.items
      }

    case types.DESTROY_LINE_ITEM:
      return {
        ...state,
        items: action.payload.items
      }

    case types.CHANGE_MANUAL:
      return {
        ...state,
        manual: action.payload.manual
      }

    default:
      return state;
  }
}
