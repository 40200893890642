import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { DatePicker } from 'antd';

const propTypes = {
  allowClear: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.string,
};

const defaultProps = {
  allowClear: false,
  onBlur: undefined,
  onChange: undefined,
  value: undefined,
};

function CustomDatePicker(props) {
  const { allowClear, onChange, value } = props;
  const validValue = value !== 'Invalid date' ? value : '';
  const defaultValue = validValue && moment(validValue);

  return (
    <DatePicker
      allowClear={allowClear}
      defaultValue={defaultValue}
      onChange={onChange}
      format={['MM/DD/YYYY', 'M/D/YYYY']}
    />
  );
}

CustomDatePicker.propTypes = propTypes;
CustomDatePicker.defaultProps = defaultProps;

export default CustomDatePicker;
