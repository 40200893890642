import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  hint: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

const defaultProps = {
  link: false,
};

const Stat = (props) => {
  const { hint, value, link } = props;

  const statValue = Array.isArray(value) ? value : [value];

  return (
    <div className="panel text-centered">
      <div className="panel__body panel__body-padded">
        {statValue.map((val, i) => (
          <div key={i} style={{ display: 'inline-block', width: `${100 / statValue.length}%` }}>
            {link ? (
              <a href={link} target="_blank" className="m-none text-bold" style={{ fontSize: '2em' }}>
                {val}
              </a>
            ) : (
              <span className="m-none text-bold" style={{ fontSize: '2em' }}>
                {val}
              </span>
            )}
          </div>
        ))}
        <span className="small" style={{ display: 'block', width: '100%' }}>
          {hint}
        </span>
      </div>
    </div>
  );
};

Stat.propTypes = propTypes;
Stat.defaultProps = defaultProps;

export default Stat;
