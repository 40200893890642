import { pipe } from 'ramda';
import { stringify, parse } from 'qs';

import { decamelizeKeys, camelizeKeys } from 'utils/humps';

export const convertToQueryParams = (values) => pipe(stringify)(decamelizeKeys(values));

export const addQueryParamsToCurrent = (values = {}) => {
  const params = convertToQueryParams(values);

  window.history.pushState('', '', `?${params}`);
};

export const getQueryParamsFromCurrent = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const str = urlParams.toString();
  return camelizeKeys(parse(str));
};

export const getQueryParams = (name) => {
  const urlParams = new URLSearchParams(window.location.search);
  const stringParams = urlParams.toString();
  const parsedParams = parse(stringParams);

  if (name) {
    return parsedParams[name];
  }

  return parsedParams;
};
