import { toLower } from 'ramda';
import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  searchValue: PropTypes.string.isRequired,
  item: PropTypes.shape({}).isRequired,
}

const defaultProps = {};

function Name({ searchValue, item }) {
  const { name } = item;
  const index = toLower(name).indexOf(toLower(searchValue));
  const beforeStr = name.substr(0, index);
  const searchStr = name.substr(beforeStr.length, searchValue.length);
  const afterStr = name.substr(index + searchValue.length);

  return (
    <>
      { index > -1 ? (
        <>
          <span className="found-item" style={{ padding: 0 }}>
            {beforeStr}
            <span style={{ color: '#f50' }}>{searchStr}</span>
            {afterStr}
          </span>
        </>
      ) : (
        <>
          <span style={{ padding: 0 }}>{`${name}`}</span>
        </>
      )}

    </>
  )
};

Name.propTypes = propTypes;
Name.defaultProps = defaultProps;

export default Name;