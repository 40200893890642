import { history } from 'stores/configureStore';
import TaskItemPatternsApi from '../../../apiAdapters/funds/taskItemPatterns';

export function newTaskItemPattern(fundId) {
  return (dispatch) => {
    dispatch({ type: 'NEW_TASK_ITEM_PATTERN' });
    history.push(Routes.new_fund_task_item_pattern_path(fundId));
    document.title = 'Fund | Task Item Pattern | New';
  };
}

export function editTaskItemPattern(fundId, id) {
  return (dispatch) => {
    dispatch({ type: 'EDIT_TASK_ITEM_PATTERN' });
    history.push(Routes.edit_fund_task_item_pattern_path(fundId, id));
    document.title = 'Fund | Task Item Pattern | Edit';
  };
}

// eslint-disable-next-line max-len
export const createTaskItemPattern = (values, { fundId }) => (dispatch, getState) => TaskItemPatternsApi.submit(values, { fundId })
  .then(({ payload }) => {
    const { fund: { taskItemPatterns: { collection } } } = getState();
    const newCollection = [...collection, { ...payload }];

    dispatch({ type: 'CREATE_TASK_ITEM_PATTERN', payload: newCollection });
    history.push(Routes.fund_task_item_patterns_path(fundId));
    document.title = 'Fund | Task Item Patterns';
  })
  .catch((error) => console.log(error));

// eslint-disable-next-line max-len
export const updateTaskItemPattern = (values, { fundId, id }) => (dispatch) => TaskItemPatternsApi.submit(values, { fundId, id })
  .then(({ payload }) => {
    dispatch({ type: 'UPDATE_TASK_ITEM_PATTERN', payload });
    history.push(Routes.fund_task_item_patterns_path(fundId));
    document.title = 'Fund | Task Item Patterns';
  })
  .catch((error) => console.log(error));

export function closeTaskItemPattern(fundId) {
  return (dispatch) => {
    dispatch({ type: 'CLOSE_TASK_ITEM_PATTERN' });
    history.push(Routes.fund_task_item_patterns_path(fundId));
    document.title = 'Fund | Task Item Patterns';
  };
}

export const deleteTaskItemPattern = ({ fundId, id }) => (dispatch) => {
  TaskItemPatternsApi.delete({ fundId, id })
    .then(({ payload }) => {
      dispatch({ type: 'DESTROY_TASK_ITEM_PATTERN', payload });
      history.push(Routes.fund_task_item_patterns_path(fundId));
      document.title = 'Fund | Task Item Patterns';
    })
    .catch((error) => console.log(error));
};

export function newTaskItemPatterns(fundId) {
  return (dispatch) => {
    dispatch({ type: 'NEW_TASK_ITEM_PATTERNS' });
    history.push(Routes.import_new_fund_task_item_patterns_path(fundId));
    document.title = 'Fund | Task Item Patterns | Import | New';
  };
}

export const importTaskItemPatterns = ({ taskListPatternId, fundId }) => (dispatch) => {
  TaskItemPatternsApi.import({ taskListPatternId }, { fundId })
    .then(({ payload = [] }) => {
      dispatch({ type: 'IMPORT_TASK_ITEM_PATTERNS', payload });
      history.push(Routes.fund_task_item_patterns_path(fundId));
      document.title = 'Fund | Task Item Patterns';
    })
    .catch((error) => console.log(error));
};

// eslint-disable-next-line max-len
export const changeTaskItemPatternPosition = (itemPosition, position, { fundId, id }) => (dispatch) => TaskItemPatternsApi.submit(
  { position }, { fundId, id },
)
  .then(({ payload }) => dispatch({ type: 'CHANGE_TASK_ITEM_PATTERN_POSITION', payload }))
  .catch((error) => console.log(error));
