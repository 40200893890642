import React, { Component } from 'react';
import PropTypes from 'prop-types';

import FontAwesome from 'react-fontawesome';

import MappingItem from './MappingItem';
import Policy from '../../../Policy';

const contextTypes = {
  onSelectAccount: PropTypes.func,
};

const propTypes = {
  account: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  category: PropTypes.object.isRequired,
  fundId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const defaultProps = {
  fundId: null,
};

class AccountItem extends Component {
  render() {
    const { account, category, fundId, actions } = this.props;
    const { changeMode, fillFormAccount, deleteMapping, toggleMappings } = actions;

    const MappingItems = account.mappings.map((mapping) => (
      <MappingItem key={mapping.id} mapping={mapping} fundId={fundId} deleteMapping={deleteMapping} />
    ));

    return (
      <li className="tree__item tree__item-sub">
        <span className="tree__actions_wrapper">
          {!this.context.onSelectAccount && account.mappings && account.mappings.length > 0 ? (
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                toggleMappings(account.id);
              }}
            >
              {account.displayTitle} <FontAwesome name={account.mappingsCollapsed ? 'angle-up' : 'angle-down'} />
            </a>
          ) : (
            account.displayTitle
          )}

          <span className="tree__actions">
            {this.context.onSelectAccount && (
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  this.context.onSelectAccount(account.id);
                }}
                className="btn btn-primary btn-xs"
              >
                Select
              </a>
            )}{' '}
            <Policy name="chart.category.account.edit" type="hide">
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  fillFormAccount(account);
                  changeMode('accountForm', category.chart);
                }}
                className="btn btn-default btn-xs"
              >
                Edit
              </a>
            </Policy>
          </span>
          {!this.context.onSelectAccount && account.formAttributes && account.formAttributes.classSpecific === 'true' && (
            <div className="small">
              <strong>Specific Allocation</strong>
            </div>
          )}
        </span>

        {!fundId && (
          <div className="small">
            {account.applyToExistingFunds ? 'Applies to existing fund' : 'Does not apply to existing fund'}
          </div>
        )}

        {MappingItems && MappingItems.length > 0 && !this.context.onSelectAccount && !account.mappingsCollapsed && (
          <ul className="tree tree-sub">{MappingItems}</ul>
        )}
      </li>
    );
  }
}

AccountItem.contextTypes = contextTypes;
AccountItem.propTypes = propTypes;
AccountItem.defaultProps = defaultProps;

export default AccountItem;
