import fetch from '../fetch';
import { deleteRequest, postRequest, putRequest } from '../requests';

export default class InvestorDocumentPatternDirectoriesAPI {
  static submit({ body, patternId, id = null }) {
    const requestBody = {
      body: {
        investor_document_pattern_directory: body
      }
    };

    const url = id
      ? Routes.investor_document_pattern_directory_path(patternId, id)
      : Routes.investor_document_pattern_directories_path(patternId);
    const request = id ? putRequest(requestBody) : postRequest(requestBody);
    return fetch(url, request);
  }

  static delete({ patternId, id }) {
    return fetch(Routes.investor_document_pattern_directory_path(patternId, id), deleteRequest())
  }
}