import React from 'react';
import { hot } from 'react-hot-loader/root';
import { Switch, Route } from 'react-router-dom';

import { FUNDS_PATH } from 'constants/router';

import InvestorDocumentPatterns from '../InvestorDocumentPatterns';
import MyTasksPage from '../MyTasksPage';
import TaskListPatterns from '../TaskListPatterns';

import FundsRoutes from './funds';

function RootRoutes(props) {
  return (
    <Switch>
      <Route
        path={['/tasks']}
        render={(routerProps) => <MyTasksPage {...props} {...routerProps} />}
        exact
      />
      <Route
        path={[
          '/task_list_patterns',
          '/task_list_patterns/new',
          '/task_list_patterns/:id/edit',
          '/task_list_patterns/:id/task_item_patterns/new',
          '/task_list_patterns/:id/task_item_patterns/:taskItemPatternId/edit',
        ]}
        render={(routerProps) => <TaskListPatterns {...props} {...routerProps} />}
        exact
      />
      <Route
        path={[
          '/investor_document_patterns',
          '/investor_document_patterns/new',
          '/investor_document_patterns/:id/edit',
          '/investor_document_patterns/:id/directories/new',
          '/investor_document_patterns/:id/directories/:directoryId/edit'
        ]}
        render={(routerProps) => <InvestorDocumentPatterns {...props} {...routerProps} />}
        exact
      />
      <Route path={FUNDS_PATH} render={(routerProps) => <FundsRoutes {...props} {...routerProps} />} />
    </Switch>
  );
}

export default hot(RootRoutes);
