import React from 'react';
import PropTypes from 'prop-types';

import { dateFormat } from 'utils/formats';
import ColumnGroup from 'antd/lib/table/ColumnGroup';

const propTypes = {
  date: PropTypes.string,
  status: PropTypes.string,
  reportId: PropTypes.number,
  fundId: PropTypes.number.isRequired,
  valuationId: PropTypes.number.isRequired,
};

const defaultProps = {
  date: '',
  status: '',
  reportId: null,
};

const ValuationInfo = (props) => {
  const { date, status, reportId, fundId, valuationId } = props;


  return (
    <div className="ant-statistic">
      <div className="ant-statistic-title">{status}</div>
      <div className="ant-statistic-content">
        <span className="ant-statistic-content-value">
          <span className="ant-statistic-content-value-int">{dateFormat(date)}</span>
        </span>

        {reportId && <a className='btn btn-primary m-l-xs' href={Routes.fund_valuation_report_path(fundId, valuationId, reportId)} target="_blank">Go to this period’s reports</a>}

      </div>
    </div>
  );
}

ValuationInfo.propTypes = propTypes;
ValuationInfo.defaultProps = defaultProps;

export default ValuationInfo;
