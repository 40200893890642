import * as types from 'constants/actionTypes';

const initialState = {
  collection: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.MY_TASKS_ADD_TASKS:
      return action.payload;
    default:
      return state;
  }
}
