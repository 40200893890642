/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect';
import { filter } from 'lodash';
import { makeGetAllocatedAmounts } from 'components/shared/AllocatedAmounts/data/selectors';

const stateSelector = (state) => state;
const getAllocatedAmounts = makeGetAllocatedAmounts();

export const getSubmitingFormData = createSelector(stateSelector, (state) => {
  const glEntry = state.glEntryForm.data;

  const filterAllocatedAmountsForLineItem = (lineItem, index) => {
    const allocatedAmounts = getAllocatedAmounts(state, { parentId: index }).current;

    return allocatedAmounts.reduce((result, allocatedAmount) => {
      if (allocatedAmount.id || allocatedAmount.selected || allocatedAmount.amount !== 0) {
        result.push({
          id: allocatedAmount.id,
          amount: allocatedAmount.amount,
          targetId: allocatedAmount.targetId,
          targetType: allocatedAmount.targetType,
          selected: allocatedAmount.selected,
          _destroy: lineItem.specialAllocation ? allocatedAmount._destroy : true,
        });

        return result;
      }
    }, []);
  };

  const filteredLineItems = glEntry.lineItems.reduce((result, lineItem, index) => {
    result.push({
      id: lineItem.id,
      glEntryId: lineItem.glEntryId,
      creditAmount: lineItem.creditAmount ? lineItem.creditAmount : undefined,
      debitAmount: lineItem.creditAmount ? undefined : lineItem.debitAmount,
      accountId: lineItem.accountId,
      specialAllocation: lineItem.specialAllocation,
      allocatedAmounts: filterAllocatedAmountsForLineItem(lineItem, index),
      specialAllocationType: lineItem.specialAllocationType,
      _destroy: lineItem._destroy ? lineItem._destroy : undefined,
    });

    return result;
  }, []);

  return {
    ...glEntry,
    lineItems: filteredLineItems,
  };
});
