import { applyMiddleware, createStore, combineReducers, compose } from 'redux';

import thunk from 'redux-thunk';
import { stringToDate } from 'utils/dateTransformations';

import { reducer as allocatedAmountsReducer } from 'components/shared/AllocatedAmounts';
import * as chartOfAccounts from 'components/shared/ChartOfAccounts/reducer';
import resourceFund from '../reducer';
import glEntryForm from './reducer';
import * as reducers from '../Charts/reducer';

const policies = (state = {}) => state;

const reducer = combineReducers({
  ...chartOfAccounts,
  ...reducers,
  resourceFund,
  glEntryForm,
  policies,
  allocatedAmounts: allocatedAmountsReducer
});

export default function configureStore(initialState) {
  // Middleware and store enhancers
  const enhancers = [applyMiddleware(thunk)];

  if (process.env.NODE_ENV !== 'production') {
    window.devToolsExtension && enhancers.push(window.devToolsExtension());
  }

  const state = {
    ...initialState,
    glEntryForm: {
      data: {
        ...initialState.glEntryForm.data,
        accountingDate: initialState.glEntryForm.data.accountingDate
          ? stringToDate(initialState.glEntryForm.data.accountingDate)
          : undefined,
      },
      meta: initialState.glEntryForm.meta,
    },
  };

  const store = createStore(reducer, state, compose(...enhancers));

  return store;
}
