import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';

import { dateToString } from 'utils/dateTransformations';
import { CurrencyInput, PercentageInput } from 'components/shared';

const propTypes = {
  account: PropTypes.object.isRequired,
  fundId: PropTypes.number.isRequired,
};

function AccountItem(props) {
  const { account, fundId } = props;

  const displayAccountCommitmentAmount = numeral(account.commitmentAmount).format('$0,0.00');
  const displayAccountAmountPercent = numeral(account.amountPercent).format('0.00%');

  return (
    <li className="tree__item tree__item-sub">
      <div className="row">
        <div className="col-md-3">
          <input checked type="checkbox" />{' '}
          <a target="_blank" rel="noopener noreferrer" href={Routes.fund_account_path(fundId, account.id)}>
            <strong>{account.name}</strong>
          </a>
          {(account.openDate || account.closeDate) && (
            <div className="small">
              {account.openDate && (
                <span>
                  <strong>Open</strong>
                  <span>: {dateToString(account.openDate)}</span>
                </span>
              )}

              {account.closeDate && (
                <span>
                  <strong> Close</strong>
                  <span>: {dateToString(accountCloseDate)}</span>
                </span>
              )}
            </div>
          )}
        </div>

        <div className="col-md-2">
          {displayAccountCommitmentAmount}
          <br />
          <em className="small">{displayAccountAmountPercent}</em>
        </div>

        <div className="col-md-3">
          <CurrencyInput
            id={`allocated_amounts${account.id}`}
            name={'allocated_amounts[amount][]'}
            required
            dataStateKey={`data.allocatedAmounts.${account.id}.amount`}
            value={account.amount}
            onChange={() => false}
          />
        </div>

        <div className="col-md-3">
          <PercentageInput
            id={`allocated_amounts${account.id}`}
            name={'allocated_amounts[amountPercent][]'}
            required
            dataStateKey={`data.allocatedAmounts.${account.id}.amountPercent`}
            value={'0'}
            onChange={() => false}
          />
        </div>
      </div>
    </li>
  );
}

AccountItem.propTypes = propTypes;

export default AccountItem;
