import { pipe } from 'ramda';

import { sortObjectsByLabel } from 'utils/sort';

function optionsDecorator(arr, generalValues = []) {
  function makeOptions() {
    if (arr) {
      const options = arr.map((item) => ({ value: item.id, label: item.name }));
      generalValues.forEach((generalValue) => {
        options.unshift(generalValue);
      });

      return options.sort(sortObjectsByLabel);
    }

    return [];
  }

  return pipe(makeOptions);
}

export default optionsDecorator;
