import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useDrag, useDrop } from 'react-dnd';
import { isEmpty } from 'ramda';
import {
  Drawer,
  Button,
  Card,
  Icon,
  Col,
  Row,
  Table,
  Tag,
  Popconfirm,
  message,
  Select,
  Divider,
  Input,
  Checkbox,
  Popover,
  Empty,
  Form,
} from 'antd';
import { Field, Form as FinalForm } from 'react-final-form';

import { validateRequired } from 'utils/validates';
import {
  collectionSelector as taskListPatternsSelector,
  getEditable as editableTaskListPatternSelector,
  getNew as getNewTaskListPatterns,
  getActive as getActiveTaskListPatterns,
} from '../../selectors/taskListPatterns';
import {
  collectionSelector as taskItemPatternsSelector,
  currentList as currentTaskItemPatternsSelector,
  getEditable as editableTaskItemPatternSelector,
  dataSource as getTableCurrentTaskItemPatterns,
  getDependentOptions as getDependentTaskItemPatterns,
} from '../../selectors/taskListPatterns/taskItemPatterns';

import {
  newTaskListPattern,
  createTaskListPattern,
  updateTaskListPattern,
  editTaskListPattern,
  closeTaskListPattern,
  deleteTaskListPattern,
  changeTaskListPatternPosition,
} from '../../actions/taskListPatterns';

import {
  newTaskItemPattern,
  createTaskItemPattern,
  updateTaskItemPattern,
  editTaskItemPattern,
  closeTaskItemPattern,
  deleteTaskItemPattern,
  changeTaskItemPatternPosition,
} from '../../actions/taskListPatterns/taskItemPatterns';

import { roles } from '../../constants/user';

const { Meta } = Card;
const { Option } = Select;

const types = ['hf', 'pe', 'fof', 'tl', 're', 'manco', 'dl', 'feeder'];

// eslint-disable-next-line react/prop-types
const DraggableBodyRow = ({ index, id, position, moveRow, className, style, ...restProps }) => {
  const ref = React.useRef();

  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: 'DraggableBodyRow',
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};

      if (dragIndex === index) {
        return {};
      }

      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      };
    },
    drop: (item) => (item.index !== index ? moveRow(item.id, item.position, position) : null),
  });

  const [, drag] = useDrag({
    item: { type: 'DraggableBodyRow', index, id, position },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drop(drag(ref));

  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ''}`}
      style={{ cursor: 'grab', ...style }}
      {...restProps}
    />
  );
};

// eslint-disable-next-line react/prop-types
const TaskListPatternCard = ({ index, id, title, position, fundType, onEdit, onDelete, onMove }) => {
  const ref = React.useRef(null);

  const [{ isActive }, drop] = useDrop({
    accept: 'TaskListPatternCard',
    collect: (monitor) => {
      const { position: dragPosition } = monitor.getItem() || {};

      if (position === dragPosition) {
        return {};
      }

      return {
        isActive: monitor.canDrop() && monitor.isOver(),
      };
    },
    drop: (item) => (item.position !== position ? onMove(item.id, item.position, position) : null),
  });

  const [{ isDragging }, drag] = useDrag({
    item: { type: 'TaskListPatternCard', id, index, position },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <div ref={ref} style={{ opacity: isDragging ? 0.5 : 1 }}>
      <Card
        style={{
          top: isActive ? 20 : 0,
          transform: isActive ? 'scale(0.9)' : 'none',
          opacity: isActive ? 0.7 : 1,
          cursor: 'grab',
        }}
        hoverable
        actions={[
          <Button type='link' icon='edit' onClick={() => onEdit(id)} />,
          (
            <Popconfirm
              placement="top"
              title='Delete Task List Pattern?'
              onConfirm={() => {
                onDelete(id);
                return message.info('Deleted.');
              }}
              okText="Confirm"
              cancelText="Close"
            >
              <Button type='link' icon='delete' />
            </Popconfirm>
          ),
        ]}
      >
        <Meta
          title={position ? `#${position} - ${title}` : title}
          description={<Tag color={fundType ? 'volcano' : null} key={fundType}>{fundType || 'No Fund Type'}</Tag>}
        />
      </Card>
    </div>
  );
};

// eslint-disable-next-line react/prop-types
const TaskListPatternForm = ({ submitting, handleSubmit, initialValues, pristine }) => (
  <Form layout='vertical' onSubmit={handleSubmit}>
    <Field name="title" validate={validateRequired()}>
      {({ input, meta }) => (
        <Form.Item
          label='Title'
          validateStatus={meta.error && meta.touched ? 'error' : 'success'}
          help={meta.error && meta.touched ? meta.error : undefined}
          required
        >
          <Input {...input} />
        </Form.Item>
      )}
    </Field>
    <Field name="fundType" parse={(v) => v || null}>
      {({ input }) => (
        <Form.Item label='Fund Type' wrapperCol={{ xs: { span: 24 }, sm: { span: 24 }, md: { span: 8 } }}>
          <Select {...input} allowClear={input.value}>
            {types.map((type) => (
              <Option value={type}>{type}</Option>
            ))}
          </Select>
        </Form.Item>
      )}
    </Field>
    <Form.Item>
      <Button type="primary" htmlType="submit" disabled={pristine || submitting}>
        {initialValues ? 'Update' : 'Create'}
      </Button>
    </Form.Item>
  </Form>
);

// eslint-disable-next-line react/prop-types
const TaskItemPatternForm = ({ submitting, handleSubmit, initialValues, pristine, dependentItemOptions = [] }) => (
  <Form layout='vertical' onSubmit={handleSubmit}>
    <Field name="title" validate={validateRequired()}>
      {({ input, meta }) => (
        <Form.Item
          label='Title'
          validateStatus={meta.error && meta.touched ? 'error' : 'success'}
          help={meta.error && meta.touched ? meta.error : undefined}
          required
        >
          <Input {...input} />
        </Form.Item>
      )}
    </Field>
    <Field name="fundUserRole" validate={validateRequired()}>
      {({ input, meta }) => (
        <Form.Item
          label='User Role'
          validateStatus={meta.error && meta.touched ? 'error' : 'success'}
          help={meta.error && meta.touched ? meta.error : undefined}
          wrapperCol={{
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 8 },
          }}
          required
        >
          <Select {...input}>
            {roles.map((role) => (
              <Option value={role}>{role}</Option>
            ))}
          </Select>
        </Form.Item>
      )}
    </Field>
    <Field name="dependentTaskItemPatternId" parse={(v) => v || null}>
      {({ input }) => (
        <Form.Item label='Dependent Task Pattern'>
          <Select {...input} allowClear={input.value}>
            {dependentItemOptions.map(({ id, title }) => (
              <Option value={id}>{title}</Option>
            ))}
          </Select>
        </Form.Item>
      )}
    </Field>
    {/* eslint-disable-next-line react/prop-types */}
    {initialValues && initialValues.active && !pristine && (
      <Field name="applyToExistingFunds">
        {({ input }) => (
          <Form.Item>
            <Checkbox {...input}>Apply to existing funds?</Checkbox>
          </Form.Item>
        )}
      </Field>
    )}
    <Form.Item>
      <Button type="primary" htmlType="submit" disabled={pristine || submitting}>
        {initialValues ? 'Update' : 'Create'}
      </Button>
    </Form.Item>
  </Form>
);

const TaskListPatterns = (props) => {
  const {
    // eslint-disable-next-line react/prop-types
    isVisibleEditTaskListPattern,
    // eslint-disable-next-line react/prop-types
    isVisibleEditTaskItemPattern,
    // eslint-disable-next-line react/prop-types
    editableTaskListPattern,
    // eslint-disable-next-line react/prop-types
    editableTaskItemPattern,
    // eslint-disable-next-line react/prop-types
    onAddTaskListPattern,
    // eslint-disable-next-line react/prop-types
    onSubmitTaskListPattern,
    // eslint-disable-next-line react/prop-types
    onSubmitTaskItemPattern,
    // eslint-disable-next-line react/prop-types
    onEditTaskListPattern,
    // eslint-disable-next-line react/prop-types
    onCloseTaskListPattern,
    // eslint-disable-next-line react/prop-types
    onAddTaskItemPattern,
    // eslint-disable-next-line react/prop-types
    onEditTaskItemPattern,
    // eslint-disable-next-line react/prop-types
    onCloseTaskItemPattern,
    // eslint-disable-next-line react/prop-types
    onDeleteTaskListPattern,
    // eslint-disable-next-line react/prop-types
    onDeleteTaskItemPattern,
    // eslint-disable-next-line react/prop-types
    tableCurrentTaskItemPatterns,
    // eslint-disable-next-line react/prop-types
    newTaskListPatterns,
    // eslint-disable-next-line react/prop-types
    activeTaskListPatterns,
    // eslint-disable-next-line react/prop-types
    dependentTaskItemPatterns,
    // eslint-disable-next-line react/prop-types
    onMoveTaskListPattern,
    // eslint-disable-next-line react/prop-types
    onMoveTaskItemPattern,
  } = props;

  return (
    <>
      <div style={{ padding: 30 }}>
        <Row gutter={16}>
          <Col span={24} style={{ paddingBottom: 8 }}>
            <div className='pull-right'>
              <Button
                type='default'
                onClick={onAddTaskListPattern}
                size='large'
              >
                Add Task List Pattern
              </Button>
            </div>
          </Col>
        </Row>
        <Divider orientation='left'>New Patterns</Divider>
        <Row gutter={16} justify="space-around">
          {
            isEmpty(newTaskListPatterns)
              ? (<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />)
              // eslint-disable-next-line react/prop-types
              : newTaskListPatterns.map((taskListPattern, index) => (
                <Col span={8} style={{ paddingBottom: 16 }}>
                  <TaskListPatternCard
                    index={index}
                    onEdit={onEditTaskListPattern}
                    onDelete={onDeleteTaskListPattern}
                    onMove={onMoveTaskListPattern}
                    {...taskListPattern}
                  />
                </Col>
              ))
          }
        </Row>
        <Divider orientation='left'>Active Patterns</Divider>
        <Row gutter={16} justify="space-around">
          {
            isEmpty(activeTaskListPatterns)
              ? (<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />)
              // eslint-disable-next-line react/prop-types
              : activeTaskListPatterns.map((taskListPattern, index) => (
                <Col span={8} style={{ paddingBottom: 16 }}>
                  <TaskListPatternCard
                    index={index}
                    onEdit={onEditTaskListPattern}
                    onDelete={onDeleteTaskListPattern}
                    onMove={onMoveTaskListPattern}
                    {...taskListPattern}
                  />
                </Col>
              ))
          }
        </Row>
      </div>
      <Drawer
        title="Task List Pattern"
        width={editableTaskListPattern ? '70%' : '50%'}
        placement='left'
        closable={false}
        onClose={onCloseTaskListPattern}
        visible={isVisibleEditTaskListPattern}
        bodyStyle={{ paddingBottom: 80 }}
        destroyOnClose
      >
        <FinalForm
          component={TaskListPatternForm}
          initialValues={editableTaskListPattern}
          onSubmit={onSubmitTaskListPattern}
          subscription={{
            pristine: true,
            hasSubmitErrors: true,
            submitting: true,
            submitError: true,
            initialValues: true,
          }}
        />
        {editableTaskListPattern && (
          <>
            <Divider orientation='left'>Task Patterns</Divider>
            <Row gutter={16}>
              <Col span={24} style={{ paddingBottom: 8 }}>
                <div className='pull-right'>
                  <Button
                    type='default'
                    onClick={() => onAddTaskItemPattern(editableTaskListPattern.id)}
                    size='medium'
                  >
                    Add Task Pattern
                  </Button>
                </div>
              </Col>
            </Row>
            <Table
              dataSource={tableCurrentTaskItemPatterns}
              onRow={(record, index) => ({
                index,
                id: record.id,
                position: record.position,
                moveRow: onMoveTaskItemPattern,
              })}
              components={{
                body: {
                  row: DraggableBodyRow,
                },
              }}
              columns={[{
                title: '#',
                dataIndex: 'position',
                key: 'position',
                fixed: 'left',
                width: 50,
                sorter: (a, b) => a.position - b.position,
                ellipsis: true,
                align: 'center',
              },
              {
                title: 'Title',
                dataIndex: 'title',
                key: 'title',
                render: (title, item) => (
                  <>
                    {title}
                    {item.dependentTaskItemPatternId && (
                      <>
                        {' '}
                        <Popover
                          content={(
                            <Button
                              type="link"
                            >
                              {/* eslint-disable-next-line max-len */}
                              {`Dependent on task #${item.dependentTaskItemPatternPosition} - ${item.dependentTaskItemPatternTitle}`}
                            </Button>
                          )}
                          trigger="hover"
                        >
                          <Icon type='info-circle' theme='twoTone' twoToneColor='#ff4d4f' />
                        </Popover>
                      </>
                    )}
                  </>
                ),
              },
              {
                title: 'Role',
                dataIndex: 'role',
                key: 'role',
                fixed: 'right',
                width: 100,
                sorter: (a, b) => a.role.length - b.role.length,
                ellipsis: true,
                render: (role) => <Tag color='volcano' key={role}>{role}</Tag>,
              },
              {
                title: undefined,
                key: 'edit',
                fixed: 'right',
                width: 50,
                align: 'center',
                render: (item) => (
                  <Button
                    type='link'
                    icon='edit'
                    onClick={() => onEditTaskItemPattern(editableTaskListPattern.id, item.id)}
                  />
                ),
              },
              {
                title: undefined,
                key: 'delete',
                fixed: 'right',
                width: 50,
                align: 'center',
                render: (item) => (
                  <Popconfirm
                    placement="left"
                    title='Delete Task Pattern?'
                    onConfirm={() => {
                      onDeleteTaskItemPattern(editableTaskListPattern.id, item.id);
                      return message.info('Deleted.');
                    }}
                    okText='Confirm'
                    cancelText='Cancel'
                  >
                    <Button type='link' icon='delete' />
                  </Popconfirm>
                ),
              }]}
              scroll={{ x: 1300 }}
              size='small'
              pagination={false}
            />
            <Drawer
              title="Task Pattern"
              width='50%'
              placement='left'
              closable={false}
              onClose={onCloseTaskItemPattern}
              visible={isVisibleEditTaskItemPattern}
              bodyStyle={{ paddingBottom: 80 }}
              destroyOnClose
            >
              <FinalForm
                component={TaskItemPatternForm}
                initialValues={editableTaskItemPattern}
                onSubmit={onSubmitTaskItemPattern}
                dependentItemOptions={dependentTaskItemPatterns}
                subscription={{
                  pristine: true,
                  hasSubmitErrors: true,
                  submitting: true,
                  submitError: true,
                  initialValues: true,
                }}
              />
              <div
                style={{
                  position: 'absolute',
                  zIndex: 999999,
                  right: 0,
                  bottom: 0,
                  width: '100%',
                  borderTop: '1px solid #e9e9e9',
                  padding: '10px 16px',
                  background: '#fff',
                  textAlign: 'right',
                }}
              >
                <Button onClick={onCloseTaskItemPattern}>
                  Close
                </Button>
              </div>
            </Drawer>
          </>
        )}
        <div
          style={{
            position: 'absolute',
            zIndex: 999999,
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
          }}
        >
          <Button onClick={onCloseTaskListPattern}>
            Close
          </Button>
        </div>
      </Drawer>
    </>
  );
};

const mapStateToProps = (state, { match: { path, params } }) => {
  const editableTaskListPatternId = (
    path === '/task_list_patterns/:id/edit'
    || path === '/task_list_patterns/:id/task_item_patterns/new'
    || path === '/task_list_patterns/:id/task_item_patterns/:taskItemPatternId/edit'
  ) && params.id;

  const editableTaskItemPatternId = path === '/task_list_patterns/:id/task_item_patterns/:taskItemPatternId/edit'
    && params.taskItemPatternId;

  const isVisibleEditTaskItemPattern = path === '/task_list_patterns/:id/task_item_patterns/:taskItemPatternId/edit'
    || path === '/task_list_patterns/:id/task_item_patterns/new';

  const isVisibleEditTaskListPattern = isVisibleEditTaskItemPattern
    || !!(params.id && path === '/task_list_patterns/:id/edit')
    || path === '/task_list_patterns/new';

  const taskListPatterns = taskListPatternsSelector(state);
  const taskItemPatterns = taskItemPatternsSelector(state);
  const currentTaskItemPatterns = currentTaskItemPatternsSelector(editableTaskListPatternId, taskItemPatterns);
  const editableTaskListPattern = editableTaskListPatternSelector(editableTaskListPatternId, taskListPatterns);
  const editableTaskItemPattern = editableTaskItemPatternSelector(editableTaskItemPatternId, currentTaskItemPatterns);

  return ({
    isVisibleEditTaskListPattern,
    isVisibleEditTaskItemPattern,
    dependentTaskItemPatterns: getDependentTaskItemPatterns(currentTaskItemPatterns, editableTaskItemPattern),
    tableCurrentTaskItemPatterns: getTableCurrentTaskItemPatterns(editableTaskListPatternId, taskItemPatterns),
    editableTaskListPattern,
    editableTaskItemPattern: editableTaskItemPattern
      ? { ...editableTaskItemPattern, active: editableTaskListPattern.active }
      : editableTaskListPattern ? { active: editableTaskListPattern.active } : {},
    newTaskListPatterns: getNewTaskListPatterns(state),
    activeTaskListPatterns: getActiveTaskListPatterns(state),
  });
};

const mapDispatchToProps = (dispatch, { match: { path, params } }) => {
  const editableTaskListPatternId = (
    path === '/task_list_patterns/:id/edit'
    || path === '/task_list_patterns/:id/task_item_patterns/new'
    || path === '/task_list_patterns/:id/task_item_patterns/:taskItemPatternId/edit'
  ) && params.id;

  const editableTaskItemPatternId = path === '/task_list_patterns/:id/task_item_patterns/:taskItemPatternId/edit'
    && params.taskItemPatternId;

  return ({
    onAddTaskListPattern: bindActionCreators(newTaskListPattern, dispatch),
    onSubmitTaskListPattern: editableTaskListPatternId
      ? bindActionCreators((values) => updateTaskListPattern(values, { id: editableTaskListPatternId }), dispatch)
      : bindActionCreators((values) => createTaskListPattern(values), dispatch),
    onSubmitTaskItemPattern: editableTaskItemPatternId
      ? bindActionCreators((values) => updateTaskItemPattern(values, {
        taskListPatternId: editableTaskListPatternId,
        id: editableTaskItemPatternId,
      }), dispatch)
      : bindActionCreators((values) => createTaskItemPattern(values, {
        taskListPatternId: editableTaskListPatternId,
      }), dispatch),
    onEditTaskListPattern: bindActionCreators((taskListPatternId) => editTaskListPattern(taskListPatternId), dispatch),
    onCloseTaskListPattern: bindActionCreators(closeTaskListPattern, dispatch),
    onEditTaskItemPattern: bindActionCreators(
      (taskListPatternId, taskItemPatternId) => editTaskItemPattern(taskListPatternId, taskItemPatternId),
      dispatch,
    ),
    // eslint-disable-next-line max-len
    onCloseTaskItemPattern: bindActionCreators(() => closeTaskItemPattern(editableTaskListPatternId), dispatch),
    onAddTaskItemPattern: bindActionCreators((taskListPatternId) => newTaskItemPattern(taskListPatternId), dispatch),
    // eslint-disable-next-line max-len
    onDeleteTaskListPattern: bindActionCreators((taskListPatternId) => deleteTaskListPattern({ id: taskListPatternId }), dispatch),
    onDeleteTaskItemPattern: bindActionCreators((taskItemPatternId, id) => deleteTaskItemPattern({
      taskListPatternId: editableTaskListPatternId,
      id,
    }), dispatch),
    onMoveTaskListPattern: bindActionCreators(
      (taskListPatternId, listPosition, index) => changeTaskListPatternPosition(listPosition, index, {
        id: taskListPatternId,
      }),
      dispatch,
    ),
    onMoveTaskItemPattern: bindActionCreators(
      (taskItemPatternId, itemPosition, index) => changeTaskItemPatternPosition(itemPosition, index, {
        taskListPatternId: editableTaskListPatternId,
        id: taskItemPatternId,
      }),
      dispatch,
    ),
  });
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskListPatterns);
