import { propEq, find, map, addIndex, filter } from 'ramda';
import { getIn } from 'utils/ramda';

const taskItemPatternsSelector = (state) => getIn('fund.taskItemPatterns', state) || {};

export const collectionSelector = (state) => getIn('collection', taskItemPatternsSelector(state)) || [];

export const dataSource = (state) => addIndex(map)((item, index) => ({
  key: index,
  id: item.id,
  title: item.title,
  role: item.fundUserRole,
  position: item.position,
  owner: item.owner,
  ownerName: item.ownerName,
  dependentFundTaskItemPatternId: item.dependentFundTaskItemPatternId,
  dependentFundTaskItemPatternTitle: item.dependentFundTaskItemPatternTitle,
  // eslint-disable-next-line max-len
  dependentFundTaskItemPatternPosition: item.dependentFundTaskItemPattern ? item.dependentFundTaskItemPattern.position : null,
}), collectionSelector(state));

// eslint-disable-next-line radix
export const getEditable = (id, collection) => find(propEq('id', parseInt(id)))(collection);

export const getDependentOptions = (collection, editable = null) => {
  if (editable) {
    return filter((item) => item.id !== editable.id && item.dependentFundTaskItemPatternId !== editable.id, collection);
  }

  return collection;
};
