import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Select from 'react-select';

import { FormGroup } from '../';

const propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  valueKey: PropTypes.string,
  labelKey: PropTypes.string,
  options: PropTypes.array.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.arrayOf(PropTypes.object)]),
  multi: PropTypes.bool,
  dataStateKey: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  noResultsText: PropTypes.string,
  prompt: PropTypes.string,
  errors: PropTypes.array,
  clearable: PropTypes.bool,
  disabled: PropTypes.bool,
  searchable: PropTypes.bool,
  append: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  prepend: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  onChange: PropTypes.func.isRequired,
};

const defaultProps = {
  includeBlank: false,
  prompt: 'Please select…',
  dataStateKey: undefined,
  value: '',
  label: null,
  required: false,
  errors: null,
  onChange: null,
  noResultsText: 'No results',
  disabled: false,
  clearable: false,
  append: undefined,
  prepend: undefined,
  multi: false,
  valueKey: 'value',
  labelKey: 'label',
  searchable: true,
};

const SelectReact = (props) => {
  const {
    id,
    name,
    dataStateKey,
    value,
    label,
    required,
    options,
    onChange,
    errors,
    disabled,
    clearable,
    valueKey,
    labelKey,
    noResultsText,
    append,
    prepend,
    multi,
    prompt,
    searchable,
  } = props;

  const addonClasses = classNames({
    form__addon: append || prepend,
    'form__addon-right': append,
    'form__addon-left': prepend,
  });

  return (
    <FormGroup label={label} required={required} id={id} errors={errors}>
      <div className={addonClasses}>
        {prepend && <span className="form__addon_icon">{prepend}</span>}

        <Select
          name={name}
          id={id}
          options={options}
          valueKey={valueKey}
          placeholder={prompt}
          labelKey={labelKey}
          clearable={clearable}
          searchable={searchable}
          multi={multi}
          noResultsText={noResultsText}
          value={value}
          onChange={onChange}
          disabled={disabled}
          data-statekey={dataStateKey}
        />

        {append && <span className="form__addon_icon">{append}</span>}
      </div>
    </FormGroup>
  );
};

SelectReact.propTypes = propTypes;
SelectReact.defaultProps = defaultProps;

export default SelectReact;
