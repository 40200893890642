import React from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';

import MappingsContainer from 'components/shared/Mappings/Container';

import configureStore from './store';

const propTypes = {
  refs: PropTypes.object.isRequired,
};

function ChartsMappings(props) {
  const { refs, state } = props;

  return (
    <Provider store={configureStore(state)}>
      <MappingsContainer refs={refs} />
    </Provider>
  );
}

ChartsMappings.propTypes = propTypes;

export default ChartsMappings;
