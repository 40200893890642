export * from 'constants/actionTypes';

export const CHANGE_DEALING_DATE = 'CHANGE_DEALING_DATE';
export const UPDATE_THIRD_PARTY = 'UPDATE_THIRD_PARTY';
export const SELECT_RADIO_INPUT = 'SELECT_RADIO_INPUT';

export const UPDATE_LAST_VALUATION = 'UPDATE_LAST_VALUATION';
export const START_FETCH_VALUATION_DATA = 'START_FETCH_VALUATION_DATA';
export const FINISH_FETCH_VALUATION_DATA = 'FINISH_FETCH_VALUATION_DATA';

export const START_FETCH_FUND_ACCOUNTS_OPTIONS = 'START_FETCH_FUND_ACCOUNTS_OPTIONS';
export const FINISHED_SUCCESS_FETCH_FUND_ACCOUNTS_OPTIONS = 'FINISHED_SUCCESS_FETCH_FUND_ACCOUNTS_OPTIONS';

export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT';
export const UPDATE_FROM_ACCOUNT = 'UPDATE_FROM_ACCOUNT';
export const UPDATE_TO_ACCOUNT = 'UPDATE_TO_ACCOUNT';
export const UPDATE_ACCOUNT_DATA = 'UPDATE_ACCOUNT_DATA';
export const UPDATE_FROM_ACCOUNT_DATA = 'UPDATE_FROM_ACCOUNT_DATA';

export const CLEAR_VALUES = 'CLEAR_VALUES';
export const CLEAR_REDEMPTION_HOLDBACK_VALUES = 'CLEAR_REDEMPTION_HOLDBACK_VALUES';
