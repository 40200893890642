import React from 'react';
import PropTypes from 'prop-types';

class Block extends React.Component {
  static defaultProps = {
    visible: true,
  };

  render() {
    const { title, subtitle, visible, fields } = this.props;

    return visible ? (
      <div className="panel">
        <div className="panel__header">
          {subtitle ? (
            <div>
              <h3 className="panel__title">{title}</h3>
              <span className="panel__subtitle">{subtitle}</span>
            </div>
          ) : (
            <h3 className="panel__title">{title}</h3>
          )}
        </div>
        <div className="panel__body panel__body-padded">{fields}</div>
      </div>
    ) : null;
  }
}

export default Block;
