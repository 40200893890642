import humps from 'humps';
import axios from 'axios';
import { get } from 'lodash';

import { loadState } from 'utils/localStorage';
import scrollToFormAlerts from 'utils/scrollToFormAlerts';

import * as types from 'constants/actionTypes';

export function submitForm(data, url, method) {
  return (dispatch) => {
    dispatch(submittingForm(true));

    axios
      .request({
        url,
        method,
        data,
        withCredentials: true,
        xsrfHeaderName: 'X-CSRF-Token',
      })
      .then((response) => {
        if (response.headers.location) {
          return (window.location.href = response.headers.location); //eslint-disable-line
        }

        dispatch(submittingForm(false));
        dispatch(clearForm());
        dispatch(submittingFormSucceded(humps.camelizeKeys(get(response.data.payload, 'success'))));
      })
      .catch((res) => {
        dispatch(submittingForm(false));
        dispatch(submittingFormFailed(humps.camelizeKeys(get(res.response.data.payload, 'errors'))));
        scrollToFormAlerts();
      });
  };
}

function clearForm() {
  return {
    type: types.CLEAR_FORM,
    payload: {
      state: loadState(),
    },
  };
}

function submittingForm(bool) {
  return {
    type: types.SUBMITTING_FORM,
    payload: {
      submitting: bool,
    },
  };
}

function submittingFormSucceded(data = {}) {
  return {
    type: types.SUBMITTING_FORM_SUCCEEDED,
    payload: {
      success: data,
    },
  };
}

function submittingFormFailed(data = {}) {
  return {
    type: types.SUBMITTING_FORM_FAILED,
    payload: {
      errors: data,
    },
  };
}
