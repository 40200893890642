import React from 'react';
import { CurrencyInput } from 'components/shared';

const BandPattern = (props) => {
  const {
    // eslint-disable-next-line react/prop-types
    position, id, lowerBoundPercentage, upperBoundPercentage, rate, _destroy, actions, last, errors,
  } = props;

  const { changeInput, deleteBandPattern, changeLowerBoundPercentage, changeUpperBoundPercentage } = actions;

  return (
    <div className="form__inline form__inline-width_lg">
      <CurrencyInput
        id={`lower_bound_percentage${position}`}
        name={`fee_pattern[band_patterns_attributes][${position}][lower_bound_percentage]`}
        dataStateKey={`bandPatterns.[${position}].lowerBoundPercentage`}
        value={lowerBoundPercentage}
        label='Lower bound'
        required
        currency='%'
        decimalLimit={7}
        onChange={(e) => changeLowerBoundPercentage(e, position)}
        errors={_.get(errors, 'lowerBoundPercentage') ? [' '] : undefined}
      />
      <CurrencyInput
        id={`upper_bound_percentage${position}`}
        name={`fee_pattern[band_patterns_attributes][${position}][upper_bound_percentage]`}
        dataStateKey={`bandPatterns.[${position}].upperBoundPercentage`}
        value={upperBoundPercentage}
        label='Upper bound'
        required={!last}
        currency='%'
        decimalLimit={7}
        onChange={(e) => changeUpperBoundPercentage(e, position)}
        errors={_.get(errors, 'upperBoundPercentage') ? [' '] : undefined}
      />
      <CurrencyInput
        id={`rate${position}`}
        name={`fee_pattern[band_patterns_attributes][${position}][rate]`}
        dataStateKey={`bandPatterns.[${position}].rate`}
        value={rate}
        label='Rate'
        required
        currency='%'
        decimalLimit={7}
        onChange={changeInput}
        errors={_.get(errors, 'rate') ? [' '] : undefined}
      />
      <input
        type="hidden"
        name={`fee_pattern[band_patterns_attributes][${position}][id]`}
        id={`id${position}`}
        value={id}
      />
      <input
        type="hidden"
        name={`fee_pattern[band_patterns_attributes][${position}][_destroy]`}
        id={`_destroy${position}`}
        value={_destroy ? 'true' : 'false'}
      />
      <button type="button" className="btn btn-danger btn-width_sm" onClick={(e) => deleteBandPattern(e, position)}>
        <i className="fa fa-trash" />
      </button>
    </div>
  );
};

const Form = (props) => {
  // eslint-disable-next-line react/prop-types
  const { bandPatterns, errors, actions, actions: { addBandPattern }, disabled, baseErrors } = props;
  const isLastBandPattern = (currentBandPattern) => currentBandPattern === bandPatterns[bandPatterns.length - 1];

  return (
    <div>
      <legend className="form__legend">Band Patterns</legend>
      {/* eslint-disable-next-line jsx-a11y/aria-role */}
      <div role="bandPatterns">
        {
          bandPatterns.map((bandPattern, i) => (
            // eslint-disable-next-line no-underscore-dangle,react/no-array-index-key
            <div className={bandPattern._destroy ? 'hide' : 'show'} key={i}>
              <BandPattern {...bandPattern}
                actions={actions}
                position={i}
                errors={errors[i]}
                last={isLastBandPattern(bandPattern)}
              />
              <hr className="hr" />
            </div>
          ))
        }
        <div className="form__group">
          <button
            type="button"
            className={baseErrors.length > 0 && bandPatterns.length === 0 ? 'btn btn-danger' : 'btn btn-default'}
            onClick={addBandPattern}
            disabled={disabled}
          >
            <i className="fa fa-plus" />
            Add Band Pattern
          </button>
        </div>
      </div>
    </div>
  );
};

export default Form;
