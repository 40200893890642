import { history } from 'stores/configureStore';
import TaskItemPatternsApi from '../../../apiAdapters/taskListPatterns/taskItemPatterns';

export function newTaskItemPattern(taskListPatternId) {
  return (dispatch) => {
    dispatch({ type: 'NEW_TASK_ITEM_PATTERN' });
    history.push(Routes.new_task_list_pattern_task_item_pattern_path(taskListPatternId));
    document.title = 'Task Item Pattern | New';
  };
}

export function editTaskItemPattern(taskListPatternId, taskItemPatternId) {
  return (dispatch) => {
    dispatch({ type: 'EDIT_TASK_ITEM_PATTERN' });
    history.push(Routes.edit_task_list_pattern_task_item_pattern_path(taskListPatternId, taskItemPatternId));
    document.title = 'Task Item Pattern | Edit';
  };
}

// eslint-disable-next-line max-len
export const createTaskItemPattern = (values, { taskListPatternId }) => (dispatch, getState) => TaskItemPatternsApi.submit(values, { taskListPatternId })
  .then(({ payload }) => {
    const { taskItemPatterns: { collection } } = getState();
    const newCollection = [...collection, { ...payload }];

    dispatch({ type: 'CREATE_TASK_ITEM_PATTERN', payload: newCollection });
    history.push(Routes.edit_task_list_pattern_path(taskListPatternId));
    document.title = 'Task List Pattern | Edit';
  })
  .catch((error) => console.log(error));

// eslint-disable-next-line max-len
export const updateTaskItemPattern = (values, { taskListPatternId, id }) => (dispatch) => TaskItemPatternsApi.submit(values, { taskListPatternId, id })
  .then(({ payload }) => {
    dispatch({ type: 'UPDATE_TASK_ITEM_PATTERN', payload });
    history.push(Routes.edit_task_list_pattern_path(taskListPatternId));
    document.title = 'Task List Pattern | Edit';
  })
  .catch((error) => console.log(error));

export function closeTaskItemPattern(taskListPatternId) {
  return (dispatch) => {
    dispatch({ type: 'CLOSE_TASK_ITEM_PATTERN' });
    history.push(Routes.edit_task_list_pattern_path(taskListPatternId));
    document.title = 'Task List Pattern | Edit';
  };
}

export const deleteTaskItemPattern = ({ taskListPatternId, id }) => (dispatch) => {
  TaskItemPatternsApi.delete({ taskListPatternId, id })
    .then(({ payload }) => {
      dispatch({ type: 'DESTROY_TASK_ITEM_PATTERN', payload });
      history.push(Routes.edit_task_list_pattern_path(taskListPatternId));
      document.title = 'Task List Pattern | Edit';
    })
    .catch((error) => console.log(error));
};

// eslint-disable-next-line max-len
export const changeTaskItemPatternPosition = (itemPosition, position, { taskListPatternId, id }) => (dispatch) => TaskItemPatternsApi.submit(
  { position }, { taskListPatternId, id },
)
  .then(({ payload }) => dispatch({ type: 'CHANGE_TASK_ITEM_PATTERN_POSITION', payload }))
  .catch((error) => console.log(error));
