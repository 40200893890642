import PropTypes from 'prop-types';

export const paymentsItemPropTypes = PropTypes.shape({
  id: PropTypes.number,
  accrualId: PropTypes.number,
  date: PropTypes.string,
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  paymentAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  paymentDate: PropTypes.string,
  key: PropTypes.number,
});

export const paymentsItemsinitialValues = PropTypes.arrayOf(paymentsItemPropTypes);

export const paymentsItemsEditableKeys = PropTypes.objectOf(PropTypes.objectOf(PropTypes.bool));

export const accrualDefaultProps = {};
