import { flatten } from 'ramda';
import { createSelector } from 'reselect';
import keys from 'components/Funds/Portfolios/Tree/keys';

import { getIn } from 'utils/ramda';

const initialState = {
  classes: [],
  series: [],
  accounts: [],
}

const getClassKey = ({ item: { id } }) => keys.classKey(id);
const getSeriesKey = ({ item: { id } }) => keys.seriesKey(id);
const getAccountKey = ({ item: { id } }) => keys.accountKey(id);

const portfolio = (state) => getIn('fund.portfolios', state) || initialState;

export const getClasses = createSelector(portfolio, (portfolio) => portfolio.classes);
export const getSeries = createSelector(portfolio, (portfolio) => portfolio.series);
export const getAccounts = createSelector(portfolio, (portfolio) => portfolio.accounts);
export const getIsAddingPortfolio = createSelector(portfolio, (portfolio) => portfolio.newRecord);

export const getClassEntities = createSelector(getClasses, (classes) => classes.map(({ item }) => item));
export const getSeriesEntities = createSelector(getSeries, (series) => series.map(({ item }) => item));
export const getAccountEntities = createSelector(getAccounts, (accounts) => accounts.map(({ item }) => item));
export const getAllEntities = createSelector(
  getClassEntities, getSeriesEntities, getAccountEntities,
  (classEntities, seriesEntities, accountEntites) => ({
    classes: classEntities,
    series: seriesEntities,
    accounts: accountEntites
  })
);

export const getCheckedClasses = createSelector(getClasses, (classes) => classes.filter(({ checked }) => checked));
export const getCheckedSeries = createSelector(getSeries, (series) => series.filter(({ checked }) => checked));
export const getCheckedAccounts = createSelector(getAccounts, (accounts) => accounts.filter(({ checked }) => checked));

export const getAllocatedClasses = createSelector(getCheckedClasses, (checkedClasses) => checkedClasses.filter(({ type }) => type === 'allocation'));
export const getExcludedClasses = createSelector(getCheckedClasses, (checkedClasses) => checkedClasses.filter(({ type }) => type === 'exclusion'));
export const getAllocatedSeries = createSelector(getCheckedSeries, (checkedSeries) => checkedSeries.filter(({ type }) => type === 'allocation'));
export const getExcludedSeries = createSelector(getCheckedSeries, (checkedSeries) => checkedSeries.filter(({ type }) => type === 'exclusion'));
export const getAllocatedAccounts = createSelector(getCheckedAccounts, (checkedAccounts) => checkedAccounts.filter(({ type }) => type === 'allocation'));
export const getExcludedAccounts = createSelector(getCheckedAccounts, (checkedAccounts) => checkedAccounts.filter(({ type }) => type === 'exclusion'));

export const getDefaultAllocatedItems = createSelector(
  getAllocatedClasses, getAllocatedSeries, getAllocatedAccounts,
  (allocClasses, allocSeries, allocAccounts) => {
    const classKeys = allocClasses.map(getClassKey);
    const seriesKeys = allocSeries.map(getSeriesKey);
    const accountsKeys = allocAccounts.map(getAccountKey);
    return flatten([classKeys, seriesKeys, accountsKeys]);
  }
)
export const getDefaultExcludedItems = createSelector(
  getExcludedClasses, getExcludedSeries, getExcludedAccounts,
  (exclClasses, exclSeries, exclAccounts) => {
    const classKeys = exclClasses.map(getClassKey);
    const seriesKeys = exclSeries.map(getSeriesKey);
    const accountsKeys = exclAccounts.map(getAccountKey);
    return flatten([classKeys, seriesKeys, accountsKeys]);
  }
)