import React from 'react';
import FontAwesome from 'react-fontawesome';
import humps from 'humps';
import ReactHtmlParser from 'react-html-parser';

import { FormAlerts, Warnings } from 'components/shared';

import { SubscriptionFields, RedemptionFields, TransferFields, InfoPanel, DistributionFields } from './shared';

import { getInfo, getAccountInfo, getValuationInfo, getNotice } from '../selectors';

export default (props) => {
  const { investorTransactionForm, resourceFund, actions } = props;
  const { investorTransactionActions } = actions;
  const { data, meta } = investorTransactionForm;
  const { errors, warnings, submitting, investor } = meta;
  const { submitForm } = investorTransactionActions;

  const onSubmit = (e) => {
    e.preventDefault();

    const formData = {
      investor_transaction: humps.decamelizeKeys(data),
    };

    data.id
      ? submitForm(formData, Routes.fund_investor_transaction_path(data.fundId, data.id, { format: 'json' }), 'put')
      : submitForm(formData, Routes.fund_investor_transactions_path(data.fundId, { format: 'json' }), 'post');
  };

  const showing =
    resourceFund.type === 'share_based' || resourceFund.type === 'pe_share_based'
      ? data.type === 'Fund::InvestorTransaction::Transfer'
        ? data.fromFundAccountId
        : data.fundAccountId
      : data.type === 'Fund::InvestorTransaction::Transfer'
        ? data.fromFundAccountId
        : data.fundAccountId;

  return (
    <div className="row">
      <div className="col-md-6">
        <FormAlerts errors={errors} />
        <Warnings messages={warnings} />
        <div className="panel">
          <div className="panel__header">
            <h3 className="panel__title">
              {data.id
                ? `${I18n.t('funds.investor_transactions.edit.title')} — ${meta.displayType}`
                : `${I18n.t('funds.investor_transactions.new.title')} — ${meta.displayType}`}
            </h3>
          </div>
          <form onSubmit={(e) => onSubmit(e)} className="form" noValidate="novalidate">
            <div className="panel__body panel__body-padded">
              {data.type === 'Fund::InvestorTransaction::Subscription' && <SubscriptionFields {...props} />}
              {data.type === 'Fund::InvestorTransaction::Redemption' && <RedemptionFields {...props} />}
              {data.type === 'Fund::InvestorTransaction::Transfer' && <TransferFields {...props} />}
              {data.type === 'Fund::InvestorTransaction::Distribution' && <DistributionFields {...props} />}
            </div>

            {showing && (
              <div className="panel__footer">
                <button type="submit" className="btn btn-primary" disabled={submitting}>
                  {submitting ? (
                    <FontAwesome name="spinner" spin />
                  ) : data.id ? (
                    I18n.t('buttons.update')
                  ) : (
                    I18n.t('buttons.create')
                  )}
                </button>
                <a className="btn" href={Routes.fund_investor_transactions_path(data.fundId)}>
                  Cancel
                </a>
              </div>
            )}
          </form>
        </div>
      </div>

      <div className="col-md-6">
        <div className="row">
          <div className="col-md-12">
            <div className="panel">
              <div className="panel__body panel__body-padded">
                <InfoPanel data={getInfo(props)} />
              </div>
            </div>
          </div>

          {(data.fundAccountId || data.fromFundAccountId) && (
            <div className="col-md-6">
              {meta.accountFetchingInProgress ? (
                <FontAwesome name="spinner" spin />
              ) : (
                <div className="panel">
                  <div className="panel__body panel__body-padded">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="contact_card">
                          <div className="contact_card__avatar">{ReactHtmlParser(investor.avatar)}</div>
                          <div className="contact_card__info">
                            <span className="contact_card__name">
                              <span>{investor.displayName}</span>
                            </span>
                            <span className="contact_card__general">{investor.email}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <InfoPanel data={getAccountInfo(props)} />
                  </div>
                </div>
              )}
            </div>
          )}

          {data.dealingDate && (
            <div className="col-md-6">
              {meta.valuationFetchingInProgress ? (
                <FontAwesome name="spinner" spin />
              ) : (
                <div className="panel">
                  <div className="panel__body panel__body-padded">
                    <InfoPanel data={getValuationInfo(props)} />
                  </div>
                </div>
              )}
            </div>
          )}
          <div className="col-md-12">
            {getNotice(props).messages.map((message) => (
              <div className="alert alert-info">{message}</div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
