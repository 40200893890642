import { filter, find, isEmpty } from 'lodash';
import { includes, identity, map, values } from 'ramda';
import { createSelector } from 'reselect';
import shortid from 'shortid';

import { getIn, toTitle } from 'utils/ramda';

import { getEntity, getEntityKeys, getEntityDetail } from '../entities';

export const initialState = {};

const stateSelector = (state) => state;

export const fundSelector = (state) => getIn('resourceFund', state) || initialState;
const fundChartsSelector = (state) => getIn('fund.charts', state);

const categoriesSelector = (state) => getEntity(state, { entityName: 'categories' });
const categoryIdsSelector = (state) => getEntityKeys(state, { entityName: 'categories' });
const accountsSelector = (state) => getEntity(state, { entityName: 'accounts' });
const accountIdsSelector = (state) => getEntityKeys(state, { entityName: 'accounts' });
const mappingsSelector = (state) => getEntity(state, { entityName: 'mappings' });

export const getFundUsers = (state) => getIn('fund.details.teamMembers', state) || [];

export const getFund = createSelector(fundSelector, identity);

export const getFundId = createSelector(getFund, (fund) => fund.id);

export const getFundInvestorClasses = createSelector(getFund, (fund) => fund.investorClasses);

export const getFundInvestorSeries = createSelector(getFund, (fund) => fund.investorSeries);

export const getFundAccounts = createSelector(getFund, (fund) => fund.accounts);

export const getFundCurrencySymbol = createSelector(getFund, (fund) => fund.currencySymbol);

export const getFundCharts = createSelector(fundChartsSelector, (charts) => charts || {});

export const getFundChartsTypes = createSelector(getFundCharts, (charts) => charts.types || []);

export const getFundChartsCollapsedKeys = createSelector(getFundCharts, (charts) => charts.collapsedKeys || []);

export const getFundChartsSearchValue = createSelector(getFundCharts, (charts) => charts.searchValue || '');

export const getFundChartsCategoryKeys = createSelector(categoryIdsSelector, (ids) =>
  map((id) => `category${id}`, ids),
);

export const getFundChartsAccountKeys = createSelector(accountIdsSelector, (ids) => map((id) => `account${id}`, ids));

export const getFundChartsCategoryAndAccountKeys = createSelector(
  getFundChartsCategoryKeys,
  getFundChartsAccountKeys,
  (categoryKeys, accountKeys) => [...categoryKeys, ...accountKeys],
);

const getFundChartsCategoryAccountMappingsSelector = createSelector(mappingsSelector, identity);

const getFundChartsCategoryAccountsSelector = createSelector(
  accountsSelector,
  getFundChartsCollapsedKeys,
  getFundChartsCategoryAccountMappingsSelector,
  getFundChartsSearchValue,
  (accounts, collapsedKeys, mappings, searchValue) => {
    const prepareAccount = (account) => {
      if (account.displayTitle.toLowerCase().includes(searchValue.toLowerCase())) {
        return {
          ...account,
          expanded: !includes(`account${account.id}`, collapsedKeys),
          mappings: values(mappings).filter((mapping) => includes(mapping.id, account.mappings) && mapping),
        };
      }

      return {};
    };

    return map(prepareAccount, accounts);
  },
);

const getFundChartsCategoriesSelector = createSelector(
  categoriesSelector,
  getFundChartsCollapsedKeys,
  getFundChartsCategoryAccountsSelector,
  getFundChartsSearchValue,
  (categories, collapsedKeys, accounts, searchValue) => {
    const prepareCategory = (category) => {
      const categoryAccounts = values(accounts).filter((account) => includes(account.id, category.accounts) && account);

      const isAccounts = !searchValue || categoryAccounts.length > 0;

      return (
        isAccounts && {
          ...category,
          expanded: !includes(`category${category.id}`, collapsedKeys),
          accounts: categoryAccounts,
        }
      );
    };

    return map(prepareCategory, categories);
  },
);

export const getFundChartsSecurityFilters = createSelector(getFundCharts, (charts) => charts.securityFilters || []);

export const getFundChartsTypesOptions = createSelector(getFundChartsTypes, (types) =>
  map((type) => ({ label: toTitle(type), value: type }), types),
);

export const getFundChartsTree = createSelector(
  getFundChartsTypes,
  getFundChartsCategoriesSelector,
  stateSelector,
  (chartTypes, categories, state) => {
    const prepareChartType = (chartType) => {
      const chart = getEntityDetail(state, { entityName: 'charts', entityId: chartType });

      const chartCategories = values(categories).filter(
        (category) => includes(category.id, chart.categories) && category,
      );

      return {
        ...chart,
        categories: chartCategories,
      };
    };

    return map(prepareChartType, chartTypes);
  },
);

export const getFundChartsMappingsBySecurityKlassed = createSelector(
  getFundChartsCategoryAccountMappingsSelector,
  getFundChartsSecurityFilters,
  (mappings, filters) => {
    if (isEmpty(filters)) {
      return mappings;
    }

    // TODO. Discuss with Dmitry, how the filter should work.
    return filter(
      mappings,
      (mapping) => mapping.securityKlass == null || find(filters, { value: mapping.securityKlass }),
    );
  },
);

// Copy from selectors.js
export const isShareBasedTypes = createSelector(
  fundSelector,
  (fund) => fund.type === 'share_based' || fund.type === 'pe_share_based',
);

export const isPartnershipTypes = createSelector(
  fundSelector,
  (fund) => fund.type === 'partnership' || fund.type === 'pe_partnership',
);

export const isShareBased = createSelector(fundSelector, (fund) => fund.type === 'share_based');

export const isPartnership = createSelector(fundSelector, (fund) => fund.type === 'partnership');

export const isPeShareBased = createSelector(fundSelector, (fund) => fund.type === 'pe_share_based');

export const isPePartnership = createSelector(fundSelector, (fund) => fund.type === 'pe_partnership');

export const isPeTypes = createSelector(
  fundSelector,
  (fund) => fund.type === 'pe_partnership' || fund.type === 'pe_share_based',
);

export const getInvestorClassesAndSeriesTree = createSelector(
  getFundInvestorClasses,
  getFundInvestorSeries,
  (klasses, series) =>
    klasses.map((ic) => {
      const investorSeriesForClass = filter(series, (is) => includes(ic.investorSeriesIds, is.id));
      return {
        ...ic,
        _id: shortid.generate(),
        investorSeries: investorSeriesForClass,
      };
    }),
);

const getInvestorSeriesSelector = createSelector(getFundInvestorSeries, getFundAccounts, (series, accounts) =>
  series.map((is) => {
    const seriesAccounts = accounts.filter((account) => includes(is.accountIds, account.id) && account);

    return {
      ...is,
      _id: shortid.generate(),
      accounts: seriesAccounts,
    };
  }),
);

export const getAllocatedAmountsTree = createSelector(
  getFundInvestorClasses,
  getInvestorSeriesSelector,
  (klasses, series) =>
    klasses.map((klass) => {
      const investorClassSeries = series.filter((series) => includes(klass.investorSeriesIds, series.id) && series);
      return {
        ...klass,
        _id: shortid.generate(),
        investorSeries: investorClassSeries,
      };
    }),
);
