import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { get, upperFirst } from 'lodash';

import ChartsCategoryForm from './components/Form';
import * as actions from './actions';

const mapStateToProps = state => ({
  category: state.chartsCategoryForm.data,
  meta: {
    errors: get(state.chartsCategoryForm.meta, 'errors') || {},
    submitting: get(state.chartsCategoryForm.meta, 'submitting') || false,
  },
  refs: {
    chartOptions: state.chartOfAccounts.result.map(chart => ({
      label: upperFirst(chart),
      value: chart,
    })),
  },
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChartsCategoryForm);
