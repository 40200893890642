
import { filter } from 'ramda';

import DocumentItemPatternsApi from 'apiAdapters/funds/investorDocumentDirectories';
import DirectoryAPI from 'apiAdapters/funds/investorDocumentDirectories/directories';
import * as types from 'constants/actionTypes';
import { history } from 'stores/configureStore';
import { catcher } from 'utils/actions';
import { getIn } from 'utils/ramda';

export function parseDocuments(fundAccountFilter) {
  return (dispatch) => {
    dispatch({ type: 'PARSE_FUND_DOCUMENTS', payload: { fundAccountFilter } });
  }
}

export function buildDirectoryTree() {
  return (dispatch) => {
    dispatch({ type: 'BUILD_FUND_DIRECTORY_TREE' });
  }
}

export function openDrawer(payload) {
  return (dispatch) => {
    dispatch({ type: 'OPEN_DOCUMENT_ACCOUNTS_DRAWER', payload });
  }
}

export function closeDrawer() {
  return (dispatch) => {
    dispatch({ type: 'CLOSE_DOCUMENT_ACCOUNTS_DRAWER' });
  }
}

function setFundAccountFilter(fundAccountId) {
  return (dispatch) => {
    dispatch({ type: types.INVESTOR_DOCUMENTS_SET_FILTER, payload: { fundAccountId } });
  }
}

function setTreeCollection(payload) {
  return (dispatch) => {
    dispatch({ type: types.INVESTOR_DOCUMENTS_SET_COLLECTION, payload });
    dispatch(parseDocuments());
    dispatch(buildDirectoryTree());
  }
}

export function setPattern({ values, fundId }) {
  const { pattern: patternId } = values;
  return (dispatch) => DocumentItemPatternsApi
    .import(patternId, fundId)
    .then(({ payload }) => {
      dispatch({ type: 'SET_NEW_PATTERN', payload: { patterns: payload, currentPatternId: patternId } });
      dispatch(parseDocuments());
      dispatch(buildDirectoryTree());
      return payload;
    })
    .catch((error) => console.log(error))
}

export function fetchSetFundAccountFilter({ fundId, fundAccountId }) {
  return catcher(async (dispatch) => {
    const payload = await DirectoryAPI.fetch(fundId, fundAccountId);
    const collection = getIn('collection', getIn('fundInvestorDocumentDirectories', getIn('fund', getIn('state', payload))))
    dispatch(setFundAccountFilter(fundAccountId));
    dispatch(setTreeCollection(collection));
  });
}

export function fetchSetPrivateFolders({ fundId, accesses }) {
  return catcher(async (dispatch, getState) => {
    const { payload } = await DocumentItemPatternsApi.setPrivate(fundId, accesses);
    const treeCollection = getIn('collection', getIn('fund.fundInvestorDocumentDirectories', getState()));
    const newCollection = [...filter((node) => !node.isPrivate, treeCollection), ...payload];
    dispatch(setTreeCollection(newCollection));
    dispatch(closeDrawer());
    return 200;
  });
}