import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure, lifecycle, withHandlers, withState } from 'recompose';
import numeral from 'numeral';
import MaskedInput from 'react-text-mask';
import { currencyMask } from 'utils/masks';
import Icon from 'antd/lib/icon';

const propTypes = {
  currency: PropTypes.string,
  currencyFormat: PropTypes.string.isRequired,
  currencySymbol: PropTypes.string.isRequired,
  isEditable: PropTypes.bool,
  onBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func,
  setEditable: PropTypes.func.isRequired,
};

const defaultProps = {
  isEditable: false,
  currency: undefined,
  handleChange: undefined,
};

function EditableCurrencyCell(props) {
  const { currencyFormat, currencySymbol, isEditable, onBlur, handleChange, setEditable, currency } = props;

  return (
    <div className="editable_cell">
      {isEditable ? (
        <div className="editable_cell__wrapper">
          <div className="ant-input-wrapper ant-input-group">
            <span className="ant-input-group-addon">{currencySymbol}</span>
            <MaskedInput
              autoFocus
              className="ant-input ant-input-sm"
              guide={false}
              mask={currencyMask}
              style={{ boxSizing: 'border-box', fontSize: '14px' }}
              onBlur={onBlur}
              onChange={handleChange}
              type="text"
              value={currency}
            />
          </div>
          <Icon type="check" className="editable_cell__icon" />
        </div>
      ) : (
        <React.Fragment>
          {numeral(currency).format(currencyFormat)}
          <div className="editable_cell__link" onClick={() => setEditable(true)}>
            <span className="editable_cell__indicator">Editable</span>
          </div>
        </React.Fragment>
      )}
    </div>
  );
}

const enhance = compose(
  withState('currency', 'updateCurrency', ({ value }) => value),
  withState('isEditable', 'setEditable', false),
  withState('isChanged', 'setChanged', false),
  withHandlers({
    onBlur: ({ isChanged, onSubmit, name, setEditable, sourceId, currency }) => () => {
      if (isChanged) {
        onSubmit({
          sourceId,
          [name]: numeral(currency).format('string.00'),
        });
      }
      setEditable(false);
    },
    handleChange: ({ updateCurrency, isChanged, setChanged }) => (e) => {
      const { value } = e.target;

      if (!isChanged) {
        setChanged(true);
      }
      updateCurrency(value);
    },
  }),
  lifecycle({
    componentWillReceiveProps(nextProps) {
      if (nextProps.value !== this.props.value && !this.props.isEditable) {
        const currency = this.props.defaultIfNull ? nextProps.value || this.props.value : nextProps.value;
        this.props.updateCurrency(currency);
      }
    },
  }),
  pure,
);

EditableCurrencyCell.propTypes = propTypes;
EditableCurrencyCell.defaultProps = defaultProps;

export default enhance(EditableCurrencyCell);
