import React, { Component } from 'react';
import PropTypes from 'prop-types';

import FontAwesome from 'react-fontawesome';

import AccountItem from './AccountItem';
import Policy from '../../../Policy';

const contextTypes = {
  accountQuery: PropTypes.string,
};

const propTypes = {
  actions: PropTypes.object.isRequired,
  category: PropTypes.object.isRequired,
  fundId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const defaultProps = {
  fundId: null,
};

class CategoryItem extends Component {
  render() {
    const { category, fundId, isCollapsed, actions } = this.props;
    const { toggleAccounts, changeMode, fillFormCategory, fillFormAccount } = actions;

    const formAccountData = {
      code: category.newAccountCode,
      categoryId: category.id,
    };

    const AccountItems = category.accounts.map((account) => (
      <AccountItem
        key={account.id}
        fundId={fundId}
        account={account}
        category={category}
        actions={actions}
        isCollapsed={isCollapsed}
      />
    ));

    const categoryDisplayAdditionalInfo = (!fundId || category.description) && (
      <div className="small">
        {!fundId && (
          <strong>
            Related to {category.relatedToFund ? 'Fund' : 'Invesment'}
            {category.description && ', '}
          </strong>
        )}
        {category.description && `${category.description}`}
      </div>
    );

    return (
      <li className="tree__item tree__item-sub">
        <span className="tree__actions_wrapper">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              toggleAccounts(category.id);
            }}
          >
            {category.displayTitle} <FontAwesome name={category.accountsCollapsed ? 'angle-up' : 'angle-down'} />
          </a>

          <Policy name="chart.category.edit" type="hide">
            <span className="tree__actions">
              <a
                href="#"
                className="btn btn-default btn-xs"
                onClick={(e) => {
                  e.preventDefault();
                  fillFormCategory(category);
                  changeMode('categoryForm');
                }}
              >
                Edit
              </a>
            </span>
          </Policy>
          {categoryDisplayAdditionalInfo}
        </span>

        {!category.accountsCollapsed && (
          <ul className="tree tree-sub">
            {AccountItems}
            <Policy name="chart.category.account.add" type="hide">
              <li className="tree__item tree__item-sub">
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    fillFormAccount(formAccountData);
                    changeMode('accountForm', category.chart);
                  }}
                >
                  Add Account
                </a>
              </li>
            </Policy>
          </ul>
        )}
      </li>
    );
  }
}

CategoryItem.contextTypes = contextTypes;
CategoryItem.propTypes = propTypes;
CategoryItem.defaultProps = defaultProps;

export default CategoryItem;
