import { Tree } from 'antd';
import React from 'react';

const { TreeNode } = Tree;

const renderTree = (data, Node, nodeProps = () => { }, treeNodeProps = () => { }) =>
  data.map((item) => {
    const { key, ...restItem } = item;

    if (item.children && item.children.length > 0) {
      return (
        <TreeNode title={<Node {...restItem} {...nodeProps(item)} />} key={key} dataRef={item} isLeaf={item.isLeaf} {...treeNodeProps(item)}>
          {renderTree(item.children, Node, nodeProps, treeNodeProps)}
        </TreeNode>
      );
    }
    return <TreeNode title={<Node {...restItem} {...nodeProps(item)} />} key={key} isLeaf={item.isLeaf} {...(item.isCreating ? { icon: () => null } : {})} {...treeNodeProps(item)} />;
  })

export default renderTree;