import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import FontAwesome from 'react-fontawesome';

import { Checkbox, CurrencyInput, FormAlerts, Input, RadioGroup, Select } from 'components/shared';

import ClassSpecificFields from './shared/ClassSpecificFields';

const propTypes = {
  account: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  changeMode: PropTypes.func.isRequired,
  fundId: PropTypes.number,
  onSelectAccount: PropTypes.func,
  refs: PropTypes.object,
  meta: PropTypes.shape({
    errors: PropTypes.object,
    success: PropTypes.object,
  }),
};

const defaultProps = {
  meta: {},
  refs: {},
  fundId: null,
  onSelectAccount: null,
};

function ChartsAccountForm(props) {
  const { meta, account, fundId, changeMode, onSelectAccount } = props;
  const { taxAttributeOptions, chartAccountInvestorPropertyProxyTypes, fundBaseCurrencyIso } = props.refs;
  const {
    onSubmit,
    clearAccount,
    changeInput,
    toggleInvestorClass,
    toggleInvestorSeries,
    addInvestor,
    removeInvestor,
    deleteAccount,
  } = props.actions;
  const { errors, success, submitting } = meta;

  const { formAttributes, investorClasses, investorSeries, investors, initialBalance } = account;

  return (
    <div className="row">
      <div className="col-md-8">
        <form onSubmit={(e) => onSubmit(e, fundId)} className="form" noValidate="novalidate">
          <h2 className="m-t-none">{account.id ? 'Edit Account' : 'Add Account'}</h2>

          <FormAlerts errors={errors} success={success} />

          <div className="row">
            <div className="col-md-2">
              <Input
                id="account_code"
                type="text"
                name="account[code]"
                dataStateKey="data.code"
                required
                autofocus
                value={account.code}
                label="Code"
                onChange={changeInput}
                errors={get(errors, 'code')}
              />
            </div>
            <div className="col-md-8">
              <Input
                id="account_name"
                type="text"
                name="account[name]"
                dataStateKey="data.name"
                required
                value={account.name}
                label="Name"
                onChange={changeInput}
                errors={get(errors, 'name')}
              />
            </div>
          </div>

          {fundId && (
            <div className="row">
              <div className="col-md-4">
                <CurrencyInput
                  id="initial_balance"
                  name="account[initial_balance]"
                  label="Initial Balance"
                  dataStateKey="data.initialBalance"
                  value={initialBalance}
                  onChange={changeInput}
                  errors={get(errors, 'initialBalance')}
                  currency={fundBaseCurrencyIso}
                  decimalLimit={8}
                  allowNegative
                />
              </div>
            </div>
          )}

          <div className="d-flex">
            <span className="m-r-sm">
              <Checkbox
                id="account_reconciliation"
                name="account[reconciliation]"
                label="Account Reconciliation"
                dataStateKey="data.reconciliation"
                dataType="checkbox"
                onChange={changeInput}
                checked={account.reconciliation}
              />
            </span>
          </div>

          <div className="d-flex">
            <span className="m-r-sm">
              <Checkbox
                id="cash_reconciliation"
                name="account[cash_reconciliation]"
                label="Cash Reconciliation"
                dataStateKey="data.cashReconciliation"
                dataType="checkbox"
                onChange={changeInput}
                checked={account.cashReconciliation}
              />
            </span>
          </div>

          <div className="d-flex">
            <span className="m-r-sm">
              <Checkbox
                id="admin_fee"
                name="account[admin_fee]"
                label="Admin Fee"
                dataStateKey="data.adminFee"
                dataType="checkbox"
                onChange={changeInput}
                checked={account.adminFee}
              />
            </span>
          </div>

          {!fundId && (
            <RadioGroup
              id="applyToExistingFunds"
              name="account[apply_to_existing_funds]"
              inline
              dataStateKey="data.applyToExistingFunds"
              value={account.applyToExistingFunds || 'false'}
              label="Apply to existing funds?"
              onChange={changeInput}
              options={[
                { label: 'Yes', value: 'true' },
                { label: 'No', value: 'false' },
              ]}
              errors={get(errors, 'applyToExistingFunds')}
            />
          )}

          {taxAttributeOptions && (
            <div>
              <Select
                id="taxAttributeId"
                name="account[tax_attribute_id]"
                includeBlank
                dataStateKey="data.taxAttributeId"
                value={account.taxAttributeId}
                label="Tax attribute"
                onChange={changeInput}
                options={taxAttributeOptions}
              />

              {chartAccountInvestorPropertyProxyTypes && (
                <ClassSpecificFields
                  refs={props.refs}
                  formAttributes={formAttributes}
                  accountInvestorClasses={investorClasses}
                  accountInvestorSeries={investorSeries}
                  investors={investors}
                  actions={{ changeInput, addInvestor, removeInvestor, toggleInvestorClass, toggleInvestorSeries }}
                />
              )}
            </div>
          )}

          <div className="m-t-sm">
            <button type="submit" className="btn btn-primary" disabled={submitting}>
              {submitting ? (
                <FontAwesome name="spinner" spin />
              ) : account.id ? (
                I18n.t('buttons.update')
              ) : (
                I18n.t('buttons.create')
              )}
            </button>

            <a
              className="btn btn-link"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                clearAccount();
                changeMode('tree');
              }}
            >
              Cancel
            </a>

            {account.id && !onSelectAccount && (
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  deleteAccount(
                    account,
                    fundId
                      ? Routes.fund_charts_category_account_path(fundId, account.categoryId, account.id, {
                          format: 'json',
                        })
                      : Routes.charts_category_account_path(account.categoryId, account.id, { format: 'json' }),
                  );
                }}
                className="btn btn-danger pull-right"
              >
                Delete
              </a>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

ChartsAccountForm.propTypes = propTypes;
ChartsAccountForm.defaultProps = defaultProps;

export default ChartsAccountForm;
