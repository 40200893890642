import React, { useState, useEffect } from 'react';
import { Tree as AntdTree, Icon, Checkbox } from 'antd';
import { map, without, flatten, values, isEmpty } from 'ramda';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import {
  getIsAddingPortfolio,
  getAllEntities,
  getDefaultAllocatedItems,
  getDefaultExcludedItems
} from 'selectors/funds/portfolios';

import Name from './Name';
import TreeHelper from './treeHelper';
import FormIntegration from './FormIntegration';
import keyFuncs from './keys';

import styles from '../portfolios.module.css';

const propTypes = {
  searchValue: PropTypes.string,
}

const defaultProps = {
  searchValue: '',
}

function Tree({ searchValue }) {
  const isAddingPortfolio = useSelector(getIsAddingPortfolio);
  const entities = useSelector(getAllEntities);
  const defaultAllocatedItems = useSelector(getDefaultAllocatedItems);
  const defaultExcludedItems = useSelector(getDefaultExcludedItems);

  const treeHelper = new TreeHelper(entities, isAddingPortfolio);

  const [defaultAllocated, defaultExcluded] = treeHelper.getDefaultKeys(defaultAllocatedItems, defaultExcludedItems);
  const [allocated, setAllocated] = useState(defaultAllocated);
  const [excluded, setExcluded] = useState(defaultExcluded);
  const [expanded, setExpanded] = useState([]);

  const isAllAllocated = treeHelper.getAllKeys().every((key) => allocated.includes(key));
  const [submitAllocated, submitExcluded] = treeHelper.getKeysForSubmittion(allocated, excluded);

  const addToAllocated = (...keys) => setAllocated([...allocated, ...keys]);
  const addToExcluded = (...keys) => setExcluded([...excluded, ...keys]);
  const removeFromAllocated = (...keys) => setAllocated(without(keys, allocated));
  const removeFromExcluded = (...keys) => setExcluded(without(keys, excluded));

  const getIsParentAllocated = (key) => {
    const directParentKey = treeHelper.getDirectParentKey(key);
    return allocated.includes(directParentKey);
  }

  const onCheckAll = () => {
    if (isAllAllocated) {
      return setAllocated([]);
    }
    return setAllocated(treeHelper.getAllKeys());
  }

  const onCheck = (chKeys, event) => {
    const { eventKey: checkee } = event.node.props;
    const isAllocated = allocated.includes(checkee);
    const hasParent = treeHelper.hasParent(checkee);
    const isParentAllocated = getIsParentAllocated(checkee);
    const childrenKeys = treeHelper.getChildrenKeys(checkee);
    const checkeeWithChildren = [checkee, ...childrenKeys];

    if (isAllocated && isParentAllocated) {
      removeFromAllocated(...checkeeWithChildren);
      addToExcluded(...checkeeWithChildren);
      return;
    }

    if (!isAllocated && isParentAllocated) {
      removeFromExcluded(...checkeeWithChildren);
      addToAllocated(...checkeeWithChildren);
      return;
    }

    if (isAllocated && !isParentAllocated) {
      removeFromAllocated(...checkeeWithChildren);
      if (!hasParent) {
        removeFromExcluded(...checkeeWithChildren);
      }
      return;
    }

    if (!isAllocated && !isParentAllocated) {
      addToAllocated(...checkeeWithChildren);
      removeFromExcluded(...checkeeWithChildren);
      return;
    }
  }

  const onExpand = (expandedKeys) => {
    setExpanded(expandedKeys);
  }

  useEffect(() => {
    const excessiveExcludedItems = treeHelper.getExcessiveExcludedItems(excluded);

    if (excessiveExcludedItems.length !== 0) {
      removeFromExcluded(...excessiveExcludedItems);
    }
  }, [allocated]);

  useEffect(() => {
    const expandedKeys = flatten(values(entities))
      .map(item => {
        if (searchValue.length > 0 && item.name.indexOf(searchValue) > -1) {
          return treeHelper.getParentsKeys(treeHelper.getKey(item));
        }
        return null;
      })
      .filter((item, i, self) => item && self.indexOf(item) === i);
    setExpanded(flatten(expandedKeys));
  }, [searchValue]);

  useEffect(() => {
    if (!isEmpty(searchValue)) {
      const element = document.getElementsByClassName('found-item')[0];

      if (element) {
        setTimeout(() => element.scrollIntoView({ block: 'center', behavior: 'smooth' }), 1);
      }
    }
  }, [searchValue]);

  return (
    <>
      <Checkbox checked={isAllAllocated} onChange={onCheckAll} style={{ margin: '0 0 15px 30px' }}>Allocate all classes</Checkbox>
      <AntdTree
        onCheck={onCheck}
        onExpand={onExpand}
        checkedKeys={{ checked: allocated, halfChecked: treeHelper.getHalfCheckedKeys(allocated) }}
        expandedKeys={expanded}
        switcherIcon={<Icon type="down" />}
        selectable={false}
        showIcon
        checkable
        checkStrictly
      >
        {map((klass) => {
          const classKey = keyFuncs.classKey(klass.id);
          return (
            <AntdTree.TreeNode
              title={<Name searchValue={searchValue} item={klass} />}
              key={classKey}
            >
              {map((series) => {
                const seriesKey = keyFuncs.seriesKey(series.id);
                const noTick = allocated.includes(seriesKey) && !submitAllocated.includes(seriesKey);
                return (
                  <AntdTree.TreeNode
                    title={<Name searchValue={searchValue} item={series} />}
                    key={seriesKey}
                    {...(noTick ? { className: styles.noTick } : {})}
                  >
                    {map((account) => {
                      const accountKey = keyFuncs.accountKey(account.id);
                      const noTick = allocated.includes(accountKey) && !submitAllocated.includes(accountKey);
                      return (
                        <AntdTree.TreeNode
                          title={<Name searchValue={searchValue} item={account} />}
                          key={accountKey}
                          {...(noTick ? { className: styles.noTick } : {})}
                        />
                      )
                    }, treeHelper.getDirectChildren(seriesKey))}
                  </AntdTree.TreeNode>
                )
              }, treeHelper.getDirectChildren(classKey))}
            </AntdTree.TreeNode>
          )
        }, entities.classes)}
      </AntdTree>
      <FormIntegration keys={[submitAllocated, submitExcluded]} />
    </>
  )
}

Tree.propTypes = propTypes;
Tree.defaultProps = defaultProps;

export default Tree;