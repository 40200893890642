import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import thunk from 'redux-thunk';
import humps from 'humps';

import resourceFund from '../../reducer';
import valuationReducer from '../reducer';
import { reducer } from './index';

const rootReducer = combineReducers({
  resourceFund,
  valuation: valuationReducer,
  valuationOverrideItems: reducer,
});

export default function configureStore(initialState) {
  const enhancers = [applyMiddleware(thunk)];

  if (process.env.NODE_ENV !== 'production') {
    window.devToolsExtension && enhancers.push(window.devToolsExtension());
  }

  const camelizeState = humps.camelizeKeys(initialState);

  const store = createStore(rootReducer, camelizeState, compose(...enhancers));

  return store;
}
