const initialState = {
  collection: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'START_INVITE_ACCOUNTS':
    case 'INVITE_ACCOUNT_DONE':
    case 'DELETE_ACCOUNT':
    default:
      return state;
  }
};
