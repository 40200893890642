import React from 'react';
import PropTypes from 'prop-types';

import FontAwesome from 'react-fontawesome';
import { get } from 'lodash';

import { SelectReact, DateInput, Input, FormAlerts } from 'components/shared';

import TradeDetails from './shared/Details';
import TradeFees from './shared/Fees';
import TradeRates from './shared/Rates';
import Summary from './shared/Summary';

const propTypes = {
  resourceFund: PropTypes.object.isRequired,
  tradeExerciseForm: PropTypes.shape({
    data: PropTypes.object,
  }).isRequired,
  actions: PropTypes.object.isRequired,
  // refs: PropTypes.shape({
  //   securities: PropTypes.array.isRequired,
  // }).isRequired,
  security: PropTypes.object,
  realizations: PropTypes.object,
  realizationsSummary: PropTypes.object,
  securities: PropTypes.shape({
    data: PropTypes.array,
  }).isRequired,
  submitting: PropTypes.bool,
  errors: PropTypes.object,
  success: PropTypes.object,
};

const defaultProps = {
  realizations: undefined,
  realizationsSummary: undefined,
  security: undefined,
  securities: {
    data: undefined,
  },
};

const FundsBatchesTradeExercisesForm = (props) => {
  const { resourceFund, tradeExerciseForm, realizations, security, realizationsSummary, securities } = props;
  const securitiesData = securities.data;
  const {
    updateQuantity,
    changeSecurity,
    updateTradeDate,
    changeInput,
    onSubmit,
    changeFxRate,
    changeFeesCurrency,
  } = props.actions;
  const tradeExercise = tradeExerciseForm.data;
  const { errors, success, submitting } = props;
  const haveTradesToExercise =
    tradeExercise.signedQuantity && realizations && realizations.availableQuantity >= tradeExercise.signedQuantity;
  const showTradeDetails =
    security && tradeExercise.securityId && tradeExercise.tradeDate && tradeExercise.signedQuantity;
  const canBeExercised = showTradeDetails && haveTradesToExercise;
  const fx = security && resourceFund.baseCurrencyIso != security.currencyIso;

  return (
    <form onSubmit={(e) => onSubmit(e)} className="form" noValidate="novalidate">
      <FormAlerts errors={errors} success={success} />

      <input type='hidden' name="trade[broker_account_id]" value={tradeExercise.brokerAccountId} />
      <div className="row">
        <div className="col-md-5">
          <div className="panel">
            <div className="panel__body panel__body-padded">
              <div className="row">
                <div className="col-md-6">
                  <DateInput
                    id="exercise_date"
                    required
                    name="trade[trade_date]"
                    label="Exercise Date"
                    value={tradeExercise.tradeDate}
                    onChange={updateTradeDate}
                    errors={get(errors, 'tradeDate')}
                  />
                </div>
              </div>

              {!securitiesData &&
                securitiesData !== undefined && (
                  <div className="row">
                    <div className="col-md-12">
                      <div className="alert alert-warning">No available options</div>
                    </div>
                  </div>
                )}

              {securitiesData && (
                <div className="row">
                  <div className="col-md-12">
                    <SelectReact
                      name="trade[security_id]"
                      id="security_id"
                      autoload
                      options={securitiesData}
                      labelKey="displayTitle"
                      valueKey="id"
                      clearable={false}
                      label="Security"
                      required
                      value={tradeExercise.securityId}
                      onChange={changeSecurity}
                      errors={get(errors, 'securityId')}
                      disabled={tradeExercise.id && true}
                      append={
                        security && (
                          <a href={Routes.security_path(security.id)} rel="noopener noreferrer" target="_blank">
                            <FontAwesome name="eye" />
                          </a>
                        )
                      }
                    />
                  </div>

                  <div className="col-md-6">
                    <Input
                      id="quantity"
                      type="number"
                      name="trade[signed_quantity]"
                      dataStateKey="tradeExerciseForm.data.signedQuantity"
                      value={tradeExercise.signedQuantity}
                      step="1"
                      label="Quantity"
                      onChange={updateQuantity}
                      errors={_.get(errors, 'signedQuantity')}
                      required
                    />
                  </div>
                </div>
              )}

              {showTradeDetails && (
                <div>
                  <hr className="hr" />
                  <h3 className="m-b-xs">Trade Details</h3>

                  <TradeDetails
                    resourceFund={resourceFund}
                    tradeExercise={tradeExercise}
                    security={security}
                    realizations={realizations}
                  />

                  {canBeExercised && (
                    <div>
                      <TradeFees
                        fund={resourceFund}
                        tradeExercise={tradeExercise}
                        security={security}
                        fx={fx || false}
                        changeInput={changeInput}
                        changeFeesCurrency={changeFeesCurrency}
                        errors={errors}
                      />

                      {fx && (
                        <TradeRates
                          tradeExercise={tradeExercise}
                          changeInput={changeInput}
                          changeFxRate={changeFxRate}
                          errors={errors}
                        />
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>

            {canBeExercised && (
              <div className="panel__footer">
                <button type="submit" className="btn btn-primary" disabled={submitting}>
                  {tradeExercise.id ? I18n.t('buttons.update') : I18n.t('buttons.create')}
                  {submitting && ' '}
                  {submitting && <FontAwesome name="spinner" spin />}
                </button>
                <a className="btn btn-link" href={Routes.fund_trades_path(resourceFund.id)}>
                  Cancel
                </a>
              </div>
            )}
          </div>
        </div>
        {canBeExercised && (
          <div className="col-md-7">
            <Summary
              realizationsSummary={realizationsSummary}
              underlyingInvestmentType={tradeExercise.underlyingInvestmentType}
              underlyingInvestmentSecondType={tradeExercise.underlyingInvestmentSecondType}
              security={security}
              fund={resourceFund}
              fx={fx}
            />
          </div>
        )}
      </div>
    </form>
  );
};

FundsBatchesTradeExercisesForm.propTypes = propTypes;
FundsBatchesTradeExercisesForm.defaultProps = defaultProps;

export default FundsBatchesTradeExercisesForm;
