import React from 'react';
import PropTypes from 'prop-types';

import { Checkbox, CurrencyInput } from 'components/shared';

const propTypes = {
  tradeExercise: PropTypes.object.isRequired,
  changeInput: PropTypes.func.isRequired,
  errors: PropTypes.object,
};

const defaultProps = {
  errors: undefined,
};

const Fees = (props) => {
  const { fund, security, tradeExercise: { baseFundFeesCurrency, commissionFee, brokerFee, secFee, otherFee, parentId, taxes }, fx, changeInput, changeFeesCurrency, errors } = props;
  const currencySymbol = baseFundFeesCurrency ? fund.baseCurrencySymbol : (security.type == 'currency' && security.holdingsDetails && security.holdingsDetails.sellCurrency ? security.holdingsDetails.sellCurrency.symbol : security.currencySymbol);


  return (
    <div>
      <hr className='hr' />
      <h3 className='m-b-xs'>Fees & Taxes</h3>

      { fx && (
        <div className="row">
          <div className="col-md-12">
            <Checkbox
              label={I18n.t('activerecord.attributes.fund/trade.base_fund_fees_currency')}
              name='trade[base_fund_fees_currency]'
              dataStateKey='tradeExerciseForm.data.baseFundFeesCurrency'
              onChange={changeFeesCurrency}
              checked={baseFundFeesCurrency}
              value={baseFundFeesCurrency || false}
              errors={_.get(errors, 'baseFundFeesCurrency')}
              disabled
            />
          </div>
        </div>
      )}

      <div className="row">
        <div className="col-md-6">
          <CurrencyInput
            id="commission_fee"
            name="trade[commission_fee]"
            dataStateKey='tradeExerciseForm.data.commissionFee'
            value={commissionFee}
            label="Commission Fee"
            onChange={changeInput}
            errors={_.get(errors, 'commissionFee')}
            disabled={Boolean(parentId)}
            currency={currencySymbol}
          />
        </div>

        <div className="col-md-6">
          <CurrencyInput
            id="broker_fee"
            name="trade[broker_fee]"
            dataStateKey='tradeExerciseForm.data.brokerFee'
            value={brokerFee}
            label="Broker Fee"
            onChange={changeInput}
            errors={_.get(errors, 'brokerFee')}
            disabled={Boolean(parentId)}
            currency={currencySymbol}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <CurrencyInput
            id="sec_fee"
            name="trade[sec_fee]"
            dataStateKey='tradeExerciseForm.data.secFee'
            value={secFee}
            label="SEC Fee"
            onChange={changeInput}
            errors={_.get(errors, 'secFee')}
            disabled={Boolean(parentId)}
            currency={currencySymbol}
          />
        </div>
        <div className="col-md-6">
          <CurrencyInput
            id="other_fee"
            name="trade[other_fee]"
            dataStateKey='tradeExerciseForm.data.otherFee'
            value={otherFee}
            label="Other Fee"
            onChange={changeInput}
            errors={_.get(errors, 'otherFee')}
            disabled={Boolean(parentId)}
            currency={currencySymbol}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <CurrencyInput
            id="taxes"
            name="trade[taxes]"
            dataStateKey='tradeExerciseForm.data.taxes'
            value={taxes}
            label="Taxes"
            onChange={changeInput}
            errors={_.get(errors, 'taxes')}
            disabled={Boolean(parentId)}
            currency={currencySymbol}
          />
        </div>
      </div>

    </div>
  );
};

Fees.propTypes = propTypes;
Fees.defaultProps = defaultProps;

export default Fees;
