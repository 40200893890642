import PropTypes from 'prop-types';
import { mappingPropTypes } from './mapping';

export const accountPropTypes = PropTypes.shape({
  mappings: PropTypes.arrayOf(mappingPropTypes),
  categoryId: PropTypes.number,
  code: PropTypes.number,
  description: PropTypes.string,
  displayTitle: PropTypes.string,
  id: PropTypes.number,
  name: PropTypes.string,
  initialBalance: PropTypes.string,
});

export const accountDefaultProps = {};
