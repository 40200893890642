import { Divider, Icon } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';

import { fetchFundAccounts } from 'actions/funds/accounts';
import { setPattern, fetchSetPrivateFolders } from 'actions/funds/investorDocumentDirectories';
import { getCurrentUpload, currentPatternIdSelector } from 'selectors/funds/investorDocumentDirectories';
import { useAsyncFetchPromise, useAsyncFetchDefer } from 'utils/useAsyncFetch';
import { fetchBindDocument, fetchRemoveDocument } from 'actions/funds/investorDocumentDirectories/documents';
import { fetchBindDirectory, fetchRemoveDirectory } from 'actions/funds/investorDocumentDirectories/directories';


import Drawer from './Drawer';
import SelectPattern from './SelectPattern';
import Tree from './Tree';
import styles from './fundDocumentPatterns.module.css';

function FundInvestorDocumentPatterns(props) {
  const { match } = props;
  const { path, params: { fundId } } = match;
  const currentUpload = useSelector(getCurrentUpload);
  const currentPattern = useSelector(currentPatternIdSelector);
  const [isFetchingFundAccounts] = useAsyncFetchPromise(fetchFundAccounts, { fundId });
  const [asyncSetPrivateFolders, isSettingPrivateFolders] = useAsyncFetchDefer(fetchSetPrivateFolders);
  const [asyncBindDocument, isBindingDocument] = useAsyncFetchDefer(fetchBindDocument);
  const [asyncBindDirectory, isBindingDirectory] = useAsyncFetchDefer(fetchBindDirectory);
  const [asyncRemoveDocument, isRemovingDocument] = useAsyncFetchDefer(fetchRemoveDocument);
  const [asyncRemoveDirectory, isRemovingDirectory] = useAsyncFetchDefer(fetchRemoveDirectory);

  const [asyncSetPattern, isLoadingSetPattern] = useAsyncFetchDefer(setPattern);

  return (
    <>
      <Drawer
        match={match}
        drawerProps={currentUpload}
        isFetchingFundAccounts={isFetchingFundAccounts}
        asyncSetPrivateFolders={asyncSetPrivateFolders}
        isSettingPrivateFolders={isSettingPrivateFolders}
        asyncBindDocument={asyncBindDocument}
        isBindingDocument={isBindingDocument}
        asyncBindDirectory={asyncBindDirectory}
        isBindingDirectory={isBindingDirectory}
      />
      <div className={styles.overview}>
        {isFetchingFundAccounts ? (
          <Icon type="loading" className={styles.loading} />
        ) : (
            <>
              <SelectPattern match={match} currentPattern={currentPattern} isFetchingFundAccounts={isFetchingFundAccounts} asyncSetPattern={asyncSetPattern} isLoadingSetPattern={isLoadingSetPattern} />
              <Divider orientation='left'>Documents</Divider>
              <Tree
                {...{
                  match,
                  currentPattern,
                  currentUpload,
                  isLoadingSetPattern,
                  isSettingPrivateFolders,
                  isBindingDirectory,
                  isBindingDocument,
                  asyncRemoveDirectory,
                  isRemovingDirectory,
                  asyncRemoveDocument,
                  isRemovingDocument
                }}
              />
            </>
          )}
      </div>
    </>
  )
}

export default FundInvestorDocumentPatterns;
