import set from 'lodash/fp/set';
import * as types from './tradeActionTypes';

const initialState = {
  trade: {},
  security: {},
  submitting: false,
  errors: null,
  success: null,
};

const trade = (state = initialState, action) => {
  switch (action.type) {
    case types.SELECT_SEARCH_SECURITY_TYPE:
      return {
        ...state,
        searchSecurity: {
          ...state.searchSecurity,
          type: action.payload.securityType,
        },
      };

    case types.CHANGE_SEARCH_EXPIRED_SECURITY:
      return {
        ...state,
        searchSecurity: {
          ...state.searchSecurity,
          expired: action.payload.securityExpired,
        },
      };

    case types.UPDATE_SECURITY:
      return {
        ...state,
        trade: {
          ...state.trade,
          securityId: action.payload.securityId,
          settleDate: action.payload.settleDate,
        },
        security: {
          ...state.security,
          ...action.payload.security,
        },
        success: null,
      };

    case types.REMEMBER_SECURITY:
      return {
        ...state,
        previousSecurity: action.payload.security,
      };

    case types.REMOVE_SECURITY:
      return {
        ...state,
        trade: {
          ...state.trade,
          securityId: undefined,
        },
        security: undefined,
      };

    case types.CLEAR_PREVIOUS_SECURITY:
      return {
        ...state,
        previousSecurity: undefined,
      };

    case types.RESTORE_PREVIOUS_SECURITY:
      return {
        ...state,
        previousSecurity: action.payload.security,
      };

    case types.FETCH_CURRENT_HOLDINGS:
      return {
        ...state,
        security: {
          ...state.security,
          currentHoldings: action.payload.currentHoldings,
          totalBuys: action.payload.totalBuys,
          totalSells: action.payload.totalSells,
          pricingDate: action.payload.pricingDate,
          withSecurityPrice: action.payload.withSecurityPrice,
          securityPrice: action.payload.securityPrice,
          holdingsDetails: action.payload.holdingsDetails,
          currenciesBalance: action.payload.currenciesBalance,
        },
      };

    case types.UPDATE_TRADE_DATE:
      return {
        ...state,
        trade: {
          ...state.trade,
          tradeDate: action.payload.tradeDate,
          settleDate: action.payload.settleDate,
        },
      };

    case types.UPDATE_HISTORIC_TRADE_DATE:
      return {
        ...state,
        trade: {
          ...state.trade,
          historicTradeDate: action.payload.date,
        },
      };

    case types.CHANGE_INPUT:
      return set(action.payload.stateKey, action.payload.value)(state);

    case types.SUBMITTING_FORM:
      return {
        ...state,
        submitting: action.payload.submitting,
        success: null,
      };

    case types.SUBMITTING_FORM_SUCCEEDED:
      return {
        ...state,
        errors: null,
        success: action.payload.success,
      };

    case types.SUBMITTING_FORM_FAILED:
      return {
        ...state,
        errors: action.payload.errors,
        success: null,
      };

    case types.CLEAR_FORM:
      return {
        ...action.payload.state,
      };

    case types.MAKE_SETTLE_FX_RATE_EQUAL_TO_TRADE_FX_RATE:
      return {
        ...state,
        trade: {
          ...state.trade,
          settleFxRate: state.trade.tradeFxRate,
        },
      };

    case types.SET_FX_RATE:
      return {
        ...state,
        trade: {
          ...state.trade,
          tradeFxRate: action.payload.fxRate,
        },
      };

    case types.CONVERT_FEES_BY_FX_RATE:
      return {
        ...state,
        trade: {
          ...state.trade,
          commissionFee: action.payload.commissionFee,
          brokerFee: action.payload.brokerFee,
          secFee: action.payload.secFee,
          otherFee: action.payload.otherFee,
          taxes: action.payload.taxes,
        },
      };

    case types.UPDATE_TRADE_FX:
      return {
        ...state,
        trade: {
          ...state.trade,
          fx: action.payload.fx,
        },
      };

    default:
      return state;
  }
};

export default trade;
