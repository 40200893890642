import humps from 'humps';
import axios from 'axios';
import { normalize } from 'normalizr';

import * as types from './actionTypes';
import chartSchema from './chartSchema';

import { fillFormCategory } from './CategoryForm/actions';
import { fillFormAccount } from './AccountForm/actions';

export { fillFormCategory, fillFormAccount };

export function fetchChartOfAccounts(fundId) {
  const url = fundId ? Routes.fund_charts_path(fundId, { format: 'json' }) : Routes.charts_path({ format: 'json' });

  return (dispatch) => {
    dispatch(chartOfAccountsFetchRequest(true));

    axios
      .get(url, {
        withCredentials: true,
      })
      .then((res) => {
        const camelizeData = humps.camelizeKeys(res.data);
        const normalizedData = normalize(camelizeData, chartSchema);

        dispatch(chartOfAccountsFetchCompleted(normalizedData));
        dispatch(chartOfAccountsFetchRequest(false));
      });
  };
}

export function changeAccountQuery(event) {
  return {
    type: types.ACCOUNT_QUERY_CHANGE,
    payload: {
      value: event.target.value,
    },
  };
}

export function onAddAccount(account) {
  return (dispatch) => {
    dispatch({
      type: types.ON_ACCOUNT_ADD,
      payload: {
        account,
      },
    });
    dispatch(changeMode('tree'));
  };
}

export function onUpdateAccount(account) {
  return (dispatch) => {
    dispatch({
      type: types.ON_ACCOUNT_UPDATE,
      payload: {
        account,
      },
    });
    dispatch(changeMode('tree'));
  };
}

export function onDeleteAccount(account) {
  return (dispatch) => {
    dispatch({
      type: types.ON_ACCOUNT_DELETE,
      payload: {
        account,
      },
    });
    dispatch(changeMode('tree'));
  };
}

export function deleteMapping(mapping) {
  return (dispatch, getState) => {
    const resourceFund = getState().resourceFund;
    const url = resourceFund
      ? Routes.fund_charts_mapping_path(resourceFund.id, mapping.id, { format: 'json' })
      : Routes.charts_mapping_path(mapping.id, { format: 'json' });

    axios
      .request({
        url,
        method: 'delete',
        withCredentials: true,
        xsrfHeaderName: 'X-CSRF-Token',
      })
      .then(() => {
        dispatch({
          type: types.ON_MAPPING_DELETE,
          payload: {
            mapping,
          },
        });
      });
  };
}

export function onAddCategory(category) {
  return (dispatch) => {
    dispatch({
      type: types.ON_CATEGORY_ADD,
      payload: {
        category,
      },
    });
    dispatch(changeMode('tree'));
  };
}

export function onUpdateCategory(category) {
  return (dispatch) => {
    dispatch({
      type: types.ON_CATEGORY_UPDATE,
      payload: {
        category,
      },
    });
    dispatch(changeMode('tree'));
  };
}

export function onDeleteCategory(category) {
  return (dispatch) => {
    dispatch({
      type: types.ON_CATEGORY_DELETE,
      payload: {
        category,
      },
    });
    dispatch(changeMode('tree'));
  };
}

export function changeMode(mode, activeChart = undefined) {
  return {
    type: types.MODE_CHANGE,
    payload: {
      mode,
      activeChart,
    },
  };
}

export function toggleChartOfAccounts() {
  return (dispatch, getState) => {
    const { chartOfAccounts } = getState();

    dispatch({
      type: types.CHART_OF_ACCOUNTS_TOGGLE,
      payload: {
        isVisible: !chartOfAccounts.isVisible,
      },
    });
  };
}

export function toggleAccounts(categoryId) {
  return {
    type: types.ACCOUNTS_TOGGLE,
    payload: {
      categoryId,
    },
  };
}

export function toggleMappings(accountId) {
  return {
    type: types.MAPPINGS_TOGGLE,
    payload: {
      accountId,
    },
  };
}

export function toggleAll() {
  return (dispatch, getState) => {
    const {
      chartOfAccounts: {
        isCollapsed,
        entities: { accounts, categories },
      },
    } = getState();
    const newAccounts = {};
    const newCategories = {};

    Object.keys(accounts).map((id) => {
      newAccounts[id] = {
        ...accounts[id],
        mappingsCollapsed: !isCollapsed,
      };
    });

    Object.keys(categories).map((id) => {
      newCategories[id] = {
        ...categories[id],
        accountsCollapsed: !isCollapsed,
      };
    });

    dispatch({
      type: types.ALL_TOGGLE,
      payload: {
        isCollapsed: !isCollapsed,
        accounts: newAccounts,
        categories: newCategories,
      },
    });
  };
}

function chartOfAccountsFetchRequest(bool) {
  return {
    type: types.CHART_OF_ACCOUNTS_FETCH_REQUESTED,
    payload: {
      loading: bool,
    },
  };
}

function chartOfAccountsFetchCompleted(chartOfAccounts) {
  return {
    type: types.CHART_OF_ACCOUNTS_FETCH_COMPLETED,
    payload: {
      chartOfAccounts,
    },
  };
}
