import React from 'react';
import { Provider } from 'react-redux';

export default (WrappedComponent, configureStore) =>
  function WithReduxProviderWrapper(props) {
    const { state } = props;

    return (
      <Provider store={configureStore(state)}>
        <WrappedComponent {...props} />
      </Provider>
    );
  };
