import React from 'react';
import PropTypes from 'prop-types';
import { getFirstError } from 'utils/forms';

const propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

const defaultProps = {
  error: undefined,
};

function InputError(props) {
  const { error } = props;

  return error ? <p className="form__error">{getFirstError(error)}</p> : null;
}

InputError.propTypes = propTypes;
InputError.defaultProps = defaultProps;

export default InputError;
