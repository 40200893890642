import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';

import Dimensions from 'react-dimensions';
import ReactTooltip from 'react-tooltip';
import numeral from 'numeral';
import { StyleSheet, css } from 'aphrodite';

import { Table, Column, Cell } from 'fixed-data-table-2';
import 'fixed-data-table-2/dist/fixed-data-table.css';

import { addQueryParamsToCurrent } from 'utils/queryParams';
import overrideItemsFilter, { getDecoratedValuesFromUrl } from 'utils/funds/valuations';
import ValuationOverrideFilter from './shared/Filter';
import EditableCurrencyCell from './EditableCurrencyCell';

import './style.css';

const propTypes = {
  containerHeight: PropTypes.number.isRequired,
  containerWidth: PropTypes.number.isRequired,
  currencySymbol: PropTypes.string.isRequired,
  defaultIfNull: PropTypes.bool.isRequired,
  showResetAll: PropTypes.bool.isRequired,
  handleExportData: PropTypes.func,
  onReset: PropTypes.func,
  onResetAll: PropTypes.func,
  onSubmit: PropTypes.func,
  valuationOverrideItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      managementFeesAmount: PropTypes.string,
      performanceFeesAmount: PropTypes.string,
      openingPriorHighAmount: PropTypes.string,
      allocation: PropTypes.shape({
        openingGrossAmount: PropTypes.string,
        periodIncomeAmount: PropTypes.string,
        preFeeGrossAmount: PropTypes.string,
        managementFeesAmount: PropTypes.string,
        performanceFeesAmount: PropTypes.string,
        openingPriorHighAmount: PropTypes.string,
        closingPriorHighAmount: PropTypes.string,
        closingGrossAmount: PropTypes.string,
      }),
      source: PropTypes.shape({
        name: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
      }),
    }),
  ),
};

const defaultProps = {
  handleExportData: undefined,
  onReset: undefined,
  onResetAll: undefined,
  onSubmit: undefined,
  valuationOverrideItems: [],
};

function ValuationOverrideTable(props) {
  const {
    valuationOverrideItems,
    currencySymbol,
    containerWidth,
    containerHeight,
    onReset,
    onResetAll,
    onSubmit,
    showResetAll,
    defaultIfNull,
    handleExportData,
  } = props;

  const currencyFormat = `${currencySymbol}0,0.00`;
  const tableItemHeight = 65;

  const [filteredItems, setFilteredItems] = useState([]);
  const [initialValues, setInitialValues] = useState({});

  const handleSubmit = (values) => {
    const filteredOverrideItems = overrideItemsFilter(values, valuationOverrideItems);
    addQueryParamsToCurrent(values);
    return setFilteredItems(filteredOverrideItems);
  };

  useEffect(() => {
    const params = getDecoratedValuesFromUrl();
    if (!params || isEmpty(params)) {
      return setFilteredItems(valuationOverrideItems);
    }
    setInitialValues(params);
    handleSubmit(params);
  }, [valuationOverrideItems]);

  return (
    <>
      <div className="valuation_override__header">
        <ValuationOverrideFilter
          handleSubmit={handleSubmit}
          initialValues={initialValues}
          clearInitialValues={() => setInitialValues({})}
        />

        <button
          type="button"
          href="#"
          className="btn btn-default m-b-15 m-r-xxs"
          onClick={() => handleExportData(filteredItems)}
          disabled={isEmpty(filteredItems)}
        >
          Export template data
        </button>
      </div>

      {isEmpty(filteredItems) ? (
        <div className="valuation_override__empty">No data</div>
      ) : (
        <>
          <Table
            bufferRowCount={50}
            headerHeight={60}
            maxHeight={containerHeight - 20}
            overflowY="auto"
            rowsCount={filteredItems.length}
            rowHeight={tableItemHeight}
            width={containerWidth - 10}
            {...props}
          >
            <Column
              cell={({ rowIndex, columnKey }) => {
                const source = filteredItems[rowIndex][columnKey];

                return (
                  <Cell>
                    <span className="valuation_override__additional_info">{source.meta}</span>
                    <a href={source.path} target="blank" data-tip={source.meta} className="valuation_override__name">
                      {source.name}
                    </a>
                  </Cell>
                );
              }}
              columnKey="source"
              fixed
              header={<Cell>Fund Account</Cell>}
              pureRendering={false}
              width={250}
            />

            <Column
              cell={({ rowIndex }) => {
                const { openingGrossAmount } = filteredItems[rowIndex].allocation;
                return (
                  <Cell height={tableItemHeight} className="text-right">
                    {numeral(openingGrossAmount).format(currencyFormat)}
                  </Cell>
                );
              }}
              columnKey="allocation.openingGrossAmount"
              header={<Cell className="text-right">Openning Gross</Cell>}
              pureRendering={false}
              width={120}
            />

            <Column
              cell={({ rowIndex }) => {
                const { periodIncomeAmount } = filteredItems[rowIndex].allocation;
                return (
                  <Cell height={tableItemHeight} className="text-right">
                    {numeral(periodIncomeAmount).format(currencyFormat)}
                  </Cell>
                );
              }}
              columnKey="allocation.periodIncomeAmount"
              header={<Cell className="text-right">Period Income</Cell>}
              pureRendering={false}
              width={120}
            />

            <Column
              cell={({ rowIndex }) => {
                const { preFeeGrossAmount } = filteredItems[rowIndex].allocation;
                return (
                  <Cell height={tableItemHeight} className="text-right">
                    {numeral(preFeeGrossAmount).format(currencyFormat)}
                  </Cell>
                );
              }}
              columnKey="allocation.preFeeGrossAmount"
              header={<Cell className="text-right">Pre Fee Gross</Cell>}
              pureRendering={false}
              width={120}
            />

            <Column
              cell={({ rowIndex }) => {
                const { allocation, openingPriorHighAmount, source } = filteredItems[rowIndex];
                const value = openingPriorHighAmount || allocation.openingPriorHighAmount;
                return (
                  <Cell
                    className={openingPriorHighAmount ? css(styles.edited) : null}
                    height={tableItemHeight}
                    className="text-right"
                  >
                    <EditableCurrencyCell
                      currencySymbol={currencySymbol}
                      currencyFormat={currencyFormat}
                      name="openingPriorHighAmount"
                      onSubmit={onSubmit}
                      sourceId={source.id}
                      value={value}
                      defaultIfNull={defaultIfNull}
                    />
                  </Cell>
                );
              }}
              columnKey="openingPriorHighAmount"
              header={<Cell className="text-right">Opening HWM</Cell>}
              width={150}
            />

            <Column
              cell={({ rowIndex }) => {
                const { allocation, managementFeesAmount, source } = filteredItems[rowIndex];
                const value = managementFeesAmount || allocation.managementFeesAmount;

                return (
                  <Cell
                    className={`text-right ${managementFeesAmount ? css(styles.edited) : null}`}
                    height={tableItemHeight}
                  >
                    <EditableCurrencyCell
                      currencySymbol={currencySymbol}
                      currencyFormat={currencyFormat}
                      name="managementFeesAmount"
                      onSubmit={onSubmit}
                      sourceId={source.id}
                      value={value}
                      defaultIfNull={defaultIfNull}
                    />
                  </Cell>
                );
              }}
              columnKey="managementFeesAmount"
              header={<Cell className="text-right">Management Fee</Cell>}
              width={150}
            />

            <Column
              cell={({ rowIndex }) => {
                const { allocation, performanceFeesAmount, source } = filteredItems[rowIndex];
                const value = performanceFeesAmount || allocation.performanceFeesAmount;

                return (
                  <Cell
                    className={`text-right ${performanceFeesAmount ? css(styles.edited) : null}`}
                    height={tableItemHeight}
                  >
                    <EditableCurrencyCell
                      currencySymbol={currencySymbol}
                      currencyFormat={currencyFormat}
                      name="performanceFeesAmount"
                      onSubmit={onSubmit}
                      sourceId={source.id}
                      value={value}
                      defaultIfNull={defaultIfNull}
                    />
                  </Cell>
                );
              }}
              columnKey="performanceFeesAmount"
              header={<Cell className="text-right">Incentive Fee</Cell>}
              width={150}
            />

            <Column
              cell={({ rowIndex }) => {
                const { closingPriorHighAmount } = filteredItems[rowIndex].allocation;
                return (
                  <Cell className="text-right" height={tableItemHeight}>
                    {numeral(closingPriorHighAmount).format(currencyFormat)}
                  </Cell>
                );
              }}
              columnKey="closingPriorHighAmount"
              header={<Cell className="text-right">Closing HWM</Cell>}
              pureRendering={false}
              width={120}
            />

            <Column
              cell={({ rowIndex }) => {
                const { closingGrossAmount } = filteredItems[rowIndex].allocation;
                return (
                  <Cell className="text-right" height={tableItemHeight}>
                    {numeral(closingGrossAmount).format(currencyFormat)}
                  </Cell>
                );
              }}
              columnKey="closingGross"
              header={<Cell className="text-right">Closing Gross</Cell>}
              pureRendering={false}
              width={120}
            />

            <Column
              cell={({ rowIndex }) => {
                const { name } = filteredItems[rowIndex].user;
                return (
                  <Cell className="text-centered" height={tableItemHeight}>
                    {name}
                  </Cell>
                );
              }}
              columnKey="changesUserName"
              header={<Cell className="text-centered">Changed by</Cell>}
              width={150}
            />

            <Column
              cell={({ rowIndex }) => {
                const { id, sourceId } = filteredItems[rowIndex];

                return (
                  <Cell height={tableItemHeight} className="text-centered">
                    {id && (
                      <button
                        type="button"
                        className="btn btn-danger-outlined btn-sm"
                        onClick={() => onReset({ sourceId })}
                      >
                        Reset
                      </button>
                    )}
                  </Cell>
                );
              }}
              columnKey="actions"
              fixedRight
              header={
                <Cell>
                  {showResetAll && (
                    <button type="button" className="btn btn-danger-outlined btn-sm" onClick={() => onResetAll()}>
                      Reset All
                    </button>
                  )}
                </Cell>
              }
              width={90}
            />
          </Table>

          <ReactTooltip />
        </>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  edited: {
    height: '100%',
    backgroundColor: '#deefff',
  },
});

ValuationOverrideTable.propTypes = propTypes;
ValuationOverrideTable.defaultProps = defaultProps;

export default Dimensions({
  getHeight: () => window.innerHeight - 200,
  getWidth: () => {
    const widthOffset = window.innerWidth < 680 ? 0 : 240;
    return window.innerWidth - widthOffset;
  },
})(ValuationOverrideTable);
