import React from 'react';
import PropTypes from 'prop-types';
import { accounting } from 'accounting';
import FontAwesome from 'react-fontawesome';
import { reject, get, isEmpty } from 'lodash';
import getSymbolFromCurrency from 'currency-symbol-map';

import { DateInput, FormAlerts, Select, TextArea, Input } from 'components/shared';

import LineItem from './shared/LineItem';
import AmountsAlert from './shared/AmountsAlert';

const propTypes = {
  resourceFund: PropTypes.object.isRequired,
  glEntryForm: PropTypes.object.isRequired,
  refs: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

function glEntriesForm(props) {
  const { glEntryForm, resourceFund, refs } = props;
  const glEntry = glEntryForm.data;
  const { submitting, errors, success, entryChanged, entryNeedBeUpdated } = glEntryForm.meta;
  const { creditAmount, debitAmount, equalCreditAndDebitAmounts } = glEntryForm.extra;
  const {
    currencyInputChange,
    accountingDateInputChange,
    changeInput,
    onSubmit,
    addLineItem,
    changeEntry,
    changeDescription,
  } = props.actions;
  const { types, currenciesIso, currenciesSymbols, fundBaseCurrencyIso } = refs;

  const lineItemsList = glEntry.lineItems.map((lineItem, index) => (
    <LineItem
      key={index}
      glEntry={glEntry}
      lineItem={lineItem}
      index={index}
      size={reject(glEntry.lineItems, (a) => a._destroy).length}
      currencySymbol={get(currenciesSymbols, glEntry.currencyIso.toLowerCase())}
      fundBaseCurrencyIso={fundBaseCurrencyIso}
      actions={props.actions}
      errors={errors.lineItems}
    />
  ));

  return (
    <form onSubmit={(e) => onSubmit(e)} className="form" noValidate="novalidate">
      <div className="row">
        <div className="col-md-12">
          <FormAlerts errors={errors} success={success} />
          <div className="panel">
            <div className="panel__body panel__body-padded">
              <div className="row">
                <div className="col-md-4">
                  <DateInput
                    id="accounting_date"
                    name="gl_entry[accounting_date]"
                    required
                    label={I18n.t('activerecord.attributes.fund/gl_entry.accounting_date')}
                    dataStateKey="data.accountingDate"
                    value={glEntry.accountingDate}
                    onChange={(event) => accountingDateInputChange(event)}
                    errors={get(errors, 'accountingDate')}
                  />
                </div>
                <div className="col-md-2">
                  <Select
                    id="currency_iso"
                    name="gl_entry[currency_iso]"
                    required
                    dataStateKey="data.currencyIso"
                    value={glEntry.currencyIso}
                    label={I18n.t('activerecord.attributes.fund/gl_entry.currency_iso')}
                    className="form__control"
                    onChange={(value) => currencyInputChange(value, fundBaseCurrencyIso, glEntry.accountingDate)}
                    options={currenciesIso}
                    errors={get(errors, 'currencyIso')}
                  />
                </div>
                {glEntry.currencyIso !== fundBaseCurrencyIso && (
                  <div className="col-md-4">
                    <Input
                      id="rate"
                      name="gl_entry[rate]"
                      required
                      type="number"
                      dataStateKey="data.rate"
                      value={glEntry.rate}
                      label={I18n.t('activerecord.attributes.fund/gl_entry.rate')}
                      className="form__control"
                      onChange={changeInput}
                      errors={get(errors, 'rate')}
                    />
                  </div>
                )}
              </div>

              <legend className="form__legend">Line Items</legend>

              <div className="row">
                <div className="col-md-12 form__group">
                  <table className="table table-auto table-condensed table-centered">
                    <thead>
                      <tr>
                        <th />
                        <th className="text-centered">Debit</th>
                        <th className="text-centered">Credit</th>
                        <th colSpan="2" />
                      </tr>
                    </thead>
                    <tbody>
                      {lineItemsList}
                      <tr className="text-centered">
                        <td className="text-bold">Total</td>
                        <td>
                          {accounting.formatMoney(debitAmount, getSymbolFromCurrency(glEntry.currencyIso), 2, ',', '.')}
                        </td>
                        <td>
                          {accounting.formatMoney(
                            creditAmount,
                            getSymbolFromCurrency(glEntry.currencyIso),
                            2,
                            ',',
                            '.',
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="btn btn-primary" onClick={addLineItem}>
                            <FontAwesome name="plus" /> <span>Add Line Item</span>
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              {(!equalCreditAndDebitAmounts || entryNeedBeUpdated) && (
                <AmountsAlert
                  equalCreditAndDebitAmounts={equalCreditAndDebitAmounts}
                  creditAmount={creditAmount}
                  debitAmount={debitAmount}
                  currency={glEntry.currencyIso}
                  entryNeedBeUpdated={entryNeedBeUpdated}
                />
              )}

              <TextArea
                id="entry"
                name="gl_entry[entry]"
                value={glEntry.entry}
                dataStateKey="data.entry"
                placeholder="Entry…"
                onChange={changeEntry}
                rows={glEntry.lineItems.length}
                disabled='true'
              />

              <TextArea
                id="description"
                name="gl_entry[description]"
                value={glEntry.description}
                dataStateKey="data.description"
                placeholder="Description…"
                onChange={changeDescription}
              />
            </div>

            <div className="panel__footer">
              <button type="submit" className="btn btn-primary" disabled={!equalCreditAndDebitAmounts || !isEmpty(errors)}>
                {submitting ? (
                  <FontAwesome name="spinner" spin />
                ) : glEntry.id ? (
                  I18n.t('buttons.update')
                ) : (
                  I18n.t('buttons.create')
                )}
              </button>
              <a className="btn btn-link" href={Routes.fund_gl_entries_path(resourceFund.id)}>
                Cancel
              </a>
            </div>
          </div>
        </div>

        {glEntry.currencyIso !== fundBaseCurrencyIso && (
          <div className="col-md-3">
            <div className="panel">
              <div className="panel__body panel__body-padded">
                <ul className="list-unstyled">
                  <li>
                    Base Currency: <strong>{fundBaseCurrencyIso}</strong>
                  </li>
                  <li>
                    Selected Currency: <strong>{glEntry.currencyIso}</strong>
                  </li>
                  <li>
                    Security Master Rate:{' '}
                    <strong>{glEntry.securityMasterRate ? glEntry.securityMasterRate : '-'}</strong>
                  </li>
                  <li>
                    {' '}
                    Pricing Date: <strong>
                      {glEntry.securityPricingDate ? glEntry.securityPricingDate : '-'}
                    </strong>{' '}
                  </li>
                  {glEntry.rate !== glEntry.securityMasterRate && (
                    <li>
                      Override Rate: <strong>{glEntry.rate ? glEntry.rate : '-'}</strong>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>
    </form>
  );
}

glEntriesForm.propTypes = propTypes;

export default glEntriesForm;
