import React from 'react';
import PropTypes from 'prop-types';

import { Input } from 'components/shared';

const propTypes = {
  tradeExercise: PropTypes.shape({
    tradeFxRate: PropTypes.number,
    settleFxRate: PropTypes.number,
  }).isRequired,

  changeInput: PropTypes.func.isRequired,
  changeFxRate: PropTypes.func.isRequired,
  errors: PropTypes.object,
};

const Rates = (props) => {
  const { tradeFxRate, settleFxRate, parentId } = props.tradeExercise;
  const { errors, changeInput, changeFxRate } = props;

  return (
    <div>
      <hr className='hr' />
      <h3 className="m-b-none">Rates</h3>
      <span className="m-b-xs" style={{ fontSize: '12px', color: '#6D6B69', display: 'block' }}>(Native / Rate)</span>

      <div className="row">
        <div className="col-md-6">
          <Input
            id='trade_fx_rate'
            type='number'
            name='trade[trade_fx_rate]'
            dataStateKey='tradeExerciseForm.data.tradeFxRate'
            value={tradeFxRate}
            step='0.01'
            required
            label='Trade FX Rate'
            onChange={changeFxRate}
            errors={_.get(errors, 'tradeFxRate')}
            disabled={Boolean(parentId)}
          />
        </div>

        <div className="col-md-6">
          <Input
            id='settle_fx_rate'
            type='number'
            name='trade[settle_fx_rate]'
            dataStateKey='tradeExerciseForm.data.settleFxRate'
            value={settleFxRate}
            step='0.01'
            required
            label='Settle FX Rate'
            onChange={changeInput}
            errors={_.get(errors, 'settleFxRate')}
            disabled={Boolean(parentId)}
          />
        </div>
      </div>
    </div>
  );
};

Rates.propTypes = propTypes;
export default Rates;
