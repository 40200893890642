import React from 'react';
import PropTypes from 'prop-types';
import { selectOptionsPropTypes, selectOptionsNumbersPropTypes } from 'propTypes';
import { connect } from 'react-redux';
import { Form as FinalForm } from 'react-final-form';
import createFocusDecorator from 'final-form-focus';
import { compose, lifecycle, pure } from 'recompose';

import { accrualFormMutatorsShape, accrualPropTypes } from 'constants/propTypes/funds/accruals';
import { getAccrualInitialValues } from 'selectors/funds/accruals';
import { getIsVisibleChart } from 'selectors/chartOfAccounts';
import { createAccrualInitialValues, submitAccrualForm, selectAccount } from 'actions/funds/accruals';
import { fetchFundCharts } from 'actions/funds';
import { toggleChartOfAccounts } from 'actions/chartOfAccounts';
import Form from './shared/Form';

const propTypes = {
  accrualId: PropTypes.string,
  fundId: PropTypes.string.isRequired,
  selectChartAccount: PropTypes.func.isRequired,
  toggleChart: PropTypes.func.isRequired,
  initialValues: accrualPropTypes.isRequired,
  onSubmit: PropTypes.func.isRequired,
  mutators: PropTypes.shape(accrualFormMutatorsShape),
  isVisibleChart: PropTypes.bool,
  refs: PropTypes.shape({
    accrualCategoryOptions: selectOptionsNumbersPropTypes,
    frequencyOptions: selectOptionsPropTypes,
  }).isRequired,
};

const defaultProps = {
  accrualId: undefined,
  isVisibleChart: false,
  mutators: undefined,
};

const focusOnError = createFocusDecorator();

function FundsAccrualForm(props) {
  const {
    accrualId,
    isVisibleChart,
    initialValues,
    fundId,
    refs,
    onSubmit,
    mutators,
    selectChartAccount,
    toggleChart,
  } = props;
  const { accrualCategoryOptions, frequencyOptions } = refs;

  return (
    <>
      <FinalForm
        accrualCategoryOptions={accrualCategoryOptions}
        accrualId={accrualId}
        cancelUrl={Routes.fund_accruals_path(fundId)}
        component={Form}
        decorators={[focusOnError]}
        frequencyOptions={frequencyOptions}
        initialValues={initialValues}
        onSubmit={onSubmit}
        subscription={{ hasSubmitErrors: true, pristine: true, submitting: true, submitError: true }}
        mutators={mutators}
        refs={refs}
        isVisibleChart={isVisibleChart}
        selectChartAccount={selectChartAccount}
        toggleChart={toggleChart}
        reinitialized={false}
      />
    </>
  );
}

const mapStateToProps = (state, props) => {
  const { match } = props;
  const { accrualId, fundId } = match.params;
  const initialValues = getAccrualInitialValues(state);
  const isVisibleChart = getIsVisibleChart(state);
  const mutators = {
    changeSpecialAllocation: (name, ownState, { changeValue }) => {
      changeValue(ownState, 'specialAllocation', () => false);
    },
  };

  return {
    accrualId,
    fundId,
    initialValues,
    mutators,
    isVisibleChart,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { fundId, accrualId } = ownProps.match.params;

  return {
    fetchChartsData: (params) => dispatch(fetchFundCharts(params)),
    onSubmit: (values) => dispatch(submitAccrualForm(values, { fundId, accrualId })),
    selectChartAccount: (chartAccountId) => dispatch(selectAccount(chartAccountId)),
    toggleChart: () => dispatch(toggleChartOfAccounts()),
    createInitialValues: () => dispatch(createAccrualInitialValues()),
  };
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    componentDidMount() {
      const { createInitialValues, fetchChartsData, fundId } = this.props;
      createInitialValues();
      fetchChartsData({ id: fundId });
    },
  }),
  pure,
);

FundsAccrualForm.propTypes = propTypes;
FundsAccrualForm.defaultProps = defaultProps;

export default enhance(FundsAccrualForm);
