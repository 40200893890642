import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { FormGroup } from '../';

const propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  dataStateKey: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  required: PropTypes.bool,
  autofocus: PropTypes.bool,
  includeBlank: PropTypes.bool,
  prompt: PropTypes.string,
  errors: PropTypes.array,
  append: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  prepend: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  onChange: PropTypes.func.isRequired,
};

const defaultProps = {
  includeBlank: false,
  prompt: 'Please select…',
  value: '',
  label: null,
  required: false,
  errors: null,
  onChange: null,
  disabled: false,
  autofocus: false,
  append: undefined,
};

const Select = (props) => {
  const {
    id,
    name,
    dataStateKey,
    value,
    label,
    required,
    options,
    onChange,
    errors,
    includeBlank,
    autofocus,
    prompt,
    disabled,
    append,
    prepend,
  } = props;

  const addonClasses = classNames({
    form__addon: append || prepend,
    'form__addon-right': append,
    'form__addon-left': prepend,
  });

  return (
    <FormGroup label={label} required={required} id={id} errors={errors}>
      <div className={addonClasses}>
        {prepend && <span className="form__addon_icon">{prepend}</span>}

        <select
          id={id}
          name={name}
          data-statekey={dataStateKey}
          value={value || ''}
          className="form__control"
          onChange={onChange}
          autoFocus={autofocus}
          disabled={disabled}
        >
          {includeBlank && <option value="">{prompt}</option>}

          {options.map((opt) => {
            return (
              <option key={opt.value} value={opt.value}>
                {opt.label}
              </option>
            );
          })}
        </select>

        {append && <span className="form__addon_icon">{append}</span>}
      </div>
    </FormGroup>
  );
};

Select.propTypes = propTypes;
Select.defaultProps = defaultProps;

export default Select;
