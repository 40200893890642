import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { map, snakeCase } from 'lodash';

import * as actions from './actions';
import { filteredMappingsBySecurityTypes } from './selectors';
import { Filter, Table } from './components';
import MappingForm from './MappingForm/Container';

const propTypes = {
  actions: PropTypes.object.isRequired,
  refs: PropTypes.object.isRequired,
  chartsMappings: PropTypes.object.isRequired,
  fundId: PropTypes.number,
};

const defaultProps = {
  fundId: undefined,
};

class ChartMappings extends React.Component {
  componentDidMount() {
    this.props.actions.fetchMappings(this.props.fundId);
  }

  render() {
    const { chartsMappings, refs, actions, fundId } = this.props;
    const { mode, status, filtered, changed } = chartsMappings;

    const tableList = map(refs.mappingsConfiguration || {}, (events, namespace) => (
      <div className="panel" key={`namespace_${namespace}`}>
        <div className="panel__body panel__body-padded">
          <h3>{I18n.t(`mappings.namespaces.${snakeCase(namespace)}`)}</h3>
          <Table
            mappings={filteredMappingsBySecurityTypes(this.props)}
            status={status}
            namespace={namespace}
            events={events}
            changed={changed}
            actions={actions}
          />
        </div>
      </div>
    ));

    if (mode === 'mappingForm') {
      return (
        <div className="panel">
          <div className="panel__body panel__body-padded">
            <MappingForm changeMode={actions.changeMode} fundId={fundId} refs={refs} />
          </div>
        </div>
      );
    }

    return (
      <React.Fragment>
        <Filter onChange={actions.changeFilter} options={refs.securityTypes} filtered={filtered} />
        {tableList}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  chartsMappings: state.chartsMappings,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

ChartMappings.propTypes = propTypes;
ChartMappings.defaultProps = defaultProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChartMappings);
