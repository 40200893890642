import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  receiveReports: PropTypes.bool,
};

const defaultProps = {
  receiveReports: false,
};

const Explanation = ({ receiveReports }) => {
  if (!receiveReports) {
    return <strong className="account__explanation"> (Missing Reports Settings)</strong>;
  }

  return <strong className="account__explanation"> (No Web Access)</strong>;
};

Explanation.propTypes = propTypes;
Explanation.defaultProps = defaultProps;

export default Explanation;
