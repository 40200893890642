import React from 'react';

import { default as Currency } from './Currency';
import { default as Future } from './Future';
import { default as Investment } from './Investment';
import { default as All } from './All';

const Summary = (props) => {
  const { security, previousSecurity, trade } = props;
  const currentSecurity = security || (trade.id ? previousSecurity : undefined);

  const summary = (type) => {
    switch(type) {
      case 'currency':
        return <Currency {...props} />;
      case 'future':
        return <Future {...props} />;
      case 'partnership_investment':
      case 'private_equity_investment':
        return <Investment {...props} />;
      default:
        return <All {...props} />;
    }
  };

  return currentSecurity ? summary(currentSecurity.type) : null;
};

export default Summary;
