/* eslint-disable no-nested-ternary */
import { Button, Drawer as AntdDrawer, Tree, Popconfirm, Typography, Input, Icon, Checkbox } from 'antd';
import { map, toLower, uniq, isEmpty, includes, equals, without, flatten, difference, symmetricDifference, compose } from 'ramda';
import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { fetchFundAccounts } from 'actions/funds/accounts';
import { closeDrawer, fetchSetPrivateFolders } from 'actions/funds/investorDocumentDirectories';
import { fetchRenameDirectory, fetchBindDirectory } from 'actions/funds/investorDocumentDirectories/directories';
import { fetchUploadDocuments, fetchBindDocument } from 'actions/funds/investorDocumentDirectories/documents';
import * as accountStatuses from 'constants/account';
import { getFundAccountEntities, getFundAssociateEntities } from 'selectors/funds/investorDocumentDirectories';
import { getAccountStatus } from 'utils/accounts';
import { useAsyncFetchDefer } from 'utils/useAsyncFetch';

import Name from './Name';
import styles from './drawer.module.css';

const types = { class: 'Fund::InvestorClass', series: 'Fund::InvestorSeries', account: 'Fund::Account' };

function Drawer(props) {
  const { match, drawerProps, isFetchingFundAccounts, asyncSetPrivateFolders, isSettingPrivateFolders, asyncBindDirectory, isBindingDirectory, asyncBindDocument, isBindingDocument } = props;
  const { path, params: { fundId } } = match;
  const isVisible = !!drawerProps;
  const {
    id, isDocument, isEditing, folderId, accesses, files, type, isPrivate, inheritedAccesses,
  } = drawerProps || {};
  const dispatch = useDispatch();
  const fundAccounts = useSelector((state) => getFundAccountEntities(state, fundId));
  const fundSeries = uniq(map((acc) => ({ id: acc.investorSeries.id, classId: acc.investorClass.id, name: acc.investorSeries.name }), fundAccounts));

  const normalizeAccesses = (accesses) => ((accesses || []).map(({ ownerType, ownerId }) => `${ownerType}-${ownerId}`));
  const normalizedAccesses = normalizeAccesses(accesses);
  const normalizedInheritedAccesses = normalizeAccesses(inheritedAccesses);

  const [expandedKeys, setExpandedKeys] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [wantToCancel, setWantToCancel] = useState(false);
  const [checkedFiles, setCheckedFiles] = useState([]);
  const [asyncUploadDocuments, isAddingDocuments] = useAsyncFetchDefer(fetchUploadDocuments);

  const [checkedKeys, setCheckedKeys] = useState([]);
  const [halfCheckedKeys, setHalfCheckedKeys] = useState([]);
  const [disabledCheckedKeys, setDisabledCheckedKeys] = useState([]);
  const isLoading = isAddingDocuments || isBindingDocument || isBindingDirectory;
  const isPristine = compose(isEmpty, symmetricDifference)(without(normalizedInheritedAccesses, normalizedAccesses), without(normalizedInheritedAccesses, checkedKeys));
  const privateHasBeenDeleted = difference(normalizedAccesses, checkedKeys).length > 0;

  const getParents = (key) => {
    const [type, id] = key.split('-');
    const parents = [];
    if (type === types.series) {
      const series = fundSeries.find((item) => item.id === Number(id));
      parents.push(`${types.class}-${series.classId}`)
    }
    if (type === types.account) {
      const account = fundAccounts.find((item) => item.id === Number(id));
      parents.push(`${types.class}-${account.investorClassId}`);
      parents.push(`${types.series}-${account.investorSeriesId}`);
    }
    return parents
  }

  const getChildren = (key) => {
    const [type, id] = key.split('-');
    const children = [];
    if (type === types.series) {
      const accounts = fundAccounts.filter(item => item.investorSeriesId === Number(id));
      children.push(...accounts.map((account) => `${types.account}-${account.id}`));
    }
    if (type === types.class) {
      const accounts = fundAccounts.filter(item => item.investorClassId === Number(id));
      children.push(...flatten(accounts.map((account) => [`${types.series}-${account.investorSeriesId}`, `${types.account}-${account.id}`])));
    }
    return children;
  }

  useEffect(() => {
    if (!isFetchingFundAccounts && (files || isEditing || type === 'PRIVATE_FOLDERS')) {
      const allParents = uniq(flatten([normalizedAccesses.map((key) => getParents(key)), normalizedInheritedAccesses.map((key) => getParents(key))]));
      const allChildren = uniq(flatten([normalizedAccesses.map((key) => getChildren(key)), normalizedInheritedAccesses.map((key) => getChildren(key))]));
      setCheckedKeys([...normalizedAccesses]);
      setHalfCheckedKeys(without(normalizedAccesses, allParents));
      setDisabledCheckedKeys(allChildren)
    }
  }, [isVisible, isFetchingFundAccounts]);

  useEffect(() => {
    if (!isLoading) {
      dispatch(closeDrawer());
    }
  }, [isLoading]);

  useEffect(() => {
    if (!isEmpty(searchValue)) {
      const element = document.getElementsByClassName('found-item')[0];

      if (element) {
        setTimeout(() => element.scrollIntoView({ block: 'center', behavior: 'smooth' }), 1);
      }
    }
  }, [searchValue]);

  const onExpand = useCallback((keys) => setExpandedKeys([...keys]), [expandedKeys]);

  const onCheck = ({ checked: treeChecked, halfChecked }, event) => {
    const { children, eventKey } = event.node.props;
    const checked = treeChecked.filter(key => !disabledCheckedKeys.includes(key));
    const childrenKeys = children ? flatten(children.map(({ key, props: { children: cChildren } }) => [key, ...(cChildren ? cChildren.map(({ key }) => key) : [])])) : [];
    const [type, id] = eventKey.split('-');
    const removing = [];
    const parents = getParents(eventKey);
    halfCheckedKeys.filter((key) => key.split('-')[0] === types.series).map((key) => {
      if (fundAccounts
        .filter((acc) => acc.investorSeriesId === Number(key.split('-')[1]))
        .every((item) => !checked.includes(`${types.account}-${item.id}`))) {
        removing.push(key);
      }
    })
    halfCheckedKeys.filter((key) => key.split('-')[0] === types.class).map((key) => {
      if (!fundSeries
        .filter((s) => s.classId === Number(key.split('-')[1]))
        .some((item) => {
          const key = `${types.series}-${item.id}`;
          return !removing.includes(key) && (checked.includes(key) || halfChecked.includes(key) || parents.includes(key))
        })) {
        removing.push(key);
      }
    })
    setHalfCheckedKeys(uniq(flatten([...without(removing, [...without(eventKey, halfCheckedKeys), ...parents]), normalizedInheritedAccesses.map((key) => getParents(key))])));
    setCheckedKeys(children ? [...checked.filter((key) => !childrenKeys.includes(key))] : [...checked]);
    setDisabledCheckedKeys(checked.includes(eventKey) ? uniq([...disabledCheckedKeys, ...childrenKeys]) : without(childrenKeys, disabledCheckedKeys));
  }

  const onSearch = useCallback(
    (e) => {
      const { value: initialValue } = e.target;
      const value = toLower(initialValue);
      const data = [];

      if (value.length > 0) {
        map((account) => {
          const name = toLower(account.name);

          if (includes(value, name)) {
            data.push(`${types.class}-${account.investorClassId}`);
            data.push(`${types.series}-${account.investorSeriesId}`);
          }
        }, fundAccounts);
        map((series) => {
          const name = toLower(series.name);

          if (includes(value, name)) {
            data.push(`${types.class}-${series.classId}`);
          }
        }, fundSeries);
      }

      setExpandedKeys(uniq(data));
      setSearchValue(value);
    },
    [fundAccounts],
  );

  const bind = () => isDocument ? asyncBindDocument({ fundId, id, accesses: checkedKeys }) : asyncBindDirectory({ fundId, id, accesses: checkedKeys });
  const onSubmit = () => files ? asyncUploadDocuments({ fundId, folderId, files: files.filter(f => checkedFiles.includes(f.uid)), ...(isPrivate ? { isPrivate } : { accesses: checkedKeys }) }) : bind();
  const onSubmitPrivate = () => asyncSetPrivateFolders({ fundId, accesses: checkedKeys.filter((x) => x.split('-')[0] === types.account).map((x) => x.split('-')[1]) });

  if (isEditing && wantToCancel) {
    dispatch(closeDrawer());
    setWantToCancel(false);
  }

  const data = (fundAccounts || []).reduce((acc, cur) => {
    const klass = acc.find((item) => item.type === 'class' && item.id === cur.investorClassId);
    const series = klass && klass.series.find((item) => item.id === cur.investorSeriesId);

    const newSeries = { key: `${types.series}-${cur.investorSeriesId}`, type: 'series', id: cur.investorSeriesId, name: cur.investorSeries.name, accounts: [cur] };
    const newClass = { key: `${types.class}-${cur.investorClassId}`, type: 'class', id: cur.investorClassId, name: cur.investorClass.name, series: [newSeries] };

    if (!klass) {
      return [...acc, newClass];
    }
    if (!series) {
      return [...acc.filter((item) => item !== klass), { ...klass, series: [...klass.series, newSeries] }];
    }
    return [...acc.filter((item) => item !== klass), { ...klass, series: [...klass.series.filter((item) => item !== series), { ...series, accounts: [...series.accounts, cur] }] }];
  }, []);

  useEffect(() => {
    if (files) {
      setCheckedFiles((files).map(file => file.uid))
      setExpandedKeys(flatten(map((klass) => [klass.key, ...(!checkedKeys.includes(klass.key) && !normalizedInheritedAccesses.includes(klass.key) ? map((series) => series.key, klass.series) : [])], data)));
    }
  }, [files])

  if (!isVisible) {
    if (expandedKeys.length > 0) setExpandedKeys([]);
    if (checkedKeys.length > 0) setCheckedKeys([]);
    if (halfCheckedKeys.length > 0) setHalfCheckedKeys([]);
    return null;
  };

  if (type === 'PRIVATE_FOLDERS') {
    return (
      <AntdDrawer
        className={styles.drawer}
        title="Choose Fund Accounts"
        width="40%"
        placement='right'
        visible={isVisible}
        onClose={() => dispatch(closeDrawer())}
        drawerStyle={{ display: 'flex', flexDirection: 'column', overflowX: 'hidden' }}
        bodyStyle={{ flexGrow: 1, paddingBottom: 80 }}
        closable={false}
        destroyOnClose
      >
        {isFetchingFundAccounts
          ? (
            <div className={styles.loading}>
              <Icon type="loading" style={{ fontSize: '30px', marginBottom: 20 }} />
              <span>Loading fund accounts...</span>
            </div>
          ) : (
            <>
              <div className={styles.content}>
                <div className={styles.contentContainer}>
                  <div className={styles.search}>
                    <Input.Search placeholder="Search" onChange={onSearch} allowClear />
                  </div>
                  <Tree
                    onExpand={onExpand}
                    expandedKeys={[...expandedKeys]}
                    onCheck={(keys) => setCheckedKeys([...keys])}
                    checkedKeys={checkedKeys}
                    switcherIcon={<Icon type="down" />}
                    selectable={false}
                    showIcon
                    checkable
                  >
                    {map((klass) => (
                      <Tree.TreeNode
                        title={<Name searchValue={searchValue} item={klass} />}
                        key={klass.key}
                      >
                        {map((series) => (
                          <Tree.TreeNode
                            title={<Name searchValue={searchValue} item={series} />}
                            key={series.key}
                          >
                            {map((account) => (
                              <Tree.TreeNode
                                title={<Name searchValue={searchValue} item={account} />}
                                key={`${types.account}-${account.id}`}
                              />
                            ), series.accounts)}
                          </Tree.TreeNode>
                        ), klass.series)}
                      </Tree.TreeNode>
                    ), data)}
                  </Tree>
                </div>
              </div>
              <div className={styles.footer}>
                {isPristine ? (
                  <Button type="primary" disabled>{isLoading ? <Icon type="loading" /> : 'Update'}</Button>
                ) : (
                  privateHasBeenDeleted ? (
                    <Popconfirm
                      title="Are you sure you want to delete private folders? All documents inside will be permanently deleted!"
                      onConfirm={onSubmitPrivate}
                    >
                      <Button type="primary" loading={isSettingPrivateFolders} disabled={isLoading}>{isLoading ? <Icon type="loading" /> : 'Update'}</Button>
                    </Popconfirm>
                  ) : (
                    <Button type="primary" onClick={onSubmitPrivate} loading={isSettingPrivateFolders} disabled={isLoading}>{isLoading ? <Icon type="loading" /> : 'Update'}</Button>
                  )
                )}
                <Button onClick={() => dispatch(closeDrawer())} disabled={isLoading}>Cancel</Button>
              </div>
            </>
          )}
      </AntdDrawer >
    )
  }

  return (
    <AntdDrawer
      className={styles.drawer}
      title="Choose Fund Accounts"
      width={isEditing ? '40%' : isPrivate ? '30%' : '60%'}
      placement='right'
      visible={isVisible}
      onClose={() => setWantToCancel(true)}
      drawerStyle={{ display: 'flex', flexDirection: 'column', overflowX: 'hidden' }}
      bodyStyle={{ flexGrow: 1, paddingBottom: 80 }}
      closable={false}
      destroyOnClose
    >
      {isFetchingFundAccounts
        ? (
          <div className={styles.loading}>
            <Icon type="loading" style={{ fontSize: '30px', marginBottom: 20 }} />
            <span>Loading fund accounts...</span>
          </div>
        ) : (
          <>
            {!isEditing && (
              <div className={`${styles.files}${isPrivate ? ` ${styles.filesPrivate}` : ''}`}>
                <div className={styles.filesContainer}>
                  <Typography.Text strong className={styles.selectFiles}>Select files</Typography.Text>
                  <Checkbox
                    className={styles.allFiles}
                    onChange={(e) => e.target.checked ? setCheckedFiles(files.map(file => file.uid)) : setCheckedFiles([])}
                    checked={checkedFiles.length === files.length}
                  >
                    All files
                </Checkbox>
                  <Checkbox.Group
                    value={checkedFiles}
                    options={files.map(file => ({ label: file.name, value: file.uid }))}
                    onChange={setCheckedFiles}
                  />
                </div>
              </div>
            )}
            {!isPrivate && (
              <div className={styles.content}>
                <div className={styles.contentContainer}>
                  <div className={styles.search}>
                    <Input.Search placeholder="Search" onChange={onSearch} allowClear />
                  </div>
                  <Tree
                    onExpand={onExpand}
                    onCheck={onCheck}
                    checkedKeys={{ checked: [...checkedKeys, ...normalizedInheritedAccesses, ...disabledCheckedKeys], halfChecked: halfCheckedKeys }}
                    expandedKeys={[...expandedKeys]}
                    switcherIcon={<Icon type="down" />}
                    selectable={false}
                    showIcon
                    checkable
                    checkStrictly
                  >
                    {map((klass) => (
                      <Tree.TreeNode
                        title={<Name searchValue={searchValue} item={klass} />}
                        key={klass.key}
                        disableCheckbox={normalizedInheritedAccesses.includes(klass.key)}
                      >
                        {map((series) => {
                          const disabledSeries = disabledCheckedKeys.includes(series.key);
                          return (
                            <Tree.TreeNode
                              title={<Name searchValue={searchValue} item={series} />}
                              key={series.key}
                              disableCheckbox={disabledSeries || normalizedInheritedAccesses.includes(series.key)}
                            >
                              {map((account) => {
                                const key = `${types.account}-${account.id}`;
                                const disabledAccount = disabledCheckedKeys.includes(key);
                                return (
                                  <Tree.TreeNode
                                    title={<Name searchValue={searchValue} item={account} />}
                                    key={key}
                                    disableCheckbox={disabledAccount || normalizedInheritedAccesses.includes(key)}
                                  />
                                )
                              }, series.accounts)}
                            </Tree.TreeNode>
                          )
                        }, klass.series)}
                      </Tree.TreeNode>
                    ), data)}
                  </Tree>
                </div>
              </div>
            )}
            <div className={styles.footer}>
              {checkedKeys.length === 0 && isPristine && !isPrivate ? (
                isEditing
                  ? <Button type="primary" disabled>Update</Button>
                  : (isLoading || checkedFiles.length === 0) ? (
                    <Button type="primary" disabled>{isLoading ? <Icon type="loading" /> : 'Upload'}</Button>
                  ) : (
                    normalizedInheritedAccesses.length === 0
                      ? (
                        <Popconfirm
                          title={<><div>No accounts will have access to this document.</div><div>Do you want to upload it regardless?</div></>}
                          onConfirm={onSubmit}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="primary" disabled={isLoading}>{isLoading ? <Icon type="loading" /> : 'Upload'}</Button>
                        </Popconfirm>
                      )
                      : <Button type="primary" onClick={onSubmit} disabled={isLoading}>{isLoading ? <Icon type="loading" /> : 'Upload'}</Button>
                  )
              ) : (
                <Button type="primary" onClick={onSubmit} disabled={isLoading || (!isPrivate && isEditing ? isPristine : checkedFiles.length === 0)}>{isLoading ? <Icon type="loading" /> : isEditing ? 'Update' : 'Upload'}</Button>
              )}
              {isEditing || isLoading ? (
                <Button onClick={() => dispatch(closeDrawer())} disabled={isLoading}>Cancel</Button>
              ) : (
                <Popconfirm
                  visible={wantToCancel}
                  title={<><div>The document(s) will not be uploaded.</div><div>Proceed?</div></>}
                  placement="topRight"
                  onConfirm={() => { setWantToCancel(false); dispatch(closeDrawer()) }}
                  onCancel={() => setWantToCancel(false)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button onClick={() => setWantToCancel(true)} disabled={isLoading}>Cancel</Button>
                </Popconfirm>
              )}
            </div>
          </>
        )}
    </AntdDrawer >
  )
}

export default Drawer;
