import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isEmpty } from 'ramda';
import { Form as FinalForm } from 'react-final-form';
import { Drawer, Button, Card, Col, Row, Typography, Statistic, PageHeader, Empty, Progress } from 'antd';

import { collectionSelector as getTaskNotes, getLastTaskNotes } from 'selectors/funds/valuations/taskNotes';
import { getFundUsers } from 'selectors/funds';
import {
  collectionSelector as taskItemsSelector,
  dataSource as getTableTaskItems,
  getEditable as editableTaskItemSelector,
  getDependentOptions as getDependentTaskItems,
  getCompletedCount as getCompletedTasksCount,
} from 'selectors/funds/valuations/taskItems';

import {
  closeTaskItem,
  createTaskItem,
  deleteTaskItem,
  editTaskItem,
  newTaskItem,
  updateTaskItem,
  changeTaskItemOwner,
  completeTaskItem,
  changeTaskItemPosition,
} from 'actions/funds/valuations/taskItems';

import {
  showNotes,
  hideNotes,
  editNote,
  cancelEditNote,
  submitTaskNote,
  deleteNote,
} from 'actions/funds/valuations/taskNotes';

import AddNoteForm from './shared/AddNoteForm';
import Notes from './shared/Notes';
import TaskItemForm from './shared/TaskItemForm';
import TaskItemsTable from './shared/TaskItemsTable';
import ValuationInfo from './shared/ValuationInfo';

const propTypes = {
  currentUserId: PropTypes.number,
  currentUserInitials: PropTypes.string,
  dependentTaskItems: PropTypes.arrayOf(PropTypes.object),
  editableTaskItem: PropTypes.any,
  fundUserList: PropTypes.arrayOf(PropTypes.object),
  isVisibleEditTaskItem: PropTypes.bool,
  isVisibleNotes: PropTypes.bool,
  lastTaskNotes: PropTypes.arrayOf(PropTypes.object),
  onSubmitTaskItem: PropTypes.func,
  onEditTaskItem: PropTypes.func,
  onCloseTaskItem: PropTypes.func,
  onAddTaskItem: PropTypes.func,
  onDeleteTaskItem: PropTypes.func,
  onChangeTaskItemOwner: PropTypes.func,
  onCompleteTaskItem: PropTypes.func,
  onMoveTaskItem: PropTypes.func,
  onShowNotes: PropTypes.func,
  onHideNotes: PropTypes.func,
  onSubmitTaskNote: PropTypes.func,
  onEditNote: PropTypes.func,
  onCancelEditNote: PropTypes.func,
  onDeleteNote: PropTypes.func,
  taskProgress: PropTypes.number,
  taskProgressCounter: PropTypes.string,
  taskNotes: PropTypes.arrayOf(PropTypes.object),
  taskItems: PropTypes.arrayOf(PropTypes.object),
  valuationDetails: PropTypes.shape({
    date: PropTypes.string,
    status: PropTypes.string,
    reportId: PropTypes.number,
    fundIdstatus: PropTypes.number.isRequired,
    valuationId: PropTypes.number.isRequired,
  }),
  administrativeFeeAmount: PropTypes.string,
};

const defaultProps = {
  currentUserId: undefined,
  currentUserInitials: '',
  dependentTaskItems: [],
  editableTaskItem: undefined,
  fundUserList: [],
  isVisibleEditTaskItem: false,
  isVisibleNotes: false,
  lastTaskNotes: [],
  onSubmitTaskItem: undefined,
  onEditTaskItem: undefined,
  onCloseTaskItem: undefined,
  onAddTaskItem: undefined,
  onDeleteTaskItem: undefined,
  onChangeTaskItemOwner: undefined,
  onCompleteTaskItem: undefined,
  onMoveTaskItem: undefined,
  onShowNotes: undefined,
  onHideNotes: undefined,
  onSubmitTaskNote: undefined,
  onEditNote: undefined,
  onCancelEditNote: undefined,
  onDeleteNote: undefined,
  taskProgress: 0,
  taskProgressCounter: '',
  taskNotes: [],
  taskItems: [],
  valuationDetails: {
    date: '',
    status: '',
    reportId: null,
  },
  administrativeFeeAmount: undefined,
};

const TaskItems = (props) => {
  const {
    isVisibleEditTaskItem,
    editableTaskItem,
    onSubmitTaskItem,
    onEditTaskItem,
    onCloseTaskItem,
    onAddTaskItem,
    onDeleteTaskItem,
    fundUserList,
    onChangeTaskItemOwner,
    onCompleteTaskItem,
    dependentTaskItems,
    onMoveTaskItem,
    isVisibleNotes,
    onShowNotes,
    onHideNotes,
    taskProgress,
    taskProgressCounter,
    taskNotes,
    lastTaskNotes,
    onSubmitTaskNote,
    onEditNote,
    onCancelEditNote,
    onDeleteNote,
    currentUserInitials,
    currentUserId,
    taskItems,
    valuationDetails,
    administrativeFeeAmount,
  } = props;

  return (
    <>
      <ValuationInfo {...valuationDetails} />

      <Row gutter={16}>
        <Col span={14}/>
        <Col span={10}>
          <div
            style={{
              padding: '16px 24px',
              background: '#fff',
              fontWeight: 'bold',
              border: '1px solid #e8e8e8',
            }}
          >
            Administrative Fee: {administrativeFeeAmount}
          </div>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={14}>
          {isEmpty(taskNotes) ? (
            <>
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              <Button onClick={onShowNotes}>Add Notes</Button>
            </>
          ) : (
              <Card
                title="Last Notes"
                actions={[<Button onClick={onShowNotes}>{`Add / Show All (${taskNotes.length})`}</Button>]}
              >
                <Notes
                  data={lastTaskNotes}
                  onEdit={onEditNote}
                  onCancel={onCancelEditNote}
                  onDelete={onDeleteNote}
                  pagination={false}
                  touchable={false}
                />

                {/* eslint-disable-next-line react/prop-types */}
              </Card>
            )}
        </Col>
        <Col span={10}>
          <Card title="Task Progress">
            <Row gutter={16}>
              <Col span={{ lg: 14 }}>
                <Progress type="circle" percent={taskProgress} />
              </Col>
              <Col span={{ lg: 14 }}>
                <Statistic title="Completed" value={taskProgressCounter} />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>

      <Drawer
        title="Notes"
        placement="right"
        width="50%"
        closable={false}
        onClose={onHideNotes}
        visible={isVisibleNotes}
        bodyStyle={{ paddingBottom: 80 }}
        destroyOnClose
      >
        <FinalForm
          component={AddNoteForm}
          onSubmit={onSubmitTaskNote}
          author={currentUserInitials}
          subscription={{
            pristine: true,
            hasSubmitErrors: true,
            submitting: true,
            submitError: true,
            initialValues: true,
          }}
        />
        {!isEmpty(taskNotes) && (
          <Notes
            data={taskNotes}
            onEdit={onEditNote}
            onCancel={onCancelEditNote}
            onDelete={onDeleteNote}
            onSubmit={onSubmitTaskNote}
            isAllowed={(authorId) => currentUserId === authorId}
          />
        )}
        <div
          style={{
            position: 'absolute',
            zIndex: 999999,
            left: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
          }}
        >
          <Button onClick={onHideNotes}>Close</Button>
        </div>
      </Drawer>

      <PageHeader title="Tasks" extra={[<Button onClick={onAddTaskItem}>Add Task</Button>]} />

      {isEmpty(taskItems) ? (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      ) : (
          <TaskItemsTable
            currentUserId={currentUserId}
            dataSource={taskItems}
            onComplete={onCompleteTaskItem}
            onChangeOwner={onChangeTaskItemOwner}
            onEdit={onEditTaskItem}
            onDelete={onDeleteTaskItem}
            onMoveRow={onMoveTaskItem}
            ownerOptions={fundUserList}
          />
        )}
      <Drawer
        title="Task"
        width="50%"
        placement="right"
        closable={false}
        onClose={onCloseTaskItem}
        visible={isVisibleEditTaskItem}
        bodyStyle={{ paddingBottom: 80 }}
        destroyOnClose
      >
        <FinalForm
          component={TaskItemForm}
          initialValues={editableTaskItem}
          onSubmit={onSubmitTaskItem}
          dependentItemOptions={dependentTaskItems}
          subscription={{
            pristine: true,
            hasSubmitErrors: true,
            submitting: true,
            submitError: true,
            initialValues: true,
          }}
        />
        <div
          style={{
            position: 'absolute',
            zIndex: 999999,
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
          }}
        >
          <Button type="default" onClick={onCloseTaskItem}>
            Close
          </Button>
        </div>
      </Drawer>
    </>
  );
};

const mapStateToProps = (state, { match: { path, params } }) => {
  const { id } = params;
  const editableTaskItemId = path === '/funds/:fundId/valuations/:valuationId/task_items/:id/edit' && id;

  const isVisibleEditTaskItem =
    path === '/funds/:fundId/valuations/:valuationId/task_items/:id/edit' ||
    path === '/funds/:fundId/valuations/:valuationId/task_items/new';

  const isVisibleNotes = path === '/funds/:fundId/valuations/:valuationId/task_items/task_notes';
  const taskItems = taskItemsSelector(state);
  const editableTaskItem = editableTaskItemSelector(editableTaskItemId, taskItems);
  const taskNotes = getTaskNotes(state);
  const lastTaskNotes = getLastTaskNotes(state);
  const completedTasksCount = getCompletedTasksCount(state);

  return {
    currentUserId: state.user.id,
    currentUserInitials: state.user.initials,
    taskItems: getTableTaskItems(taskItems),
    taskProgress: Math.round((100 / taskItems.length) * completedTasksCount),
    taskProgressCounter: `${completedTasksCount}/${taskItems.length}`,
    isVisibleEditTaskItem,
    isVisibleNotes,
    taskNotes,
    lastTaskNotes,
    editableTaskItem,
    dependentTaskItems: getDependentTaskItems(taskItems, editableTaskItem),
    fundUserList: [{ id: null, name: 'None' }, ...getFundUsers(state)],
    valuationDate: state.fund.valuation.details.date,
    valuationStatus: state.fund.valuation.details.status,
    valuationDetails: state.fund.valuation.details,
    administrativeFeeAmount: state.fund.valuation.administrativeFeeAmount
  };
};

const mapDispatchToProps = (dispatch, { match: { path, params } }) => {
  const { id, fundId, valuationId } = params;
  const editableTaskItemId = path === '/funds/:fundId/valuations/:valuationId/task_items/:id/edit' && id;

  return {
    onSubmitTaskItem: editableTaskItemId
      ? bindActionCreators(
        (values) => updateTaskItem(values, { fundId, valuationId, id: editableTaskItemId }),
        dispatch,
      )
      : bindActionCreators((values) => createTaskItem(values, { fundId, valuationId }), dispatch),
    onEditTaskItem: bindActionCreators((taskItemId) => editTaskItem(fundId, valuationId, taskItemId), dispatch),
    // eslint-disable-next-line max-len
    onCloseTaskItem: bindActionCreators(() => closeTaskItem(fundId, valuationId), dispatch),
    onAddTaskItem: bindActionCreators(() => newTaskItem(fundId, valuationId), dispatch),
    onDeleteTaskItem: bindActionCreators(
      (taskItemId) => deleteTaskItem({ fundId, valuationId, id: taskItemId }),
      dispatch,
    ),
    onChangeTaskItemOwner: bindActionCreators(
      ({ ownerId }, taskItemId) => changeTaskItemOwner({ ownerId }, { fundId, valuationId, id: taskItemId }),
      dispatch,
    ),
    onCompleteTaskItem: bindActionCreators(
      ({ completed }, taskItemId) => completeTaskItem({ completed }, { fundId, valuationId, id: taskItemId }),
      dispatch,
    ),
    onMoveTaskItem: bindActionCreators(
      (taskItemId, itemPosition, index) =>
        changeTaskItemPosition(itemPosition, index, {
          fundId,
          valuationId,
          id: taskItemId,
        }),
      dispatch,
    ),
    onShowNotes: bindActionCreators(() => showNotes(fundId, valuationId), dispatch),
    onHideNotes: bindActionCreators(() => hideNotes(fundId, valuationId), dispatch),
    // eslint-disable-next-line no-shadow
    onSubmitTaskNote: bindActionCreators((values) => submitTaskNote(values, { fundId, valuationId }), dispatch),
    onEditNote: bindActionCreators((noteId) => editNote(noteId), dispatch),
    onCancelEditNote: bindActionCreators(cancelEditNote, dispatch),
    onDeleteNote: bindActionCreators((noteId) => deleteNote(fundId, valuationId, noteId), dispatch),
  };
};

TaskItems.propTypes = propTypes;
TaskItems.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(TaskItems);
