export const isDestroyed = item => _.has(item, '_destroy') && item._destroy === true;

export const isUpdated = item => _.has(item, '_updated') && item._updated === true;

export const isCreated = item => _.has(item, '_created') && item._created === true;

export const getUniqueKey = (currentKeys) => {
  function generateNum() {
    const randNumber = Math.floor(Math.random() * 3000) + 1;

    if (currentKeys.indexOf(randNumber) === -1) {
      return randNumber;
    }

    return generateNum();
  }

  return generateNum();
};
