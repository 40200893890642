import { createSelector } from 'reselect';

const chartsMappingsSelector = state => state.chartsMappings;

export const filteredMappingsBySecurityTypes = createSelector(
  chartsMappingsSelector,
  (chartsMappings) => {
    const { mappings, filtered } = chartsMappings;

    if (_.isEmpty(filtered)) {
      return mappings;
    }

    return _.filter(mappings, (mapping) => mapping.securityKlass == null || _.find(filtered, { value: mapping.securityKlass }));
  },
);
