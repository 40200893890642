import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { get } from 'lodash';

import ChartsAccountForm from './components/Form';
import * as actions from './actions';

const mapStateToProps = (state, ownProps) => {
  const activeChart = state.chartOfAccounts.activeChart;
  const taxAttributeOptions = get(state.chartOfAccounts.entities.charts[activeChart], 'taxAttributes') || undefined;
  const investorOptions = state.resourceFund && state.resourceFund.accounts.map(account => ({
    label: account.code,
    value: account.id,
  }));

  return {
    account: state.chartsAccountForm.data,
    meta: {
      errors: get(state.chartsAccountForm.meta, 'errors') || {},
      submitting: get(state.chartsAccountForm.meta, 'submitting') || false,
    },
    refs: {
      ...ownProps.refs,
      taxAttributeOptions: (taxAttributeOptions && taxAttributeOptions.length > 0) ? taxAttributeOptions : undefined,
      investorOptions,
    },
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChartsAccountForm);
