import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import { isEmpty, lensPath, has, view } from 'ramda';
import { Icon } from 'antd';

import { updatePaymentItemsEditableKeys, setPaymentItemsEditableKeyFalse } from 'actions/funds/accruals';
import { paymentsItemPropTypes } from 'propTypes';
import { getAccrualEditableKeys, getPaymentItemIndex, getPaymentItemFieldInitialValue } from 'selectors/funds/accruals';
import { getPaymentsScheduleSubmitErrors, formDirtySinceLastSubmitSelector } from 'selectors/forms';
import FormGroup from 'components/shared/form/FormGroup';
import dateAddon from 'components/shared/form/inputs/addons';
import handleEnterPreventDefault from 'utils/preventDefault';
import { validateDate } from 'utils/validates';
import { currencyWithoutCommaFormat, dateFormat } from 'utils/formats';

const propTypes = {
  addonBefore: PropTypes.oneOfType([PropTypes.string, PropTypes.any]).isRequired,
  children: PropTypes.arrayOf(PropTypes.any),
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  fieldName: PropTypes.string.isRequired,
  formatField: PropTypes.func.isRequired,
  formFieldValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  handleEditableKeysUpdate: PropTypes.func.isRequired,
  handleSetEditableKeyFalse: PropTypes.func.isRequired,
  inputType: PropTypes.string,
  isEditable: PropTypes.bool,
  isEditedNow: PropTypes.bool,
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  record: PropTypes.shape({ paymentsItemPropTypes }),
  type: PropTypes.string,
  typeOfMask: PropTypes.string.isRequired,
  validate: PropTypes.oneOfType([PropTypes.bool, PropTypes.func, PropTypes.string]),
};

const defaultProps = {
  children: undefined,
  errorMessage: false,
  formFieldValue: undefined,
  inputType: undefined,
  isEditable: false,
  isEditedNow: false,
  initialValue: undefined,
  record: undefined,
  type: undefined,
  validate: undefined,
};

function RenderCell(props) {
  const {
    addonBefore,
    children,
    errorMessage,
    fieldName,
    formatField,
    formFieldValue,
    handleEditableKeysUpdate,
    handleSetEditableKeyFalse,
    inputType,
    initialValue,
    isEditable,
    isEditedNow,
    record,
    type,
    typeOfMask,
    validate,
  } = props;

  const [fieldValue, setFieldValue] = useState(formFieldValue);

  const wrapperClass = classNames({
    table__editing_wrap: true,
    'table__editing_wrap-error': record.error,
  });

  const cellValuesClass = classNames({
    'editable-cell-value-wrap': true,
    table__editing_value: isEditable,
    'table__editing_value-currency': type !== 'date',
  });

  const handleChange = (event) => {
    if (event && has('target', event)) {
      return setFieldValue(event.target.value);
    }
    const date = dateFormat(event);
    return setFieldValue(date);
  };

  return (
    <td className={wrapperClass}>
      {isEditable ? (
        <>
          {isEditedNow ? (
            <div className="table__editing_cell">
              <Field name={fieldName} parse={formatField}>
                {({ input, meta }) => (
                  <>
                    <FormGroup
                      addonBefore={addonBefore}
                      allowClear={false}
                      customOnChange={handleChange}
                      input={input}
                      meta={meta}
                      type={inputType}
                      typeOfMask={typeOfMask}
                      label={false}
                      onKeyDown={handleEnterPreventDefault}
                      validate={validate}
                      value={fieldValue}
                      autoFocus
                    />
                    <Icon
                      type="close"
                      className="table__editing_check"
                      onClick={() => {
                        input.onChange(initialValue);
                        setFieldValue(initialValue);
                        handleSetEditableKeyFalse();
                      }}
                    />
                  </>
                )}
              </Field>
              {errorMessage && <span className="table__editing_error">{errorMessage}</span>}
            </div>
          ) : (
              <>
                <div
                  className={cellValuesClass}
                  onClick={handleEditableKeysUpdate}
                  onKeyDown={handleEnterPreventDefault}
                  role="button"
                  tabIndex={record.key}
                >
                  {children}
                </div>
              </>
            )}
        </>
      ) : (
          <div className={cellValuesClass}>{children}</div>
        )}
    </td>
  );
}

const mapStateToProps = (state, ownProps) => {
  const { dataIndex, editable, type, record } = ownProps;
  const index = getPaymentItemIndex(record.id, state);
  const addonBefore = type === 'date' ? dateAddon : state.resourceFund.currencySymbol;
  const dirtySinceLastSubmit = formDirtySinceLastSubmitSelector(state, { name: 'paymentSchedule' });
  const errorKeys = record.error ? Object.keys(record.error) : [];
  const errorsValues = errorKeys.filter((item) => item === dataIndex);
  const errorMessage = !isEmpty(errorsValues) && record.error[dataIndex][0];
  const editableKeyLens = lensPath([[record.key], dataIndex]);
  const editableKeys = getAccrualEditableKeys(state);
  const submitErrors = getPaymentsScheduleSubmitErrors(state);
  const fieldName = `paymentItems.${index}.${dataIndex}`;
  const inputType = type === 'date' ? 'datePicker' : 'text';
  const isEditable = !!(editable && record.id && !record.approvedPeriod && !record.lockedPeriod);
  const isEditedNow = view(editableKeyLens, editableKeys) || errorMessage;
  const typeOfMask = type === 'date' ? 'date' : 'currency';
  const validate = type === 'date' ? validateDate() : undefined;
  const initialValue = record[dataIndex];
  const formFieldValue = getPaymentItemFieldInitialValue(record.id, dataIndex, state);

  const formatField = (value) => {
    if (type === 'date') {
      return dateFormat(value);
    }
    return currencyWithoutCommaFormat(value);
  };

  return {
    addonBefore,
    dirtySinceLastSubmit,
    errorMessage,
    formFieldValue,
    fieldName,
    formatField,
    inputType,
    initialValue,
    isEditedNow,
    isEditable,
    submitErrors,
    typeOfMask,
    validate,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { dataIndex, record } = ownProps;

  return {
    handleEditableKeysUpdate: () => dispatch(updatePaymentItemsEditableKeys(dataIndex, record.key)),
    handleSetEditableKeyFalse: () => dispatch(setPaymentItemsEditableKeyFalse(dataIndex, record.key, record.id)),
  };
};

RenderCell.propTypes = propTypes;
RenderCell.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(RenderCell);
