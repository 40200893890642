import PropTypes from 'prop-types';

const formShape = {
  submit: PropTypes.func,
  reset: PropTypes.func,
  focus: PropTypes.func,
  change: PropTypes.func,
  blur: PropTypes.func,
};

export default formShape;
