import React from 'react';
import PropTypes from 'prop-types';
import FontAwesome from 'react-fontawesome';
import humps from 'humps';
import { get, snakeCase } from 'lodash';

import { ChartOfAccounts, Dock, FormAlerts, Select, RadioGroup, SelectChartOfAccount } from 'components/shared';

import { getNamespaceOptions, getEventOptions, getTransactionKeyOptions } from '../selectors';

const propTypes = {
  mappingForm: PropTypes.object.isRequired,
  chartOfAccountsIsVisible: PropTypes.bool.isRequired,
  actions: PropTypes.object.isRequired,
  refs: PropTypes.object.isRequired,
  fundId: PropTypes.number,
  changeMode: PropTypes.func.isRequired,
};

const defaultProps = {
  fundId: undefined,
};

function MappingForm(props) {
  const { changeMode, mappingForm, chartOfAccountsIsVisible, fundId, refs } = props;
  const {
    data,
    meta: { errors, success, submitting },
  } = mappingForm;
  const {
    onSubmit,
    clearMapping,
    changeInput,
    changeEvent,
    changeNamespace,
    selectAccount,
    toggleChartOfAccounts,
  } = props.actions;

  const camelizeField = fundId ? humps.camelize('chart_account_id') : humps.camelize('account_pattern_id');

  return (
    <div className="panel__body panel__body-padded">
      <div className="row">
        <div className="col-md-8">
          <form onSubmit={(e) => onSubmit(e)} className="form" noValidate="novalidate">
            <h2 className="m-t-none">{data.id ? 'Edit Mapping' : 'Add Mapping'}</h2>

            <FormAlerts errors={errors} success={success} />
            <div className="row">
              <div className="col-md-4">
                <Select
                  id="namespace"
                  name="mapping[namespace]"
                  dataStateKey="data.namespace"
                  value={snakeCase(data.namespace)}
                  label="Namespace"
                  onChange={changeNamespace}
                  options={getNamespaceOptions(props)}
                  errors={get(errors, 'namespace')}
                  required
                />
              </div>

              {data.namespace && (
                <div className="col-md-4">
                  <Select
                    id="event"
                    name="mapping[event]"
                    dataStateKey="data.event"
                    value={snakeCase(data.event)}
                    label="Event"
                    includeBlank={!data.event}
                    onChange={changeEvent}
                    options={getEventOptions(props)}
                    errors={get(errors, 'event')}
                    required
                  />
                </div>
              )}
            </div>

            {data.namespace
              && data.event && (
                <div className="row">
                  <div className="col-md-8">
                    <Select
                      id="transaction_key"
                      name="mapping[transaction_key]"
                      dataStateKey="data.transactionKey"
                      value={snakeCase(data.transactionKey)}
                      label="Transaction Key"
                      includeBlank={!data.transactionKey}
                      onChange={changeInput}
                      options={getTransactionKeyOptions(props)}
                      errors={get(errors, 'transactionKey')}
                      required
                    />
                  </div>
                </div>
            )}

            {data.namespace
              && data.namespace === 'trade' && (
                <div className="row">
                  <div className="col-md-4">
                    <Select
                      id="security_klass"
                      name="mapping[security_klass]"
                      includeBlank
                      dataStateKey="data.securityKlass"
                      value={data.securityKlass}
                      label="Security"
                      onChange={changeInput}
                      options={refs.securityTypes}
                      errors={get(errors, 'securityKlass')}
                    />
                  </div>
                </div>
            )}

            <SelectChartOfAccount
              field={camelizeField}
              selectedAccountId={data[camelizeField]}
              errors={get(errors, fundId ? 'chartAccount' : 'accountPattern')}
            />

            {!fundId && data.namespace && (
              <RadioGroup
                id="applyToExistingFunds"
                name="mapping[apply_to_existing_funds]"
                inline
                dataStateKey="data.applyToExistingFunds"
                value={data.applyToExistingFunds || 'false'}
                label="Update existing mapping?"
                onChange={changeInput}
                options={[{ label: 'Yes', value: 'true' }, { label: 'No', value: 'false' }]}
                errors={get(errors, 'applyToExistingFunds')}
              />
            )}

            <div className="m-t-sm">
              <button type="submit" className="btn btn-primary" disabled={submitting}>
                {submitting ? (
                  <FontAwesome name="spinner" spin />
                ) : data.id ? (
                  I18n.t('buttons.update')
                ) : (
                  I18n.t('buttons.create')
                )}
              </button>

              <a
                className="btn btn-link"
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  clearMapping();
                  changeMode('table');
                }}
              >
                Cancel
              </a>
            </div>
          </form>

          <Dock id="dock" isVisible={chartOfAccountsIsVisible} onVisibleChange={toggleChartOfAccounts}>
            <ChartOfAccounts onSelectAccount={selectAccount} fundId={fundId} refs={refs} />
          </Dock>
        </div>
      </div>
    </div>
  );
}

MappingForm.propTypes = propTypes;
MappingForm.defaultProps = defaultProps;

export default MappingForm;
