import humps from 'humps';
import axios from 'axios';
import { get } from 'lodash';
import numeral from 'numeral';

import scrollToFormAlerts from 'utils/scrollToFormAlerts';
import { stringToDate } from 'utils/dateTransformations';
import { onAddCategory, onUpdateCategory, onDeleteCategory } from 'components/shared/ChartOfAccounts/actions';
import * as types from './actionTypes';

export function fillFormCategory(data) {
  return {
    type: types.CHARTS_CATEGORY_FORM_FILL,
    payload: {
      data,
    },
  };
}

export function clearCategory() {
  return {
    type: types.CHARTS_CATEGORY_CLEAR,
  };
}

export function changeInput(event) {
  const target = event.target ? event.target : event;
  const stateKey =
    target.getAttribute('data-statekey') ||
    target.name
      .replace(/]/g, '')
      .split('[')
      .join('.');
  const type = target.getAttribute('data-type') || target.type;
  const targetValue = target.getAttribute('data-value') || target.value;

  const value = () => {
    switch (type) {
      case 'date':
        return stringToDate(targetValue);

      case 'currency':
        return numeral(targetValue).value();

      case 'checkbox':
        return target.checked;

      case 'number':
        if (target.step === '1') {
          return isNaN(parseInt(targetValue, 10)) ? targetValue : parseInt(targetValue, 10);
        }

        return isNaN(parseFloat(targetValue)) ? targetValue : parseFloat(targetValue);

      default:
        return targetValue;
    }
  };

  return {
    type: types.CHARTS_CATEGORY_CHANGE_INPUT,
    payload: {
      stateKey,
      value: value(),
    },
  };
}

export function changeDateInput(event) {
  const target = event.target ? event.target : event;
  const value = target.value;
  const stateKey = target.getAttribute('data-statekey');

  return changeInputAction(
    stateKey ||
      target.name
        .replace(/]/g, '')
        .split('[')
        .join('.'),
    stringToDate(value),
  );
}

const changeInputAction = (stateKey, value) => ({
  type: types.CHARTS_CATEGORY_CHANGE_INPUT,
  payload: {
    stateKey,
    value,
  },
});

export function deleteCategory(category, url) {
  return (dispatch) => {
    axios
      .request({
        url,
        method: 'delete',
        withCredentials: true,
        xsrfHeaderName: 'X-CSRF-Token',
      })
      .then(() => {
        dispatch(onDeleteCategory(category));
      });
  };
}

export function onSubmit(e, fundId = undefined) {
  return (dispatch, getState) => {
    const { data } = getState().chartsCategoryForm;
    e.preventDefault();

    const formData = {
      category: humps.decamelizeKeys(data),
    };
    dispatch(
      fundId
        ? data.id
          ? submitForm(formData, Routes.fund_charts_category_path(fundId, data.id, { format: 'json' }), 'put')
          : submitForm(formData, Routes.fund_charts_categories_path(fundId, { format: 'json' }), 'post')
        : data.id
          ? submitForm(formData, Routes.charts_category_path(data.id, { format: 'json' }), 'put')
          : submitForm(formData, Routes.charts_categories_path({ format: 'json' }), 'post'),
    );
  };
}

function submitForm(data, url, method) {
  return (dispatch) => {
    dispatch(submittingForm(true));

    axios
      .request({
        url,
        method,
        data,
        withCredentials: true,
        xsrfHeaderName: 'X-CSRF-Token',
      })
      .then((res) => {
        dispatch(submittingForm(false));

        if (method === 'post') {
          dispatch(submittingCreateFormSucceded(humps.camelizeKeys(get(res.data.payload, 'category'))));
        } else {
          dispatch(submittingUpdateFormSucceded(humps.camelizeKeys(get(res.data.payload, 'category'))));
        }
      })
      .catch((res) => {
        dispatch(submittingForm(false));
        dispatch(submittingFormFailed(humps.camelizeKeys(get(res.response.data.payload, 'errors'))));
        scrollToFormAlerts('dock');
      });
  };
}

function submittingForm(bool) {
  return {
    type: types.CHARTS_CATEGORY_SUBMITTING_FORM,
    payload: {
      submitting: bool,
    },
  };
}

function submittingCreateFormSucceded(category = {}) {
  return (dispatch) => {
    dispatch(onAddCategory(category));

    dispatch({
      type: types.CHARTS_CATEGORY_SUBMITTING_FORM_SUCCEEDED,
    });

    dispatch(clearCategory());
  };
}

function submittingUpdateFormSucceded(category = {}) {
  return (dispatch) => {
    dispatch(onUpdateCategory(category));

    dispatch({
      type: types.CHARTS_CATEGORY_SUBMITTING_FORM_SUCCEEDED,
    });

    dispatch(clearCategory());
  };
}

function submittingFormFailed(data = {}) {
  return {
    type: types.CHARTS_CATEGORY_SUBMITTING_FORM_FAILED,
    payload: {
      errors: data,
    },
  };
}
