export * from 'constants/actionTypes';

export const SECURITY_UPDATE = 'SECURITY_UPDATE';
export const TRADE_DATE_UPDATE = 'TRADE_DATE_UPDATE';
export const MAKE_SETTLE_FX_RATE_EQUAL_TO_TRADE_FX_RATE = 'MAKE_SETTLE_FX_RATE_EQUAL_TO_TRADE_FX_RATE';

export const CLEAR_SECURITY_DATA = 'CLEAR_SECURITY_DATA';
export const CLEAR_REALIZATIONS_DATA = 'CLEAR_REALIZATIONS_DATA';
export const REALIZATIONS_FETCH_REQUESTED = 'REALIZATIONS_FETCH_REQUESTED';
export const REALIZATIONS_FETCH_SUCCEEDED = 'REALIZATIONS_FETCH_SUCCEEDED';
export const REALIZATIONS_FETCH_FAILED = 'REALIZATIONS_FETCH_FAILED';

export const SECURITIES_FETCH_REQUESTED = 'SECURITIES_FETCH_REQUESTED';
export const SECURITIES_FETCH_SUCCEEDED = 'SECURITIES_FETCH_SUCCEEDED';
export const SECURITIES_FETCH_FAILED = 'SECURITIES_FETCH_FAILED';

export const CURRENT_HOLDINGS_FOR_SECURITY_FETCH_REQUESTED = 'CURRENT_HOLDINGS_FOR_SECURITY_FETCH_REQUESTED';
export const CURRENT_HOLDINGS_FOR_SECURITY_FETCH_COMPLETED = 'CURRENT_HOLDINGS_FOR_SECURITY_FETCH_COMPLETED';

export const CURRENT_HOLDINGS_FOR_UNDERLYING_INVESTMENT_FETCH_REQUESTED = 'CURRENT_HOLDINGS_FOR_UNDERLYING_INVESTMENT_FETCH_REQUESTED';
export const CURRENT_HOLDINGS_FOR_UNDERLYING_INVESTMENT_FETCH_COMPLETED = 'CURRENT_HOLDINGS_FOR_UNDERLYING_INVESTMENT_FETCH_COMPLETED';

export const SET_FX_RATE = 'SET_FX_RATE';
export const CONVERT_FEES_BY_FX_RATE = 'CONVERT_FEES_BY_FX_RATE';