import React from 'react';
import { Button, Icon, Tooltip, Popconfirm, message } from 'antd';

import { dateFormat, decimalCurrencySymbolFormat } from 'utils/formats';
import BlankLink from '../../../../shared/BlankLink';

export default (accounts, fundType, currencySymbol, onDeleteAccount, openInviteModal) => {
  const data = [
    {
      title: 'Investor Class',
      dataIndex: 'investorClassName',
      width: '25%',
      ellipsis: true,
    },
    {
      title: 'Code',
      dataIndex: 'code',
      align: 'center',
      ellipsis: true,
      width: 60,
      render: (value, item) => <BlankLink title={value} link={Routes.fund_account_path(item.fundId, item.id)} />,
    },
    {
      title: 'Investor',
      dataIndex: 'investor.displayName',
      ellipsis: true,
      width: '10%',
      render: (value, item) => <BlankLink title={value} link={Routes.contact_path(item.investorId)} />,
    },
    {
      title: 'Investor Series',
      dataIndex: 'investorSeriesName',
      width: '10%',
      ellipsis: true,
    },
  ];

  if (fundType !== 'Share Based' && fundType !== 'PE Share Based') {
    data.push({
      title: 'HWM',
      dataIndex: 'lastCalculatedHwm',
      align: 'right',
      ellipsis: true,
      render: (value) => decimalCurrencySymbolFormat(value, currencySymbol),
    });
  }

  data.push({
    title: fundType === 'Share Based' || fundType === 'Partnership' ? 'Capital Balance' : 'Commitment Amount',
    dataIndex: 'lastCalculatedCapitalBalance',
    align: 'right',
    ellipsis: true,
    render: (value, item) => (
      <span title={`${decimalCurrencySymbolFormat(value, currencySymbol)} on ${dateFormat(item.lastReportDate)}`}>
        {decimalCurrencySymbolFormat(value, currencySymbol)}{' '}
        <Tooltip placement="top" title={`on ${dateFormat(item.lastReportDate)}`}>
          <Icon type="info-circle" theme="twoTone" />
        </Tooltip>
      </span>
    ),
  });

  if (fundType === 'Share Based' || fundType === 'PE Share Based') {
    data.push({
      title: 'Shares Amount',
      dataIndex: 'sharesAmount',
      align: 'right',
      ellipsis: true,
      render: (value, item) => (
        <span title={`${decimalCurrencySymbolFormat(value, currencySymbol)} on ${dateFormat(item.lastReportDate)}`}>
          {decimalCurrencySymbolFormat(value, currencySymbol)}{' '}
          <Tooltip placement="top" title={`on ${dateFormat(item.lastReportDate)}`}>
            <Icon type="info-circle" theme="twoTone" />
          </Tooltip>
        </span>
      ),
    });
  }

  data.push(
    ...[
      {
        title: 'Open Date',
        dataIndex: 'openDate',
        align: 'center',
        ellipsis: true,
        render: (value) => dateFormat(value),
      },
      {
        title: 'Close Date',
        dataIndex: 'closeDate',
        align: 'center',
        ellipsis: true,
        render: (value) => dateFormat(value),
      },
    ],
  );

  if (fundType === 'Partnership' || fundType === 'PE Partnership') {
    data.push({
      title: 'GP',
      dataIndex: 'generalPartner',
      align: 'center',
      ellipsis: true,
      width: 60,
      render: (value) => (value ? <Icon type="check" title="Yes" /> : null),
    });
  }

  data.push({
    title: <Button onClick={() => openInviteModal()}>Invite to Portal</Button>,
    key: 'actions',
    align: 'right',
    width: 170,
    render: (item) => (
      <>
        <Button.Group>
          <Button onClick={() => openInviteModal(item)}>Invite</Button>
          <Button icon="eye" href={Routes.fund_account_path(item.fundId, item.id)} />
          <Button icon="edit" href={Routes.edit_fund_account_path(item.fundId, item.id)} />
          <Popconfirm
            placement="left"
            title="Are you sure you want to delete this account?"
            onConfirm={() => {
              onDeleteAccount(item.id);
              return message.info('Deleted.');
            }}
            okText="Confirm"
            cancelText="Cancel"
            disabled={!item.deletable}
          >
            <Button icon="delete" disabled={!item.deletable} />
          </Popconfirm>
        </Button.Group>
      </>
    ),
  });

  return data;
};
