import React from 'react';
import PropTypes from 'prop-types';

import { CurrencyInput, PercentageInput } from 'components/shared';

import AccountItem from './AccountItem';

const propTypes = {
  investorSeries: PropTypes.object.isRequired,
  fundId: PropTypes.number.isRequired,
};

function InvestorSeriesItem(props) {
  const { investorSeries, fundId } = props;

  const AccountItems = investorSeries.accounts.map((account) => (
    <AccountItem key={account.id} account={account} fundId={fundId} />
  ));

  return (
    <li className="tree__item tree__item-sub">
      <div className="row">
        <div className="col-md-3">
          <input checked type="checkbox" /> {investorSeries.name}
        </div>

        <div className="col-md-3 col-md-offset-2">
          <CurrencyInput
            id={`allocated_amounts${investorSeries.id}`}
            name={'allocated_amounts[amount][]'}
            required
            dataStateKey={`data.allocatedAmounts.${investorSeries.id}.amount`}
            value={investorSeries.amount}
            onChange={() => false}
          />
        </div>

        <div className="col-md-3">
          <PercentageInput
            id={`allocated_amounts${investorSeries.id}`}
            name={'allocated_amounts[amountPercent][]'}
            required
            dataStateKey={`data.allocatedAmounts.${investorSeries.id}.amountPercent`}
            value={'0'}
            onChange={() => false}
          />
        </div>
        {/* AccountItems.length > 0 &&
          <ul className='tree tree-sub'>
            { AccountItems }
          </ul>
        */}
      </div>
    </li>
  );
}

InvestorSeriesItem.propTypes = propTypes;

export default InvestorSeriesItem;
