import set from 'lodash/fp/set';
import * as types from './actionTypes';

const initialState = {};

export default function(state = initialState, action) {
  switch (action.type) {
    case types.MAPPING_CLEAR:
      return state;

    case types.MAPPING_FORM_FILL:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload.data,
        },
      };

    case types.MAPPING_CHANGE_INPUT:
      return set(action.payload.stateKey, action.payload.value)(state);

    case types.MAPPING_TRANSACTION_TYPE_UPDATE:
      return {
        ...state,
        data: {
          ...state.data,
          transactionKlass: action.payload.value,
          transactionType: undefined,
        },
      };

    case types.MAPPING_SUBMITTING_FORM:
      return {
        ...state,
        meta: {
          submitting: action.payload.submitting,
        },
      };

    case types.MAPPING_SUBMITTING_FORM_SUCCEEDED:
      return {
        ...state,
        meta: {
          errors: null,
        },
      };

    case types.CHARTS_ACCOUNT_SELECT:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.field]: action.payload.value,
        },
      };

    case types.MAPPING_SUBMITTING_FORM_FAILED:
      return {
        ...state,
        meta: {
          errors: action.payload.errors,
        },
      };

    case types.UPDATE_SECURITY_KLASS:
      return {
        ...state,
        data: {
          ...state.data,
          securityKlass: action.payload.value,
        },
      };

    case types.CLEAR_EVENT_AND_TRANSACTION_KEY:
      return {
        ...state,
        data: {
          ...state.data,
          event: action.payload.event || null,
          transactionKey: action.payload.transactionKey || null,
        },
      };

    case types.CLEAR_TRANSACTION_KEY:
      return {
        ...state,
        data: {
          ...state.data,
          transactionKey: action.payload.transactionKey || null,
        },
      };

    default:
      return state;
  }
}
