import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import { SelectAsync } from 'components/shared';

const propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  loadOptions: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  label: PropTypes.string,
  required: PropTypes.bool,
  includeBlank: PropTypes.bool,
  prompt: PropTypes.string,
  errors: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  clearable: PropTypes.bool,
  autoload: PropTypes.bool,
  valueKey: PropTypes.string.isRequired,
  labelKey: PropTypes.string.isRequired,
  noResultsText: PropTypes.string,
  optionClassName: PropTypes.string
};

const defaultProps = {
  includeBlank: false,
  prompt: 'Please select…',
  value: '',
  label: null,
  required: false,
  errors: null,
  onChange: null,
  disabled: false,
  clearable: false,
  autoload: true,
  noResultsText: 'No results',
  optionClassName: 'select__option--contact'
};


const ContactList = (props) => {
  const { id, name, value, label, required, onChange, errors, includeBlank, prompt, disabled, loadOptions, clearable, noResultsText, valueKey, labelKey, autoload, optionClassName, optionKey } = props;

  const optionRenderer = (item, index) => {
    const contact = optionKey ? get(item, optionKey) : item;
    const size = 37;

    return (
      <div>
        { contact.shortName &&
          <span className="selectize__avatar">
            <div style={{lineHeight: size + "px", fontSize: size/2 + "px", width: size + "px", height: size + "px", minWidth: size + "px"}} className="avatar avatar-txt">
              {contact.shortName}
            </div>
          </span>
        }

        { contact.displayName &&
          <span className="selectize__info">
            <span className="selectize__name">{contact.displayName}</span>
            <ul className="selectize__meta">
              { contact.email &&
                <li className="selectize__meta_item">
                  <span>{contact.email}</span>
                </li>
              }
            </ul>
          </span>
        }
      </div>
    );
  }

  return (
    <SelectAsync
      id={id}
      name={name}
      label={label}
      errors={errors}
      autoload={autoload}
      loadOptions={loadOptions}
      valueKey={valueKey}
      labelKey={labelKey}
      clearable={clearable}
      required={required}
      noResultsText={noResultsText}
      value={value}
      onChange={onChange}
      optionRenderer={optionRenderer}
      optionClassName={optionClassName}
      valueRenderer={(option) => get(option, labelKey)}
    />
  );
};

ContactList.propTypes = propTypes
ContactList.defaultProps = defaultProps;

export default ContactList
