import { filter, map, sort } from 'ramda';

import buildATree from 'utils/trees/buildATree';
import { deepMapArray } from 'utils/trees/deepMap';

const initialState = {
  collection: [],
};

const addDirectoryBtn = (key, parentId) => ({
  key,
  parentId,
  className: 'addDirBtn',
  title: '',
  isLeaf: true,
  isCreating: true,
  icon: () => null,
});
const appendButton = (arr, parent = {}) => [...arr, addDirectoryBtn(`${parent.key}-addDir`, parent.key || null)];

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case 'CREATE_DOCUMENTS_PATTERN_DIRECTORY': {
      const collection = [...state.collection, payload];
      return { ...state, collection }
    }
    case 'RENAME_DOCUMENTS_PATTERN_DIRECTORY': {
      const collection = [...state.collection.filter((item) => item.id !== payload.id), payload];
      return { ...state, collection }
    }
    case 'REMOVE_DOCUMENTS_PATTERN_DIRECTORY': {
      const collection = [...state.collection.filter((item) => item.id !== payload.id)];
      return { ...state, collection }
    }
    case 'BUILD_DIRECTORY_TREE': {
      const filteredCollection = filter((item) => Number(item.patternId) === Number(payload.id), state.collection);
      const idsToKeys = map(({ id, ...item }) => ({ key: id, ...item }), filteredCollection);
      const sortedCollection = sort((a, b) => a.key - b.key, idsToKeys);
      const tree = deepMapArray(appendButton, buildATree(sortedCollection, { idName: 'key', parentIdName: 'parentId' }), { idName: 'key' });
      return { ...state, tree }
    }
    default:
      return state;
  }
}
