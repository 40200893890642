import * as types from './actionTypes';
import { changeInput } from '../../../../actions/changeInput';

export const addBand = () => (dispatch, getState) => {
  const { bands } = getState();
  const band = lastBand(bands);

  dispatch({
    type: types.ADD_BAND,
    payload: {
      band: {
        id: undefined,
        lowerBoundPercentage: band ? band.upperBoundPercentage : 0,
        upperBoundPercentage: undefined,
        rate: undefined,
        _destroy: false,
      },
    },
  });
};

export const deleteBand = (e, position) => (dispatch) => {
  dispatch({
    type: types.DELETE_BAND,
    payload: {
      position,
    },
  });
};

export const changeLowerBoundPercentage = (e, position) => (dispatch, getState) => {
  dispatch(changeInput(e));

  const { bands } = getState();
  const band = prevBand(position, bands);

  dispatch({
    type: types.CHANGE_LOWER_BOUND_PERCENTAGE,
    payload: {
      index: band ? bands.indexOf(band) : undefined,
      value: e.target.value,
    },
  });
};

export const changeUpperBoundPercentage = (e, position) => (dispatch, getState) => {
  dispatch(changeInput(e));

  const { bands } = getState();
  const band = nextBand(position, bands);

  dispatch({
    type: types.CHANGE_UPPER_BOUND_PERCENTAGE,
    payload: {
      index: band ? bands.indexOf(band) : undefined,
      value: e.target.value,
    },
  });
};

const nextBand = (currentPosition, bands) => {
  if ((currentPosition + 1) === bands.length) return null;

  return bands[currentPosition + 1];
};

const prevBand = (currentPosition, bands) => {
  if (currentPosition === 0) return null;

  return bands[currentPosition - 1];
};

const lastBand = (bands) => {
  if (bands.length === 0) return null;

  return bands[bands.length - 1];
};

export * from '../../../../actions/changeInput';
