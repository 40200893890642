import React from 'react';
import PropTypes from 'prop-types';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { hot } from 'react-hot-loader';
import { get, reject } from 'lodash';

import withReduxProvider from 'components/hocs/withReduxProvider';
import { Dock } from 'components/shared';
import ChartOfAccountsContainer from 'components/shared/ChartOfAccounts';
import * as chartOfAccountsActions from 'components/shared/ChartOfAccounts/actions';

import configureStore from './store';
import { components, actions } from './index';

import { equalCreditAndDebitAmounts, creditAmount, debitAmount } from './calculateAmounts';

const propTypes = {
  resourceFund: PropTypes.object.isRequired,
  glEntryForm: PropTypes.object.isRequired,
  refs: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  chartOfAccountsActions: PropTypes.object.isRequired,
};

function glEntriesContainer(props) {
  const { resourceFund, glEntryForm, refs, actions, chartOfAccountsActions } = props;
  const { chartOfAccountsIsVisible } = glEntryForm.extra;

  return (
    <div>
      <components.glEntriesForm {...props} />
      <Dock
        id="dock"
        isVisible={chartOfAccountsIsVisible}
        onVisibleChange={chartOfAccountsActions.toggleChartOfAccounts}
      >
        <ChartOfAccountsContainer fundId={resourceFund.id} onDate={glEntryForm.data.accountingDate} refs={refs} onSelectAccount={actions.selectAccount} />
      </Dock>
    </div>
  );
}

const mapStateToProps = (state) => ({
  resourceFund: state.resourceFund,
  glEntryForm: {
    data: state.glEntryForm.data,
    meta: {
      errors: get(state.glEntryForm.meta, 'errors') || {},
      submitting: get(state.glEntryForm.meta, 'submitting') || false,
      entryChanged: get(state.glEntryForm.meta, 'entryChanged') || false,
      entryNeedBeUpdated: get(state.glEntryForm.meta, 'entryNeedBeUpdated') || false,
    },
    extra: {
      equalCreditAndDebitAmounts: equalCreditAndDebitAmounts(
        reject(state.glEntryForm.data.lineItems, (a) => a._destroy),
      ),
      creditAmount: creditAmount(reject(state.glEntryForm.data.lineItems, (a) => a._destroy)),
      debitAmount: debitAmount(reject(state.glEntryForm.data.lineItems, (a) => a._destroy)),
      security: state.security,
      chartOfAccountsIsVisible: state.chartOfAccounts.isVisible,
    },
  },
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
  chartOfAccountsActions: bindActionCreators(chartOfAccountsActions, dispatch),
});

glEntriesContainer.propTypes = propTypes;

export default withReduxProvider(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(hot(module)(glEntriesContainer)),
  configureStore,
);
