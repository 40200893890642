export const ROOT_PATH = '/';

export const CHARTS_PATH = '/charts';
export const CHARTS_MAPPINGS_PATH = '/charts/mappings';
export const NEW_CHARTS_MAPPING_PATH = '/charts/mappings/new';

export const FUNDS_PATH = '/funds';
export const FUND_CHARTS_PATH = '/funds/:id/charts';

export const NEW_CHARTS_CATEGORY_PATH = '/charts/categories/new';
export const EDIT_CHARTS_CATEGORY_PATH = '/charts/categories/:id/edit';

export const NEW_CHARTS_CATEGORY_ACCOUNT_PATH = '/charts/categories/:categoryId/accounts/new';
export const EDIT_CHARTS_CATEGORY_ACCOUNT_PATH = '/charts/categories/:categoryId/accounts/:id/edit';

export const NEW_FUND_CHARTS_CATEGORY_PATH = '/funds/:fundId/charts/categories/new';
export const EDIT_FUND_CHARTS_CATEGORY_PATH = '/funds/:fundId/charts/categories/:id/edit';

export const NEW_FUND_CHARTS_CATEGORY_ACCOUNT_PATH = '/funds/:fundId/charts/categories/:categoryId/accounts/new';
export const EDIT_FUND_CHARTS_CATEGORY_ACCOUNT_PATH = '/funds/:fundId/charts/categories/:categoryId/accounts/:id/edit';

export const NEW_FUND_ACCRUALS_PATH = '/funds/:fundId/accruals/new';
export const EDIT_FUND_ACCRUALS_PATH = '/funds/:fundId/accruals/:accrualId/edit';
export const SCHEDULE_FUND_ACCRUALS_PATH = '/funds/:fundId/accruals/:accrualId/schedule';

export const FUND_CHARTS_MAPPINGS_PATH = '/funds/:fundId/charts/mappings';
export const NEW_FUND_CHARTS_MAPPING_PATH = '/funds/:fundId/charts/mappings/new';

export const NEW_FUND_BATCHES_TRADES_PATH = '/funds/:fundId/batches/:batchId/trades/new';
export const NEW_FUND_BATCHES_TRADE_EXERCISES_PATH = '/funds/:fundId/batches/:batchId/trade_exercises/new';

export const FUND_PORTFOLIOS_PATH = '/funds/:fundId/portfolios';