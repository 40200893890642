import React, { Component } from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import classNames from 'classnames';

import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import { FormGroup } from '../';

const propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string,
  currency: PropTypes.string,
  name: PropTypes.string.isRequired,
  dataStateKey: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  hint: PropTypes.string,
  errors: PropTypes.array,
  className: PropTypes.string,
  decimalLimit: PropTypes.number,
  allowNegative: PropTypes.bool,
};

const defaultProps = {
  type: 'string',
  className: 'form__control',
  disabled: false,
  dataStateKey: undefined,
  value: '',
  placeholder: '',
  hint: undefined,
  errors: undefined,
  currency: '$',
  label: undefined,
  required: false,
  onChange: undefined,
  decimalLimit: 2,
  allowNegative: false,
};

class CurrencyInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stringValue: props.value,
      currencyMask: createNumberMask({
        allowDecimal: true,
        prefix: '',
        decimalLimit: props.decimalLimit,
        allowNegative: props.allowNegative,
      }),
    };
  }

  componentWillReceiveProps(nextProps) {
    const inputValue = this.input.inputElement.value;
    const stringValue = nextProps.value !== numeral(inputValue).value() ? nextProps.value : inputValue;

    return this.setState({
      stringValue,
    });
  }

  render() {
    const {
      id,
      type,
      name,
      dataStateKey,
      label,
      hint,
      required,
      placeholder,
      className,
      errors,
      onChange,
      disabled,
      currency,
    } = this.props;
    const addonClass = classNames({
      form__addon_icon: true,
      'form__addon_icon-disabled': disabled,
    });

    return (
      <FormGroup label={label} required={required} id={id} errors={errors}>
        <div className="form__addon form__addon-left">
          <span className={addonClass}>{currency || '$'}</span>
          <MaskedInput
            ref={(el) => (this.input = el)}
            id={id}
            type={type}
            required={required}
            name={name}
            data-statekey={dataStateKey}
            data-type="currency"
            guide={false}
            value={this.state.stringValue}
            placeholder={placeholder}
            mask={this.state.currencyMask}
            className={className}
            onChange={onChange}
            disabled={disabled}
          />
        </div>
        {hint && <p className="form__hint">{hint}</p>}
      </FormGroup>
    );
  }
}

CurrencyInput.propTypes = propTypes;
CurrencyInput.defaultProps = defaultProps;

export default CurrencyInput;
