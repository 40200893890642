import * as actionTypes from 'constants/actionTypes';
import { getPaymentsScheduleSubmitErrors } from 'selectors/forms';
import { prop } from 'ramda';

export default function updateFormState(form, state) {
  return {
    type: actionTypes.FORM_UPDATE,
    form,
    payload: state,
  };
}

export function updatePaymentsScheduleSubmitErrors(index, name) {
  return (dispatch, getState) => {
    const submitErrors = getPaymentsScheduleSubmitErrors(getState());
    const newSubmitErrors = submitErrors.filter((item) => item[name] !== prop(name, submitErrors[index]));

    dispatch({
      type: actionTypes.FORM_PAYMENTS_SCHEDULE_SUBMIT_ERRORS_UPDATE,
      payload: newSubmitErrors,
    });
  };
}
