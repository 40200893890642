import React from 'react';

import { SelectReact } from 'components/shared';

export default (props) => {
  const { options, filtered, onChange } = props;

  return (
    <div className="panel">
      <div className="panel__body panel__body-padded">
        <SelectReact
          name="security_types_multiselect"
          id="security_types_multiselect_id"
          multi
          clearable
          searchable={false}
          prompt={'Select security types to filter...'}
          options={options}
          value={filtered}
          onChange={onChange}
        />
      </div>
    </div>
  );
};
