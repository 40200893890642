import { CHART_OF_ACCOUNTS_TOGGLE } from 'constants/actionTypes/chartOfAccounts';
import { getIsVisibleChart } from 'selectors/chartOfAccounts';

export const toggleChartOfAccounts = () => (dispatch, getState) => {
  const state = getState();
  const isVisibleChart = getIsVisibleChart(state);

  dispatch({
    type: CHART_OF_ACCOUNTS_TOGGLE,
    payload: {
      isVisible: !isVisibleChart,
    },
  });
};
