import React from 'react';
import PropTypes from 'prop-types';
import FontAwesome from 'react-fontawesome';
import { get } from 'lodash';

import { Checkbox, FormAlerts, Input, RadioGroup, Select, TextArea } from 'components/shared';

const propTypes = {
  category: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  changeMode: PropTypes.func.isRequired,
  refs: PropTypes.shape({
    chartOptions: PropTypes.array.isRequired,
  }).isRequired,
  fundId: PropTypes.number,
  meta: PropTypes.shape({
    errors: PropTypes.object,
    success: PropTypes.object,
  }),
};

const defaultProps = {
  meta: {},
  fundId: null,
};

function ChartsCategoryForm(props) {
  const { meta, category, fundId, changeMode, onSelectAccount } = props;
  const { chartOptions } = props.refs;
  const { clearCategory, changeInput, onSubmit, deleteCategory } = props.actions;
  const { errors, success, submitting } = meta;

  return (
    <div className="row">
      <div className="col-md-8">
        <form onSubmit={(e) => onSubmit(e, fundId)} className="form" noValidate="novalidate">
          <h2 className="m-t-none">{category.id ? 'Edit Category' : 'Add Category'}</h2>
          <FormAlerts errors={errors} success={success} />
          <Select
            id="category_chart"
            name="category[chart]"
            required
            includeBlank
            dataStateKey="data.chart"
            value={category.chart}
            autofocus
            label="Chart"
            onChange={changeInput}
            options={chartOptions}
            errors={get(errors, 'chart')}
          />

          <div className="row">
            <div className="col-md-2">
              <Input
                id="category_name"
                type="text"
                name="category[code]"
                dataStateKey="data.code"
                required
                value={category.code}
                label="Code"
                onChange={changeInput}
                errors={get(errors, 'code')}
              />
            </div>

            <div className="col-md-10">
              <Input
                id="category_name"
                type="text"
                name="category[name]"
                dataStateKey="data.name"
                required
                value={category.name}
                label="Name"
                onChange={changeInput}
                errors={get(errors, 'name')}
              />
            </div>
          </div>

          <TextArea
            id="description"
            name="category[description]"
            value={category.description}
            row="2"
            dataStateKey="data.description"
            placeholder="Description…"
            onChange={changeInput}
          />

          <RadioGroup
            id="category_related_to_fund"
            name="category[related_to_fund]"
            required
            inline
            dataStateKey="data.relatedToFund"
            value={category.relatedToFund}
            label="Related to"
            onChange={changeInput}
            options={[
              { label: 'Fund', value: 'true' },
              { label: 'Investment', value: 'false' },
            ]}
            errors={get(errors, 'relatedToFund')}
          />

          <div className="d-flex">
            <span className="m-r-sm">
              <Checkbox
                id="category_cash"
                name="category[cash]"
                label="Cash"
                dataStateKey="data.cash"
                dataType="checkbox"
                onChange={changeInput}
                checked={category.cash}
              />
            </span>
            <span className="m-r-sm">
              <Checkbox
                id="category_reconciliation"
                name="category[reconciliation]"
                label="Account Reconciliation"
                dataStateKey="data.reconciliation"
                dataType="checkbox"
                onChange={changeInput}
                checked={category.reconciliation}
              />
            </span>
            <span className="m-r-sm">
              <Checkbox
                id="category_cash_reconciliation"
                name="category[cash_reconciliation]"
                label="Cash Reconciliation"
                dataStateKey="data.cashReconciliation"
                dataType="checkbox"
                onChange={changeInput}
                checked={category.cashReconciliation}
              />
            </span>
            <span className="m-r-sm">
              <Checkbox
                id="admin_fee"
                name="category[admin_fee]"
                label="Admin Fee"
                dataStateKey="data.adminFee"
                dataType="checkbox"
                onChange={changeInput}
                checked={category.adminFee}
              />
            </span>
          </div>

          <div className="m-t-sm">
            <button type="submit" className="btn btn-primary" disabled={submitting}>
              {submitting ? (
                <FontAwesome name="spinner" spin />
              ) : category.id ? (
                I18n.t('buttons.update')
              ) : (
                I18n.t('buttons.create')
              )}
            </button>

            <a
              className="btn btn-link"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                clearCategory();
                changeMode('tree');
              }}
            >
              Cancel
            </a>

            {category.id && !onSelectAccount && (
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  deleteCategory(
                    category,
                    fundId
                      ? Routes.fund_charts_category_path(fundId, category.id, { format: 'json' })
                      : Routes.charts_category_path(category.id, { format: 'json' }),
                  );
                }}
                className="btn btn-danger pull-right"
              >
                Delete
              </a>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

ChartsCategoryForm.propTypes = propTypes;
ChartsCategoryForm.defaultProps = defaultProps;

export default ChartsCategoryForm;
