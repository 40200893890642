export const CHARTS_FETCH = 'CHARTS_FETCH';
export const CHARTS_CATEGORY_FETCH = 'CHARTS_CATEGORY_FETCH';
export const CHARTS_CATEGORY_ACCOUNT_FETCH = 'CHARTS_CATEGORY_ACCOUNT_FETCH';

export const FUND_CHARTS_FETCH = 'FUND_CHARTS_FETCH';
export const FUND_CHARTS_CATEGORY_FETCH = 'FUND_CHARTS_CATEGORY_FETCH';
export const FUND_CHARTS_CATEGORY_ACCOUNT_FETCH = 'FUND_CHARTS_CATEGORY_ACCOUNT_FETCH';
export const FUND_ACCOUNTS_FETCH = 'FUND_ACCOUNTS_FETCH';

export const ACCRUALS_FORM_SUBMIT = 'ACCRUALS_FORM_SUBMIT';
export const ACCRUALS_PAYMENT_ITEM_CREATE = 'ACCRUALS_PAYMENT_ITEM_CREATE';
export const ACCRUALS_PAYMENT_ITEM_PATCH = 'ACCRUALS_PAYMENT_ITEM_PATCH';
export const ACCRUALS_PAYMENT_ITEM_DELETE = 'ACCRUALS_PAYMENT_ITEM_DELETE';

export const DOCUMENTS_PATTERN_CREATE = 'DOCUMENTS_PATTERN_CREATE';
export const DOCUMENTS_PATTERN_UPDATE = 'DOCUMENTS_PATTERN_UPDATE';
export const DOCUMENTS_PATTERN_REMOVE = 'DOCUMENTS_PATTERN_REMOVE';
export const DOCUMENTS_DIRECTORY_CREATE = 'DOCUMENTS_DIRECTORY_CREATE';
export const DOCUMENTS_DIRECTORY_UPDATE = 'DOCUMENTS_DIRECTORY_UPDATE';
export const DOCUMENTS_DIRECTORY_REMOVE = 'DOCUMENTS_DIRECTORY_REMOVE';
