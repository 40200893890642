import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import withReduxProvider from 'components/hocs/withReduxProvider';

import configureStore from './store';
import { getTradeWithType, getSummary, getNotionalAmounts } from './tradeSelectors';
import FundsBatchesTradesForm from './components/Form';

import * as actions from './tradeActions';

const mapStateToProps = (state) => ({
  ...state,
  notionalAmounts: getNotionalAmounts(state),
  trade: getTradeWithType(state),
  security: state.security,
  summary: getSummary(state),
  submitting: state.submitting,
  errors: state.errors || null,
  success: state.success || null,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default withReduxProvider(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(FundsBatchesTradesForm),
  configureStore,
);
