import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import withReduxProvider from 'components/hocs/withReduxProvider';
import MappingsContainer from 'components/shared/Mappings/Container';

import configureStore from './store';

const propTypes = {
  resourceFund: PropTypes.object.isRequired,
  refs: PropTypes.object.isRequired,
};

function FundsChartsMappingsContainer(props) {
  const { resourceFund, refs } = props;

  return <MappingsContainer refs={refs} fundId={resourceFund.id} />;
}

const mapStateToProps = (state) => ({
  resourceFund: state.resourceFund,
});

FundsChartsMappingsContainer.propTypes = propTypes;

export default withReduxProvider(connect(mapStateToProps)(FundsChartsMappingsContainer), configureStore);
