import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';

import accruals from './accruals';
import allocatedAmounts from './allocatedAmounts';
import chartOfAccounts from './chartOfAccounts';
import chartsAccountForm from './chartsAccountForm';
import chartsCategoryForm from './chartsCategoryForm';
import connectCharts from './connectCharts';
import entities from './entities';
import forms from './forms';
import fund from './funds';
import investorDocumentPatterns from './investorDocumentPatterns';
import investorDocumentPatternDirectories from './investorDocumentPatterns/directories';
import policies from './policies';
import request from './request';
import resourceFund from './resourceFund';
import taskListPatterns from './taskListPatterns';
import taskItemPatterns from './taskListPatterns/taskItemPatterns';
import tasks from './tasks';
import user from './user';

export default (history) => combineReducers({
  user,
  tasks,
  fund,
  accruals, // FIX ME move to fund
  allocatedAmounts, // FIX ME move to accruals
  charts: connectCharts(),
  chartsAccountForm,
  chartsCategoryForm,
  chartOfAccounts,
  entities,
  policies,
  forms,
  request,
  resourceFund, // FIX ME move to fund
  taskListPatterns,
  taskItemPatterns,
  investorDocumentPatterns,
  investorDocumentPatternDirectories,
  router: connectRouter(history),
});

// For old modules
export { connectCharts };
