import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, prop, partition } from 'ramda';
import { Card, Col, Divider, Empty, Row } from 'antd';

import { tableItemShape, ownerOptionsShape } from 'constants/propTypes/funds/tasks';

import TaskTable from './TaskTable';
import DraggableBodyRow from './DraggableBodyRow';

const propTypes = {
  currentUserId: PropTypes.number.isRequired,
  dataSource: PropTypes.arrayOf(tableItemShape),
  onComplete: PropTypes.func,
  onChangeOwner: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onMoveRow: PropTypes.func,
  ownerOptions: PropTypes.arrayOf(ownerOptionsShape),
};

const defaultProps = {
  dataSource: [],
  onComplete: undefined,
  onChangeOwner: undefined,
  onEdit: undefined,
  onDelete: undefined,
  onMoveRow: undefined,
  ownerOptions: [],
};

const TaskItemsTable = ({
  currentUserId,
  dataSource,
  onComplete,
  onChangeOwner,
  onEdit,
  onDelete,
  onMoveRow,
  ownerOptions,
}) => {
  const [completedData, incompletedData] = partition(prop('completed'), dataSource);

  return (
    <Row gutter={16}>
      <Col xxl={12} className="m-b-15">
        <Card size="small" title="Incomplete Tasks" bordered={false}>
          {isEmpty(incompletedData) ? (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          ) : (
              <TaskTable
                currentUserId={currentUserId}
                dataSource={incompletedData}
                DraggableBodyRow={DraggableBodyRow}
                onComplete={onComplete}
                onChangeOwner={onChangeOwner}
                onEdit={onEdit}
                onDelete={onDelete}
                onMoveRow={onMoveRow}
                ownerOptions={ownerOptions}
              />
            )}
        </Card>
      </Col>
      <Col xxl={12} className="m-b-15">
        <Card size="small" title="Completed Tasks" bordered={false}>
          {isEmpty(completedData) ? (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          ) : (
              <TaskTable
                dataSource={completedData}
                DraggableBodyRow={DraggableBodyRow}
                onComplete={onComplete}
                onChangeOwner={onChangeOwner}
                onEdit={onEdit}
                onDelete={onDelete}
                onMoveRow={onMoveRow}
                ownerOptions={ownerOptions}
              />
            )}
        </Card>
      </Col>
    </Row>
  );
};

TaskItemsTable.propTypes = propTypes;
TaskItemsTable.defaultProps = defaultProps;

export default TaskItemsTable;
