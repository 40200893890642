import { map } from 'ramda';

import { history } from 'stores/configureStore';
import TaskListPatternsApi from '../../apiAdapters/taskListPatterns';

export const newTaskListPattern = () => (dispatch) => {
  dispatch({ type: 'NEW_TASK_LIST_PATTERN' });
  history.push(Routes.new_task_list_pattern_path());
  document.title = 'Task List Pattern | New';
};

export const createTaskListPattern = (values) => (dispatch, getState) => TaskListPatternsApi.submit(values)
  .then(({ payload }) => {
    const { taskListPatterns: { collection } } = getState();
    const newCollection = [...collection, { ...payload }];

    dispatch({ type: 'CREATE_TASK_LIST_PATTERN', payload: newCollection });
    history.push(Routes.task_list_patterns_path());
    document.title = 'Task List Patterns';
  })
  .catch((error) => console.log(error));

// eslint-disable-next-line max-len
export const updateTaskListPattern = (values, { id }) => (dispatch) => TaskListPatternsApi.submit(values, { id })
  .then(({ payload }) => {
    dispatch({ type: 'UPDATE_TASK_LIST_PATTERN', payload });
    history.push(Routes.task_list_patterns_path());
    document.title = 'Task List Patterns';
  })
  .catch((error) => console.log(error));

export const editTaskListPattern = (taskListPatternId) => (dispatch) => {
  dispatch({ type: 'EDIT_TASK_LIST_PATTERN' });
  history.push(`/task_list_patterns/${taskListPatternId}/edit`);
  history.push(Routes.edit_task_list_pattern_path(taskListPatternId));
  document.title = 'Task List Pattern | Edit';
};

export const closeTaskListPattern = () => (dispatch) => {
  dispatch({ type: 'CLOSE_TASK_LIST_PATTERN' });
  history.push(Routes.task_list_patterns_path());
  document.title = 'Task List Patterns';
};

export const deleteTaskListPattern = ({ id }) => (dispatch) => {
  TaskListPatternsApi.delete({ id })
    .then(({ payload }) => {
      dispatch({ type: 'DESTROY_TASK_LIST_PATTERN', payload });
      history.push(Routes.task_list_patterns_path());
      document.title = 'Task List Patterns';
    })
    .catch((error) => console.log(error));
};

// eslint-disable-next-line max-len
export const changeTaskListPatternPosition = (listPosition, position, { id }) => (dispatch) => TaskListPatternsApi.submit(
  { position }, { id },
)
  .then(({ payload }) => dispatch({ type: 'CHANGE_TASK_LIST_PATTERN_POSITION', payload }))
  .catch((error) => console.log(error));
