import React from 'react';
import PropTypes from 'prop-types';
import Textarea from 'react-textarea-autosize';

const propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
};

const defaultProps = {
  id: null,
  name: null,
};

function TextareaInput(props) {
  return <Textarea {...props} className="ant-input" />;
}

TextareaInput.propTypes = propTypes;
TextareaInput.defaultProps = defaultProps;

export default TextareaInput;
