import axios from 'axios';
import humps from 'humps';
import fileDownload from 'js-file-download';
import moment from 'moment';

import FundsAdapter from 'apiAdapters/funds';
import {
  getResourceFund,
  getValuation,
  getValuationOverrideItemIds,
  getValuationOverrideItemBySourceId,
  getFundId,
  getValuationId,
  getFundName,
} from './selectors';
import { actionTypes } from './index';

export function saveValuationOverrideItem(values) {
  return (dispatch, getState) => {
    const { sourceId } = values;

    const resourceFund = getResourceFund(getState());
    const valuationOverrideItem = getValuationOverrideItemBySourceId(getState(), sourceId);

    const prepareData = {
      valuationOverrideItem: {
        id: valuationOverrideItem.id,
        fundValuationId: valuationOverrideItem.fundValuationId,
        ...values,
      },
    };

    const formData = humps.decamelizeKeys(prepareData);

    const createOrUpdateUrl = () => {
      if (valuationOverrideItem.id) {
        return Routes.fund_valuation_override_item_path(
          resourceFund.id,
          valuationOverrideItem.fundValuationId,
          valuationOverrideItem.id,
          {
            format: 'json',
          },
        );
      }

      return Routes.fund_valuation_override_items_path(resourceFund.id, valuationOverrideItem.fundValuationId, {
        format: 'json',
      });
    };

    axios
      .request({
        url: createOrUpdateUrl(),
        method: valuationOverrideItem.id ? 'PATCH' : 'POST',
        data: formData,
        withCredentials: true,
        xsrfHeaderName: 'X-CSRF-Token',
      })
      .then((response) => {
        dispatch(updateValuationOverrideItemSucceded(humps.camelizeKeys(response.data), valuationOverrideItem.index));
      });
  };
}

export function resetValuationOverrideItem(values) {
  return (dispatch, getState) => {
    const { sourceId } = values;

    const resourceFund = getResourceFund(getState());
    const valuationOverrideItem = getValuationOverrideItemBySourceId(getState(), sourceId);

    const prepareData = {
      valuationOverrideItem: {
        id: valuationOverrideItem.id,
        fundValuationId: valuationOverrideItem.fundValuationId,
        sourceId,
      },
    };

    const formData = humps.decamelizeKeys(prepareData);

    axios
      .request({
        url: Routes.fund_valuation_override_item_path(
          resourceFund.id,
          valuationOverrideItem.fundValuationId,
          valuationOverrideItem.id,
          {
            format: 'json',
          },
        ),
        method: 'DELETE',
        data: formData,
        withCredentials: true,
        xsrfHeaderName: 'X-CSRF-Token',
      })
      .then((response) => {
        dispatch(updateValuationOverrideItemSucceded(humps.camelizeKeys(response.data), valuationOverrideItem.index));
      });
  };
}

export function resetValuationOverrideItems() {
  return (dispatch, getState) => {
    const resourceFund = getResourceFund(getState());
    const valuation = getValuation(getState());
    const valuationOverrideItemIds = getValuationOverrideItemIds(getState());

    const formData = humps.decamelizeKeys(valuationOverrideItemIds);

    axios
      .request({
        url: Routes.fund_valuation_override_path(resourceFund.id, valuation.id, {
          format: 'json',
        }),
        method: 'DELETE',
        data: { ids: formData },
        withCredentials: true,
        xsrfHeaderName: 'X-CSRF-Token',
      })
      .then((response) => {
        dispatch(updateValuationOverrideItemsSucceded(humps.camelizeKeys(response.data)));
      });
  };
}

export const exportTemplate = (filteredOverrideItems) => (dispatch, getState) => {
  const state = getState();
  const fundId = getFundId(state);
  const valuationId = getValuationId(state);
  const sourceIds = [];

  if (filteredOverrideItems) {
    filteredOverrideItems.forEach((item) => sourceIds.push(item.sourceId));
  }

  return FundsAdapter.exportData(fundId, valuationId, sourceIds)
    .then((res) => {
      return res.blob();
    })
    .then((resData) => {
      const fundName = getFundName(state);
      const date = moment(new Date()).format('MM/DD/YYYY');
      const fileName = `${fundName}-override_data-${date}.csv`;
      fileDownload(resData, fileName);
    })
    .catch((res) => {
      console.error(res);
    });
};

function updateValuationOverrideItemSucceded(data = {}, index) {
  return {
    type: actionTypes.VALUATION_OVERRIDE_ITEM_UPDATE_SUCCEEDED,
    payload: data,
    meta: {
      index,
    },
  };
}

function updateValuationOverrideItemsSucceded(data = []) {
  return {
    type: actionTypes.VALUATION_OVERRIDE_ITEMS_UPDATE_SUCCEEDED,
    payload: data,
  };
}
