import React from 'react';
import PropTypes from 'prop-types';

import { RadioGroup } from 'components/shared';

import ClassesAndSeriesFields from './ClassesAndSeriesFields';
import InvestorsFields from './InvestorsFields';

const propTypes = {
  formAttributes: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  refs: PropTypes.shape({
    chartAccountInvestorPropertyProxyTypes: PropTypes.array,
    investorOptions: PropTypes.array,
  }),
  investors: PropTypes.array,
};

const defaultProps = {
  investors: [],
  refs: undefined,
  formAttributes: {},
};

function ClassSpecificFields(props) {
  const { formAttributes, investors } = props;
  const { chartAccountInvestorPropertyProxyTypes, investorOptions } = props.refs;
  const { changeInput, addInvestor, removeInvestor } = props.actions;

  return (
    <div>
      <RadioGroup
        id='classSpecific'
        name='form_attributes[class_specific]'
        inline
        dataStateKey='data.formAttributes.classSpecific'
        value={formAttributes.classSpecific }
        label='Specific Allocation?'
        onChange={changeInput}
        options={[{ label: 'Yes', value: 'true' }, { label: 'No', value: 'false' }]}
      />

      { formAttributes.classSpecific === 'true' &&
        <div>
          <RadioGroup
            id='classesAndSeries'
            name='form_attributes[classes_and_series]'
            inline
            dataStateKey='data.formAttributes.classesAndSeries'
            value={formAttributes.classesAndSeries}
            onChange={changeInput}
            options={[
              { label: 'Select investor classes and series', value: 'true' },
              { label: 'Select investors', value: 'false' },
            ]}
          />

          { formAttributes.classesAndSeries === 'false' &&
            <InvestorsFields
              investors={investors}
              refs={{ chartAccountInvestorPropertyProxyTypes, investorOptions }}
              actions={{ addInvestor, removeInvestor, changeInput }}
            />
          }

          { formAttributes.classesAndSeries === 'true' &&
            <ClassesAndSeriesFields refs={{ chartAccountInvestorPropertyProxyTypes }} />
          }
        </div>
      }
    </div>
  )
}

ClassSpecificFields.propTypes = propTypes;
ClassSpecificFields.defaultProps = defaultProps;

export default ClassSpecificFields;
