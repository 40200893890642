import { createSelector } from 'reselect';
import { accounting } from 'accounting';
import numeral from 'numeral';
import { dateToString } from 'utils/dateTransformations';
import { fundSelector } from 'components/Funds/selectors';

const tradeSelector = (state) => state.trade;
const securitySelector = (state) => state.security || state.previousSecurity || {};

export const getTradeWithType = createSelector(
  tradeSelector,
  securitySelector,
  (trade, security) => {
    const type = fetchType(trade, trade.signedQuantity, security);

    return {
      ...trade,
      tradeFxRate: trade.tradeFxRate,
      settleFxRate: trade.settleFxRate,
      type,
      secondTradeType: fetchSecondTradeType(type, trade.signedQuantity, security),
    };
  },
);

export const getSummary = createSelector(
  fundSelector,
  tradeSelector,
  securitySelector,
  (fund, trade, security) => {
    if (security == undefined || security == null) return {};

    const fx = fund.baseCurrencyIso != security.currencyIso;
    const type = fetchType(trade, trade.signedQuantity, security);
    const currencySymbol =
      security.type == 'currency' && security.holdingsDetails && security.holdingsDetails.sellCurrency
        ? security.holdingsDetails.sellCurrency.symbol
        : security.currencySymbol;

    const totalFeesValue = (() => {
      const { commissionFee, brokerFee, secFee, otherFee, taxes } = trade;
      return Math.abs(
        [commissionFee, brokerFee, secFee, otherFee, taxes].reduce(
          (a, b) => numeral(a).value() + numeral(b).value(),
          0,
        ),
      );
    })();

    const displayTotalFees = formatMoney(totalFeesValue, currencySymbol);

    const grossAmountValue = (() => {
      if (security.type === 'option' || security.type === 'future' || security.type === 'fixed_income') {
        return Math.abs((trade.signedQuantity || 0) * (trade.price || 0) * (security.pricingFactor || 0));
      } else if (
        security.type === 'partnership_investment' ||
        security.type === 'private_equity_investment' ||
        security.type === 'currency'
      ) {
        return Math.abs(trade.price || 0);
      } else {
        return Math.abs((trade.signedQuantity || 0) * (trade.price || 0));
      }
    })();

    const displayTotalFeesTradeAmount = (() => {
      if (fx) {
        if (trade.baseFundFeesCurrency) {
          return [
            formatMoney(totalFeesValue * (trade.tradeFxRate || 1), currencySymbol),
            formatMoney(totalFeesValue, fund.baseCurrencySymbol),
          ];
        } else {
          return [
            formatMoney(totalFeesValue, currencySymbol),
            formatMoney(totalFeesValue / (trade.tradeFxRate || 1), fund.baseCurrencySymbol),
          ];
        }
      }

      return displayTotalFees;
    })();

    const displayGrossAmount = formatMoney(grossAmountValue, currencySymbol);

    const displayGrossTradeAmount = (() => {
      if (fx) {
        return [
          formatMoney(grossAmountValue, currencySymbol),
          formatMoney(grossAmountValue / (trade.tradeFxRate || 1), fund.baseCurrencySymbol),
        ];
      }

      return displayGrossAmount;
    })();

    const displayGrossSettleAmount = (() => {
      if (fx) {
        return [
          formatMoney(grossAmountValue, currencySymbol),
          formatMoney(grossAmountValue / (trade.settleFxRate || 1), fund.baseCurrencySymbol),
        ];
      }

      return displayGrossAmount;
    })();

    const displayNetAmount = (() => {
      const accruedInterest = security.type == 'fixed_income' ? Math.abs(trade.accruedInterest) : 0;

      if (type == 'sell' || type == 'short' || type == 'sell_contract' || type == 'short_contract') {
        const value = grossAmountValue - totalFeesValue + accruedInterest;
        return formatMoney(value, currencySymbol);
      }

      if (type == 'buy' || type == 'cover' || type == 'buy_contract' || type == 'cover_contract') {
        const value = grossAmountValue + totalFeesValue + accruedInterest;
        return formatMoney(value, currencySymbol);
      }

      return formatMoney(0, currencySymbol);
    })();

    const displayNetTradeAmount = (() => {
      const feesValue = trade.baseFundFeesCurrency ? totalFeesValue * (trade.tradeFxRate || 1) : totalFeesValue;
      const accruedInterest = security.type == 'fixed_income' ? Math.abs(trade.accruedInterest) : 0;

      if (type == 'sell' || type == 'short' || type == 'sell_contract' || type == 'short_contract') {
        const value = grossAmountValue - feesValue + accruedInterest;
        return fx
          ? [formatMoney(value, currencySymbol), formatMoney(value / (trade.tradeFxRate || 1), fund.baseCurrencySymbol)]
          : formatMoney(value, currencySymbol);
      }

      if (type == 'buy' || type == 'cover' || type == 'buy_contract' || type == 'cover_contract') {
        const value = grossAmountValue + feesValue + accruedInterest;
        return fx
          ? [formatMoney(value, currencySymbol), formatMoney(value / (trade.tradeFxRate || 1), fund.baseCurrencySymbol)]
          : formatMoney(value, currencySymbol);
      }

      return fx
        ? [formatMoney(0, currencySymbol), formatMoney(0, fund.baseCurrencySymbol)]
        : formatMoney(0, currencySymbol);
    })();

    const displayNetSettleAmount = (() => {
      const feesValue = trade.baseFundFeesCurrency ? totalFeesValue * (trade.settleFxRate || 1) : totalFeesValue;
      const accruedInterest = security.type == 'fixed_income' ? Math.abs(trade.accruedInterest) : 0;

      if (type == 'sell' || type == 'short' || type == 'sell_contract' || type == 'short_contract') {
        const value = grossAmountValue - feesValue + accruedInterest;
        return fx
          ? [
              formatMoney(value, currencySymbol),
              formatMoney(value / (trade.settleFxRate || 1), fund.baseCurrencySymbol),
            ]
          : formatMoney(value, currencySymbol);
      }

      if (type == 'buy' || type == 'cover' || type == 'buy_contract' || type == 'cover_contract') {
        const value = grossAmountValue + feesValue + accruedInterest;
        return fx
          ? [
              formatMoney(value, currencySymbol),
              formatMoney(value / (trade.settleFxRate || 1), fund.baseCurrencySymbol),
            ]
          : formatMoney(value, currencySymbol);
      }

      return fx
        ? [formatMoney(0, currencySymbol), formatMoney(0, fund.baseCurrencySymbol)]
        : formatMoney(0, currencySymbol);
    })();

    return {
      displayTotalFees,
      displayTotalFeesTradeAmount,
      displayGrossAmount,
      displayGrossTradeAmount,
      displayGrossSettleAmount,
      displayNetAmount,
      displayNetTradeAmount,
      displayNetSettleAmount,
      displayTradeDate: dateToString(trade.tradeDate),
    };
  },
);

function formatMoney(value, symbol) {
  return accounting.formatMoney(value, symbol, 2, ',', '.');
}

export function fetchType(trade, signedQuantity, security) {
  if (security) {
    const { currentHoldings, type } = security;
    const isFuture = type === 'future';

    if (type === 'partnership_investment' || type === 'private_equity_investment') {
      return trade.type;
    }

    if (type === 'currency') {
      if (signedQuantity) {
        return signedQuantity > 0 ? 'buy' : 'sell';
      } else {
        return undefined;
      }
    }

    if (signedQuantity > 0) {
      if (currentHoldings >= 0) {
        return isFuture ? 'buy_contract' : 'buy';
      }

      return isFuture ? 'cover_contract' : 'cover';
    } else if (signedQuantity < 0) {
      if (currentHoldings > 0) {
        return isFuture ? 'sell_contract' : 'sell';
      }

      return isFuture ? 'short_contract' : 'short';
    }
  }

  return null;
}

export function fetchSecondTradeType(type, signedQuantity, security) {
  if (security) {
    if (
      security.type === 'partnership_investment' ||
      security.type === 'private_equity_investment' ||
      security.type === 'currency'
    ) {
      return null;
    }

    const isFuture = security.type === 'future';

    if (security.currentHoldings !== 0) {
      if ((type === 'sell' || type === 'sell_contract') && security.currentHoldings < Math.abs(signedQuantity)) {
        return isFuture ? 'Short Contract' : 'Short';
      }

      if (
        (type === 'cover' || type === 'cover_contract') &&
        Math.abs(signedQuantity) > Math.abs(security.currentHoldings)
      ) {
        return isFuture ? 'Buy Contract' : 'Buy';
      }
    }
  }

  return null;
}

const getSecurityPricingFactor = createSelector(
  securitySelector,
  (security) => security.pricingFactor || 0,
);

const getSecurityCurrencySimbol = createSelector(
  securitySelector,
  (security) => security.currencySymbol || '',
);

const getSingedQuantity = createSelector(
  tradeSelector,
  (trade) => trade.signedQuantity || 0,
);

const getPrice = createSelector(
  tradeSelector,
  (trade) => trade.price || 0,
);

export const getNotionalAmounts = createSelector(
  getSingedQuantity,
  getPrice,
  getSecurityPricingFactor,
  getSecurityCurrencySimbol,
  (quantity, price, pricingFactor, currencySimbol) => {
    const notionalAmounts = quantity * price * pricingFactor;
    return formatMoney(notionalAmounts, currencySimbol);
  },
);
