import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  htmlFor: PropTypes.string.isRequired,
  required: PropTypes.bool,
};

const defaultProps = {
  label: false,
  required: false,
};

const FormLabel = (props) => {
  const { label, htmlFor, required } = props;

  return (
    label ? (
      <label className="form__label" htmlFor={htmlFor}>
        { required && <abbr title='required'>* </abbr> }
        { label }
      </label>
    ) : (
      null
    )
  );
};

FormLabel.propTypes = propTypes;
FormLabel.defaultProps = defaultProps;

export default FormLabel;
