// import 'antd/dist/antd.css';

import 'antd/lib/affix/style/index.css';
import 'antd/lib/alert/style/index.css';
import 'antd/lib/drawer/style/index.css';
import 'antd/lib/form/style/index.css';
import 'antd/lib/input/style/index.css';
import 'antd/lib/select/style/index.css';
import 'antd/lib/checkbox/style/index.css';
import 'antd/lib/dropdown/style/index.css';
import 'antd/lib/menu/style/index.css';
import 'antd/lib/radio/style/index.css';
import 'antd/lib/date-picker/style/index.css';
import 'antd/lib/button/style/index.css';
import 'antd/lib/list/style/index.css';
import 'antd/lib/icon/style/index.css';
import 'antd/lib/notification/style/index.css';
import 'antd/lib/table/style/index.css';
import 'antd/lib/pagination/style/index.css';
import 'antd/lib/grid/style/index.css';
import 'antd/lib/popover/style/index.css';
import 'antd/lib/avatar/style/index.css';
import 'antd/lib/card/style/index.css';
import 'antd/lib/tag/style/index.css';
import 'antd/lib/message/style/index.css';
import 'antd/lib/divider/style/index.css';
import 'antd/lib/empty/style/index.css';
import 'antd/lib/typography/style/index.css';
import 'antd/lib/statistic/style/index.css';
import 'antd/lib/comment/style/index.css';
import 'antd/lib/tooltip/style/index.css';
import 'antd/lib/collapse/style/index.css';
import 'antd/lib/page-header/style/index.css';
import 'antd/lib/progress/style/index.css';
import 'antd/lib/tree/style/index.css';
import 'antd/lib/modal/style/index.css';

import '../stylesheets/bundle.sass';

var componentRequireContext = require.context('components', true);
var ReactRailsUJS = require('react_ujs');

ReactRailsUJS.useContext(componentRequireContext);
