import { has, includes, values } from 'ramda';

import { dateFormat } from 'utils/formats';

const getIsEditPage = () => {
  const currentUrl = window.location.href.split('/');
  const isEditPage = currentUrl[currentUrl.length - 1] === 'edit';

  return isEditPage;
};

export const getSubmitBtnText = (submitting, btnType) => {
  const isEditPage = getIsEditPage();
  let btnText = '';

  if (btnType === 'additional') {
    btnText = isEditPage ? 'Update And Go To Payments Schedule' : 'Create And Go To Payments Schedule';
  } else {
    btnText = isEditPage ? 'Update' : 'Create';
  }

  const submitBtnText = submitting ? 'Please wait…' : btnText;

  return submitBtnText;
};

export const getIsNeedToShowAdditionalBtns = (recordId, editableKeys) => {
  if (has(recordId, editableKeys)) {
    const recordKeyValues = values(editableKeys[recordId]);
    if (includes(true, recordKeyValues)) {
      return true;
    }
  }
  return false;
};

export const getFormatedPaymentItem = (paymentItem) => {
  const { amount, date, paymentAmount, paymentDate } = paymentItem;
  const paymentNum = Number(paymentAmount);
  const payment = paymentNum === 0 ? null : paymentNum;
  const formatedPaymentItem = {
    amount: Number(amount),
    date: dateFormat(date),
    paymentAmount: payment,
    paymentDate: dateFormat(paymentDate),
  };

  return formatedPaymentItem;
};

export const redirectAfterSubmitAccrualForm = (formValues, params) => {
  const { fundId, accrualId, active } = params;
  const { redirectPage } = formValues;

  const path =
    redirectPage === 'paymentSchedule'
      ? Routes.fund_accrual_schedule_path(fundId, accrualId)
      : Routes.fund_accruals_path(fundId, { active });

  window.location.href = path;
};
