import { createSelector } from 'reselect';

const bandPatternsSelector = (state) => state.bandPatterns;

export const isDisabled = createSelector(bandPatternsSelector, (bandPatterns) => {
  const lastBandPattern = bandPatterns[bandPatterns.length - 1];

  return lastBandPattern && (
    isNaN(parseFloat(lastBandPattern.lowerBoundPercentage))
    || isNaN(parseFloat(lastBandPattern.upperBoundPercentage))
    || isNaN(parseFloat(lastBandPattern.rate))
  );
});
