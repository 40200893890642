import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import withReduxProvider from 'components/hocs/withReduxProvider';

import configureStore from './store';
import Form from './components/Form';
import * as actions from './actions';
import { isDisabled } from './selectors';

const mapStateToProps = (state) => ({
  ...state,
  disabled: isDisabled(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default withReduxProvider(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Form),
  configureStore,
);
