import React from 'react';

// eslint-disable-next-line react/prop-types
function Tabs({ data }) {
  return (
    <ul className="panel_tabs">
      {/* eslint-disable-next-line react/prop-types */}
      {data && data.map(({ name, link, active }, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <li key={index} className={`panel_tabs__item ${active ? 'active' : ''}`}>
          <a className="panel_tabs__link" href={link}>{name}</a>
        </li>
      ))}
    </ul>
  )
};

export default Tabs;