import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';

import {
  FUND_CHARTS_PATH,
  EDIT_FUND_ACCRUALS_PATH,
  NEW_FUND_ACCRUALS_PATH,
  SCHEDULE_FUND_ACCRUALS_PATH,
  FUND_CHARTS_MAPPINGS_PATH,
  NEW_FUND_BATCHES_TRADES_PATH,
  NEW_FUND_BATCHES_TRADE_EXERCISES_PATH,
  FUND_PORTFOLIOS_PATH,
} from '../../constants/router';
import FundAccounts from '../Funds/Accounts';
import FundsAccrualForm from '../Funds/Accruals/Form';
import FundsAccrualsPaymentItemsContainer from '../Funds/Accruals/PaymentItems';
import TradeExcercisesNewContainer from '../Funds/Batches/TradeExercises';
import TradesNewContainer from '../Funds/Batches/Trades';
import FundsCharts from '../Funds/Charts';
import FundChartsMappings from '../Funds/Charts/Mappings';
import FundDocumentPatterns from '../Funds/InvestorDocumentDirectories';
import FundTaskItemPatterns from '../Funds/TaskItemPatterns';
import FundValuationTaskItems from '../Funds/Valuations/TaskItems';
import PortfolioInvestors from '../Funds/Portfolios';

function FundRoutes(props) {
  return (
    <>
      <Route
        path={[
          '/funds/:fundId/task_item_patterns',
          '/funds/:fundId/task_item_patterns/new',
          '/funds/:fundId/task_item_patterns/import/new',
          '/funds/:fundId/task_item_patterns/:id/edit',
        ]}
        render={(routerProps) => <FundTaskItemPatterns {...props} {...routerProps} />}
        exact
      />
      <Route
        path={[
          '/funds/:fundId/investor_document_directories',
        ]}
        render={(routerProps) => <FundDocumentPatterns {...props} {...routerProps} />}
        exact
      />
      <Route
        path={[
          '/funds/:fundId/valuations/:valuationId/task_items',
          '/funds/:fundId/valuations/:valuationId/task_items/new',
          '/funds/:fundId/valuations/:valuationId/task_items/:id/edit',
          '/funds/:fundId/valuations/:valuationId/task_items/task_notes',
        ]}
        render={(routerProps) => <FundValuationTaskItems {...props} {...routerProps} />}
        exact
      />
      <Route
        path={['/funds/:fundId/accounts']}
        render={(routerProps) => <FundAccounts {...props} {...routerProps} />}
        exact
      />
      <Route path={FUND_CHARTS_PATH} render={(routerProps) => <FundsCharts {...props} {...routerProps} />} exact />
      <Route
        path={[EDIT_FUND_ACCRUALS_PATH, NEW_FUND_ACCRUALS_PATH]}
        render={(routerProps) => <FundsAccrualForm {...props} {...routerProps} />}
        exact
      />
      <Route
        path={SCHEDULE_FUND_ACCRUALS_PATH}
        render={(routerProps) => <FundsAccrualsPaymentItemsContainer {...props} {...routerProps} />}
        exact
      />
      <Route
        path={FUND_CHARTS_MAPPINGS_PATH}
        render={(routerProps) => <FundChartsMappings {...props} {...routerProps} />}
        exact
      />
      <Route
        path={NEW_FUND_BATCHES_TRADES_PATH}
        render={(routerProps) => <TradesNewContainer {...props} {...routerProps} />}
        exact
      />
      <Route
        path={NEW_FUND_BATCHES_TRADE_EXERCISES_PATH}
        render={(routerProps) => <TradeExcercisesNewContainer {...props} {...routerProps} />}
        exact
      />
      <Route
        path={FUND_PORTFOLIOS_PATH}
        render={(routerProps) => <PortfolioInvestors {...props} {...routerProps} />}
        exact
      />
    </>
  );
}

export default FundRoutes;
