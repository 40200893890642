import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import { connect } from 'react-redux';
import { has, get } from 'lodash';

import { getInvestorClassesAndSeriesTree } from 'components/Funds/selectors';
import { toggleInvestorClass, toggleInvestorSeries, changeInput } from '../../actions';

const propTypes = {
  investorClassesAndSeries: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired,
  refs: PropTypes.shape({
    chartAccountInvestorPropertyProxyTypes: PropTypes.array.isRequired,
  }).isRequired,
  accountInvestorClasses: PropTypes.object,
  accountInvestorSeries: PropTypes.object,
};

const defaultProps = {
  accountInvestorClasses: {},
  accountInvestorSeries: {},
};

function ClassesAndSeriesFields(props) {
  const { investorClassesAndSeries, accountInvestorClasses, accountInvestorSeries } = props;
  const { chartAccountInvestorPropertyProxyTypes } = props.refs;
  const { toggleInvestorClass, toggleInvestorSeries, changeInput } = props.actions;

  return (
    <div className="panel panel-bordered">
      <div className="panel__body panel__body-padded">
        <ul className="tree">
          {investorClassesAndSeries.map((klass) => {
            const classChecked = has(accountInvestorClasses, klass.id);

            return (
              <li className="tree__item form-inline" key={klass.id}>
                <label>
                  <input
                    name="investor_classes[][target_id]"
                    value={klass.id}
                    type="checkbox"
                    onChange={() => toggleInvestorClass(klass.id)}
                    checked={classChecked}
                  />

                  <strong>{` ${klass.name} `}</strong>

                  {classChecked && (
                    <select
                      id={`class${klass.id}`}
                      name={`investor_classes[${klass.id}][type]`}
                      data-statekey={`data.investorClasses.${klass.id}.type`}
                      value={get(accountInvestorClasses[klass.id], 'type')}
                      onChange={changeInput}
                    >
                      {chartAccountInvestorPropertyProxyTypes.map((opt) => (
                        <option key={opt.value} value={opt.value}>
                          {opt.label}
                        </option>
                      ))}
                    </select>
                  )}
                </label>

                <ul className="tree tree-sub">
                  {klass.investorSeries.map((series) => {
                    const seriesChecked = has(accountInvestorSeries, series.id);

                    return (
                      <li className="tree__item tree__item-sub" key={series.id}>
                        <label>
                          <input
                            name="investor_series[][target_id]"
                            value={series.id}
                            type="checkbox"
                            onChange={() => toggleInvestorSeries(series.id)}
                            checked={seriesChecked}
                          />
                          {` ${series.name} `}
                        </label>

                        {seriesChecked && (
                          <select
                            id={`series${series.id}`}
                            name={`investor_series[${series.id}][type]`}
                            data-statekey={`data.investorSeries.${series.id}.type`}
                            value={get(accountInvestorSeries[series.id], 'type')}
                            onChange={changeInput}
                          >
                            {chartAccountInvestorPropertyProxyTypes.map((opt) => (
                              <option key={opt.value} value={opt.value}>
                                {opt.label}
                              </option>
                            ))}
                          </select>
                        )}
                      </li>
                    );
                  })}
                </ul>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  const chartsAccountFormData = state.chartsAccountForm.data;
  const accountInvestorClasses = chartsAccountFormData.investorClasses;
  const accountInvestorSeries = chartsAccountFormData.investorSeries;
  const investorClassesAndSeries = getInvestorClassesAndSeriesTree(state.resourceFund);

  return {
    ...ownProps,
    accountInvestorClasses,
    accountInvestorSeries,
    investorClassesAndSeries,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ toggleInvestorClass, toggleInvestorSeries, changeInput }, dispatch),
});

ClassesAndSeriesFields.propTypes = propTypes;
ClassesAndSeriesFields.defaultProps = defaultProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ClassesAndSeriesFields);
