import React from 'react';
import PropTypes from 'prop-types';

import { compact } from 'lodash';
import Policy from '../../../Policy';

const propTypes = {
  deleteMapping: PropTypes.func.isRequired,
  fundId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mapping: PropTypes.object.isRequired,
};

const defaultProps = {
  fundId: null,
};

const MappingItem = (props) => {
  const { mapping, fundId, deleteMapping } = props;

  const displayTitle = compact([mapping.displayEvent, mapping.displayTransactionKey]).join(' - ');

  return (
    <li className="tree__item tree__item-sub">
      <span className="tree__actions_wrapper">
        <strong>{mapping.displayNamespace}</strong>
        {' - '}
        {displayTitle}
        {mapping.displaySecurityKlass && `, ${mapping.displaySecurityKlass}`}
        <Policy name="chart.category.account.mapping.delete" type="hide">
          <span className="tree__actions">
            <a
              href="#"
              className="btn btn-danger btn-xs"
              onClick={(e) => {
                e.preventDefault();
                deleteMapping(mapping);
              }}
            >
              Delete
            </a>
          </span>
        </Policy>
      </span>
    </li>
  );
};

MappingItem.propTypes = propTypes;
MappingItem.defaultProps = defaultProps;

export default MappingItem;
