import { find, reduce } from 'lodash';

export const getSelectedItems = (inboxItems, fundItems) => {
  const selectedItems = [];

  if (inboxItems) {
    inboxItems.forEach((selectedItem) => {
      const accounts = find(fundItems, {
        id: selectedItem.targetId,
      });
      selectedItems.push(accounts);
    });
  }

  return selectedItems;
};

export const getAutoCalculateAmounts = (
  total,
  ownershipPercentage,
  grossAssetValue,
  grossAssetSum,
  allAccountsLength,
  selectedAccountsLength,
) => {
  if (selectedAccountsLength === 1) {
    return total;
  }
  if (grossAssetSum === 0) {
    return 0;
  }
  const calculatedPercentage = (grossAssetValue * 100) / grossAssetSum / 100;

  return total * calculatedPercentage;
};

export const getGrossAssetSum = (accounts) => {
  const grossAssetSum = reduce(
    accounts,
    (sum, item) => {
      if (item && item.closingGrossCapitalAmount) {
        return sum + Number(item.closingGrossCapitalAmount);
      }

      return sum;
    },
    0,
  );

  return grossAssetSum;
};

export const getActiveSeriesAccounts = (selectedSeries, fundAccounts) => {
  const seriesAccounts = [];
  const activeAccounts = [];

  selectedSeries.forEach((seria) => {
    seriesAccounts.push(seria.accountIds);
  });

  const seriesAccountIds = seriesAccounts.flat();

  seriesAccountIds.forEach((id) => {
    fundAccounts.forEach((account) => {
      if (id === account.id) {
        activeAccounts.push(id);
      }
    });
  });

  return activeAccounts;
};

export const getActiveClassesAccounts = (selectedClassses, fundSeries, fundAccounts) => {
  const classesSeries = [];
  const activeSeries = [];

  selectedClassses.forEach((classItem) => {
    classesSeries.push(classItem.investorSeriesIds);
  });

  const classesSeriesIds = classesSeries.flat();

  classesSeriesIds.forEach((seriaId) => {
    fundSeries.forEach((seria) => {
      if (seriaId === seria.id) {
        activeSeries.push(seria);
      }
    });
  });

  const activeAccounts = getActiveSeriesAccounts(activeSeries, fundAccounts);

  return activeAccounts;
};

export const getArrayFromTargetType = (targetType, fundAccounts, fundSeries, fundClasses) => {
  switch (targetType) {
    case 'Fund::Account':
      return fundAccounts;

    case 'Fund::InvestorSeries':
      return fundSeries;

    case 'Fund::InvestorClass':
      return fundClasses;

    default:
      return [...fundAccounts, ...fundSeries, ...fundClasses];
  }
};
