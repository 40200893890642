import { createSelector } from 'reselect';
import humps from 'humps';

const mappingsConfigurationSelector = state => state.refs.mappingsConfiguration;
const mappingFormSelector = state => state.mappingForm.data;

export const getNamespaceOptions = createSelector(
  mappingsConfigurationSelector,
  (mappingsConfiguration) => {
    return _.keys(mappingsConfiguration).map((namespace) => {
      return { value: _.snakeCase(namespace), label: I18n.t(`mappings.namespaces.${_.snakeCase(namespace)}`)};
    });
  }
);

export const getEventOptions = createSelector(
  mappingFormSelector,
  mappingsConfigurationSelector,
  (mappingForm, mappingsConfiguration) => {
    if (mappingForm.namespace) {
      return _.keys(mappingsConfiguration[humps.camelize(mappingForm.namespace)]).map((event) => {
        return { value: _.snakeCase(event), label: I18n.t(`mappings.events.${_.snakeCase(mappingForm.namespace)}.${_.snakeCase(event)}`)};
      });
    }

    return [];
  }
);

export const getTransactionKeyOptions = createSelector(
  mappingFormSelector,
  mappingsConfigurationSelector,
  (mappingForm, mappingsConfiguration) => {
    if (mappingForm.namespace && mappingForm.event) {
      return _.keys(mappingsConfiguration[humps.camelize(mappingForm.namespace)][humps.camelize(mappingForm.event)]).map((transactionKey) => {
        return { value: _.snakeCase(transactionKey), label: I18n.t(`mappings.transaction_keys.${_.snakeCase(mappingForm.namespace)}.${_.snakeCase(mappingForm.event)}.${_.snakeCase(transactionKey)}`)};
      });
    }

    return [];
  }
);