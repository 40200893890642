import React, { useEffect } from 'react';
import { Checkbox, Row, Col } from 'antd';
import { equals, map, reject, isNil, mapObjIndexed } from 'ramda';

import { fetchTasks } from 'actions/tasks';
import { useAsyncFetchDefer } from 'utils/useAsyncFetch';

import { getQueryParams } from '../../utils/queryParams';

// eslint-disable-next-line react/prop-types
function ColumnFilter(props) {
  const { name, setSelectedKeys, selectedKeys, confirm, items, visible } = props;
  const plainItems = map((item) => item.value, items);
  const allSelected = equals(selectedKeys, plainItems);
  const [asyncFetchTasks] = useAsyncFetchDefer(fetchTasks);
  const params = getQueryParams();

  const handleConfirm = () => {
    const updatedParams = allSelected
      ? { ...params, [name]: 'all' }
      : { ...params, [name]: selectedKeys };

    const filterParams = reject((v) => !v, mapObjIndexed(
      (val, key) => !['sort', 'page', 'limit'].includes(key) && val,
      updatedParams
    ));
    asyncFetchTasks(filterParams);
    confirm();
  }

  useEffect(() => {
    if (visible && params[name] === 'all') {
      setSelectedKeys(plainItems)
    }
  }, [visible])

  return (
    <>
      <Checkbox.Group
        style={{ width: '100%' }}
        onChange={(checked) => setSelectedKeys(checked)}
        value={selectedKeys}
        defaultValue={selectedKeys || []}
      >
        <Row>
          <Col>
            <ul
              className="ant-dropdown-menu ant-dropdown-menu-without-submenu
                    ant-dropdown-menu-root ant-dropdown-menu-vertical"
              role='menu'
              tabIndex='0'
            >
              {
                // eslint-disable-next-line react/prop-types
                items.map(({ text, value }) => (
                  <li key={value} className="ant-dropdown-menu-item" role="menuitem">
                    <Checkbox value={value}>{text}</Checkbox>
                  </li>
                ))
              }
            </ul>
          </Col>
        </Row>
      </Checkbox.Group>
      <div className="ant-table-filter-dropdown-btns">
        {/* eslint-disable-next-line max-len */}
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <a className="ant-table-filter-dropdown-link confirm" onClick={handleConfirm}>Apply</a>
        {/* eslint-disable-next-line max-len */}
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <a
          className="ant-table-filter-dropdown-link clear"
          onClick={() => {
            if (allSelected) {
              return setSelectedKeys([]);
            }

            return setSelectedKeys(plainItems);
          }}
        >
          {allSelected ? 'Clear All' : 'Select All'}
        </a>
      </div>
    </>
  );
}

export default ColumnFilter