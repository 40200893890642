import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import FontAwesome from 'react-fontawesome';
import ReactTooltip from 'react-tooltip';
import { animateScroll } from 'react-scroll';

import { get } from 'lodash';

import { DateInput, CurrencyInput } from 'components/shared';
import { isDestroyed, isUpdated, isCreated } from '../../utils';

export const NewScheduleItem = (props) => {
  const {
    data: { date, amount, paymentAmount },
    disabled,
    currency,
    actions: { changeInput, changeDateInput, addLineItem },
  } = props;
  const isDisabled = disabled || (date === null || amount === null);

  return (
    <tbody>
      <tr>
        <td />
        <td>
          <DateInput
            id="new_date"
            name="payments_schedule[new_date]"
            dataStateKey="_new.date"
            value={date}
            onChange={changeDateInput}
          />
        </td>
        <td>
          <CurrencyInput
            id="new_amount"
            name="payments_schedule[new_amount]"
            dataStateKey="_new.amount"
            value={amount}
            currency={currency}
            onChange={changeInput}
          />
        </td>
        <td>
          <CurrencyInput
            id="new_payment_amount"
            name="payments_schedule[new_payment_amount]"
            dataStateKey="_new.paymentAmount"
            value={paymentAmount}
            currency={currency}
            onChange={changeInput}
          />
        </td>
        <td />
        <td />
        <td>
          <span className={'btn btn-default btn-width_sm' + (isDisabled ? ' disabled' : '')} onClick={addLineItem}>
            <i className="fa fa-plus" />
          </span>
        </td>
      </tr>
      <tr style={{ height: 61 }}>
        <td colSpan={5}>
          {!isDisabled && (
            <div className="alert alert-danger" style={{ marginBottom: 0 }}>
              {I18n.t('components.payments_schedule.actions.add.message')}
            </div>
          )}
        </td>
      </tr>
    </tbody>
  );
};

export class ScheduleItem extends Component {
  componentDidMount(prevProps) {
    const {
      props: { data },
    } = this;

    if (isCreated(data)) {
      this._scrollToPosition();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      props: {
        data,
        data: { date },
        edited,
      },
    } = this;

    if (prevProps.data.date !== date) {
      if (!edited && isUpdated(data)) {
        this._scrollToPosition();
      }
    }
  }

  _scrollToPosition() {
    const position = ReactDOM.findDOMNode(this).getBoundingClientRect();

    animateScroll.scrollTo(position.top, {
      smooth: true,
    });
  }

  render() {
    const { actions, index, data, errors, canDestroy, disabled, edited, currency, lastLockedPeriodDate } = this.props;
    const { date, amount, paymentAmount, paymentDate, receivableAmount } = data;
    const {
      changeDate,
      changeAmount,
      changePaymentAmount,
      changePaymentDate,
      doneLineItem,
      destroyLineItem,
      undoLineItem,
    } = actions;

    const styles = {
      created: {
        backgroundColor: '#dff0d8',
      },
      updated: {
        backgroundColor: '#fcf8e3',
      },
      disabled: {
        transition: '0.5s',
        opacity: 0.5,
      },
    };

    const rowStyle = Object.assign(
      {},
      disabled ? styles.disabled : {},
      isUpdated(data) ? styles.updated : {},
      isCreated(data) ? styles.created : {},
    );

    return isDestroyed(data) ? null : (
      <tr style={rowStyle}>
        <td className='text-centered' style={{ padding: '6px 10px' }}>
          {
            Date.parse(date) <= Date.parse(lastLockedPeriodDate) ? (
              <span data-tip='Period is locked'>
                <FontAwesome name="lock" />
                <ReactTooltip effect='solid' />
              </span>
            ) : (
              <FontAwesome className='text-muted' name="lock" />
            )
          }
        </td>
        <td>
          <DateInput
            id="payment_schedule_date"
            name="payment_schedule[date]"
            required
            dataStateKey={`items.${index}.date`}
            value={date}
            onChange={(e) => changeDate(e, index)}
            errors={get(errors, 'date')}
            disabled={disabled}
          />
        </td>
        <td>
          <CurrencyInput
            id="payment_schedule_amount"
            name="payment_schedule[amount]"
            required
            dataStateKey={`items.${index}.amount`}
            value={amount}
            onClick={() => console.log('123')}
            onChange={(e) => changeAmount(e, index)}
            currency={currency}
            errors={get(errors, 'amount')}
            disabled={disabled}
          />
        </td>
        <td>
          <CurrencyInput
            id="payment_schedule_payment_amount"
            name="payment_schedule[payment_amount]"
            required
            dataStateKey={`items.${index}.paymentAmount`}
            value={paymentAmount}
            onChange={(e) => changePaymentAmount(e, index)}
            currency={currency}
            errors={get(errors, 'paymentAmount')}
            disabled={disabled}
          />
        </td>
        <td>
          <DateInput
            id="payment_schedule_payment_date"
            name="payment_schedule[payment_date]"
            required
            dataStateKey={`items.${index}.paymentDate`}
            value={paymentDate}
            onChange={(e) => changePaymentDate(e, index)}
            errors={get(errors, 'paymentDate')}
            disabled={disabled}
          />
        </td>
        <td>
          {edited ? (
            <span className="btn btn-default" onClick={() => undoLineItem(index)} style={{ width: '100%' }}>
              <i className="fa fa-undo" />
            </span>
          ) : (
            <CurrencyInput
              id="payment_schedule_receivable_amount"
              name="payment_schedule[receivable_amount]"
              value={receivableAmount}
              currency={currency}
              allowNegative={true}
              disabled
            />
          )}
        </td>
        <td>
          {edited ? (
            <span
              className="btn btn-primary btn-width_sm"
              onClick={() => {
                confirm('Please click Update to save your changes');
                doneLineItem(index);
              }}
            >
              <i className="fa fa-check" style={{ font: 'normal normal normal 10px/1 FontAwesome' }} />
            </span>
          ) : (
            <span
              className={'btn btn-danger btn-width_sm' + (canDestroy ? '' : ' disabled')}
              onClick={() => {
                if (confirm('Are you sure?')) {
                  destroyLineItem(index);
                }
              }}
            >
              <i className="fa fa-trash" />
            </span>
          )}
        </td>
      </tr>
    );
  }
}
