import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  labelHint: PropTypes.node,
  htmlFor: PropTypes.string,
  required: PropTypes.bool,
};

const defaultProps = {
  className: 'form__label',
  label: undefined,
  labelHint: undefined,
  htmlFor: undefined,
  required: false,
};

function InputLabel(props) {
  const { className, label, labelHint, htmlFor, required } = props;

  return label ? (
    <label className={className} htmlFor={htmlFor}>
      {label}
      {!required && (
        <span>
          {' '}
          <span className="form__optional" title="Optional">
            optional
          </span>
        </span>
      )}
      {labelHint && <span className="form__label__hint">{labelHint}</span>}
    </label>
  ) : null;
}

InputLabel.propTypes = propTypes;
InputLabel.defaultProps = defaultProps;

export default InputLabel;
