import { deleteRequest, getRequest, postRequest, patchRequest, putRequest } from '../requests';
import fetch from '../fetch';

export default class TaskListPatternsApi {
  static submit(body, { id = null } = {}) {
    const url = id ? Routes.task_list_pattern_path(id) : Routes.task_list_patterns_path();
    const request = id ? putRequest({ body }) : postRequest({ body });

    return fetch(url, request);
  }

  static delete({ id }) {
    return fetch(Routes.task_list_pattern_path(id), deleteRequest());
  }
}
