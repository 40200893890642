import { find, uniq, filter, values } from 'ramda';

import API from 'apiAdapters/funds/investorDocumentDirectories/directories';
import * as types from 'constants/actionTypes';
import { catcher } from 'utils/actions';
import { getIn } from 'utils/ramda';
import { getAccountCollection } from 'selectors/funds/accounts';

import { closeDrawer, buildDirectoryTree, parseDocuments } from './index';



function create(directory) {
  return (dispatch) => {
    dispatch({ type: types.INVESTOR_DOCUMENTS_CREATE_DIRECTORY, payload: directory });
    dispatch(buildDirectoryTree());
  }
}

function update(id, props) {
  return (dispatch, getState) => {
    if (props.accesses) {
      const accessTypes = { class: 'Fund::InvestorClass', series: 'Fund::InvestorSeries', account: 'Fund::Account' };
      const fundAccounts = getAccountCollection(getState());
      const childEntities = props.accesses.reduce((acc, cur) => {
        if (cur.ownerType === accessTypes.class) {
          const classFundAccounts = fundAccounts.filter((account) => account.investorClass.id === cur.ownerId);
          const normalizedSeries = classFundAccounts.map((account) => ({ ownerId: account.investorSeries.id, ownerType: accessTypes.series }));
          const normalizedFundAccounts = classFundAccounts.map((account) => ({ ownerId: account.id, ownerType: accessTypes.account }));
          return [...acc, ...normalizedSeries, ...normalizedFundAccounts];
        }
        if (cur.ownerType === accessTypes.series) {
          const seriesFundAccounts = fundAccounts.filter((account) => account.investorSeries.id === cur.ownerId);
          const normalizedFundAccounts = seriesFundAccounts.map((account) => ({ ownerId: account.id, ownerType: accessTypes.account }));
          return [...acc, ...normalizedFundAccounts];
        }
        return [...acc]
      }, []);
      dispatch({ type: types.INVESTOR_DOCUMENTS_CHILDREN_ACCESSES, payload: { id, accesses: props.accesses, accessesToRemove: uniq(childEntities) } });
    }
    dispatch({ type: types.INVESTOR_DOCUMENTS_UPDATE_DIRECTORY, payload: { id, props } });
    dispatch(parseDocuments());
    dispatch(buildDirectoryTree());
  }
}

function remove(id) {
  return (dispatch) => {
    dispatch({ type: types.INVESTOR_DOCUMENTS_REMOVE_DIRECTORY, payload: { id } });
    dispatch(buildDirectoryTree());
  }
}


export function fetchCreateDirectory({ title, parentId, fundId, isPrivate }) {
  return catcher(async (dispatch) => {
    const { payload } = await API.create({ title, parentId, fundId, isPrivate })
    dispatch(create(payload));
  })
}

export function fetchRenameDirectory({ fundId, id, title }) {
  return catcher(async (dispatch) => {
    await API.rename(fundId, id, title);
    dispatch(update(id, { title }));
  })
}

export function fetchBindDirectory({ fundId, id, accesses }) {
  return catcher(async (dispatch) => {
    const { payload } = await API.bind(fundId, id, accesses);
    dispatch(update(id, { accesses: payload }));
    dispatch(closeDrawer());
  })
}

export function fetchRemoveDirectory({ fundId, id }) {
  return catcher(async (dispatch) => {
    await API.remove(fundId, id)
    dispatch(remove(id));
  })
}

export function disableCreateNewDirectory() {
  return (dispatch, getState) => {
    const { id } = find((item) => String(item.id).includes('folder') && String(item.id) !== 'root-add-folder', getIn('collection', getIn('fundInvestorDocumentDirectories', getIn('fund', getState())))) || {};
    if (id) { dispatch(remove(id)); }
  }
}

export function enableCreateNewDirectory(parentId, fundId, isPrivate) {
  const newFolder = {
    id: `${parentId}-folder`,
    isCreating: true,
    parentId,
    fundId: Number(fundId),
    isPrivate
  }
  return (dispatch) => {
    dispatch(disableCreateNewDirectory());
    dispatch(create(newFolder));
  }
}
