import React from 'react';
import PropTypes from 'prop-types';
import { accounting } from 'accounting';
import { stringToDate } from 'utils/dateTransformations';

const propTypes = {
  data: PropTypes.object.isRequired,
};

const InfoPanel = (props) => {
  const { data } = props;
  let i = 0;
  const prepareValue = (val) => {
    switch(val.type) {
      case 'currency':
        return accounting.formatMoney(val.value, data.currencySymbol, 2, ',', '.');
      case 'units':
        return accounting.formatNumber(val.value, data.unitsDecimal, ',', '.');
      case 'date':
        return val.value;
      case 'percent':
        return `${val.value}%`;
      case 'text':
        return val.value;
      default:
        return val.value;
    }
  };

  const prepareLabel = (k, v) => {
    if (v.label) {
      return v.label;
    }
    return _.startCase(k);
  };

  const items = _.map(data, (v, k) => v.value != null ? (
    <tr key={++i}>
      <td className="text-left text-bold">{prepareLabel(k, v)}</td>
      <td className="text-right">{prepareValue(v)}</td>
    </tr>
  ) : []);

  return (
    <table className="table table-no_bordered table-condensed">
      <tbody>
        {items}
      </tbody>
    </table>
  );
};

InfoPanel.propTypes = propTypes;

export default InfoPanel;
