import { useCallback } from 'react';
import { useAsync } from 'react-async';
import { useDispatch } from 'react-redux';

function useAsyncFetchDefer(action, params) {
  const dispatch = useDispatch();
  const fn = useCallback(
    ([val], props, { signal }) => {
      async function callback() {
        const res = await dispatch(action(val, signal));
        return res;
      }
      return callback();
    },
    [action, dispatch],
  );

  const { run, isPending, data, error, reload } = useAsync({
    deferFn: fn,
    ...params,
  });

  return [run, isPending, data, error, reload];
}

function useAsyncFetchPromise(action, params, watch) {
  const dispatch = useDispatch();
  const fn = useCallback(
    (val, { signal }) => {
      async function callback() {
        const res = await dispatch(action(val, signal));
        return res;
      }
      return callback();
    },
    [action, dispatch],
  );

  const { isPending, data, error } = useAsync({
    promiseFn: fn,
    ...params,
    watch,
  });

  return [isPending, data, error];
}

export { useAsyncFetchDefer, useAsyncFetchPromise };
