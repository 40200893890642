import React from 'react';
import PropTypes from 'prop-types';
import Textarea from 'react-textarea-autosize';

import { FormGroup } from '../';

const propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  dataStateKey: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.array,
};

const defaultProps = {
  type: 'text',
  disabled: false,
  value: '',
};

const TextArea = (props) => {
  const {
    id,
    type,
    name,
    dataStateKey,
    value,
    label,
    required,
    placeholder,
    onChange,
    errors,
    disabled,
    readonly,
    rows,
  } = props;

  return (
    <FormGroup label={label} required={required} id={id} errors={errors}>
      <Textarea
        id={id}
        type={type}
        required={required}
        name={name}
        value={value || ''}
        readOnly={readonly}
        data-statekey={dataStateKey}
        placeholder={placeholder}
        className="form__control"
        onChange={onChange}
        disabled={disabled}
        rows={rows}
      />
    </FormGroup>
  );
};

TextArea.propTypes = propTypes;
TextArea.defaultProps = defaultProps;

export default TextArea;
