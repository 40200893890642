import { REQUEST_IN_PROCESS_SET } from 'constants/actionTypes';

const setRequestInProcess = (inProcess, requestType, errors) => ({
  type: REQUEST_IN_PROCESS_SET,
  payload: {
    inProcess,
    requestType,
    errors,
  },
});

export default setRequestInProcess;
