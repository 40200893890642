import { addIndex, map } from 'ramda';

const mapIdx = addIndex(map);

const initialState = {
  collection: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case 'CREATE_DOCUMENT_PATTERN':
      return {
        ...state,
        collection: [...state.collection, payload],
      }
    case 'CHANGE_DOCUMENT_PATTERN_POSITION': {
      const { movedFrom, ...movingItem } = payload;
      const collection = state.collection.filter((item) => item.id !== payload.id && item.position !== payload.position);
      const itemThatHasToBeMovedOut = state.collection.find((item) => item.position === payload.position);
      return {
        ...state,
        collection: [...collection, { ...itemThatHasToBeMovedOut, position: movedFrom }, movingItem],
      }
    }
    case 'UPDATE_DOCUMENT_PATTERN':
      return {
        ...state,
        collection: [...state.collection.filter((item) => item.id !== payload.id), payload]
      }
    case 'DESTROY_DOCUMENT_PATTERN':
      return {
        ...state,
        collection: [...mapIdx((item, idx) => ({ ...item, position: idx + 1 }), state.collection.filter((item) => item.id !== payload.id))]
      };
    case 'OPEN_DOCUMENT_PATTERN_DRAWER':
      return {
        ...state,
        drawer: true,
      }
    case 'CLOSE_DOCUMENT_PATTERN_DRAWER':
      // case 'EDIT_DOCUMENT_PATTERN':
      return { ...state, drawer: false };
    default:
      return state;
  }
}
