import numeral from 'numeral';

import { immutablePush, immutableDelete, immutableSplice } from 'utils/immutableFunctions';
import { stringToDate } from 'utils/dateTransformations';
import { changeInput } from 'actions/changeInput';
import * as types from './actionTypes';
import { updateReceivableAmounts, changeManual, destroyTempItem } from '../../actions';
import { getUniqueKey, isCreated } from '../../utils';

export * from 'actions/changeInput';

export function changeDate(e, index) {
  return (dispatch, getState) => {
    const { paymentsSchedule: { _temp } } = getState();

    if (_temp.index != index) {
      dispatch(editLineItemAndChangeInputAndCreateTempItem(e, index));
    } else {
      dispatch(changeInput(e));
    }
  }
}

export function changeAmount(e, index) {
  return (dispatch, getState) => {
    const { paymentsSchedule: { _temp } } = getState();

    if (_temp.index != index) {
      dispatch(editLineItemAndChangeInputAndCreateTempItem(e, index));
    } else {
      dispatch(changeInput(e));
    }
  }
}

export function changePaymentAmount(e, index) {
  return (dispatch, getState) => {
    const { paymentsSchedule: { _temp } } = getState();

    if (_temp.index != index) {
      dispatch(editLineItemAndChangeInputAndCreateTempItem(e, index));
    } else {
      dispatch(changeInput(e));
    }
  }
}

export function changePaymentDate(e, index) {
  return (dispatch, getState) => {
    const { paymentsSchedule: { _temp } } = getState();

    if (_temp.index != index) {
      dispatch(editLineItemAndChangeInputAndCreateTempItem(e, index));
    } else {
      dispatch(changeInput(e));
    }
  }
}

export function addLineItem() {
  return (dispatch, getState) => {
    const { paymentsSchedule: { _new, items, manual } } = getState();
    const newItem = _new;
    const currentKeys = _.map(items, 'key');

    newItem.key = getUniqueKey(currentKeys);
    newItem.paymentAmount |= (0).toFixed(2);
    newItem._created = true;
    const newItems = immutablePush(items, newItem);

    dispatch({
      type: types.ADD_LINE_ITEM,
      payload: {
        items: newItems,
        new: {
          date: null,
          amount: null,
          paymentAmount: null
        }
      }
    });

    dispatch(updateReceivableAmounts());

    if (!manual) {
      dispatch(changeManual(true));
    }
  }
}

export function destroyLineItem(index) {
  return (dispatch, getState) => {
    const { paymentsSchedule: { items, manual } } = getState();
    const item = items[index];
    const newItems = (() => {
      if (item.id) {
        const newItem = Object.assign({}, item);
        newItem._destroy = true;

        return (immutableSplice(items, index, 1, newItem));
      }

      return immutableDelete(items, index);
    })();

    dispatch({
      type: types.DESTROY_LINE_ITEM,
      payload: {
        items: newItems,
      },
    });

    dispatch(updateReceivableAmounts());

    if (!manual) {
      dispatch(changeManual(true));
    }
  }
}

export function doneLineItem(index) {
  function manualPropsHasChanged(prevItemProps, currItemProps) {
    return prevItemProps.date != currItemProps.date || prevItemProps.amount != currItemProps.amount;
  }

  return (dispatch, getState) => {
    const { paymentsSchedule: { items, _temp, manual } } = getState();
    const item = items[index];
    const newItems = (() => {
      const newItem = Object.assign({}, item);
      newItem._updated = true;

      return immutableSplice(items, index, 1, newItem);
    })();

    dispatch({
      type: types.DONE_LINE_ITEM,
      payload: {
        items: newItems
      },
    });

    dispatch(destroyTempItem());
    dispatch(updateReceivableAmounts());

    if (!manual && manualPropsHasChanged(_temp, item)) {
      dispatch(changeManual(true));
    }
  }
}

export function undoLineItem(index) {
  return (dispatch, getState) => {
    const { paymentsSchedule: { items, _temp } } = getState();
    const item = items[index];

    const temp = Object.assign({}, _temp);
    delete temp.index;

    const newItems = (() => {
      const newItem = Object.assign({}, item, temp);

      return immutableSplice(items, index, 1, newItem);
    })();

    dispatch({
      type: types.UNDO_LINE_ITEM,
      payload: {
        items: newItems
      }
    });

    dispatch(destroyTempItem());
  }
}

function editLineItemAndChangeInputAndCreateTempItem(e, index) {
  return (dispatch, getState) => {
    const target = e.target ? e.target : e;
    const stateKey = target.getAttribute('data-statekey') || target.name.replace(/]/g, '').split('[').join('.');
    const type = target.getAttribute('data-type') || target.type;
    const targetValue = target.getAttribute('data-value') || target.value;
    const { paymentsSchedule: { items } } = getState();
    const item = items[index];
    const temp = Object.assign({}, item);

    delete temp._updated;
    delete temp._created;
    temp.index = index;

    const value = () => {
      switch (type) {
        case 'date':
          return stringToDate(targetValue) || null;

        case 'currency':
          return numeral(targetValue).value();

        default:
          return targetValue;
      }
    };

    dispatch({
      type: types.EDIT_LINE_ITEM_AND_CHANGE_INPUT_AND_CREATE_TEMP_ITEM,
      payload: {
        stateKey,
        value: value(),
        temp
      }
    });
  }
}