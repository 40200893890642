import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { InputError, InputLabel } from '../';

const propTypes = {
  children: PropTypes.node.isRequired,
  error: PropTypes.node,
  touched: PropTypes.bool,
  label: PropTypes.shape({
    id: PropTypes.string,
    labelHint: PropTypes.node,
    required: PropTypes.bool,
    htmlFor: PropTypes.string,
  }),
};

const defaultProps = {
  error: null,
  label: null,
  touched: false,
};

function InputGroup(props) {
  const { children, error, touched, label } = props;

  const classes = classNames({
    form__group: true,
    'form__group-errored': touched && error,
  });

  return (
    <div className={classes}>
      {label && <InputLabel {...label} />}
      {children}
      {touched && <InputError error={error} />}
    </div>
  );
}

InputGroup.propTypes = propTypes;
InputGroup.defaultProps = defaultProps;

export default InputGroup;
