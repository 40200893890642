import { deleteRequest, getRequest, postRequest, patchRequest, putRequest } from '../../requests';
import fetch from '../../fetch';

export default class TaskItemPatternsApi {
  static submit(body, { taskListPatternId, id = null } = {}) {
    const url = id
      ? Routes.task_list_pattern_task_item_pattern_path(taskListPatternId, id)
      : Routes.task_list_pattern_task_item_patterns_path(taskListPatternId);
    const request = id ? putRequest({ body }) : postRequest({ body });

    return fetch(url, request);
  }

  static delete({ taskListPatternId, id }) {
    return fetch(Routes.task_list_pattern_task_item_pattern_path(taskListPatternId, id), deleteRequest());
  }
}
