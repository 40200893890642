import { isNil } from 'ramda';
import { notification as antNotification } from 'antd';

function notification(type = 'open') {
  return (message, config = {}) => antNotification[type]({ message, ...config });
}

function getResMessage(res) {
  if (isNil(res)) return undefined;
  if (isNil(res.payload)) return undefined;
  if (isNil(res.payload.message)) return undefined;
  return res.payload.message;
}

export const openNotification = notification();
export const successNotification = notification('success');
export const errorNotification = notification('error');
export const warningNotification = notification('warning');
export const infoNotification = notification('info');
export const internalServerErrorNotification = () => errorNotification('Something went wrong.');

function responseNotification(type) {
  return (res, config = {}) => {
    const message = getResMessage(res);
    if (isNil(message)) return;
    notification(type)(message, config);
  };
}

export const responseSuccessNotification = responseNotification('success');
export const responseErrorNotification = responseNotification('error');
