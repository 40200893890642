import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field } from 'react-final-form';
import { Button, Select, Form, Input, Checkbox } from 'antd';

import { roles } from 'constants/user';
import { validateRequired } from 'utils/validates';

const { Option } = Select;

const propTypes = {
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.shape(PropTypes.object),
  pristine: PropTypes.bool,
  dependentItemOptions: PropTypes.arrayOf(PropTypes.object),
  isDisabledComplete: PropTypes.bool,
};

const defaultProps = {
  submitting: false,
  handleSubmit: undefined,
  initialValues: {},
  pristine: false,
  dependentItemOptions: [],
  isDisabledComplete: false,
};

const TaskItemForm = (props) => {
  const { submitting, handleSubmit, initialValues, isDisabledComplete, pristine, dependentItemOptions = [] } = props;
  const { completed } = initialValues;

  return (
    <Form layout="vertical" onSubmit={handleSubmit}>
      <Field name="title" validate={validateRequired()}>
        {({ input, meta }) => (
          <Form.Item
            label="Title"
            validateStatus={meta.error && meta.touched ? 'error' : 'success'}
            help={meta.error && meta.touched ? meta.error : undefined}
            required
          >
            <Input {...input} disabled={completed} />
          </Form.Item>
        )}
      </Field>
      <Field name="fundUserRole" validate={validateRequired()}>
        {({ input, meta }) => (
          <Form.Item
            label="User Role"
            validateStatus={meta.error && meta.touched ? 'error' : 'success'}
            help={meta.error && meta.touched ? meta.error : undefined}
            wrapperCol={{
              xs: { span: 24 },
              sm: { span: 24 },
              md: { span: 8 },
            }}
            required
          >
            <Select {...input} disabled={completed}>
              {roles.map((role) => (
                <Option value={role}>{role}</Option>
              ))}
            </Select>
          </Form.Item>
        )}
      </Field>
      <Field name="dependentFundValuationTaskItemId" parse={(v) => v || null}>
        {({ input }) => (
          <Form.Item label="Dependent Task">
            <Select {...input} allowClear={!!input.value} disabled={completed}>
              {dependentItemOptions.map(({ id, title }) => (
                <Option value={id}>{title}</Option>
              ))}
            </Select>
          </Form.Item>
        )}
      </Field>
      <Field name="completed">
        {({ input }) => (
          <Form.Item>
            <Checkbox {...input} checked={input.value} disabled={isDisabledComplete}>
              Completed
            </Checkbox>
          </Form.Item>
        )}
      </Field>
      <Form.Item>
        <Button type="primary" htmlType="submit" disabled={pristine || submitting}>
          {initialValues ? 'Update' : 'Create'}
        </Button>
      </Form.Item>
    </Form>
  );
};

const mapStateToProps = (state, ownPorps) => {
  const { initialValues: { completed, canBeUncompleted, dependentFundValuationTaskItem } = {} } = ownPorps;
  const isCanBeCompleted = dependentFundValuationTaskItem ? dependentFundValuationTaskItem.completed : true;
  const isDisabledComplete = !isCanBeCompleted || (completed && !canBeUncompleted);

  return {
    isDisabledComplete,
  };
};

TaskItemForm.propTypes = propTypes;
TaskItemForm.defaultProps = defaultProps;

export default connect(mapStateToProps, null)(TaskItemForm);
