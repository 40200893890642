import React from 'react';
import PropTypes from 'prop-types';
import FontAwesome from 'react-fontawesome';

import { sortBy } from 'lodash';
import Policy from '../../Policy';

const defaultProps = {
  adding: true,
};

class MappingItem extends React.Component {
  render() {
    const { actions, mappings, fundId, namespace, event, transactionKey, adding, changed } = this.props;
    const { deleteMapping, fillFormMapping, changeMode } = actions;

    const renderMappings = () => {
      if (mappings) {
        return sortBy(mappings, [
          (m) => {
            return m.securityKlass ? 1 : -1;
          },
        ]).map((m) => {
          return (
            <li key={m.id} className={`tree__item tree__actions_wrapper${m.id == changed ? ' scroll-to-this' : ''}`}>
              <em>{m.account.displayTitle}</em>
              {m.securityKlass && (
                <span>
                  {' '}
                  <span className="label label-default">{_.last(m.securityKlass.split(':'))}</span>
                </span>
              )}{' '}
              <Policy name='chart.mapping.delete' type='hide'>
                <span className="tree__actions">
                  <a
                    href
                    className="btn btn-danger btn-xs"
                    onClick={(e) => {
                      if (window.confirm('Are you sure you wish to delete this Chart Mapping?')) {
                        e.preventDefault();
                        deleteMapping(m.id);
                      }
                    }}
                  >
                    <FontAwesome name="trash" />
                  </a>
                </span>
              </Policy>
            </li>
          );
        });
      }
    };

    return (
      <ul className="tree m-b-none">
        {renderMappings()}
        {adding && (
          <Policy name='chart.mapping.add' type='hide'>
            <li className="tree__item m-b-none">
              <a
                href="#"
                className={mappings && mappings.length > 0 ? 'btn btn-sm btn-default' : 'btn btn-sm btn-danger'}
                onClick={(e) => {
                  e.preventDefault();
                  fillFormMapping({
                    namespace: _.snakeCase(namespace),
                    event: _.snakeCase(event),
                    transactionKey: _.snakeCase(transactionKey),
                    securityKlass: undefined,
                  });
                  changeMode('mappingForm');
                }}
              >
                {'Add Mapping'}
              </a>
            </li>
          </Policy>
        )}
      </ul>
    );
  }
}

MappingItem.defaultProps = defaultProps;

export default MappingItem;
