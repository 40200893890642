import { deleteRequest, getRequest, postRequest, patchRequest, putRequest } from '../../../requests';
import fetch from '../../../fetch';

export default class TaskItemsApi {
  static submit(body, { fundId, valuationId, id = null } = {}) {
    const url = id
      ? Routes.fund_valuation_task_item_path(fundId, valuationId, id)
      : Routes.fund_valuation_task_items_path(fundId, valuationId);
    const request = id ? putRequest({ body }) : postRequest({ body });

    return fetch(url, request);
  }

  static delete({ fundId, valuationId, id }) {
    return fetch(Routes.fund_valuation_task_item_path(fundId, valuationId, id), deleteRequest());
  }
}
