import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { getIn } from 'utils/ramda';

import createRootReducer from '../reducers';
import { normalizeAccounts } from '../normalizrs/accounts';

export const history = createBrowserHistory();

export function configureStore(initialState) {
  const enhancers = [];
  const middleware = [thunk, routerMiddleware(history)];

  if (process.env.NODE_ENV === 'development') {
    const { __REDUX_DEVTOOLS_EXTENSION__ } = window;

    if (typeof __REDUX_DEVTOOLS_EXTENSION__ === 'function') {
      enhancers.push(__REDUX_DEVTOOLS_EXTENSION__());
    }
  }

  const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers,
  );

  const state = {
    ...initialState,
    ...normalizeAccounts(getIn('fund.accounts.collection', initialState)),
  };

  return createStore(createRootReducer(history), state, composedEnhancers);
}
