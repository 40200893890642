import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, Popconfirm, Popover } from 'antd';

import { decimalCurrencyFormat } from 'utils/formats';
import { getIsNeedToShowAdditionalBtns } from 'utils/accruals';

const propTypes = {
  paymentItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  paymentsScheduleBalance: PropTypes.arrayOf(PropTypes.number).isRequired,
  handleDelete: PropTypes.func.isRequired,
};

function initPaymentsScheduleColumns(props) {
  const { currencySymbol } = props;

  const initColumns = [
    {
      title: I18n.t('activerecord.attributes.fund/accrual/payment_item.date'),
      dataIndex: 'date',
      key: 'date',
      editable: true,
      type: 'date',
      width: '170px',
    },
    {
      title: I18n.t('activerecord.attributes.fund/accrual/payment_item.amount'),
      dataIndex: 'amount',
      key: 'amount',
      editable: true,
      width: '170px',
      render: (text) => `${currencySymbol} ${decimalCurrencyFormat(text)}`,
    },
    {
      title: I18n.t('activerecord.attributes.fund/accrual/payment_item.payment_amount'),
      dataIndex: 'paymentAmount',
      key: 'paymentAmount',
      editable: true,
      width: '170px',
      render: (text) => (text ? `${currencySymbol} ${decimalCurrencyFormat(text)}` : text),
    },
    {
      title: I18n.t('activerecord.attributes.fund/accrual/payment_item.payment_date'),
      dataIndex: 'paymentDate',
      key: 'paymentDate',
      editable: true,
      type: 'date',
      width: '170px',
    },
    {
      title: I18n.t('activerecord.attributes.fund/accrual/payment_item.manual_entries_amount'),
      dataIndex: 'manualEntriesAmount',
      key: 'manualEntriesAmount',
      width: '170px',
      render: (text) => `${currencySymbol} ${decimalCurrencyFormat(text)}`,
    },
    {
      title: I18n.t('activerecord.attributes.fund/accrual/payment_item.other_accruals_amount'),
      dataIndex: 'otherAccrualsAmount',
      key: 'otherAccrualsAmount',
      width: '170px',
      render: (text) => `${currencySymbol} ${decimalCurrencyFormat(text)}`,
    },
    {
      title: I18n.t('activerecord.attributes.fund/accrual/payment_item.balance'),
      dataIndex: 'balance',
      key: 'balance',
      width: '170px',
      render: (text, record) => {
        // eslint-disable-next-line react/prop-types
        const { paymentsScheduleBalance } = props;

        return (
          // eslint-disable-next-line max-len
          <span className="table__balance">{`${currencySymbol} ${decimalCurrencyFormat(paymentsScheduleBalance[record.id])}`}</span>
        );
      },
    },
    {
      title: 'Operations',
      dataIndex: 'operation',
      key: 'operation',
      width: 97,
      render: (text, record) => {
        // eslint-disable-next-line react/prop-types
        const { editableKeys, handleSubmit, handleDelete, paymentItems } = props;
        const isNeedToShowAdditionalBtns = getIsNeedToShowAdditionalBtns(record.id, editableKeys);

        // eslint-disable-next-line react/prop-types
        return paymentItems.length >= 1 && record.id ? (
          <div className="table__operations">
            {
              // eslint-disable-next-line no-nested-ternary
              record.approvedPeriod ? (
                <Popover content='Valuation period already approved' trigger='hover'>
                  <Button type="link" className="payment_schedule__operation_btn" disabled>
                    <Icon type="lock" />
                  </Button>
                </Popover>
              ) : (
                  isNeedToShowAdditionalBtns ? (
                    <Button
                      type="primary"
                      shape='circle'
                      className="payment_schedule__operation_btn pulse"
                      onClick={() => handleSubmit(record.id)}
                      title="Update"
                      ghost
                    >
                      <Icon type="check" />
                    </Button>
                  ) : (
                      <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.id)}>
                        <Button type="link" className="payment_schedule__operation_btn" title="Delete">
                          <Icon type="delete" />
                        </Button>
                      </Popconfirm>
                    )
                )
            }
          </div>
        ) : null;
      },
    },
  ];

  return initColumns.map((col) => ({
    ...col,
    onCell: (record, index) => ({
      record,
      index,
      editable: col.editable,
      dataIndex: col.dataIndex,
      title: col.title,
      type: col.type,
    }),
  }));
}

initPaymentsScheduleColumns.propTypes = propTypes;

export default initPaymentsScheduleColumns;
