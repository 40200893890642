import React from 'react';
import PropTypes from 'prop-types';

import Select from 'react-select';

import { FormGroup } from '../';

const propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  loadOptions: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  required: PropTypes.bool,
  includeBlank: PropTypes.bool,
  prompt: PropTypes.string,
  errors: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  optionRenderer: PropTypes.func,
  disabled: PropTypes.bool,
  clearable: PropTypes.bool,
  autoload: PropTypes.bool,
  valueKey: PropTypes.string.isRequired,
  labelKey: PropTypes.string.isRequired,
  noResultsText: PropTypes.string,
  optionClassName: PropTypes.string,
};

const defaultProps = {
  includeBlank: false,
  prompt: 'Please select…',
  value: '',
  label: null,
  required: false,
  errors: null,
  onChange: null,
  disabled: false,
  clearable: false,
  autoload: true,
  noResultsText: 'No results',
};

const SelectAsync = (props) => {
  const {
    id,
    name,
    value,
    label,
    required,
    onChange,
    errors,
    includeBlank,
    prompt,
    disabled,
    loadOptions,
    clearable,
    noResultsText,
    valueKey,
    labelKey,
    autoload,
    optionRenderer,
    optionClassName,
    valueRenderer,
  } = props;

  return (
    <FormGroup label={label} required={required} id={id} errors={errors}>
      <Select.Async
        id={id}
        name={name}
        autoload={autoload}
        loadOptions={loadOptions}
        valueKey={valueKey}
        labelKey={labelKey}
        clearable={clearable}
        noResultsText={noResultsText}
        value={value}
        onChange={onChange}
        optionRenderer={optionRenderer}
        optionClassName={optionClassName}
        valueRenderer={valueRenderer}
      />
    </FormGroup>
  );
};

SelectAsync.propTypes = propTypes;
SelectAsync.defaultProps = defaultProps;

export default SelectAsync;
