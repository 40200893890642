import { createSelector } from 'reselect';
import { compact, includes, last, values, sortBy } from 'lodash';

const accountQuerySelector = state => state.accountQuery;
const chartsSelector = state => state.entities && state.entities.charts;
const categoriesSelector = state => state.entities && state.entities.categories;
const accountsSelector = state => state.entities && state.entities.accounts;
const mappingsSelector = state => state.entities && compact(values(state.entities.mappings));

const getMappingsSelector = createSelector(
  mappingsSelector,
  mappings => mappings,
);

const getAccountsSelector = createSelector(
  accountsSelector,
  getMappingsSelector,
  accountQuerySelector,
  (accounts, mappings, query) =>
    compact(values(sortBy(accounts, ['code']))).map(account => (
      account.displayTitle.toLowerCase().includes(query.toLowerCase()) && {
        ...account,
        mappings: mappings.filter(mapping => includes(account.mappings, mapping.id) && mapping),
      }
    ),
  ),
);

const getCategoriesSelector = createSelector(
  categoriesSelector,
  getAccountsSelector,
  accountsSelector,
  accountQuerySelector,
  (categories, accounts, allAccounts, query) =>
    compact(values(sortBy(categories, ['code']))).map((category) => {
      const categoryAccounts = compact(values(accounts)).filter(account =>
        includes(category.accounts, account.id) && account,
      );
      const showAccounts = !query || categoryAccounts.length > 0;

      return (showAccounts && {
        ...category,
        accounts: categoryAccounts,
        newAccountCode: (allAccounts ? allAccounts[last(category.accounts)] : allAccounts) ? allAccounts[last(category.accounts)].code + 10 : 100,
      });
    },
  ),
);

export const getChartsOfAccountsTree = createSelector(
  chartsSelector,
  getCategoriesSelector,
  categoriesSelector,
  (charts, categories, allCategories) =>
    compact(values(charts)).map((chart) => {
      const chartCategories = compact(values(categories)).filter(category =>
        includes(chart.categories, category.id) && category,
      );

      return ({
        ...chart,
        categories: chartCategories,
        newCategoryCode: (allCategories ? allCategories[last(chart.categories)] : allCategories) ? allCategories[last(chart.categories)].code + 10 : 10,
      });
    },
  ),
);

export default getChartsOfAccountsTree;
