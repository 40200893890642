import { immutablePush, immutableDelete } from 'utils/immutableFunctions';

import * as types from './actionTypes';

const initialState = {
  isVisible: false,
  accountQuery: '',
  fieldForSelectAccount: null,
  mode: 'tree',
  isCollapsed: true,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case types.CHART_OF_ACCOUNTS_FETCH_REQUESTED:
      return {
        ...state,
        loading: action.payload.loading,
      };

    case types.CHART_OF_ACCOUNTS_FETCH_COMPLETED:
      return {
        ...state,
        ...action.payload.chartOfAccounts,
      };

    case types.CHART_OF_ACCOUNTS_TOGGLE:
      return {
        ...state,
        isVisible: action.payload.isVisible,
      };

    case types.FIELD_FOR_SELECT_ACCOUNT_SET:
      return {
        ...state,
        fieldForSelectAccount: action.payload.field,
      };

    case types.ON_ACCOUNT_ADD: {
      const newAccount = action.payload.account;
      const categoryAccounts = state.entities.categories[newAccount.categoryId].accounts;

      return {
        ...state,
        entities: {
          ...state.entities,
          accounts: {
            ...state.entities.accounts,
            [newAccount.id]: newAccount,
          },
          categories: {
            ...state.entities.categories,
            [newAccount.categoryId]: {
              ...state.entities.categories[newAccount.categoryId],
              accounts: immutablePush(categoryAccounts, newAccount.id),
            },
          },
        },
      };
    }

    case types.ON_ACCOUNT_UPDATE: {
      const account = action.payload.account;
      const mappingsCollapsed = state.entities.accounts[account.id].mappingsCollapsed;

      return {
        ...state,
        entities: {
          ...state.entities,
          accounts: {
            ...state.entities.accounts,
            [account.id]: {
              ...account,
              mappings: account.mappings.map((mapping) => mapping.id),
              mappingsCollapsed,
            },
          },
        },
      };
    }

    case types.ON_ACCOUNT_DELETE: {
      const category = state.entities.categories[action.payload.account.categoryId];
      const accounts = category.accounts;
      const accountIndex = accounts.indexOf(action.payload.account.id);

      return {
        ...state,
        entities: {
          ...state.entities,
          accounts: {
            ...state.entities.accounts,
            [action.payload.account.id]: undefined,
          },
          categories: {
            ...state.entities.categories,
            [category.id]: {
              ...state.entities.categories[category.id],
              accounts: immutableDelete(accounts, accountIndex),
            },
          },
        },
      };
    }

    case types.ON_CATEGORY_ADD: {
      const newCategory = action.payload.category;
      const chartCategories = state.entities.charts[newCategory.chart].categories;

      return {
        ...state,
        entities: {
          ...state.entities,
          charts: {
            ...state.entities.charts,
            [newCategory.chart]: {
              ...state.entities.charts[newCategory.chart],
              categories: immutablePush(chartCategories, newCategory.id),
            },
          },
          categories: {
            ...state.entities.categories,
            [newCategory.id]: newCategory,
          },
        },
      };
    }

    case types.ON_CATEGORY_UPDATE: {
      const category = action.payload.category;
      const charts = state.entities.charts;
      const chartCategories = charts[category.chart].categories;
      const chartPreviousCategories = charts[category.previousChart].categories;

      const accountsCollapsed = state.entities.categories[category.id].accountsCollapsed;

      return {
        ...state,
        entities: {
          ...state.entities,
          charts: {
            ...charts,
            [category.previousChart]: {
              ...charts[category.previousChart],
              categories: immutableDelete(chartPreviousCategories, chartPreviousCategories.indexOf(category.id)),
            },
            [category.chart]: {
              ...charts[category.chart],
              categories: immutablePush(chartCategories, category.id),
            },
          },
          categories: {
            ...state.entities.categories,
            [category.id]: {
              ...category,
              accountsCollapsed,
            },
          },
        },
      };
    }

    case types.ON_CATEGORY_DELETE: {
      const chart = state.entities.charts[action.payload.category.chart];
      const categories = chart.categories;
      const categoryIndex = categories.indexOf(action.payload.category.id);

      return {
        ...state,
        entities: {
          ...state.entities,
          charts: {
            ...state.entities.charts,
            [chart.key]: {
              ...state.entities.charts[chart.key],
              categories: immutableDelete(categories, categoryIndex),
            },
          },
          categories: {
            ...state.entities.categories,
            [action.payload.category.id]: undefined,
          },
        },
      };
    }

    case types.ON_MAPPING_ADD: {
      const newMapping = action.payload.mapping;
      const accountMappings = state.entities.accounts[newMapping.accountId].mappings;

      return {
        ...state,
        entities: {
          ...state.entities,
          mappings: {
            ...state.entities.mappings,
            [newMapping.id]: newMapping,
          },
          accounts: {
            ...state.entities.accounts,
            [newMapping.accountId]: {
              ...state.entities.accounts[newMapping.accountId],
              mappings: immutablePush(accountMappings, newMapping.id),
              mappingsCollapsed: false,
            },
          },
        },
      };
    }

    case types.ON_MAPPING_DELETE: {
      const account = state.entities.accounts[action.payload.mapping.accountId];
      const mappings = account.mappings;
      const mappingIndex = mappings.indexOf(action.payload.mapping.id);

      return {
        ...state,
        entities: {
          ...state.entities,
          mappings: {
            ...state.entities.mappings,
            [action.payload.mapping.id]: undefined,
          },
          accounts: {
            ...state.entities.accounts,
            [action.payload.mapping.accountId]: {
              ...state.entities.accounts[action.payload.mapping.accountId],
              mappings: immutableDelete(mappings, mappingIndex),
            },
          },
        },
      };
    }

    case types.MODE_CHANGE:
      return {
        ...state,
        mode: action.payload.mode,
        activeChart: action.payload.activeChart,
      };

    case types.ACCOUNT_QUERY_CHANGE:
      return {
        ...state,
        accountQuery: action.payload.value,
      };

    case types.ACCOUNTS_TOGGLE: {
      const categoryId = action.payload.categoryId;

      return {
        ...state,
        entities: {
          ...state.entities,
          categories: {
            ...state.entities.categories,
            [categoryId]: {
              ...state.entities.categories[categoryId],
              accountsCollapsed: !state.entities.categories[categoryId].accountsCollapsed,
            },
          },
        },
      };
    }

    case types.MAPPINGS_TOGGLE: {
      const accountId = action.payload.accountId;

      return {
        ...state,
        entities: {
          ...state.entities,
          accounts: {
            ...state.entities.accounts,
            [accountId]: {
              ...state.entities.accounts[accountId],
              mappingsCollapsed: !state.entities.accounts[accountId].mappingsCollapsed,
            },
          },
        },
      };
    }

    case types.ALL_TOGGLE:
      return {
        ...state,
        isCollapsed: action.payload.isCollapsed,
        entities: {
          ...state.entities,
          accounts: {
            ...action.payload.accounts,
          },
          categories: {
            ...action.payload.categories,
          },
        },
      };

    default:
      return state;
  }
}
