import { immutableSplice } from 'utils/immutableFunctions';

import { actionTypes } from './index';

const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.VALUATION_OVERRIDE_ITEM_UPDATE_SUCCEEDED: {
      const { index } = action.meta;
      return immutableSplice(state, index, 1, action.payload);
    }

    case actionTypes.VALUATION_OVERRIDE_ITEMS_UPDATE_SUCCEEDED: {
      return action.payload;
    }

    default:
      return state;
  }
};
