import * as types from './actionTypes';
import { changeInput } from '../../../actions/changeInput';

export const addBandPattern = () => (dispatch, getState) => {
  const { bandPatterns } = getState();
  const bandPattern = lastBandPattern(bandPatterns);

  dispatch({
    type: types.ADD_BAND_PATTERN,
    payload: {
      bandPattern: {
        id: undefined,
        lowerBoundPercentage: bandPattern ? bandPattern.upperBoundPercentage : 0,
        upperBoundPercentage: undefined,
        rate: undefined,
        _destroy: false,
      },
    },
  });
};

export const deleteBandPattern = (e, position) => (dispatch) => {
  dispatch({
    type: types.DELETE_BAND_PATTERN,
    payload: {
      position,
    },
  });
};

export const changeLowerBoundPercentage = (e, position) => (dispatch, getState) => {
  dispatch(changeInput(e));

  const { bandPatterns } = getState();
  const bandPattern = prevBand(position, bandPatterns);

  dispatch({
    type: types.CHANGE_LOWER_BOUND_PERCENTAGE,
    payload: {
      index: bandPattern ? bandPatterns.indexOf(bandPattern) : undefined,
      value: e.target.value,
    },
  });
};

export const changeUpperBoundPercentage = (e, position) => (dispatch, getState) => {
  dispatch(changeInput(e));

  const { bandPatterns } = getState();
  const bandPattern = nextBand(position, bandPatterns);

  dispatch({
    type: types.CHANGE_UPPER_BOUND_PERCENTAGE,
    payload: {
      index: bandPattern ? bandPatterns.indexOf(bandPattern) : undefined,
      value: e.target.value,
    },
  });
};

const nextBand = (currentPosition, bandPatterns) => {
  if ((currentPosition + 1) === bandPatterns.length) return null;

  return bandPatterns[currentPosition + 1];
};

const prevBand = (currentPosition, bandPatterns) => {
  if (currentPosition === 0) return null;

  return bandPatterns[currentPosition - 1];
};

const lastBandPattern = (bandPatterns) => {
  if (bandPatterns.length === 0) return null;

  return bandPatterns[bandPatterns.length - 1];
};

export * from '../../../actions/changeInput';
