import React from 'react';
import { CurrencyInput } from 'components/shared';

const Band = (props) => {
  const {
    // eslint-disable-next-line react/prop-types
    position, id, lowerBoundPercentage, upperBoundPercentage, rate, _destroy, actions, last, errors,
  } = props;

  const { changeInput, deleteBand, changeLowerBoundPercentage, changeUpperBoundPercentage } = actions;

  return (
    <div className="form__inline form__inline-width_lg">
      <CurrencyInput
        id={`lower_bound_percentage${position}`}
        name={`fee[bands_attributes][${position}][lower_bound_percentage]`}
        dataStateKey={`bands.[${position}].lowerBoundPercentage`}
        value={lowerBoundPercentage}
        label='Lower bound'
        required
        currency='%'
        decimalLimit={7}
        onChange={(e) => changeLowerBoundPercentage(e, position)}
        errors={_.get(errors, 'lowerBoundPercentage') ? [' '] : undefined}
      />
      <CurrencyInput
        id={`upper_bound_percentage${position}`}
        name={`fee[bands_attributes][${position}][upper_bound_percentage]`}
        dataStateKey={`bands.[${position}].upperBoundPercentage`}
        value={upperBoundPercentage}
        label='Upper bound'
        required={!last}
        currency='%'
        decimalLimit={7}
        onChange={(e) => changeUpperBoundPercentage(e, position)}
        errors={_.get(errors, 'upperBoundPercentage') ? [' '] : undefined}
      />
      <CurrencyInput
        id={`rate${position}`}
        name={`fee[bands_attributes][${position}][rate]`}
        dataStateKey={`bands.[${position}].rate`}
        value={rate}
        label='Rate'
        required
        currency='%'
        decimalLimit={7}
        onChange={changeInput}
        errors={_.get(errors, 'rate') ? [' '] : undefined}
      />
      <input
        type="hidden"
        name={`fee[bands_attributes][${position}][id]`}
        id={`id${position}`}
        value={id}
      />
      <input
        type="hidden"
        name={`fee[bands_attributes][${position}][_destroy]`}
        id={`_destroy${position}`}
        value={_destroy ? 'true' : 'false'}
      />
      <button type="button" className="btn btn-danger btn-width_sm" onClick={(e) => deleteBand(e, position)}>
        <i className="fa fa-trash" />
      </button>
    </div>
  );
};

const Form = (props) => {
  // eslint-disable-next-line react/prop-types
  const { bands, errors, actions, actions: { addBand }, disabled, baseErrors } = props;
  const isLastBand = (currentBand) => currentBand === bands[bands.length - 1];

  return (
    <div>
      <legend className="form__legend">Bands</legend>
      {/* eslint-disable-next-line jsx-a11y/aria-role */}
      <div role="bands">
        {
          bands.map((band, i) => (
            // eslint-disable-next-line no-underscore-dangle,react/no-array-index-key
            <div className={band._destroy ? 'hide' : 'show'} key={i}>
              <Band {...band}
                actions={actions}
                position={i}
                errors={errors[i]}
                last={isLastBand(band)}
              />
              <hr className="hr" />
            </div>
          ))
        }
        <div className="form__group">
          <button
            type="button"
            className={baseErrors.length > 0 && bands.length === 0 ? 'btn btn-danger' : 'btn btn-default'}
            onClick={addBand}
            disabled={disabled}
          >
            <i className="fa fa-plus" />
            Add Band
          </button>
        </div>
      </div>
    </div>
  );
};

export default Form;
