import { toLower } from 'ramda';
import React from 'react';

/* eslint-disable react/prop-types */
function Name({ searchValue, item, associate = false }) {
  const { name } = item;
  const index = toLower(name).indexOf(searchValue);
  const beforeStr = name.substr(0, index);
  const searchStr = name.substr(beforeStr.length, searchValue.length);
  const afterStr = name.substr(index + searchValue.length);
  // const type = associate ? role : 'Investor';

  return (
    <>
      { index > -1 ? (
        <span className="found-item">
          {beforeStr}
          <span style={{ color: '#f50' }}>{searchStr}</span>
          {afterStr}
        </span>
      ) : (
          <span>{`${name}`}</span>
        )}
    </>
  )
};

export default Name;