import React from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Drawer, Row } from 'antd';
import { Field } from 'react-final-form';
import { connect } from 'react-redux';
import { compose, pure } from 'recompose';

import selectOptionShape from 'constants/propTypes/refs';
import formShape from 'constants/propTypes/form';
import { FormGroup } from 'components/shared/form';
import {
  getFundClassOptions,
  getSerieOptionsByClassId,
  getIsShareBased,
} from 'components/Funds/Valuations/ValuationOverride/selectors';

const propTypes = {
  applyHandler: PropTypes.func,
  classOptions: PropTypes.arrayOf(PropTypes.shape(selectOptionShape)),
  form: PropTypes.shape(formShape),
  seriesOptions: PropTypes.arrayOf(PropTypes.shape(selectOptionShape)),
  isDecidedClass: PropTypes.bool,
  isNeedShowSeriesField: PropTypes.bool,
  onClose: PropTypes.func,
  resetHandler: PropTypes.func,
  visible: PropTypes.bool,
};

const defaultProps = {
  applyHandler: undefined,
  classOptions: [],
  form: undefined,
  seriesOptions: [],
  isDecidedClass: false,
  isNeedShowSeriesField: false,
  onClose: undefined,
  resetHandler: undefined,
  visible: false,
};

function FilterForm(props) {
  const {
    applyHandler,
    classOptions,
    form,
    seriesOptions,
    isDecidedClass,
    isNeedShowSeriesField,
    onClose,
    resetHandler,
    visible,
  } = props;

  return (
    <>
      {isDecidedClass && (
        <Button type="link" onClick={resetHandler}>
          Reset Filters
        </Button>
      )}

      <Drawer title="Filters" placement="right" onClose={onClose} visible={visible} width={270}>
        <div>
          <Row>
            <Col>
              <Field
                name="classId"
                label="Class"
                type="select"
                component={FormGroup}
                options={classOptions}
                style={{ width: '100%' }}
                onSelect={form.mutators.resetSeriesId}
              />
            </Col>
          </Row>

          {isNeedShowSeriesField && (
            <Row>
              <Col>
                <Field
                  name="seriesId"
                  label="Series"
                  type="select"
                  component={FormGroup}
                  options={seriesOptions}
                  style={{ width: '100%' }}
                />
              </Col>
            </Row>
          )}

          <div className="drawer__footer">
            {isDecidedClass && (
              <Button type="link" onClick={resetHandler}>
                Reset Filters
              </Button>
            )}
            <Button className="m-r-xs" onClick={onClose}>
              Close
            </Button>
            <Button onClick={applyHandler} type="primary" disabled={!isDecidedClass}>
              Apply
            </Button>
          </div>
        </div>
      </Drawer>
    </>
  );
}

const mapStateToProps = (state, ownProps) => {
  const { clearInitialValues, form, onClose, handleSubmit, values } = ownProps;
  const classId = values && values.classId;
  const classOptions = getFundClassOptions(state);
  const seriesOptions = getSerieOptionsByClassId(state, classId);
  const isDecidedClass = !!(values && values.classId);
  const isSharedBase = getIsShareBased(state);
  const isNeedShowSeriesField = !!(isDecidedClass && !isSharedBase);

  const applyHandler = () => {
    handleSubmit();
    onClose();
  };

  const resetHandler = async () => {
    await clearInitialValues();
    form.reset();
    handleSubmit();
  };

  return {
    applyHandler,
    classOptions,
    seriesOptions,
    isDecidedClass,
    isNeedShowSeriesField,
    resetHandler,
  };
};

const enhance = compose(
  connect(mapStateToProps),
  pure,
);

FilterForm.propTypes = propTypes;
FilterForm.defaultProps = defaultProps;

export default enhance(FilterForm);
