import React from 'react';
import PropTypes from 'prop-types';

import { Block, Checkbox, CurrencyInput, DateInput, Input, Select } from 'components/shared';

const Investment = (props) => {
  const { resourceFund, resourceFund: { baseCurrencyIso }, trade, summary, security, submitting, errors, success, refs: { pePartnershipSecurityTradeTypes }, actions } = props;

  return (
    <div>
      <Block
        title={"Trade Details"}
        fields={
          <div>
            <div className='row'>
              <div className='col-md-6'>
                <DateInput
                  id='trade_date'
                  required
                  name='trade[trade_date]'
                  dataStateKey='trade.tradeDate'
                  label={"Trade Date"}
                  value={trade.tradeDate}
                  onChange={actions.updateTradeDate}
                  errors={_.get(errors, 'tradeDate')}
                />
              </div>
              <div className='col-md-6'>
                <DateInput
                  id='settle_date'
                  required
                  name='trade[settle_date]'
                  dataStateKey='trade.settleDate'
                  label={"Settle Date"}
                  value={trade.settleDate}
                  onChange={actions.changeDateInput}
                  errors={_.get(errors, 'settleDate')}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <Select
                  id={`type`}
                  name={'trade[type]'}
                  required
                  dataStateKey={`trade.type`}
                  value={trade.type}
                  onChange={actions.changeInput}
                  options={pePartnershipSecurityTradeTypes}
                  errors={_.get(errors, 'type')}
                  includeBlank
                  prompt='Please select'
                  label={'Trade Type'}
                />
              </div>
              <div className="col-lg-6 col-md-6 col-xs-12">
                <CurrencyInput
                  id='price'
                  name='trade[price]'
                  required
                  dataStateKey='trade.price'
                  value={trade.price}
                  label={'Price'}
                  onChange={actions.changeInput}
                  errors={_.get(errors, 'price')}
                  disabled={Boolean(trade.parentId)}
                  currency={security.currencySymbol}
                  decimalLimit={8}
                />
              </div>
            </div>
          </div>
        }
      />
      <Block
        title={"Fees & Taxes"}
        fields={
          <div>
            { trade.fx && (
              <div className="row">
                <div className="col-md-12 col-lg-12">
                  <Checkbox
                    label={I18n.t('activerecord.attributes.fund/trade.base_fund_fees_currency')}
                    name='trade[base_fund_fees_currency]'
                    dataStateKey='trade.baseFundFeesCurrency'
                    onChange={actions.changeFeesCurrency}
                    checked={trade.baseFundFeesCurrency}
                    disabled
                    value={trade.baseFundFeesCurrency || false}
                    errors={_.get(errors, 'baseFundFeesCurrency')}
                  />
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-md-6">
                <CurrencyInput
                  id="commission_fee"
                  name="trade[commission_fee]"
                  dataStateKey='trade.commissionFee'
                  value={trade.commissionFee}
                  label={"Commission Fee"}
                  onChange={actions.changeInput}
                  errors={_.get(errors, 'commissionFee')}
                  disabled={Boolean(trade.parentId)}
                  currency={security.currencySymbol}
                />
              </div>
              <div className="col-md-6">
                <CurrencyInput
                  id="broker_fee"
                  name="trade[broker_fee]"
                  dataStateKey='trade.brokerFee'
                  value={trade.brokerFee}
                  label={"Broker Fee"}
                  onChange={actions.changeInput}
                  errors={_.get(errors, 'brokerFee')}
                  disabled={Boolean(trade.parentId)}
                  currency={security.currencySymbol}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <CurrencyInput
                  id="sec_fee"
                  name="trade[sec_fee]"
                  dataStateKey='trade.secFee'
                  value={trade.secFee}
                  label={"SEC Fee"}
                  onChange={actions.changeInput}
                  errors={_.get(errors, 'secFee')}
                  disabled={Boolean(trade.parentId)}
                  currency={security.currencySymbol}
                />
              </div>
              <div className="col-md-6">
                <CurrencyInput
                  id="other_fee"
                  name="trade[other_fee]"
                  dataStateKey='trade.otherFee'
                  value={trade.otherFee}
                  label={"Other Fee"}
                  onChange={actions.changeInput}
                  errors={_.get(errors, 'otherFee')}
                  disabled={Boolean(trade.parentId)}
                  currency={security.currencySymbol}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <CurrencyInput
                  id="taxes"
                  name="trade[taxes]"
                  dataStateKey='trade.taxes'
                  value={trade.taxes}
                  label={"Taxes"}
                  onChange={actions.changeInput}
                  errors={_.get(errors, 'taxes')}
                  disabled={Boolean(trade.parentId)}
                  currency={security.currencySymbol}
                />
              </div>
            </div>
          </div>
        }
      />
      <Block
        title={"Rates"}
        subtitle={"(Native / Rate)"}
        visible={trade.fx}
        fields={
          <div>
            <div className="row">
              <div className="col-md-6">
                <Input
                  id='trade_fx_rate'
                  type='number'
                  name='trade[trade_fx_rate]'
                  dataStateKey='trade.tradeFxRate'
                  value={trade.tradeFxRate}
                  step='0.01'
                  required
                  label={'Trade FX Rate'}
                  onChange={actions.changeFxRate}
                  errors={_.get(errors, 'tradeFxRate')}
                  disabled={Boolean(trade.parentId)}
                />
              </div>
              <div className="col-md-6">
                <Input
                  id='settle_fx_rate'
                  type='number'
                  name='trade[settle_fx_rate]'
                  dataStateKey='trade.settleFxRate'
                  value={trade.settleFxRate}
                  step='0.01'
                  required
                  label={'Settle FX Rate'}
                  onChange={actions.changeInput}
                  errors={_.get(errors, 'settleFxRate')}
                  disabled={Boolean(trade.parentId)}
                />
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default Investment;
