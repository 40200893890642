import React from 'react';
import PropTypes from 'prop-types';
import humps from 'humps';
import FontAwesome from 'react-fontawesome';
import Select from 'react-select';
import { Block, FormAlerts } from 'components/shared';
import * as TradeForm from './TradeForm';

const propTypes = {
  resourceFund: PropTypes.object.isRequired,
  trade: PropTypes.object.isRequired,
  summary: PropTypes.object.isRequired,
  security: PropTypes.object,
  type: PropTypes.string,
  actions: PropTypes.shape({
    getSecurityOptions: PropTypes.func.isRequired,
    changeSecurity: PropTypes.func.isRequired,
    updateTradeDate: PropTypes.func.isRequired,
    changeDateInput: PropTypes.func.isRequired,
    changeInput: PropTypes.func.isRequired,
    submitForm: PropTypes.func.isRequired,
  }).isRequired,
  submitting: PropTypes.bool,
  errors: PropTypes.object,
  success: PropTypes.object,
};

const FundsBatchesTradesForm = (props) => {
  const { trade, security, searchSecurity, previousSecurity, submitting, errors, success } = props;
  const {
    selectSearchSecurityType,
    getSecurityOptions,
    changeSecurity,
    removeSecurity,
    submitForm,
    clearPreviousSecurity,
    restorePreviousSecurity,
    changeSearchExpiredSecurity,
  } = props.actions;

  const formUrl = trade.id
    ? Routes.fund_batch_trade_path(trade.fundId, trade.batchId, trade.id, { format: 'json' })
    : Routes.fund_batch_trades_path(trade.fundId, trade.batchId, { format: 'json' });

  const formMethod = trade.id ? 'put' : 'post';
  const onSubmit = (e, commit = null) => {
    e.preventDefault();

    const formData = {
      trade: humps.decamelizeKeys(trade),
      commit,
    };

    submitForm(formData, formUrl, formMethod);
  };

  return (
    <div>
      <form onSubmit={(e) => onSubmit(e)} className="form" noValidate="novalidate">
        <input type='hidden' name="trade[broker_account_id]" value={trade.brokerAccountId} />
        <div className="row">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-5">
                <FormAlerts errors={errors} success={success} />
                <Block
                  title={trade.securityId ? 'Security' : 'Please Select Security'}
                  fields={
                    <div>
                      <div className="form__group">
                        <div className='form__inline'>
                          <div className='simple-select-wrapper'>
                            <Select
                              id='search_security_type'
                              name='search_security[type]'
                              className='simple-select'
                              clearable={false}
                              searchable={false}
                              value={searchSecurity ? searchSecurity.type : null}
                              onChange={selectSearchSecurityType}
                              options={[
                                { label: 'Equity', value: 'Security::Equity' },
                                { label: 'Option', value: 'Security::Option' },
                                { label: 'Currency', value: 'Security::Currency' },
                                { label: 'Commodity', value: 'Security::Commodity' },
                                { label: 'Crypto Currency', value: 'Security::CryptoCurrency' },
                                { label: 'Index', value: 'Security::Index' },
                                { label: 'Fixed Income', value: 'Security::FixedIncome' },
                                { label: 'Tax Lien', value: 'Security::TaxLien' },
                                { label: 'Future', value: 'Security::Future' },
                                { label: 'Partnership Investment', value: 'Security::PartnershipInvestment' },
                                { label: 'Private Equity Investment', value: 'Security::PrivateEquityInvestment' },
                                { label: 'Warrant', value: 'Security::Warrant' },
                              ]}
                              includeBlank
                              placeholder="Type..."
                            />
                          </div>
                          { searchSecurity
                            && searchSecurity.type
                              && (searchSecurity.type === 'Security::Option' || searchSecurity.type === 'Security::Future')
                                && (
                                  <div className='simple-checkbox-wrapper'>
                                    <div className='simple-checkbox'>
                                      <label>
                                        <input
                                          name='search_security[expired]'
                                          onChange={changeSearchExpiredSecurity}
                                          value='1'
                                          checked={(searchSecurity && searchSecurity.expired) || false}
                                          type="checkbox"
                                        />
                                        {' '}
                                        Expired
                                      </label>
                                    </div>
                                  </div>
                                )
                          }
                        </div>
                        <div className={security && 'form__addon form__addon-right'}>
                          <Select.Async
                            name="trade[security_id]"
                            id="security_id"
                            autoload={false}
                            loadOptions={getSecurityOptions}
                            valueKey="id"
                            clearable={false}
                            escapeClearsValue={false}
                            onBlurResetsInput={false}
                            trimFilter
                            labelKey="display_title"
                            noResultsText='No results'
                            searchPromptText="Type to search (3 characters minimum)"
                            placeholder="Select security name..."
                            value={security && { display_title: security.displayTitle }}
                            onChange={changeSecurity}
                            onInputChange={removeSecurity}
                            errors={_.get(errors, 'securityId')}
                          />
                          {security && (
                            <span className="form__addon_icon">
                              <a href={Routes.security_path(security.id)} target="_blank">
                                <FontAwesome name="eye" />
                              </a>
                            </span>
                          )}
                        </div>
                      </div>
                      {previousSecurity && (
                        <div className="small">
                          <a href={Routes.security_path(previousSecurity.id)} target="_blank">
                            {previousSecurity.displayTitle}
                          </a>
                          <div className="pull-right">
                            <a className="btn btn-xs btn-link" onClick={() => clearPreviousSecurity(previousSecurity)}>
                              clear
                            </a>
                            <a className="btn btn-xs btn-link" onClick={() => restorePreviousSecurity(previousSecurity)}>
                              restore
                            </a>
                          </div>
                        </div>
                      )}
                    </div>
                  }
                />
                <TradeForm.Fields {...props} />
                {security && (
                  <div className="panel">
                    <div className="panel__body panel__body-padded">
                      <button
                        type="submit"
                        value="Save and add more"
                        className="btn btn-primary pull-right"
                        disabled={submitting}
                        onClick={(e) => onSubmit(e, 'save_and_add_more')}
                      >
                        {submitting && <FontAwesome name="spinner" spin />}
                        {submitting && ' '}
                        {I18n.t('buttons.save_and_add_more')}
                      </button>

                      <button type="submit" className="btn btn-primary" disabled={submitting}>
                        {trade.id ? I18n.t('buttons.update') : I18n.t('buttons.create')}
                        {submitting && ' '}
                        {submitting && <FontAwesome name="spinner" spin />}
                      </button>
                      <a className="btn btn-link" href={Routes.fund_trades_path(trade.fundId)}>
                        {'Cancel'}
                      </a>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-md-7">
                <TradeForm.Summary {...props} />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

FundsBatchesTradesForm.propTypes = propTypes;
export default FundsBatchesTradesForm;
