import set from 'lodash/fp/set';
import { get, omit, isEmpty } from 'lodash';

import { immutablePush, immutableDelete, immutableSplice } from 'utils/immutableFunctions';

import * as types from './actionTypes';
import { fetchPrice } from './calculateAmounts';

const initialState = {
  data: {},
  meta: {
    errors: {},
    submitting: false,
    entryChanged: false,
    entryNeedBeUpdated: false,
  },
};

export default function(state = initialState, action) {
  let lineItems = state.data.lineItems;

  switch (action.type) {
    case types.ACCOUNT_SELECT:
      state = set(`${action.payload.field}.accountId`, action.payload.value)(state);
      state = set(`${action.payload.field}.accountName`, action.payload.name)(state);
      state = set(`${action.payload.field}.specialAllocationType`, 'auto')(state);

      return {
        ...state,
        data: {
          ...state.data,
        },
      };

    case types.ACCOUNTING_DATE_INPUT_CHANGE: {
      const fundId = action.payload.fundId;
      const currencyIso = action.payload.currencyIso;
      const fundBaseCurrencyIso = action.payload.fundBaseCurrencyIso;
      const accountingDate = action.payload.accountingDate;
      const priceType = action.payload.priceType;
      const price = fetchPrice(fundId, priceType, currencyIso, fundBaseCurrencyIso, accountingDate);

      return {
        ...state,
        data: {
          ...state.data,
          accountingDate,
          rate: price.rate,
          securityMasterRate: price.rate,
          securityPricingDate: price.createdAt,
        },
      };
    }

    case types.ALLOCATED_AMOUNTS_TOGGLE:
      return set(
        `data.lineItems.${action.payload.lineItemIndex}.showAllocatedAmounts`,
        !state.data.lineItems[`${action.payload.lineItemIndex}`].showAllocatedAmounts,
      )(state);

    case types.ALLOCATED_AMOUNTS_SET_TYPE:
      return set(`data.lineItems.${action.payload.lineItemIndex}.specialAllocationType`, action.payload.value)(state);

    case types.AMOUNT_CHANGE: {
      const lineItem = lineItems[action.payload.index];
      if (action.payload.type === 'creditAmount') {
        lineItem.creditAmount = action.payload.amount;
      } else if (action.payload.type === 'debitAmount') {
        lineItem.debitAmount = action.payload.amount;
      }
      const newLineItems = immutableSplice(lineItems, action.payload.index, 1, lineItem);

      return {
        ...state,
        data: {
          ...state.data,
          lineItems: newLineItems,
        },
      };
    }

    case types.CHANGE_INPUT:
      return set(action.payload.stateKey, action.payload.value)(state);

    case types.CURRENCY_INPUT_CHANGE: {
      const fundId = action.payload.fundId;
      const currencyIso = action.payload.currencyIso;
      const fundBaseCurrencyIso = action.payload.fundBaseCurrencyIso;
      const accountingDate = action.payload.accountingDate;
      const priceType = action.payload.priceType;
      const price = fetchPrice(fundId, priceType, currencyIso, fundBaseCurrencyIso, accountingDate);

      return {
        ...state,
        data: {
          ...state.data,
          currencyIso,
          rate: price.rate,
          securityMasterRate: price.rate,
          securityPricingDate: price.createdAt,
        },
      };
    }

    case types.DESCRIPTION_CHANGE:
      return {
        ...state,
        data: {
          ...state.data,
          description: action.payload.value,
        },
      };

    case types.ENTRY_CHANGE:
      return {
        ...state,
        data: {
          ...state.data,
          entry: action.payload.value,
        },
        meta: {
          ...state.meta,
          entryChanged: action.payload.changed,
          entryNeedBeUpdated: false,
        },
      };

    case types.ENTRY_NEED_BE_UPDATED:
      return {
        ...state,
        meta: {
          ...state.meta,
          entryNeedBeUpdated: true,
        },
      };

    case types.ENTRY_SET:
      return {
        ...state,
        data: {
          ...state.data,
          entry: action.payload.entry,
        },
      };

    case types.LINE_ITEM_ADD: {
      lineItems = immutablePush(lineItems, {
        glEntryId: null,
        fundId: state.data.fundId,
        creditAmount: null,
        debitAmount: null,
        allocatedAmounts: [],
        specialAllocation: false,
      });

      return {
        ...state,
        data: {
          ...state.data,
          lineItems,
        },
      };
    }

    case types.LINE_ITEM_SPECIAL_ALLOCATION_SET:
      return set(`data.lineItems.${action.payload.lineItemIndex}.specialAllocation`, action.payload.special)(state);

    case types.LINE_ITEM_SPECIAL_ALLOCATION_TOTAL_SET:
      return set(`data.lineItems.${action.payload.lineItemIndex}.specialAllocationTotal`, action.payload.total)(state);

    case types.LINE_ITEM_SPECIAL_ALLOCATION_TOTAL_ERROR_SET:
      return {
        ...state,
        meta: {
          ...state.meta,
          errors: {
            ...state.meta.errors,
            lineItems: {
              ...get(state, 'meta.errors.lineItems'),
              [action.payload.lineItemIndex]: {
                specialAllocation: [action.payload.errorText],
              },
            },
          },
        },
      };

    case types.LINE_ITEM_SPECIAL_ALLOCATION_TOTAL_ERROR_CLEAR: {
      const errorsLineItemsIsEmpty = get(state, 'meta.errors.lineItems');

      if (errorsLineItemsIsEmpty) {
        let errors = {
          ...state.meta.errors,
          lineItems: omit(state.meta.errors.lineItems, action.payload.lineItemIndex),
        };

        errors = isEmpty(errors.lineItems) ? omit(errors, 'lineItems') : errors;

        return {
          ...state,
          meta: {
            ...state.meta,
            errors,
          },
        };
      }
      return state;
    }

    case types.LINE_ITEM_REMOVE: {
      const lineItem = lineItems[action.payload.index];

      const newLineItems = (() => {
        if (lineItem.id) {
          lineItem._destroy = true;
          return immutableSplice(lineItems, action.payload.index, 1, lineItem);
        }
        return immutableDelete(lineItems, action.payload.index);
      })();

      return {
        ...state,
        data: {
          ...state.data,
          lineItems: newLineItems,
        },
      };
    }

    case types.SUBMITTING_FORM:
      return {
        ...state,
        meta: {
          ...state.meta,
          submitting: action.payload.submitting,
        },
      };

    case types.SUBMITTING_FORM_SUCCEEDED:
      return {
        ...state,
        meta: {
          ...state.meta,
          errors: null,
          success: action.payload.data,
        },
      };

    case types.SUBMITTING_FORM_FAILED:
      return {
        ...state,
        meta: {
          ...state.meta,
          errors: action.payload.errors,
        },
      };

    default:
      return state;
  }
}
