import set from 'lodash/fp/set';

import { immutablePush, immutableDelete, immutableSplice } from 'utils/immutableFunctions';

import * as types from './actionTypes';

const initialState = [];

export default function(state = initialState, action) {
  switch (action.type) {
    case types.ALLOCATED_AMOUNT_SET_SPECIAL:
      return set(action.payload.stateKey, true)(state);

    case types.ALLOCATED_AMOUNT_CHANGE:
      return set(action.payload.stateKey, action.payload.value)(state);

    case types.ALLOCATED_AMOUNT_ADD:
      return immutablePush(state, action.payload.allocatedAmount);

    case types.ALLOCATED_AMOUNT_TOGGLE: {
      const allocatedAmount = action.payload.allocatedAmount;

      if (allocatedAmount.id) {
        allocatedAmount._destroy = !allocatedAmount._destroy;
        // allocatedAmount.amount = 0;
        allocatedAmount.selected = !allocatedAmount.selected;
        return immutableSplice(state, action.payload.index, 1, allocatedAmount);
      }
      return immutableDelete(state, action.payload.index);
    }

    default:
      return state;
  }
}
