import { createSelector } from 'reselect';
import { isDestroyed } from './utils';

const itemsSelector = state => state.paymentsSchedule.items;

export const updateReceivableForItems = createSelector(
  itemsSelector,
  (items) => {
    let prevReceivableAmount = 0;

    return sortItemsByDate(items).map((item) => {
      const newItem = Object.assign({}, item);

      if (!isDestroyed(newItem)) {
        newItem.receivableAmount = parseFloat(newItem.amount || 0) - parseFloat(newItem.paymentAmount || 0);
        newItem.receivableAmount += prevReceivableAmount;
        prevReceivableAmount = newItem.receivableAmount;
      }

      return newItem;
    });
  }
);

export const filterDestroyItems = createSelector(
  itemsSelector,
  (items) => {
    const newItems = items.slice(0);
    return newItems.filter(item => !isDestroyed(item));
  },
);

const sortItemsByDate = items => items.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());