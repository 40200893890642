import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import classNames from 'classnames';

import { InputGroup } from '..';

const propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  input: PropTypes.shape({
    checked: PropTypes.bool,
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onDragStart: PropTypes.func,
    onDrop: PropTypes.func,
    onFocus: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
  }).isRequired,
  meta: PropTypes.shape({
    error: PropTypes.node,
    touched: PropTypes.bool,
  }).isRequired,

  currency: PropTypes.string,
  decimalLimit: PropTypes.number,
  hint: PropTypes.node,
  label: PropTypes.string,
  labelHint: PropTypes.node,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.oneOf(['text', 'number']),
};

const defaultProps = {
  className: 'form__control',
  disabled: false,

  currency: undefined,
  decimalLimit: 2,
  hint: undefined,
  label: undefined,
  labelHint: undefined,
  placeholder: undefined,
  required: false,
  type: 'text',
};

class CurrencyInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currencyMask: createNumberMask({
        allowDecimal: true,
        prefix: '',
        decimalLimit: props.decimalLimit,
        allowNegative: props.allowNegative,
      }),
    };
  }

  render() {
    const {
      className,
      currency,
      input,
      id,
      type,
      label,
      labelHint,
      hint,
      required,
      placeholder,
      disabled,
      meta: { error, touched },
    } = this.props;

    const addonClass = classNames({
      form__addon_icon: true,
      'form__addon_icon-disabled': disabled,
    });

    return (
      <InputGroup error={error} touched={touched} label={{ label, labelHint, required, htmlFor: id }}>
        <div className="form__addon form__addon-left">
          <span className={addonClass}>{currency || '$'}</span>
          <MaskedInput
            {...input}
            className={className}
            data-type="currency"
            disabled={disabled}
            id={id}
            mask={this.state.currencyMask}
            placeholder={placeholder}
            required={required}
            type={type}
          />
        </div>

        {hint && <p className="form__hint">{hint}</p>}
      </InputGroup>
    );
  }
}

CurrencyInput.propTypes = propTypes;
CurrencyInput.defaultProps = defaultProps;

export default CurrencyInput;
