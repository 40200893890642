import { createSelector } from 'reselect';

const getSelectedAccount = (state, accountId) =>
  state.chartOfAccounts &&
  state.chartOfAccounts.entities &&
  state.chartOfAccounts.entities.accounts &&
  state.chartOfAccounts.entities.accounts[accountId];

const getSelectedAccountTitle = createSelector(getSelectedAccount, (account) => account && account.displayTitle);

export default getSelectedAccountTitle;
