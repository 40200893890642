import { combineReducers } from 'redux';

import taskItems from './taskItems';
import taskNotes from './taskNotes';
import administrativeFeeAmount from './administrativeFeeAmount';

const details = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default combineReducers({
  details,
  taskItems,
  taskNotes,
  administrativeFeeAmount
});
