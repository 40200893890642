import React from 'react';
import PropTypes from 'prop-types';
import withReduxProvider from 'components/hocs/withReduxProvider';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { hot } from 'react-hot-loader';

import { isEmpty } from 'lodash';

import ValuationOverrideTable from './ValuationOverrideTable';

import configureStore from './store';
import { actions } from './index';
import { getResourceFund, getValuation, getValuationOverrideItemIds, getValuationOverrideItems } from './selectors';

const propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  resourceFund: PropTypes.shape({
    id: PropTypes.number.isRequired,
    currencySymbol: PropTypes.string.isRequired,
  }).isRequired,
  valuationOverrideItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      managementFeesAmount: PropTypes.string,
      performanceFeesAmount: PropTypes.string,
      openingPriorHighAmount: PropTypes.string,
      allocation: PropTypes.shape({
        openingGrossAmount: PropTypes.string,
        periodIncomeAmount: PropTypes.string,
        preFeeGrossAmount: PropTypes.string,
        managementFeesAmount: PropTypes.string,
        performanceFeesAmount: PropTypes.string,
        openingPriorHighAmount: PropTypes.string,
        closingPriorHighAmount: PropTypes.string,
        closingGrossAmount: PropTypes.string,
      }),
      source: PropTypes.shape({
        name: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
      }),
    }),
  ),
};

const defaultProps = {
  valuationOverrideItems: [],
};

function ValuationOverride(props) {
  const { actions, showResetAll, valuationOverrideItems, resourceFund, valuation, handleExportData } = props;

  return (
    <ValuationOverrideTable
      currencySymbol={resourceFund.currencySymbol}
      onSubmit={actions.saveValuationOverrideItem}
      onReset={actions.resetValuationOverrideItem}
      showResetAll={showResetAll}
      onResetAll={actions.resetValuationOverrideItems}
      valuationOverrideItems={valuationOverrideItems}
      defaultIfNull={valuation.report}
      handleExportData={actions.exportTemplate}
    />
  );
}

const mapStateToProps = (state) => ({
  resourceFund: getResourceFund(state),
  valuation: getValuation(state),
  showResetAll: !isEmpty(getValuationOverrideItemIds(state)),
  valuationOverrideItems: getValuationOverrideItems(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

ValuationOverride.propTypes = propTypes;
ValuationOverride.defaultProps = defaultProps;

export default withReduxProvider(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(hot(module)(ValuationOverride)),
  configureStore,
);
