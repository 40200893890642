import axios from 'axios';
import humps from 'humps';

import { stringToDate, dateToString } from 'utils/dateTransformations';
import { submitForm } from 'actions/submitForm';

import * as types from './actionTypes';
import { changeInput } from 'actions/changeInput';

export * from 'actions/changeInput';

export function changeSecurity(security) {
  return (dispatch, getState) => {
    const camelizedSecurity = humps.camelizeKeys(security);

    const { resourceFund: { baseCurrencyIso }, tradeExerciseForm: { data: { securityId, tradeDate } } } = getState();
    const { currencyIso } = camelizedSecurity;

    if (camelizedSecurity.id !== securityId) {
      dispatch(clearRealizationsData());
      dispatch(updateSecurity(camelizedSecurity));

      if (camelizedSecurity && (baseCurrencyIso != currencyIso)) {
        dispatch(updateFxRateByCurrencyIso(currencyIso, baseCurrencyIso, tradeDate));
      }
    }

    dispatch(updateRealizations());
    dispatch(updateCurrentHoldings());
  }
}

export function updateQuantity(event) {
  return (dispatch, getState) => {
    dispatch(clearRealizationsData());
    dispatch(changeInput(event));

    const { tradeExerciseForm: { data: { securityId, signedQuantity } } } = getState();

    if (securityId && signedQuantity && signedQuantity > 0) {
      dispatch(fetchRealizations());
      dispatch(fetchCurrentHoldingsForSecurity());
      dispatch(fetchCurrentHoldingsForUnderlyingInvestment());
    }
  };
}

export function changeFxRate(event) {
  return (dispatch, getState) => {
    const { tradeExerciseForm: { data: { tradeFxRate, settleFxRate } } } = getState();
    const needSettleFxRateUpdate = ((tradeFxRate == settleFxRate) || !settleFxRate) ? true : false;

    dispatch(changeInput(event));
    if (needSettleFxRateUpdate) {
      dispatch(makeSettleFxRateEqualToTradeFxRate());
    };
  };
}

export function updateTradeDate(event) {
  return (dispatch, getState) => {
    const target = event.target ? event.target : event;
    const value = target.value;
    const newTradeDate = stringToDate(value);

    dispatch({
      type: types.TRADE_DATE_UPDATE,
      payload: {
        value: newTradeDate,
      },
    });
    dispatch({ type: types.CLEAR_SECURITY_DATA });
    dispatch(clearRealizationsData());
    dispatch(fetchSecurities());

    const { resourceFund, security } = getState();

    if (newTradeDate && security && (resourceFund.baseCurrencyIso != security.currencyIso)) {
      dispatch(updateFxRateByCurrencyIso(security.currencyIso, resourceFund.baseCurrencyIso, newTradeDate));
    }
  }
}

export function onSubmit(e) {
  return (dispatch, getState) => {
    const { tradeExerciseForm: { data, data: { fundId, batchId } } } = getState();
    const formData = {
      trade: data,
    };

    e.preventDefault();

    dispatch(
      submitForm(
        humps.decamelizeKeys(formData),
        Routes.fund_batch_trade_exercises_path(fundId, batchId, { format: 'json' }),
        'post',
      ),
    );
  };
}

export function changeFeesCurrency(event) {
  return (dispatch) => {
    dispatch(changeInput(event));
    dispatch(convertFeesByFxRate(event.target.value === 'true'));
  };
}

function updateRealizations() {
  return (dispatch, getState) => {
    const { tradeExerciseForm: { data: { securityId, signedQuantity } } } = getState();

    if (securityId && signedQuantity && signedQuantity > 0) {
      dispatch(fetchRealizations());
    }
  }
}

function updateCurrentHoldings() {
  return (dispatch, getState) => {
    const { tradeExerciseForm: { data: { securityId, signedQuantity } } } = getState();

    if (securityId && signedQuantity && signedQuantity > 0) {
      dispatch(fetchCurrentHoldingsForSecurity());
      dispatch(fetchCurrentHoldingsForUnderlyingInvestment());
    }
  }
}

function convertFeesByFxRate(invert = false) {
  return (dispatch, getState) => {
    const { tradeExerciseForm: { data: { tradeFxRate, commissionFee, brokerFee, secFee, otherFee, taxes } } } = getState();
    const calculate = (fee = 0) => invert ? fee * tradeFxRate : fee / tradeFxRate;

    dispatch({
      type: types.CONVERT_FEES_BY_FX_RATE,
      payload: {
        commissionFee: calculate(commissionFee),
        brokerFee: calculate(brokerFee),
        secFee: calculate(secFee),
        otherFee: calculate(otherFee),
        taxes: calculate(taxes)
      },
    });
  };
}

function fetchRealizations() {
  return (dispatch, getState) => {
    const { tradeExerciseForm: { data: { fundId, batchId, securityId, tradeDate, signedQuantity } } } = getState();

    if (securityId && tradeDate && signedQuantity) {
      dispatch(fetchRealizationsRequested());

      axios.get(Routes.fund_batch_realizations_path(fundId, batchId, { format: 'json' }), {
        withCredentials: true,
        params: {
          security_id: securityId,
          trade_date: tradeDate,
          requested_quantity: signedQuantity,
        },
      }).then((res) => {
        dispatch(fetchRealizationsSucceeded(humps.camelizeKeys(res.data)));
      }).catch((error) => {
        console.log(error);

        dispatch(fetchRealizationsFailed("We're sorry, but something went wrong (500)"));
      });
    }
  };
}

function fetchSecurities() {
  return (dispatch, getState) => {
    const { tradeExerciseForm: { data: { tradeDate, brokerAccountId } } } = getState();

    if (tradeDate) {
      dispatch(fetchSecuritiesRequested());

      axios.get(Routes.securities_path({
        format: 'json',
        search: {
          expiration_date_gteq: tradeDate,
          broker_account_id_eq: brokerAccountId,
          other_underlying_investment_eq: false,
        }
      }), {
        withCredentials: true,
      }).then((res) => {
        dispatch(fetchSecuritiesSucceeded(humps.camelizeKeys(res.data)));
      }).catch(() => {
        dispatch(fetchSecuritiesFailed("We're sorry, but something went wrong (500)"));
      });
    }
  }
}

function fetchCurrentHoldingsForSecurity() {
  return (dispatch, getState) => {
    const { tradeExerciseForm: { data: { fundId, batchId, securityId, tradeCreatedAt, tradeDate } } } = getState();

    if (securityId && tradeDate) {
      dispatch(fetchCurrentHoldingsRequested());

      axios.get(Routes.fund_batch_holdings_path(fundId, batchId, { format: 'json' }), {
        withCredentials: true,
        params: {
          trade_date: tradeDate,
          trade_created_at: tradeCreatedAt,
          security_id: securityId,
        },
      }).then((res) => {
        const camelizedData = humps.camelizeKeys(res.data);
        const pricingDate = camelizedData.pricingDate ? stringToDate(camelizedData.pricingDate) : undefined;

        return dispatch(fetchCurrentHoldingsForSecurityCompleted(
          camelizedData.currentHoldings,
          camelizedData.totalSells,
          camelizedData.totalBuys,
          camelizedData.withSecurityPrice,
          pricingDate,
          camelizedData.securityPrice,
        ));
      });
    }
  }
}

function fetchCurrentHoldingsForUnderlyingInvestment() {
  return (dispatch, getState) => {
    const { tradeExerciseForm: { data: { fundId, batchId, tradeDate, tradeCreatedAt } } } = getState();
    const { security } = getState();

    if (security.underlyingInvestment.id && tradeDate) {
      dispatch(fetchCurrentHoldingsForUnderlyingInvestmentRequested());

      axios.get(Routes.fund_batch_holdings_path(fundId, batchId, { format: 'json' }), {
        withCredentials: true,
        params: {
          trade_date: tradeDate,
          trade_created_at: tradeCreatedAt,
          security_id: security.underlyingInvestment.id,
        },
      }).then((res) => {
        const camelizedData = humps.camelizeKeys(res.data);

        return dispatch(fetchCurrentHoldingsForUnderlyingInvestmentCompleted(camelizedData.currentHoldings));
      });
    }
  }
}

function fetchSecuritiesRequested() {
  return { type: types.SECURITIES_FETCH_REQUESTED };
}

function fetchSecuritiesSucceeded(data) {
  return {
    type: types.SECURITIES_FETCH_SUCCEEDED,
    payload: {
      securities: data
    },
  };
}

function fetchSecuritiesFailed(errorText) {
  return {
    type: types.SECURITIES_FETCH_FAILED,
    payload: {
      errorText
    },
  };
}

function fetchCurrentHoldingsRequested() {
  return { type: types.CURRENT_HOLDINGS_FOR_SECURITY_FETCH_REQUESTED };
}

function fetchCurrentHoldingsForSecurityCompleted(currentHoldings, totalSells, totalBuys, withSecurityPrice, pricingDate, securityPrice) {
  return {
    type: types.CURRENT_HOLDINGS_FOR_SECURITY_FETCH_COMPLETED,
    payload: {
      currentHoldings,
      totalSells,
      totalBuys,
      withSecurityPrice,
      pricingDate,
      securityPrice
    }
  }
}

function fetchCurrentHoldingsForUnderlyingInvestmentRequested() {
  return { type: types.CURRENT_HOLDINGS_FOR_UNDERLYING_INVESTMENT_FETCH_REQUESTED };
}

function fetchCurrentHoldingsForUnderlyingInvestmentCompleted(currentHoldings) {
  return {
    type: types.CURRENT_HOLDINGS_FOR_UNDERLYING_INVESTMENT_FETCH_COMPLETED,
    payload: {
      currentHoldings
    }
  }
}

function fetchRealizationsRequested() {
  return { type: types.REALIZATIONS_FETCH_REQUESTED }
}

function fetchRealizationsSucceeded(data) {
  return {
    type: types.REALIZATIONS_FETCH_SUCCEEDED,
    payload: {
      realizations: data
    }
  }
}

function fetchRealizationsFailed(errorText) {
  return {
    type: types.REALIZATIONS_FETCH_FAILED,
    payload: {
      errorText
    }
  }
}

function fetchPrice(priceType, currencyIso, fundBaseCurrencyIso, accountingDate) {
  const result = {
    rate: 1,
    createdAt: '-',
  };

  if (currencyIso === fundBaseCurrencyIso) {
    result.rate = 1;
  } else {
    $.ajax({
      url: Routes.fx_rate_path({ search: {
        buy_currency_iso: currencyIso,
        sell_currency_iso: fundBaseCurrencyIso,
        date: dateToString(accountingDate),
        price_type: priceType || 'close'
      } }),
      type: 'GET',
      dataType: 'json',
      async: false, //!
      error: function (callback) {
        return callback;
      },
      success: function (res) {
        if (res !== undefined) {
          result.rate = res.rate;
          result.createdAt = res.date;
        }
      },
    });
  }

  console.log(result);

  return result;
}

function makeSettleFxRateEqualToTradeFxRate() {
  return { type: types.MAKE_SETTLE_FX_RATE_EQUAL_TO_TRADE_FX_RATE };
}

function clearRealizationsData() {
  return { type: types.CLEAR_REALIZATIONS_DATA };
}

function updateSecurity(security) {
  return {
    type: types.SECURITY_UPDATE,
    payload: {
      securityId: security.id,
      security
    }
  }
}

function updateFxRateByCurrencyIso(currencyIso, baseCurrencyIso, tradeDate) {
  return (dispatch, getState) => {
    const { batch } = getState();

    const price = fetchPrice(batch.brokerAccount.valuationPriceType, currencyIso, baseCurrencyIso, tradeDate);

    dispatch({ type: types.SET_FX_RATE, payload: { fxRate: price.rate } })
    dispatch(makeSettleFxRateEqualToTradeFxRate());
  }
}
