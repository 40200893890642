import * as types from 'constants/actionTypes';
import numeral from 'numeral';
import { stringToDate } from 'utils/dateTransformations';

export function changeInput(event) {
  const target = event.target ? event.target : event;
  const stateKey =
    target.getAttribute('data-statekey') ||
    target.name
      .replace(/]/g, '')
      .split('[')
      .join('.');
  const type = target.getAttribute('data-type') || target.type;
  const targetValue = target.getAttribute('data-value') || target.value;

  const value = () => {
    switch (type) {
      case 'date':
        return stringToDate(targetValue) || null;

      case 'currency':
        return numeral(targetValue).value();

      case 'checkbox':
        return target.checked;

      case 'number':
        if (target.step === '1') {
          return isNaN(parseInt(targetValue, 10)) ? targetValue : parseInt(targetValue, 10);
        }

        return isNaN(parseFloat(targetValue)) ? targetValue : parseFloat(targetValue);

      default:
        return targetValue;
    }
  };

  return {
    type: types.CHANGE_INPUT,
    payload: {
      stateKey,
      value: value(),
    },
  };
}

export function changeDateInput(event) {
  const target = event.target ? event.target : event;
  const value = target.value;
  const stateKey = target.getAttribute('data-statekey');

  return changeInputAction(
    stateKey ||
      target.name
        .replace(/]/g, '')
        .split('[')
        .join('.'),
    stringToDate(value) || null,
  );
}

const changeInputAction = (stateKey, value) => ({
  type: types.CHANGE_INPUT,
  payload: {
    stateKey,
    value,
  },
});
