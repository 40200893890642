import { toggleChartOfAccounts } from 'components/shared/ChartOfAccounts/actions';
import * as types from './actionTypes';

export function setFieldForSelectAccount(field) {
  return (dispatch) => {
    dispatch({
      type: types.FIELD_FOR_SELECT_ACCOUNT_SET,
      payload: {
        field,
      },
    });

    dispatch(toggleChartOfAccounts());
  };
}
