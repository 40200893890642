import React from 'react';
import PropTypes from 'prop-types';
import { useDrag, useDrop } from 'react-dnd';

const propTypes = {
  className: PropTypes.string,
  completed: PropTypes.bool,
  index: PropTypes.number,
  id: PropTypes.number,
  position: PropTypes.number,
  style: PropTypes.shape(PropTypes.object),
  moveRow: PropTypes.func,
};

const defaultProps = {
  className: '',
  completed: false,
  index: undefined,
  id: undefined,
  position: undefined,
  style: {},
  moveRow: undefined,
};

const DraggableBodyRow = ({ index, id, position, completed, moveRow, className, style, ...restProps }) => {
  const ref = React.useRef();

  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: `DraggableBodyRow${completed ? 'Completed' : 'Incomplete'}`,
    collect: (monitor) => {
      const { index: dragIndex, position: dragPosition } = monitor.getItem() || {};

      if (position === dragPosition) {
        return {};
      }

      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      };
    },
    drop: (item) => (item.position !== position ? moveRow(item.id, item.position, position) : null),
  });

  const [, drag] = useDrag({
    item: { type: `DraggableBodyRow${completed ? 'Completed' : 'Incomplete'}`, index, id, position, completed },
    canDrag: !completed,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drop(drag(ref));

  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ''}`}
      style={{ cursor: 'grab', ...style }}
      {...restProps}
    />
  );
};

DraggableBodyRow.propTypes = propTypes;
DraggableBodyRow.defaultProps = defaultProps;

export default DraggableBodyRow;
