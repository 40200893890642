export function entityGetter(name, entities) {
  const entity = entities[name];
  return entity || {};
}

export function entityKeysGetter(name, entities) {
  const entity = entityGetter(name, entities);
  return Object.keys(entity);
}

export function entityDetailGetter(name, id, entities) {
  const entity = entityGetter(name, entities);
  return entity[id];
}

export function entityListGetter(name, ids, entities) {
  const listIds = ids || entityKeysGetter(name, entities);
  return listIds.map((id) => entityDetailGetter(name, id, entities));
}
