import { without } from 'ramda';

export default function buildATree(array, options = {}, parentItem = null) {
  const { idName = 'id', parentIdName = 'parentId', withAccesses = false } = options;
  const { [idName]: id } = parentItem || { [idName]: null };
  return array
    .filter((item) => item[parentIdName] == id)
    .map((item) => {
      const inheritedAccesses = [...((parentItem && parentItem.accesses) || []), ...((parentItem && parentItem.inheritedAccesses) || [])]
      const newItem = { ...item, ...(withAccesses ? { inheritedAccesses } : {}) }
      return { ...newItem, children: buildATree(array, options, newItem) }
    });
}
