import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { Button, Avatar, Form, Input, Comment } from 'antd';

import { validateRequired } from 'utils/validates';

const propTypes = {
  author: PropTypes.string,
  form: PropTypes.shape(PropTypes.object),
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  pristine: PropTypes.bool,
};

const defaultProps = {
  author: '',
  form: {},
  submitting: false,
  handleSubmit: undefined,
  pristine: false,
};

const AddNoteForm = ({ form, submitting, handleSubmit, pristine, author }) => (
  <Comment
    avatar={<Avatar style={{ backgroundColor: '#ea763b', verticalAlign: 'middle' }}>{author}</Avatar>}
    content={
      /* eslint-disable react/jsx-wrap-multilines */
      <>
        <Form layout="vertical" onSubmit={(e) => handleSubmit(e).then(() => form.reset())}>
          <Field name="content" validate={validateRequired()}>
            {({ input, meta }) => (
              <Form.Item
                validateStatus={meta.error && meta.touched ? 'error' : 'success'}
                help={meta.error && meta.touched ? meta.error : undefined}
                required
              >
                <Input.TextArea rows={2} autoSize={{ minRows: 2 }} allowClear {...input} />
              </Form.Item>
            )}
          </Field>
          <Form.Item>
            <Button htmlType="submit" loading={submitting} disabled={pristine} type="primary">
              Add Note
            </Button>
          </Form.Item>
        </Form>
      </>
    }
  />
);

AddNoteForm.propTypes = propTypes;
AddNoteForm.defaultProps = defaultProps;

export default AddNoteForm;
