export const CHARTS_ACCOUNT_CHANGE_INPUT = 'CHARTS_ACCOUNT_CHANGE_INPUT';

export const CHARTS_ACCOUNT_FORM_FILL = 'CHARTS_ACCOUNT_FORM_FILL';

export const CHARTS_ACCOUNT_SUBMITTING_FORM = 'CHARTS_ACCOUNT_SUBMITTING_FORM';
export const CHARTS_ACCOUNT_SUBMITTING_FORM_SUCCEEDED = 'CHARTS_ACCOUNT_SUBMITTING_FORM_SUCCEEDED';
export const CHARTS_ACCOUNT_SUBMITTING_FORM_FAILED = 'CHARTS_ACCOUNT_SUBMITTING_FORM_FAILED';

export const CHARTS_ACCOUNT_CLEAR = 'CHARTS_ACCOUNT_CLEAR';

export const INVESTOR_CLASS_TOGGLE = 'INVESTOR_CLASS_TOGGLE';
export const INVESTOR_SERIES_TOGGLE = 'INVESTOR_SERIES_TOGGLE';

export const INVESTOR_ADD = 'INVESTOR_ADD';
export const INVESTOR_REMOVE = 'INVESTOR_REMOVE';
