import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import withReduxProvider from 'components/hocs/withReduxProvider';
import ChartOfAccountsContainer from 'components/shared/ChartOfAccounts';

import configureStore from './store';

const propTypes = {
  refs: PropTypes.object.isRequired,
  resourceFund: PropTypes.object.isRequired,
};

function FundsChartsContainer(props) {
  const { resourceFund, refs } = props;

  return <ChartOfAccountsContainer refs={refs} fundId={resourceFund.id} />;
}

const mapStateToProps = (state) => ({
  resourceFund: state.resourceFund,
});

FundsChartsContainer.propTypes = propTypes;

export default withReduxProvider(connect(mapStateToProps)(FundsChartsContainer), configureStore);
