import axios from 'axios';
import humps from 'humps';

import * as types from './actionTypes';
export { fillFormMapping } from './MappingForm/actions';

export function fetchMappings(fundId) {
  return (dispatch) => {
    const route = fundId
      ? Routes.fund_charts_mappings_path(fundId, { format: 'json' })
      : Routes.charts_mappings_path({ format: 'json' });

    dispatch(mappingsFetchRequested());
    axios
      .get(route, {
        withCredentials: true,
      })
      .then((res) => dispatch(mappingsFetchCompleted(humps.camelizeKeys(res.data))));
  };
}

function mappingsFetchRequested() {
  return {
    type: types.MAPPINGS_FETCH_REQUESTED,
    payload: {
      status: 'progress',
    },
  };
}

function mappingsFetchCompleted(mappings) {
  return {
    type: types.MAPPINGS_FETCH_COMPLETED,
    payload: {
      mappings,
      status: 'complete',
    },
  };
}

export function onAddMapping(mapping) {
  return (dispatch) => {
    dispatch({
      type: types.CHARTS_ON_MAPPING_ADD,
      payload: {
        mapping,
      },
    });
  };
}

export function deleteMapping(id) {
  return (dispatch, getState) => {
    const resourceFund = getState().resourceFund;
    const url = resourceFund
      ? Routes.fund_charts_mapping_path(resourceFund.id, id, { format: 'json' })
      : Routes.charts_mapping_path(id, { format: 'json' });

    axios
      .request({
        url,
        method: 'delete',
        withCredentials: true,
        xsrfHeaderName: 'X-CSRF-Token',
      })
      .then(() => {
        dispatch({
          type: types.CHARTS_ON_MAPPING_DELETE,
          payload: {
            id,
          },
        });
      });
  };
}

export function changeMode(mode) {
  return {
    type: types.MAPPING_MODE_CHANGE,
    payload: {
      mode,
    },
  };
}

export function changeFilter(values) {
  return {
    type: types.SECURITY_TYPES_FILTER_CHANGE,
    payload: {
      values,
    },
  };
}
