import { createSelector } from 'reselect';
import { identity, propEq, find, findIndex, isEmpty, has, keys, last } from 'ramda';

import { getIn } from 'utils/ramda';
import getRandomInt from 'utils/random';
import { getReadableError } from 'utils/errors';
import { getFormStateValues } from 'selectors/forms';

export const initialState = {
  details: {},
  paymentItems: {
    collection: [],
    editableKeys: {},
    errors: {},
  },
};

const accrualSelector = (state) => getIn('fund.accruals', state) || initialState;
const resourceAccrualSelector = (state) => getIn('accruals.details', state) || initialState;
const getAccrualSelector = (state) => getIn('accruals', state) || initialState;

const accrualDetailsSelector = (state) => accrualSelector(state).details;

const accrualAllocatedAmountsSelector = (state) => getIn('allocatedAmounts', state) || initialState;

const accrualPaymentItemsSelector = (state) => accrualSelector(state).paymentItems;

const accrualPaymentItemsCollectionSelector = (state) => accrualPaymentItemsSelector(state).collection;

const accrualPaymentItemsEditableKeysSelector = (state) => accrualPaymentItemsSelector(state).editableKeys;

const accrualPaymentItemsErrorsSelector = (state) => accrualPaymentItemsSelector(state).errors;

function paymentsItemIdSelector(state, { id } = {}) {
  return id;
}

export const getResourceAccrual = createSelector(resourceAccrualSelector, identity);

export const getAccrualDetails = createSelector(accrualDetailsSelector, identity);

export const getAccrualId = createSelector(getAccrualDetails, (accrual) => accrual.id);

export const getAccrualAllocatedAmounts = createSelector(accrualAllocatedAmountsSelector, identity);

export const getIsEmptyAllocatedAmounts = createSelector(getAccrualAllocatedAmounts, (allocatedAmounts) => isEmpty(allocatedAmounts));

export const getAccrualEditableKeys = createSelector(accrualPaymentItemsEditableKeysSelector, identity);

export const getAccrualPaymentItems = createSelector(accrualPaymentItemsCollectionSelector, (collection) => {
  collection.map((item) => {
    item.key = item.id || getRandomInt();
  });
  return collection;
});

export const getActiveAccrualPaymentItems = createSelector(getAccrualPaymentItems, (paymentItems) => paymentItems.filter((item) => !item._destroy));

export const getPaymentItemsError = createSelector(getActiveAccrualPaymentItems, (paymentItems) => paymentItems.filter((item) => item.error));

export const getPaymentItemsSubmitError = createSelector(accrualPaymentItemsErrorsSelector, (errors) => {
  if (!isEmpty(errors)) {
    const readableError = getReadableError(errors);

    return readableError;
  }

  return null;
});

export const getPaymentItemIndex = (id, state) => {
  const paymentItems = getAccrualPaymentItems(state);
  const index = findIndex(propEq('id', id))(paymentItems);

  return index;
};

export const getPaymentItemFieldInitialValue = (paymentItemId, fieldName, state) => {
  const scheduleValues = getFormStateValues(state, { form: 'paymentSchedule' });
  const { paymentItems } = scheduleValues;
  const paymentItem = find(propEq('id', paymentItemId))(paymentItems);

  return paymentItem[fieldName];
};

export const getAccrualPaymentsBalance = createSelector(getActiveAccrualPaymentItems, (paimentsItems) => {
  const paymentsBalance = {};
  let tmp = 0;

  paimentsItems.forEach((item, index) => {
    if (index === 0 || item.approvedPeriod) {
      tmp = Number(item.accountBalanceAmount);
    }

    if (item.approvedPeriod) {
      paymentsBalance[item.id] = tmp;
    } else {
      // eslint-disable-next-line max-len
      const currentBalance = Number(item.manualEntriesAmount) + Number(item.otherAccrualsAmount) + (Number(item.amount) - Number(item.paymentAmount));
      paymentsBalance[item.id] = tmp + currentBalance;
      tmp = paymentsBalance[item.id];
    }
  });

  return paymentsBalance;
});

export const getAccrualInitialValues = createSelector(getAccrualSelector, (accrual) => {
  if (has('initialValues', accrual)) {
    return accrual.initialValues;
  }

  // FIX ME: Filed 'startDate' doesn't rerender
  if (has('details', accrual)) {
    return {
      startDate: accrual.details.startDate,
      endDate: accrual.details.endDate,
      distribution: accrual.details.distribution,
    };
  }
  // END FIX ME

  return {};
});
