import React from 'react';
import PropTypes from 'prop-types';

import {
  AccountSelect,
  Checkbox,
  CustomDatePicker,
  DateInput,
  TextInput,
  RadioButtonGroup,
  RadioGroup,
  SelectInput,
  TextareaInput,
  YesOrNoInput,
} from './inputs';

const propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  labelText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

const defaultProps = {
  type: 'text',
  labelText: undefined,
};

function FormInput({ name, type, labelText, meta, ...rest }) {
  const inputProps = {
    name,
    id: name,
    'aria-describedby': `${name}Error`,
    ...rest,
  };

  if (type === 'date') {
    return <DateInput {...inputProps} />;
  }

  if (type === 'checkbox') {
    return <Checkbox {...inputProps}>{labelText}</Checkbox>;
  }

  if (type === 'radio') {
    return <RadioGroup {...inputProps} />;
  }

  if (type === 'datePicker') {
    return <CustomDatePicker {...inputProps} />;
  }

  if (type === 'radioButton') {
    return <RadioButtonGroup {...inputProps} />;
  }

  if (type === 'yesOrNo') {
    return <YesOrNoInput {...inputProps} />;
  }

  if (type === 'select') {
    return <SelectInput {...inputProps} />;
  }

  if (type === 'textarea') {
    return <TextareaInput {...inputProps} />;
  }

  if (type === 'accountSelect') {
    return <AccountSelect {...inputProps} {...meta} />;
  }
  return <TextInput type={type} {...inputProps} />;
}

FormInput.propTypes = propTypes;
FormInput.defaultProps = defaultProps;

export default FormInput;
