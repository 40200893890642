import moment from 'moment';
import numeral from 'numeral';
import { createSelector } from 'reselect';
import { get, indexOf, filter, difference, find, includes, keyBy } from 'lodash';

import {
  getSelectedItems,
  getAutoCalculateAmounts,
  getGrossAssetSum,
  getActiveSeriesAccounts,
  getActiveClassesAccounts,
  getArrayFromTargetType,
} from 'utils/specialAllocation';

export const allAllocatedAmountsSelector = (state) => state.allocatedAmounts;
const investorClasses = (state) => state.resourceFund.investorClasses;
const investorSeries = (state) => state.resourceFund.investorSeries;

function getTotalAmount(state, props) {
  return props.amount;
}

function getActiveAccountsOnDate(state, props) {
  if (!props.date) {
    return state.resourceFund.accounts;
  }

  return state.resourceFund.accounts.filter((account) => moment(account.openDate).isSameOrBefore(props.date));
}

function getTotalCommitmentAmountForAccounts(accounts) {
  return accounts.reduce((total, account) => total + Number(account.commitmentAmount), 0);
}

function getCurrentAllocatedAmounts(state, props) {
  return filter(state.allocatedAmounts, { parentId: props.parentId });
}

const groupedAllocatedAmountsSelector = createSelector(
  allAllocatedAmountsSelector,
  getCurrentAllocatedAmounts,
  (allAllocatedAmounts, currentAllocatedAmounts) => {
    const all = allAllocatedAmounts;
    const selected = filter(currentAllocatedAmounts, { selected: true, _destroy: false });
    const special = filter(selected, (allocatedAmount) => allocatedAmount.special || allocatedAmount.id);

    const autoCalculated = difference(selected, special);

    return {
      all,
      current: currentAllocatedAmounts,
      selected,
      special,
      autoCalculated,
    };
  },
);

const getRemainderSelector = createSelector(
  groupedAllocatedAmountsSelector,
  getTotalAmount,
  (groupedAllocatedAmounts, totalAmount) => {
    const specialTotalAmount = getTotalAmountForAccounts(groupedAllocatedAmounts.special);
    const remainder = totalAmount - specialTotalAmount;

    if (remainder < 0) {
      return 0;
    }

    return remainder;
  },
);

const getTotalСommitmentAmountForAccountsSelector = createSelector(getActiveAccountsOnDate, (accounts) => getTotalCommitmentAmountForAccounts(accounts));

const getAutoCalculatedAmountsSelector = createSelector(
  groupedAllocatedAmountsSelector,
  getRemainderSelector,
  getActiveAccountsOnDate,
  investorSeries,
  investorClasses,
  (groupedAllocatedAmounts, remainder, fundAccounts, fundSeries, fundClasses) => {
    const inboxAccounts = groupedAllocatedAmounts.autoCalculated.filter((item) => item.targetType === 'Fund::Account');
    const inboxSeries = groupedAllocatedAmounts.autoCalculated.filter(
      (item) => item.targetType === 'Fund::InvestorSeries',
    );
    const inboxClasses = groupedAllocatedAmounts.autoCalculated.filter(
      (item) => item.targetType === 'Fund::InvestorClass',
    );

    const selectedAccounts = getSelectedItems(inboxAccounts, fundAccounts);
    const selectedSeries = getSelectedItems(inboxSeries, fundSeries);
    const selectedClasses = getSelectedItems(inboxClasses, fundClasses);

    const grossAssetSum = getGrossAssetSum([...selectedAccounts, ...selectedSeries, ...selectedClasses]);

    const activeSelectedSeriesAccounts = getActiveSeriesAccounts(selectedSeries, fundAccounts);
    const activeSelectedClassesAccounts = getActiveClassesAccounts(selectedClasses, fundSeries, fundAccounts);

    const selectedAccountsLength = selectedAccounts.length + activeSelectedSeriesAccounts.length + activeSelectedClassesAccounts.length;

    const accountsWithAutoCalculatedAmounts = groupedAllocatedAmounts.autoCalculated.map((account) => {
      const arrayForSearch = getArrayFromTargetType(account.targetType, fundAccounts, fundSeries, fundClasses);
      const fundAccount = find(arrayForSearch, {
        id: account.targetId,
      });

      const ownershipPercentage = fundAccount && fundAccount.ownershipPercentage ? Number(fundAccount.ownershipPercentage) : 0;
      const closingGrossCapitalAmount = fundAccount && fundAccount.closingGrossCapitalAmount ? Number(fundAccount.closingGrossCapitalAmount) : 0;

      const allocationAmount = getAutoCalculateAmounts(
        remainder,
        ownershipPercentage,
        closingGrossCapitalAmount,
        grossAssetSum,
        fundAccounts.length,
        selectedAccountsLength,
      );

      account.amount = allocationAmount;

      return account;
    });

    const autoCalculatedAmounts = keyBy(accountsWithAutoCalculatedAmounts, '_id');

    return autoCalculatedAmounts;
  },
);

function getTotalAmountForAccounts(accounts) {
  return accounts.reduce((total, account) => total + Number(account.amount), 0);
}

export const getAllocatedAmountsSum = createSelector(groupedAllocatedAmountsSelector, (groupedAllocatedAmounts) => getTotalAmountForAccounts(groupedAllocatedAmounts.selected));

export const getSelectedAccounts = createSelector(groupedAllocatedAmountsSelector, (groupedAllocatedAmounts) => groupedAllocatedAmounts.selected);

export const getAccountsWithPercentageSelector = createSelector(
  getActiveAccountsOnDate,
  groupedAllocatedAmountsSelector,
  getTotalСommitmentAmountForAccountsSelector,
  getAutoCalculatedAmountsSelector,
  (accounts, groupedAllocatedAmounts, totalСommitmentAmount, autoCalculatedAmounts) => accounts.map((account) => {
    const _id = `Fund::Account-${account.id}`;
    const currentAllocatedAmount = find(groupedAllocatedAmounts.selected, {
      targetId: account.id,
      targetType: 'Fund::Account',
    });

    const amount = currentAllocatedAmount && currentAllocatedAmount.special
      ? currentAllocatedAmount.amount
      : get(autoCalculatedAmounts[`${_id}`], 'amount');

    return {
      id: account.id,
      name: account.name,
      openDate: account.openDate,
      targetType: 'Fund::Account',
      commitmentAmount: account.commitmentAmount,
      commitmentAmountPercent: numeral(account.commitmentAmount / totalСommitmentAmount).format('0.00%'),
      amount,
      selected: currentAllocatedAmount ? currentAllocatedAmount.selected : false,
      special: currentAllocatedAmount ? currentAllocatedAmount.special : false,
      indexOfRecord: indexOf(groupedAllocatedAmounts.all, currentAllocatedAmount),
      investorClassId: account.investorClassId,
      investorSeriesId: account.investorSeriesId,
      _id,
    };
  }),
);

export const getInvestorSeriesSelector = createSelector(
  investorSeries,
  getAccountsWithPercentageSelector,
  groupedAllocatedAmountsSelector,
  getAutoCalculatedAmountsSelector,
  (series, accounts, groupedAllocatedAmounts, autoCalculatedAmounts) => series.map((is) => {
    const _id = `Fund::InvestorSeries-${is.id}`;

    const currentAllocatedAmount = find(groupedAllocatedAmounts.selected, {
      targetId: is.id,
      targetType: 'Fund::InvestorSeries',
    });
    const selected = currentAllocatedAmount ? currentAllocatedAmount.selected : false;

    const seriesAccounts = accounts.filter((account) => account.investorSeriesId === is.id && account);
    const commitmentAmount = getTotalCommitmentAmountForAccounts(seriesAccounts);

    const amount = currentAllocatedAmount && currentAllocatedAmount.special
      ? currentAllocatedAmount.amount
      : get(autoCalculatedAmounts[`${_id}`], 'amount');

    return {
      id: is.id,
      name: is.name,
      targetType: 'Fund::InvestorSeries',
      children: selected ? null : seriesAccounts,
      investorClassId: is.investorClassId,
      commitmentAmount,
      amount,
      special: currentAllocatedAmount ? currentAllocatedAmount.special : false,
      selected,
      indexOfRecord: indexOf(groupedAllocatedAmounts.all, currentAllocatedAmount),
      _id,
    };
  }),
);

export const getInvestorClassesSelector = createSelector(
  investorClasses,
  getAccountsWithPercentageSelector,
  groupedAllocatedAmountsSelector,
  getAutoCalculatedAmountsSelector,
  (klasses, accounts, groupedAllocatedAmounts, autoCalculatedAmounts) => klasses.map((klass) => {
    const _id = `Fund::InvestorClass-${klass.id}`;

    const currentAllocatedAmount = find(groupedAllocatedAmounts.selected, {
      targetId: klass.id,
      targetType: 'Fund::InvestorClass',
    });
    const selected = currentAllocatedAmount ? currentAllocatedAmount.selected : false;

    const classAccounts = accounts.filter((account) => account.investorClassId === klass.id && account);
    const commitmentAmount = getTotalCommitmentAmountForAccounts(classAccounts);

    const amount = currentAllocatedAmount && currentAllocatedAmount.special
      ? currentAllocatedAmount.amount
      : get(autoCalculatedAmounts[`${_id}`], 'amount');

    return {
      ...klass,
      name: klass.name,
      targetType: 'Fund::InvestorClass',
      id: klass.id,
      amount,
      commitmentAmount,
      special: currentAllocatedAmount ? currentAllocatedAmount.special : false,
      selected,
      indexOfRecord: indexOf(groupedAllocatedAmounts.all, currentAllocatedAmount),
      _id,
    };
  }),
);

export const makeGetAllocatedAmountsTree = () => createSelector(getInvestorClassesSelector, getInvestorSeriesSelector, (klasses, series) => klasses.map((klass) => {
  const investorClassSeries = series.filter((is) => includes(klass.investorSeriesIds, is.id) && is);

  const commitmentAmount = investorClassSeries.reduce((total, is) => total + Number(is.commitmentAmount), 0);

  return {
    ...klass,
    children: klass.selected ? null : investorClassSeries,
    commitmentAmount,
  };
}));

export const makeGetAllocatedAmounts = () => createSelector(groupedAllocatedAmountsSelector, (groupedAllocatedAmounts) => groupedAllocatedAmounts);
