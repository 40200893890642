import set from 'lodash/fp/set';
import * as types from './actionTypes';

const initialState = {
  bandPatterns: [],
  errors: [],
  baseErrors: [],
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  const { bandPatterns } = state;

  switch (type) {
    case types.ADD_BAND_PATTERN:
      return {
        ...state,
        bandPatterns: [...bandPatterns, payload.bandPattern],
      };

    case types.DELETE_BAND_PATTERN:
      if (bandPatterns[payload.position].id === null || bandPatterns[payload.position].id === undefined) {
        return {
          ...state,
          bandPatterns: updateValueFromCollection(
            payload.position,
            'lowerBoundPercentage',
            bandPatterns[payload.position].lowerBoundPercentage,
            [...bandPatterns.slice(0, payload.position), ...bandPatterns.slice(payload.position + 1)],
            false,
          ),
        };
      }

      return {
        ...state,
        bandPatterns: updateValueFromCollection(
          payload.position + 1,
          'lowerBoundPercentage',
          bandPatterns[payload.position].lowerBoundPercentage,
          updateValueFromCollection(
            payload.position,
            '_destroy',
            true,
            bandPatterns,
          ),
          false,
        ),
      };

    case types.CHANGE_INPUT:
      return set(payload.stateKey, payload.value)(state);

    case types.CHANGE_LOWER_BOUND_PERCENTAGE:
      if (!payload.index && payload.index !== 0) {
        return state;
      }

      return {
        ...state,
        bandPatterns: updateValueFromCollection(payload.index, 'upperBoundPercentage', payload.value, bandPatterns),
      };

    case types.CHANGE_UPPER_BOUND_PERCENTAGE:
      if (!payload.index && payload.index !== 0) {
        return state;
      }

      return {
        ...state,
        bandPatterns: updateValueFromCollection(payload.index, 'lowerBoundPercentage', payload.value, bandPatterns),
      };

    default:
      return state;
  }
};

const updateValueFromCollection = (index, attribute, value, collection, immutable = true) => {
  const newCollection = immutable ? collection.slice() : collection;

  if (newCollection[index]) {
    newCollection[index][attribute] = value;
  }

  return newCollection;
};
