/* eslint-disable no-unused-expressions */
import { Button, Select, Input, Drawer as AntdDrawer, Form as AntdForm, Tree, Icon } from 'antd';
import React, { useState, useEffect } from 'react';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';

import { openDrawer, closeDrawer, createDocumentsPattern, updateDocumentsPattern } from 'actions/investorDocumentPatterns';
import { getPatternDetails, getDirectoriesTree, getDrawerState } from 'selectors/investorDocumentPatterns';
import renderTree from 'utils/trees/renderTree';
import { useAsyncFetchDefer } from 'utils/useAsyncFetch';
import { validateRequired } from 'utils/validates';

import TreeNode from './TreeNode';
import styles from './drawer.module.css';

const { Item } = AntdForm;
const { Option } = Select;
const { DirectoryTree } = Tree;

const types = ['hf', 'pe', 'fof', 'tl', 're', 'manco', 'dl', 'feeder'];

function Drawer(props) {
  const { match } = props;
  const { path, params: { id } } = match;

  const isEditing = path === '/investor_document_patterns/:id/edit';
  const pathIsVisible = isEditing || path === '/investor_document_patterns/new';
  const dispatch = useDispatch();

  const [expandedKeys, setExpandedKeys] = useState([])
  const [editingTreeNode, setEditingTreeNode] = useState(-1);
  const [isEditingTreeNode, setIsEditingTreeNode] = useState(false);

  const [asyncCreateDocumentsPattern, isLoadingCreatePattern, isCreatedPattern] = useAsyncFetchDefer(createDocumentsPattern);
  const [asyncUpdateDocumentsPattern, isLoadingUpdatePattern, isUpdatedPattern] = useAsyncFetchDefer(updateDocumentsPattern);

  const patternDetails = useSelector((state) => getPatternDetails(state, id));
  const treeData = useSelector(getDirectoriesTree);
  const isVisible = useSelector(getDrawerState);

  const isLoading = isLoadingCreatePattern || isLoadingUpdatePattern;

  const handleSubmit = (values) => {
    if (id) {
      return asyncUpdateDocumentsPattern({ values, id });
    }
    return asyncCreateDocumentsPattern({ values });
  };

  if (isEditingTreeNode) {
    if (editingTreeNode === -1) {
      setIsEditingTreeNode(false);
    }
  } else if (editingTreeNode !== -1) {
    setIsEditingTreeNode(true);
  }

  if (!isVisible && pathIsVisible) {
    dispatch(openDrawer({ id }));
  }

  useEffect(() => {
    if (isCreatedPattern || isUpdatedPattern) { dispatch(closeDrawer()) }
  }, [isCreatedPattern, isUpdatedPattern]);

  const treeNodeIcon = (isLeaf) => {
    if (isLeaf) {
      return () => null
    }
    return ({ expanded }) => {
      if (expanded) {
        return <Icon type="folder-open" theme="filled" />
      }
      return <Icon type="folder" theme="filled" />
    }
  }

  const treeNodeProps = (item) => {
    const props = {}
    if (item.isLeaf) {
      props.className = 'addDirBtn';
    }
    props.icon = treeNodeIcon(item.isLeaf)
    return props;
  }

  return (
    <AntdDrawer
      title="Documents Pattern"
      width={isEditing ? '50%' : '25%'}
      placement='right'
      visible={isVisible}
      onClose={() => dispatch(closeDrawer())}
      bodyStyle={{ paddingBottom: 80 }}
      className={styles.pattern}
      keyboard={!isEditingTreeNode}
      closable={false}
      destroyOnClose
    >
      <Form
        initialValues={id && patternDetails}
        onSubmit={handleSubmit}
        subscription={{
          pristine: true,
          hasSubmitErrors: true,
          submitting: true,
          submitError: true,
          initialValues: true,
        }}
        render={({ submitting, handleSubmit, initialValues, pristine }) => (
          <>
            <AntdForm layout='vertical' onSubmit={handleSubmit}>
              <Field name="title" validate={validateRequired()}>
                {({ input, meta }) => (
                  <Item
                    label='Title'
                    validateStatus={meta.error && meta.touched ? 'error' : 'success'}
                    help={meta.error && meta.touched ? meta.error : undefined}
                    required
                  >
                    <Input {...input} />
                  </Item>
                )}
              </Field>
              <Field name="fundType" parse={(v) => v || null}>
                {({ input }) => (
                  <Item label='Fund Type'>
                    <Select {...input} allowClear={!!input.value}>
                      {types.map((type, idx) => (
                        <Option key={idx} value={type}>{type}</Option>
                      ))}
                    </Select>
                  </Item>
                )}
              </Field>
              {isEditing && (
                <Field name="directories">
                  {() => (
                    <Item label='Directory Tree'>
                      <DirectoryTree
                        className={styles.tree}
                        expandedKeys={expandedKeys}
                        selectable={false}
                        onExpand={(arr) => setExpandedKeys([...arr])}
                        blockNode
                        multiple
                      >
                        {renderTree(treeData, TreeNode, ({ key }) => ({
                          patternId: id,
                          id: key,
                          isEditing: editingTreeNode === key,
                          toggleEditing: (bool) => setEditingTreeNode(bool ? key : -1)
                        }), treeNodeProps)}
                      </DirectoryTree>
                    </Item>
                  )}
                </Field>
              )}
              <div className={styles.close}>
                <Button type="primary" htmlType="submit" disabled={pristine || isLoading}>
                  {isLoading ? <Icon type="loading" /> : initialValues ? 'Update' : 'Create'}
                </Button>
                <Button onClick={() => dispatch(closeDrawer())}>
                  Close
              </Button>
              </div>
            </AntdForm>
          </>
        )}
      />
    </AntdDrawer>
  );
}

export default Drawer;
