import React from 'react';

import { RadioGroup, Checkbox, DateInput, RadioSelectInput, SelectAsync } from 'components/shared';
import { isShareBasedTypes } from 'components/Funds/selectors';

import ContactList from './ContactList';

const DistributionFields = (props) => {
  const { resourceFund, investorTransactionForm, actions } = props;
  const { investorTransactionActions } = actions;
  const { data, meta } = investorTransactionForm;
  const { errors, fundAccount } = meta;
  const {
    fundAccountId,
    recordingDate,
    dealingDate,
    amount,
    shares,
    percentage,
    thirdPartyId,
    investorContactInfoVerified,
    subscriptionDocumentsReviewed,
    highWaterMarkType,
    crystalizeFee,
  } = data;

  const {
    changeInput,
    changeDealingDate,
    changeDateInput,
    selectValueTypeRadioInput,
    changeFundAccount,
    getFundContactsOptions,
    changeThirdParty,
    getFundAccountsOptions,
  } = investorTransactionActions;

  const showing = fundAccountId ? true : false;

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <SelectAsync
            name="investor_transaction[fund_account_id]"
            id="fund_account_id"
            loadOptions={getFundAccountsOptions}
            valueKey="id"
            labelKey="displayFullInformation"
            value={fundAccountId && { displayFullInformation: fundAccount.displayFullInformation }}
            onChange={changeFundAccount}
            errors={_.get(errors, 'fundAccountId')}
            noResultsText={'No results'}
            searchPromptText="Type to search (3 characters minimum)"
            trimFilter
            required
            autoload={false}
            clearable={false}
            escapeClearsValue={false}
            onBlurResetsInput={false}
          />
        </div>
      </div>

      {showing ? (
        <div>
          <div className="row">
            <div className="col-md-6">
              <DateInput
                id="recording_date"
                name="investor_transaction[recording_date]"
                label={I18n.t('activerecord.attributes.fund/investor_transaction.recording_date')}
                dataStateKey="data.recordingDate"
                value={recordingDate}
                onChange={changeDateInput}
                errors={_.get(errors, 'recordingDate')}
              />
            </div>
            <div className="col-md-6">
              <DateInput
                id="dealing_date"
                name="investor_transaction[dealing_date]"
                required
                label={I18n.t('activerecord.attributes.fund/investor_transaction.dealing_date')}
                dataStateKey="data.dealingDate"
                value={dealingDate}
                onChange={changeDealingDate}
                errors={_.get(errors, 'dealingDate')}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <RadioSelectInput
                id="amount"
                name="investor_transaction[amount]"
                required={data.valueType === 'amount'}
                checked={data.valueType === 'amount'}
                label={I18n.t('activerecord.attributes.fund/investor_transaction.amount')}
                dataStateKey="data.amount"
                dataValueType="amount"
                dataValueTypeStateKey="data.valueType"
                value={amount}
                radioValue={true}
                onChange={changeInput}
                onChecked={selectValueTypeRadioInput}
                unit={resourceFund.currencySymbol}
                errors={_.get(errors, 'amount')}
              />
            </div>
          </div>

          {isShareBasedTypes(props) && (
            <div className="row">
              <div className="col-md-6">
                <RadioSelectInput
                  id="shares"
                  name="investor_transaction[shares]"
                  required={data.valueType === 'shares'}
                  checked={data.valueType === 'shares'}
                  label={I18n.t('activerecord.attributes.fund/investor_transaction.shares')}
                  dataStateKey="data.shares"
                  dataValueType="shares"
                  dataValueTypeStateKey="data.valueType"
                  value={shares}
                  radioValue={false}
                  onChange={changeInput}
                  onChecked={selectValueTypeRadioInput}
                  options={{ decimalLimit: resourceFund.shareDecimalPrecision }}
                  errors={_.get(errors, 'shares')}
                />
              </div>
            </div>
          )}

          <div className="row">
            <div className="col-md-6">
              <RadioSelectInput
                id="percentage"
                name="investor_transaction[percentage]"
                required={data.valueType === 'percentage'}
                checked={data.valueType === 'percentage'}
                label={I18n.t('activerecord.attributes.fund/investor_transaction.percentage')}
                dataStateKey="data.percentage"
                dataValueType="percentage"
                dataValueTypeStateKey="data.valueType"
                value={percentage}
                radioValue={false}
                onChange={changeInput}
                onChecked={selectValueTypeRadioInput}
                errors={_.get(errors, 'percentage')}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <RadioGroup
                id="high_water_mark_type"
                name="investor_transaction[high_water_mark_type]"
                required
                dataStateKey="data.highWaterMarkType"
                value={highWaterMarkType}
                label={I18n.t('activerecord.attributes.fund/investor_transaction.high_water_mark')}
                onChange={changeInput}
                options={[
                  { label: 'Yes', value: 1 },
                  { label: 'No', value: 0 },
                ]}
                errors={_.get(errors, 'highWaterMarkType')}
              />
            </div>
            <div className="col-md-6">
              <RadioGroup
                id="crystalize_fee"
                name="investor_transaction[crystalize_fee]"
                required
                dataStateKey="data.crystalizeFee"
                value={crystalizeFee}
                label={I18n.t('activerecord.attributes.fund/investor_transaction.crystalize_fee')}
                onChange={changeInput}
                options={[
                  { label: 'Yes', value: 'true' },
                  { label: 'No', value: 'false' },
                ]}
                errors={_.get(errors, 'crystalizeFee')}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <ContactList
                id="third_party_id"
                name="investor_transaction[third_party_id]"
                loadOptions={getFundContactsOptions}
                valueKey="id"
                labelKey="contact.displayName"
                optionKey="contact"
                label={I18n.t('activerecord.attributes.fund/investor_transaction.third_party')}
                value={thirdPartyId}
                onChange={changeThirdParty}
                errors={_.get(errors, 'thirdParty')}
              />
            </div>
          </div>

          {isShareBasedTypes(props) && (
            <div>
              <div className="row">
                <div className="col-md-12">
                  <Checkbox
                    name={'investor_transaction[investor_contact_info_verified]'}
                    dataType="checkbox"
                    dataStateKey="data.investorContactInfoVerified"
                    checked={investorContactInfoVerified}
                    label={I18n.t('activerecord.attributes.fund/investor_transaction.investor_contact_info_verified')}
                    onChange={changeInput}
                    errors={_.get(errors, 'investorContactInfoVerified')}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <Checkbox
                    name={'investor_transaction[subscription_documents_reviewed]'}
                    dataType="checkbox"
                    dataStateKey="data.subscriptionDocumentsReviewed"
                    checked={subscriptionDocumentsReviewed}
                    label={I18n.t('activerecord.attributes.fund/investor_transaction.subscription_documents_reviewed')}
                    onChange={changeInput}
                    errors={_.get(errors, 'subscriptionDocumentsReviewed')}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        fundAccountId && (
          <div className="alert alert-danger">
            Unable to calculate Total Subscription Amount, Issue Price is missing from this Class.
          </div>
        )
      )}
    </div>
  );
};

export default DistributionFields;
