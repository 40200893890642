import React from 'react';
import { accounting } from 'accounting';
import { startCase } from 'lodash';

import { Stat, Block } from 'components/shared';
import { dateToString } from 'utils/dateTransformations';

const Currency = (props) => {
  const { resourceFund, resourceFund: { baseCurrencyIso }, trade, summary, security, submitting, errors, success, refs: { pePartnershipSecurityTradeTypes }, actions } = props;

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <Block
            title={'Summary'}
            fields={
              <div>
                <ul className='list list-unstyled'>
                  <li>
                    {'Type:'}
                    <strong>
                      {' '}
                      { trade.type ? startCase(trade.type.split('_').join(' ')) : '?' }
                    </strong>
                  </li>
                </ul>
              </div>
            }
          />
          <Block
            title={'Current Holdings'}
            fields={
              <div>
                <ul className='list list-unstyled'>
                  {(security.currenciesBalance || []).map((balanceItem, i) => {
                    return (
                      <li key={i}>
                        <strong>
                          {`${balanceItem.currencyIso}:`}
                        </strong>
                        {' '}
                        {`${accounting.formatNumber(balanceItem.amount, 2)}`}
                      </li>
                    );
                  })}
                </ul>
              </div>
            }
          />
        </div>
        <div className='col-md-6'>
          <Stat hint={'Rate'} value={accounting.formatNumber(trade.price / Math.abs(trade.signedQuantity), 8)} />
        </div>
        <div className="col-md-6">
          <Stat
            hint={`Security Rate${security.withSecurityPrice ? ` (on ${security.pricingDate})` : ''}`}
            value={security.withSecurityPrice ? accounting.formatNumber(security.securityPrice, 8) : 'None'}
            link={security.withSecurityPrice ? false : Routes.security_prices_path(security.id)}
          />
        </div>
      </div>
    </div>
  );
};

export default Currency;
