const KEY_REGEX = /^((class)|(series)|(account))-\d+(?!.)$/;
const CLASS_REGEX = /^(class)-\d+(?!.)$/
const SERIES_REGEX = /^(series)-\d+(?!.)$/
const ACCOUNT_REGEX = /^(account)-\d+(?!.)$/

export const classKey = (id) => `class-${id}`;
export const seriesKey = (id) => `series-${id}`;
export const accountKey = (id) => `account-${id}`;
export const isKey = (string) => KEY_REGEX.test(string);
export const isClassKey = (string) => CLASS_REGEX.test(string);
export const isSeriesKey = (string) => SERIES_REGEX.test(string);
export const isAccountKey = (string) => ACCOUNT_REGEX.test(string);
export const getType = (string) => {
  if (!isKey(string)) { throw new Error('Invalid key') };
  return string.split('-')[0];
}
export const getId = (string) => {
  if (!isKey(string)) { throw new Error('Invalid key') };
  return Number(string.split('-')[1]);
}

export default { classKey, seriesKey, accountKey, isKey, isClassKey, isSeriesKey, isAccountKey, getType, getId };