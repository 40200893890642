import { pipe, mergeDeepRight, reduce, map, __, apply } from 'ramda';

import { getCookieByName } from '../utils/cookies';
import { decamelizeKeys } from '../utils/humps';
import { argsToArray } from '../utils/ramda';

function requestsComposition(...args) {
  return pipe(
    argsToArray,
    map(apply(__, args)),
    reduce(mergeDeepRight, {}),
  );
}

function getRequestWithMethod(method) {
  return () => ({
    method,
  });
}

function baseRequest() {
  const csrfToken = getCookieByName('XSRF-TOKEN');

  return {
    headers: {
      Accept: 'application/json',
      credentials: 'include',
      'X-CSRF-Token': csrfToken,
    },
  };
}

function jsonRequest() {
  return {
    headers: {
      'Content-Type': 'application/json',
    },
  };
}

function dataRequest({ body }) {
  return {
    body,
  };
}

function dataJsonRequest({ body }) {
  return {
    body: pipe(
      decamelizeKeys,
      JSON.stringify,
    )(body),
  };
}

function getRequest() {
  return {
    ...requestsComposition()(baseRequest, jsonRequest),
  };
}

function putRequest({ body }) {
  return {
    ...requestsComposition({ body })(baseRequest, getRequestWithMethod('PUT'), jsonRequest, dataJsonRequest),
  };
}

function patchRequest({ body }) {
  return {
    ...requestsComposition({ body })(baseRequest, getRequestWithMethod('PATCH'), jsonRequest, dataJsonRequest),
  };
}

function postRequest({ body }) {
  return {
    ...requestsComposition({ body })(baseRequest, getRequestWithMethod('POST'), jsonRequest, dataJsonRequest),
  };
}

function deleteRequest() {
  return {
    ...requestsComposition()(baseRequest, getRequestWithMethod('DELETE'), jsonRequest),
  };
}

function postDataRequest({ body }) {
  return {
    ...requestsComposition({ body })(baseRequest, getRequestWithMethod('POST'), dataRequest),
  };
}

export { getRequest, putRequest, patchRequest, postRequest, deleteRequest, postDataRequest };
