import { history } from 'stores/configureStore';
import TaskItemsApi from '../../../../apiAdapters/funds/valuations/taskItems';

export const newTaskItem = (fundId, valuationId) => (dispatch) => {
  dispatch({ type: 'NEW_TASK_ITEM' });
  history.push(Routes.new_fund_valuation_task_item_path(fundId, valuationId));
  document.title = 'Fund | Valuation | Task Item | New';
};

export const editTaskItem = (fundId, valuationId, id) => (dispatch) => {
  dispatch({ type: 'EDIT_TASK_ITEM' });
  history.push(Routes.edit_fund_valuation_task_item_path(fundId, valuationId, id));
  document.title = 'Fund | Valuation | Task Item | Edit';
};

// eslint-disable-next-line max-len
export const createTaskItem = (values, { fundId, valuationId }) => (dispatch, getState) => TaskItemsApi.submit(values, { fundId, valuationId })
  .then(({ payload }) => {
    const { fund: { valuation: { taskItems: { collection } } } } = getState();
    const newCollection = [...collection, { ...payload }];

    dispatch({ type: 'CREATE_TASK_ITEM', payload: newCollection });
    history.push(Routes.fund_valuation_task_items_path(fundId, valuationId));
    document.title = 'Fund | Valuation | Task Items';
  })
  .catch((error) => console.log(error));

// eslint-disable-next-line max-len
export const updateTaskItem = (values, { fundId, valuationId, id }) => (dispatch) => TaskItemsApi.submit(values, { fundId, valuationId, id })
  .then(({ payload }) => {
    dispatch({ type: 'UPDATE_TASK_ITEM', payload });
    history.push(Routes.fund_valuation_task_items_path(fundId, valuationId));
    document.title = 'Fund | Valuation | Task Items';
  })
  .catch((error) => console.log(error));

export const closeTaskItem = (fundId, valuationId) => (dispatch) => {
  dispatch({ type: 'CLOSE_TASK_ITEM' });
  history.push(Routes.fund_valuation_task_items_path(fundId, valuationId));
  document.title = 'Fund | Valuation | Task Items';
};

export const deleteTaskItem = ({ fundId, valuationId, id }) => (dispatch) => {
  TaskItemsApi.delete({ fundId, valuationId, id })
    .then(({ payload }) => {
      dispatch({ type: 'DESTROY_TASK_ITEM', payload });
      history.push(Routes.fund_valuation_task_items_path(fundId, valuationId));
      document.title = 'Fund | Valuation | Task Items';
    })
    .catch((error) => console.log(error));
};

// eslint-disable-next-line max-len
export const changeTaskItemOwner = ({ ownerId }, { fundId, valuationId, id }) => (dispatch) => TaskItemsApi.submit({
  ownerId,
}, {
  fundId,
  valuationId,
  id,
})
  .then(({ payload }) => {
    dispatch({ type: 'CHANGE_TASK_ITEM_OWNER', payload });
  })
  .catch((error) => console.log(error));

// eslint-disable-next-line max-len
export const completeTaskItem = ({ completed }, { fundId, valuationId, id }) => (dispatch, getState) => {
  const { user } = getState();

  return TaskItemsApi.submit({
    completed,
    completedById: user.id,
  }, {
    fundId,
    valuationId,
    id,
  })
    .then(({ payload }) => {
      dispatch({ type: 'COMPLETE_TASK_ITEM', payload });
    })
    .catch((error) => console.log(error));
};

// eslint-disable-next-line max-len
export const changeTaskItemPosition = (itemPosition, position, { fundId, valuationId, id }) => (dispatch) => TaskItemsApi.submit(
  { position }, { fundId, valuationId, id },
)
  .then(({ payload }) => dispatch({ type: 'CHANGE_TASK_ITEM_POSITION', payload }))
  .catch((error) => console.log(error));
