import { createSelector } from 'reselect';
import { compact, findIndex } from 'lodash';

import optionsDecorator from 'decorators/refs';

const resourceFundSelector = (state) => state.resourceFund;
const valuationSelector = (state) => state.valuation;
const valuationOverrideItemsSelector = (state) => state.valuationOverrideItems;

export const getResourceFund = createSelector(
  resourceFundSelector,
  (resourceFund) => resourceFund,
);

export const getFundId = createSelector(
  resourceFundSelector,
  (resourceFund) => resourceFund && resourceFund.id,
);

export const getFundName = createSelector(
  resourceFundSelector,
  (resourceFund) => resourceFund && resourceFund.name,
);

export const getIsShareBased = createSelector(
  resourceFundSelector,
  (resourceFund) => resourceFund && resourceFund.type === 'Share Based',
);

export const getValuation = createSelector(
  valuationSelector,
  (valuation) => valuation,
);

export const getValuationId = createSelector(
  valuationSelector,
  (valuation) => valuation && valuation.id,
);

export const getValuationOverrideItems = createSelector(
  valuationOverrideItemsSelector,
  (items) => items,
);

export const getValuationOverrideItemIds = createSelector(
  valuationOverrideItemsSelector,
  (items) => compact(items.map((item) => item.id && item.id)),
);

export const getValuationOverrideItemBySourceId = (state, sourceId) => {
  const { valuationOverrideItems } = state;
  const index = findIndex(valuationOverrideItems, (item) => item.sourceId === sourceId);

  return {
    ...valuationOverrideItems[index],
    index,
  };
};

export const getFundClasses = createSelector(
  resourceFundSelector,
  (resourceFund) => resourceFund && resourceFund.investorClasses,
);

export const getFundSeries = createSelector(
  resourceFundSelector,
  (resourceFund) => resourceFund && resourceFund.investorSeries,
);

export const getFundClassOptions = createSelector(
  getFundClasses,
  (fundClasses) => optionsDecorator(fundClasses)(),
);

export const getFundSerieOptions = createSelector(
  getFundSeries,
  (fundSeries) => optionsDecorator(fundSeries)(),
);

export const getSerieOptionsByClassId = (state, classId) => {
  const fundSeries = getFundSeries(state);

  if (!classId) {
    return optionsDecorator(fundSeries)();
  }

  const filteredOptions = fundSeries.filter((serie) => serie.investorClassId === classId);

  return optionsDecorator(filteredOptions)();
};
