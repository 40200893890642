const initialState = {
  collection: [],
};

export default function(state = initialState, action) {
  switch (action.type) {
    case 'CREATE_TASK_ITEM_PATTERN':
    case 'UPDATE_TASK_ITEM_PATTERN':
    case 'DESTROY_TASK_ITEM_PATTERN':
    case 'CHANGE_TASK_ITEM_PATTERN_POSITION':
      return {
        ...state,
        collection: action.payload,
      };
    case 'NEW_TASK_ITEM_PATTERN':
    case 'EDIT_TASK_ITEM_PATTERN':
    case 'CLOSE_TASK_ITEM_PATTERN':
      return state;
    default:
      return state;
  }
}
