import { applyMiddleware, createStore, combineReducers, compose } from 'redux';
import thunk from 'redux-thunk';
import humps from 'humps';
import { stringToDate } from 'utils/dateTransformations';

import resourceFund from '../reducer';
import investorTransactionReducer from './reducer';

const rootReducer = combineReducers({
  resourceFund,
  investorTransactionForm: investorTransactionReducer,
});

export default function configureStore(initialState) {
  // Middleware and store enhancers
  const enhancers = [
    applyMiddleware(thunk),
  ];

  if (process.env.NODE_ENV !== 'production') {
    window.devToolsExtension && enhancers.push(window.devToolsExtension());
  }

  const camelizeState = humps.camelizeKeys(initialState);
  const state = {
    ...camelizeState,
    resourceFund: camelizeState.resourceFund,
    investorTransactionForm: {
      ...camelizeState.investorTransactionForm,
      data: {
        ...camelizeState.investorTransactionForm.data,
        recordingDate: camelizeState.investorTransactionForm.data.recordingDate ?
          stringToDate(camelizeState.investorTransactionForm.data.recordingDate) : undefined,
        dealingDate: camelizeState.investorTransactionForm.data.dealingDate ?
          stringToDate(camelizeState.investorTransactionForm.data.dealingDate) : undefined,
      },
      meta: {
        ...camelizeState.investorTransactionForm.meta,
      },
    },
  };

  const store = createStore(rootReducer, state, compose(...enhancers));

  return store;
}
