import React from 'react';
import PropTypes from 'prop-types';

import { Radio } from 'antd';

const propTypes = {
  options: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  buttonStyle: PropTypes.string,
};

const defaultProps = {
  value: null,
  options: [],
  buttonStyle: 'solid',
};

function RadioButtonGroup({ value, options, ...props }) {
  return (
    <Radio.Group value={value} {...props}>
      {options.map((option) => (
        <Radio.Button key={option.value} value={option.value}>
          {option.label}
        </Radio.Button>
      ))}
    </Radio.Group>
  );
}

RadioButtonGroup.propTypes = propTypes;
RadioButtonGroup.defaultProps = defaultProps;

export default RadioButtonGroup;
