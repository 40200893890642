import { Button, Input, Upload, Popconfirm, Tooltip, Icon, Popover, Tree, Avatar } from 'antd';
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { useDispatch, useSelector } from 'react-redux';
import { uniq, reject, isNil, isEmpty, toLower } from 'ramda';
import getRandomInt from 'utils/random';

import { openDrawer } from 'actions/funds/investorDocumentDirectories';
import { enableCreateNewDirectory, disableCreateNewDirectory, fetchCreateDirectory, fetchRenameDirectory, fetchRemoveDirectory } from 'actions/funds/investorDocumentDirectories/directories';
import { fetchDownloadDocument, fetchUploadDocuments, fetchRenameDocument, fetchRemoveDocument } from 'actions/funds/investorDocumentDirectories/documents';
import { getPrivateNodesIds, getPrivateClassesIds, getPrivateSeriesIds, getPrivateAccountsIds, getAccessesForDirectory, getAccessesForDocument, getFundAccountFilter } from 'selectors/funds/investorDocumentDirectories';
import { useAsyncFetchDefer } from 'utils/useAsyncFetch';

import Name from '../Drawer/Name';
import styles from './tree.module.css';

const types = { class: 'Fund::InvestorClass', series: 'Fund::InvestorSeries', account: 'Fund::Account' };

function TreeNode(props) {
  const {
    title, isDocument, fundId, onAddInput, onRemoveInput, parentId = null, id, isEditing = false, setIsEditing, isCreating = false, isUploading, extension, accesses, setIsOpen, isPrivate, inheritedAccesses, isLoadingSetPattern, isSettingPrivateFolders, isBindingDirectory, isBindingDocument, asyncRemoveDirectory,
    isRemovingDirectory,
    asyncRemoveDocument,
    isRemovingDocument
  } = props;
  const [name, setName] = useState(title || '');
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const treeNodeRef = useRef(null);
  const infoRef = useRef(null);
  const popoverRef = useRef();
  const li = treeNodeRef.current ? treeNodeRef.current.closest('li[role="treeitem"]') : null;
  const [fileList, setFileList] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  const [notVisibleAccesses, setNotVisibleAccesses] = useState(0);
  const [nodeWidth, setNodeWidth] = useState(0);
  const [andMoreOffset, setAndMoreOffset] = useState(0);
  const resizeObserver = new ResizeObserver(() => setNodeWidth(treeNodeRef.current ? treeNodeRef.current.offsetWidth : 0));
  if (treeNodeRef.current) { resizeObserver.observe(treeNodeRef.current) }
  const accessesRef = useCallback(node => {
    if (node !== null) {
      const nodes = Array.from(node.childNodes);
      nodes.forEach(el => el.style.display = 'initial');
      const invisibleNodes = nodes.filter((el) => el.offsetTop > 1);

      invisibleNodes.forEach(el => el.style.display = 'none');
      const moreInvisibleNodes = nodes.filter((el) => el.offsetTop > 1);
      moreInvisibleNodes.forEach(el => el.style.display = 'none');
      const notVisibleCount = invisibleNodes.length + moreInvisibleNodes.length;
      const visibleWidth = nodes.filter((el, idx) => idx < (nodes.length - notVisibleCount)).reduce((a, c) => a + c.offsetWidth, 0);
      setNotVisibleAccesses(notVisibleCount);
      setAndMoreOffset(visibleWidth + 4);
    }
  }, [nodeWidth]);

  const [asyncCreateDirectory, isCreatingDirectory, hasDirectoryCreated] = useAsyncFetchDefer(fetchCreateDirectory);
  const [asyncRenameDirectory, isRenamingDirectory, hasDirectoryRenamed] = useAsyncFetchDefer(fetchRenameDirectory);
  const [asyncRenameDocument, isRenamingDocument, hasDocumentRenamed] = useAsyncFetchDefer(fetchRenameDocument);
  const [asyncDownloadDocument, isDownloadingDocument] = useAsyncFetchDefer(fetchDownloadDocument);

  const privateNodesIds = useSelector(getPrivateNodesIds);
  const privateClassesIds = useSelector(getPrivateClassesIds);
  const privateSeriesIds = useSelector(getPrivateSeriesIds);
  const privateAccountsIds = useSelector(getPrivateAccountsIds);
  const filterAccount = useSelector(getFundAccountFilter);
  const needAccountsAccess = !isPrivate && !filterAccount && !isCreating && !isRemovingDirectory && !isRemovingDocument && !isLoadingSetPattern;
  const accountsAccess = useSelector((state) => !needAccountsAccess ? [] : isDocument ? getAccessesForDocument(state, [...accesses, ...inheritedAccesses]) : getAccessesForDirectory(state, [...accesses, ...inheritedAccesses])) || []

  const isLoading = isRenamingDirectory || isRenamingDocument || isDownloadingDocument;
  const isPrivateClass = !isDocument && privateClassesIds.includes(id);
  const isPrivateSeries = !isDocument && privateSeriesIds.includes(id);
  const isPrivateAccount = !isDocument && privateAccountsIds.includes(id);
  const isPrivateClassOrSeries = isPrivateClass || isPrivateSeries;
  const isPrivateRegular = !isPrivateClassOrSeries && !isPrivateAccount;

  const onCreate = () => {
    setIsOpen(true)
    dispatch(enableCreateNewDirectory(id, fundId, isPrivate));
  }

  const handleCreate = () => {
    asyncCreateDirectory({ title: name, parentId, fundId, isPrivate: privateNodesIds.includes(parentId) });
  }

  const cancelCreate = () => {
    setIsEditing(false);
    dispatch(disableCreateNewDirectory());
  }

  const onManageAccess = (e) => {
    e.stopPropagation();
    dispatch(openDrawer({
      fundId, id, title, parentId, isDocument, isEditing: true, accesses, inheritedAccesses,
    }))
  }

  const handleUpload = (files) => {
    dispatch(openDrawer({
      fundId, folderId: id, files, inheritedAccesses: [...accesses, ...inheritedAccesses], isPrivate
    }));
  }

  const handleDownload = () => {
    asyncDownloadDocument({ title, fundId, parentId, id })
  }

  const onRename = () => {
    setIsEditing(true)
  }

  const handleRename = () => {
    if (isDocument) {
      asyncRenameDocument({ fundId, id, parentId, title: name });
    } else {
      asyncRenameDirectory({ fundId, id, title: name });
    }
  }

  const cancelRename = () => {
    setIsEditing(false);
    setName(title);
  }

  const handleRemove = () => {
    if (isDocument) {
      asyncRemoveDocument({ fundId, id, parentId });
    } else {
      asyncRemoveDirectory({ fundId, id })
    }
  }

  const onFileDrop = useCallback((item, monitor) => {
    if (monitor) {
      const { files } = monitor.getItem();
      const normalizedFiles = files.map((file) => {
        const uid = `${getRandomInt()}`;
        return { originFileObj: file, uid, name: file.name }
      });
      if (files) { handleUpload(normalizedFiles) }
    }
  }, []);

  const [{ canDrop, isOver, isDragging }, drop] = useDrop({
    accept: [NativeTypes.FILE],
    canDrop: () => !isPrivateClassOrSeries && !filterAccount,
    drop: onFileDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver({ shallow: true }),
      canDrop: monitor.canDrop(),
      isDragging: !!monitor.getItem(),
    }),
  });

  useEffect(() => {
    if (isDragging && li) {
      li.classList.add('ant-tree-node-file-drop');
    }
    if (!isDragging && li) {
      li.classList.remove('ant-tree-node-file-drop');
    }
  }, [isDragging]);

  useEffect(() => {
    if (isEditing) { inputRef.current.focus(); }
  }, [isEditing]);

  useEffect(() => {
    if (!isRenamingDirectory && !isRenamingDocument && (hasDirectoryRenamed || hasDocumentRenamed)) { setIsEditing(false); }
  }, [isRenamingDirectory, hasDirectoryRenamed, isRenamingDocument, hasDocumentRenamed]);

  useEffect(() => {
    if (hasDirectoryCreated) { dispatch(disableCreateNewDirectory()) }
  }, [hasDirectoryCreated]);

  useEffect(() => {
    if (isCreating && !isEditing) {
      setIsEditing(true);
    }
  }, []);

  useEffect(() => {
    if (fileList.length > 0) { handleUpload(fileList); }
  }, [fileList])

  useEffect(() => {
    if (!isUploading) { setFileList([]) };
  }, [isUploading])

  useEffect(() => {
    if (!isEmpty(searchValue)) {
      const element = popoverRef.current ? popoverRef.current.getElementsByClassName('found-item')[0] : null;

      if (element) {
        setTimeout(() => {
          element.scrollIntoView({ block: 'center', behavior: 'smooth' })
        }, 100);
      }
    }
  }, [searchValue]);

  const onSearch = useCallback(
    (e) => {
      const { value } = e.target;
      setSearchValue(value);
    },
    [accountsAccess],
  );

  if (isEditing) {
    return (
      <div onClick={(e) => e.stopPropagation()} style={{ display: 'inline' }}>
        <div className={styles.treeNode}>
          <Input
            size="small"
            value={name}
            placeholder="Enter the folder name"
            onKeyDown={(e) => e.key === 'Escape' && (isCreating ? cancelCreate : cancelRename)}
            onClick={(e) => e.stopPropagation()}
            onChange={(e) => { setName(e.target.value) }}
            onPressEnter={isCreating ? handleCreate : handleRename}
            ref={inputRef}
          />
          <div className={`${styles.nodeActions} ${styles.visible}`}>
            <Tooltip title={isCreating ? 'Create folder' : 'Rename'}>
              <Button
                className={styles.nodeAction}
                style={{ marginLeft: 15 }}
                type="link"
                size="small"
                icon={isCreatingDirectory || isRenamingDirectory || isRenamingDocument ? "loading" : "check"}
                onClick={isCreating ? handleCreate : handleRename}
              />
            </Tooltip>
            <Tooltip title="Cancel">
              <Button
                className={styles.nodeAction}
                style={{ marginRight: 0 }}
                type="link"
                size="small"
                icon="close"
                onClick={isCreating ? cancelCreate : cancelRename}
              />
            </Tooltip>
          </div>
        </div>
      </div>
    )
  }

  const accessesForAvatars = (accesses) => {
    return accesses.map(({ ownerId, ownerType }, id) => {
      if (ownerType === types.class) {
        const klass = accountsAccess.filter(account => account.investorClass.id === ownerId)[0].investorClass;
        return { type: types.class, payload: klass, id };
      }
      if (ownerType === types.series) {
        const series = accountsAccess.filter(account => account.investorSeries.id === ownerId)[0].investorSeries;
        return { type: types.series, payload: series, id };
      }
      if (ownerType === types.account) {
        const [account] = accountsAccess.filter(account => account.id === ownerId);
        return { type: types.account, payload: account };
      }
    })
  }

  const getAccessAccounts = (accesses) => {
    return accesses.reduce((acc, { ownerId, ownerType }) => {
      if (ownerType === types.class) {
        const accounts = accountsAccess.filter(account => account.investorClass.id === ownerId);
        return [...acc, ...accounts];
      }
      if (ownerType === types.series) {
        const accounts = accountsAccess.filter(account => account.investorSeries.id === ownerId);
        return [...acc, ...accounts]
      }
      if (ownerType === types.account) {
        const [account] = accountsAccess.filter(account => account.id === ownerId);
        return [...acc, ...(account ? [account] : [])];
      }
      return acc;
    }, [])
  }

  const generateAccountTree = () => {
    return getAccessAccounts([...accesses, ...inheritedAccesses]).reduce((acc, cur) => {
      const obj = [...acc];
      const klass = obj.find(x => x.id === cur.investorClass.id);
      if (klass) {
        const series = klass.children.find(x => x.id === cur.investorSeries.id);
        if (series) {
          series.children.push({ id: cur.id, key: `account-${cur.id}`, title: <Name searchValue={toLower(searchValue)} item={{ name: cur.name }} /> })
        } else {
          klass.children.push({ id: cur.investorSeries.id, key: `series-${cur.investorSeries.id}`, title: <Name searchValue={toLower(searchValue)} item={{ name: cur.investorSeries.name }} />, children: [{ id: cur.id, key: `account-${cur.id}`, title: <Name searchValue={toLower(searchValue)} item={{ name: cur.name }} /> }] })
        }
      } else {
        obj.push({ id: cur.investorClass.id, key: `class-${cur.investorClass.id}`, title: <Name searchValue={toLower(searchValue)} item={{ name: cur.investorClass.name }} />, children: [{ id: cur.investorSeries.id, key: `series-${cur.investorSeries.id}`, title: <Name searchValue={toLower(searchValue)} item={{ name: cur.investorSeries.name }} />, children: [{ id: cur.id, key: `account-${cur.id}`, title: <Name searchValue={toLower(searchValue)} item={{ name: cur.name }} /> }] }] })
      }
      return obj;
    }, [])
  }

  const getNames = (accounts) => {
    // const avatar = (name, type) => <Avatar
    //   className={styles.avatar}
    //   size={20}
    //   style={{ backgroundColor: type === types.class ? '#ccc' : type === types.series ? '#eab79d' : '#ea763b' }}
    // >
    //   {`${name.split(' ')[0][0]}${name.split(' ')[1] && name.split(' ')[1][0] !== '#' ? name.split(' ')[1][0] : ''}${(name.split(' ')[2] && name.split(' ')[2][0] !== '#') ? name.split(' ')[2][0] : ''}`}
    // </Avatar>
    const avatar = (name, type, isInherited) => <span className={styles.accessName} style={!isInherited ? { fontWeight: 'bold' } : {}}>{name}</span>

    const uniqAccounts = uniq(accounts.map(acc => {
      return { type: acc.type, name: acc.type !== types.account ? acc.payload.name : acc.payload.investor.displayName, isInherited: !!inheritedAccesses.find(x => x.ownerId === acc.payload.id) }
    }).sort((a, b) => {
      const priority = {
        ['Fund::InvestorClass']: 0,
        ['Fund::InvestorSeries']: 1,
        ['Fund::Account']: 2,
      }
      return priority[a.type] - priority[b.type];
    }));

    return uniqAccounts.map(acc => avatar(acc.name, acc.type, acc.isInherited))
  }

  return (
    <>
      <div className={`${styles.folder} ${styles.treeNode}`} ref={treeNodeRef}>
        {!isDocument && isDragging && <div className={`${styles.dropZone} ${isOver ? canDrop ? styles.dropOver : styles.dropOverDanger : ''}`} style={{ height: li.offsetHeight }} ref={drop} />}
        <div className={styles.info} ref={infoRef}>
          <span className={styles.title}>{`${title}${extension || ''}`}</span>
          {needAccountsAccess && !isPrivate && !filterAccount && !isBindingDirectory && !isBindingDocument && ((accesses || []).length > 0 || (inheritedAccesses || []).length > 0) && infoRef.current && infoRef.current.offsetWidth > 120 && (
            <Popover title="Access holders" onVisibleChange={(isVisible) => !isVisible && setSearchValue('')} content={
              <div onClick={(e) => e.stopPropagation()} ref={popoverRef}>
                <Input.Search placeholder="Search" value={searchValue} onChange={onSearch} style={{ marginBottom: '20px' }} allowClear />
                <div className={styles.popover} onClick={e => e.stopPropagation()}>
                  <Tree selectable={false} defaultExpandAll treeData={generateAccountTree()} />
                </div>
              </div>
            }>
              <div className={styles.avatarsWrap} onClick={onManageAccess}>
                <div className={styles.avatars} ref={accessesRef}>
                  {getNames(accessesForAvatars([...accesses, ...inheritedAccesses]))}
                </div>
                {notVisibleAccesses > 0 && (
                  <span
                    className={styles.avatarsMore}
                    style={{ marginLeft: `${andMoreOffset}px` }}
                  >
                    and {notVisibleAccesses} more
                  </span>
                )}
              </div>
            </Popover>
          )}
        </div>
        <div onClick={(e) => e.stopPropagation()}>
          <div className={styles.nodeActions}>
            {isDocument && (
              <>
                <Tooltip title="Download">
                  <Button className={styles.nodeAction} type="link" size="small" onClick={handleDownload} icon={isDownloadingDocument ? "loading" : "cloud-download"} />
                </Tooltip>
              </>
            )}
            {!isDocument && !isPrivateClassOrSeries && !filterAccount && (
              <>
                <Tooltip title="Create new folder">
                  <Button className={styles.nodeAction} type="link" size="small" onClick={onCreate} icon="folder-add" theme="filled" />
                </Tooltip>

                <Upload
                  onChange={({ fileList: files }) => setFileList([...files])}
                  fileList={fileList}
                  showUploadList={false}
                  customRequest={() => { }}
                  multiple
                >
                  <Tooltip title="Upload documents" placement={isPrivate ? 'topRight' : 'top'}>
                    <Button className={`${styles.nodeAction} ${isPrivate ? styles.nodeActionLast : ''}`} type="link" size="small" icon="file-add" theme="filled" />
                  </Tooltip>
                </Upload>
              </>
            )}
            {!isPrivate && !filterAccount && (
              <Tooltip title="Manage access">
                <Button className={`${styles.nodeAction}`} type="link" size="small" onClick={onManageAccess} icon="lock" theme="filled" />
              </Tooltip>
            )}
            {(!isPrivate || isPrivateRegular) && (
              <>
                <Tooltip title="Rename">
                  <Button className={styles.nodeAction} type="link" size="small" onClick={onRename} icon="edit" theme="filled" />
                </Tooltip>
                <Tooltip title="Delete">
                  {isRemovingDirectory || isRemovingDocument ? (
                    <Button className={`${styles.nodeAction} ${styles.nodeActionFix} ${styles.nodeActionLast}`} type="link" size="small" icon="delete" disabled theme="filled" />
                  ) : (
                      <Popconfirm
                        placement="left"
                        title={`Are you sure you want to delete this ${isDocument ? 'document' : 'folder'}`}
                        onConfirm={handleRemove}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button className={`${styles.nodeAction} ${styles.nodeActionLast}`} type="link" size="small" icon="delete" theme="filled" />
                      </Popconfirm>
                    )}
                </Tooltip>
              </>
            )}
            {/* <div className={`${styles.nodeActionsInvisible} ${!isPrivate ? styles.nodeActionsInvisibleBorder : ''}`}>
              {isDocument && (
                <>
                  <Tooltip title="Download">
                    <Button className={styles.nodeAction} type="link" size="small" onClick={handleDownload} icon={isDownloadingDocument ? "loading" : "cloud-download"} />
                  </Tooltip>
                </>
              )}
              {!isDocument && !isPrivateClassOrSeries && !filterAccount && (
                <>
                  <Tooltip title="Create new folder">
                    <Button className={styles.nodeAction} type="link" size="small" onClick={onCreate} icon="folder-add" theme="filled" />
                  </Tooltip>

                  <Upload
                    onChange={({ fileList: files }) => setFileList([...files])}
                    fileList={fileList}
                    showUploadList={false}
                    customRequest={() => { }}
                    multiple
                  >
                    <Tooltip title="Upload documents" placement={isPrivate ? 'topRight' : 'top'}>
                      <Button className={styles.nodeAction} type="link" size="small" icon="file-add" theme="filled" />
                    </Tooltip>
                  </Upload>
                </>
              )}
              {(!isPrivate || isPrivateRegular) && (
                <>
                  <Tooltip title="Rename">
                    <Button className={styles.nodeAction} type="link" size="small" onClick={onRename} icon="edit" theme="filled" />
                  </Tooltip>
                  <Tooltip title="Delete">
                    {isRemovingDirectory || isRemovingDocument ? (
                      <Button className={`${styles.nodeAction} ${styles.nodeActionFix}`} type="link" size="small" icon="delete" disabled theme="filled" />
                    ) : (
                        <Popconfirm
                          placement="left"
                          title={`Are you sure you want to delete this ${isDocument ? 'document' : 'folder'}`}
                          onConfirm={handleRemove}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button className={styles.nodeAction} type="link" size="small" icon="delete" theme="filled" />
                        </Popconfirm>
                      )}
                  </Tooltip>
                </>
              )}
            </div>
            {!isPrivate && !filterAccount && (
              <Tooltip title="Manage access" placement="topLeft">
                <Button className={`${styles.nodeAction} ${styles.nodeActionAccesses}`} type="link" size="small" onClick={onManageAccess} icon="lock" theme="filled" />
              </Tooltip>
            )} */}
          </div>
          {isLoading && <div className={styles.loading}><Icon type="loading" /></div>}
        </div>
      </div>
    </>
  )
}

export default TreeNode;
