import { Button, Input, Popconfirm, Tooltip, Icon } from 'antd'
import React, { useRef, useState, useEffect } from 'react';

import { createDirectory, renameDirectory, deleteDirectory } from 'actions/investorDocumentPatterns';
import { useAsyncFetchDefer } from 'utils/useAsyncFetch';

import styles from './drawer.module.css';

function TreeNode(props) {
  const {
    id, title, isLeaf, parentId, patternId, expand, isEditing, toggleEditing
  } = props;

  const inputRef = useRef(null);

  const [name, setName] = useState(!isLeaf && title || '');
  const [wantToDelete, setWantToDelete] = useState(false);

  const [asyncCreateDirectory, isCreateLoading, isDirectoryCreated] = useAsyncFetchDefer(createDirectory);
  const [asyncRenameDirectory, isRenameLoading, isDirectoryRenamed] = useAsyncFetchDefer(renameDirectory);
  const [asyncDeleteDirectory, isDeleteLoading] = useAsyncFetchDefer(deleteDirectory);

  const handleCreate = () => {
    asyncCreateDirectory({ title: name, parentId, patternId });
  };

  const handleDelete = (e) => {
    e.stopPropagation();
    setWantToDelete(false);
    asyncDeleteDirectory({ patternId, id });
  }

  const handleEdit = (e) => {
    e.stopPropagation();
    toggleEditing(true);
  }

  const handleChangeName = () => {
    asyncRenameDirectory({ title: name, parentId, id, patternId });
  }

  const handleCancelNameChange = (e) => {
    e.stopPropagation();
    setName(title || '');
    toggleEditing(false);
  }

  useEffect(() => {
    if (isDirectoryCreated || isDirectoryRenamed) {
      toggleEditing(false);
    }
  }, [isDirectoryCreated, isDirectoryRenamed])

  useEffect(() => {
    if (isDirectoryCreated) { setName(''); }
  }, [isDirectoryCreated])

  useEffect(() => {
    if (isEditing) { inputRef.current.focus(); }
  }, [isEditing])

  if (isEditing) {
    return (
      <div className={`${styles.treeNode} ${isLeaf ? styles.fullWidth : ''}`}>
        <Input
          size="small"
          value={name}
          onKeyDown={(e) => e.key === 'Escape' && handleCancelNameChange()}
          onChange={(e) => { setName(e.target.value) }}
          onClick={(e) => e.stopPropagation()}
          onPressEnter={isLeaf ? handleCreate : handleChangeName}
          ref={inputRef}
        />
        <div className={`${styles.nodeActions} ${isEditing && styles.visible}`}>
          {isRenameLoading || isCreateLoading
            ? <Icon type="loading" className={styles.nodeAction} />
            : <Button
              className={styles.nodeAction}
              type="link"
              size="small"
              icon="check"
              onClick={isLeaf ? handleCreate : handleChangeName}
            />}
          <Button
            className={styles.nodeAction}
            type="link"
            size="small"
            icon="close"
            onClick={handleCancelNameChange}
          />
        </div>
      </div>
    )
  }

  if (isLeaf) {
    return <Button type="link" size="small" icon="plus" onClick={handleEdit}>Add directory</Button>
  }

  return (
    <div className={styles.treeNode} onClick={expand}>
      <div className={styles.title}>{title}</div>
      <div className={styles.nodeActions}>
        <Tooltip title="Rename">
          <Button
            className={styles.nodeAction}
            type="link"
            size="small"
            icon="edit"
            onClick={handleEdit}
          />
        </Tooltip>
        {isDeleteLoading
          ? <Icon type="loading" className={styles.nodeAction} />
          : (
            <Tooltip title="Delete">
              <Popconfirm
                visible={wantToDelete}
                placement="left"
                title="Are you sure you want to delete this folder"
                onConfirm={handleDelete}
                onCancel={(e) => { e.stopPropagation(); setWantToDelete(false) }}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  className={styles.nodeAction}
                  type="link"
                  size="small"
                  icon="delete"
                  onClick={(e) => { e.stopPropagation(); setWantToDelete(true) }}
                />
              </Popconfirm>
            </Tooltip>
          )}
      </div>
    </div>
  )
}

export default TreeNode;