import { filter, sort, clone, includes, uniqBy, map } from 'ramda';
import { getIn } from 'utils/ramda';

import { types } from '../../constants/user';
import { statuses } from '../../constants/fund';

const tasksSelector = (state) => getIn('tasks', state) || {};
const tasksCollectionSelector = (state) => getIn('collection', tasksSelector(state)) || [];
const tasksUsersSelector = (state) => getIn('users', tasksSelector(state)) || [];
const tasksRolesSelector = (state) => getIn('roles', tasksSelector(state)) || [];
const tasksStatusesSelector = (state) => getIn('statuses', tasksSelector(state)) || [];
const tasksFundsSelector = (state) => getIn('funds', tasksSelector(state)) || [];

const filterTasks = (filterParams, collection) => {
  const newCollection = filter((task) => {
    const isTaskFieldInParams = (fieldName) => filterParams[fieldName] && includes(task[fieldName], filterParams[fieldName]);
    const isTaskNeeded = ['role', 'user', 'status', 'fund'].some(field => isTaskFieldInParams(field));
    return isTaskNeeded;
  }, collection);
  return newCollection;
};

const sortByTextAlphabetically = (field, collection, order = 'asc') => {
  const sortedCollection = sort((a, b) => ((a[field] && b[field]) ? a[field].localeCompare(b[field]) : 0), collection);
  return order === 'desc' ? sortedCollection.reverse() : sortedCollection;
};

const sortByDate = (field, collection, order = 'asc') => sort((a, b) => {
  if (order === 'asc') return new Date(b[field]) - new Date(a[field]);
  return new Date(a[field]) - new Date(b[field]);
}, collection);

const sortTasks = (sortParam = '', collection = []) => {
  const [field, order] = sortParam.split('.');
  switch (field) {
    case 'user_ids':
    case 'fund_ids':
      return sortByTextAlphabetically(field.split('_')[0], collection, order)
    case 'roles':
    case 'fund_statuses':
      return sortByTextAlphabetically(field, collection, order);
    case 'valuationDate':
    case 'assignedAt':
      return sortByDate(field, collection, order);
    default:
      return collection;
  }
};

export const getTasks = (state, { sort: sortParam, ...params }) => {
  let collection = tasksCollectionSelector(state);
  // collection = filterTasks(params, collection);
  collection = sortTasks(sortParam, collection);
  return collection;
};

// eslint-disable-next-line max-len
export const getUsers = (state) => sortByTextAlphabetically('text', map(
  ({ id, name }) => ({ text: name, value: `${id}` }),
  tasksUsersSelector(state),
));

// eslint-disable-next-line max-len
export const getFunds = (state) => sortByTextAlphabetically('text', map(
  ({ id, name }) => ({ text: name, value: `${id}` }),
  tasksFundsSelector(state),
));

// eslint-disable-next-line max-len
export const getRoles = (state) => sortByTextAlphabetically('text', map(
  (role) => ({ text: types[role], value: role }),
  tasksRolesSelector(state),
));

// eslint-disable-next-line max-len
export const getStatuses = (state) => sortByTextAlphabetically('text', map(
  (status) => ({ text: statuses[status], value: status }),
  tasksStatusesSelector(state),
));
