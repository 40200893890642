import axios from 'axios';
import humps from 'humps';
import moment from 'moment';

import { isDate, stringToDate, dateToString } from 'utils/dateTransformations';

import { changeInput } from 'actions/changeInput';
import * as types from './tradeActionTypes';

export * from 'actions/changeInput';
export * from 'actions/submitForm';

export function selectSearchSecurityType(type) {
  return (dispatch) => {
    dispatch(removeSecurity());

    dispatch({
      type: types.SELECT_SEARCH_SECURITY_TYPE,
      payload: {
        securityType: type.value,
      },
    });
  };
}

export function changeSearchExpiredSecurity(e) {
  return (dispatch) => {
    dispatch({
      type: types.CHANGE_SEARCH_EXPIRED_SECURITY,
      payload: {
        securityExpired: e.target.checked,
      },
    });

    dispatch(removeSecurity());
  };
}

export function getSecurityOptions(searchValue) {
  if (searchValue.length <= 2) {
    return () => Promise.resolve({ options: [] });
  }

  return (dispatch, getState) => {
    const { searchSecurity } = getState();

    return fetch(Routes.securities_path({
      s: {
        name: searchValue,
        type: searchSecurity ? searchSecurity.type : '',
        expired: searchSecurity && (searchSecurity.type === 'Security::Option' || searchSecurity.type === 'Security::Future') ? searchSecurity.expired || false : '',
      },
      format: 'json',
    }), {
      credentials: 'include',
    })
      .then((response) => response.json())
      .then((json) => ({ options: json }));
  };
}

export function changeSecurity(selectedSecurity) {
  const camelizeSecurity = humps.camelizeKeys(selectedSecurity);

  return (dispatch, getState) => {
    const { resourceFund: { baseCurrencyIso }, security, trade } = getState();

    if (selectedSecurity && selectedSecurity.id !== trade.securityId) {
      dispatch(updateSecurity(camelizeSecurity));
      dispatch(fetchCurrentHoldingsRequest());

      if (camelizeSecurity && (baseCurrencyIso != camelizeSecurity.currencyIso)) {
        dispatch(updateFxRateByCurrencyIso(camelizeSecurity.currencyIso, baseCurrencyIso, trade.tradeDate));
        dispatch(updateTradeFx(true));
      } else {
        dispatch(updateTradeFx(false));
      }
    }

    if (selectedSecurity === null) {
      dispatch({
        type: types.REMEMBER_SECURITY,
        payload: {
          security,
        },
      });

      dispatch({ type: types.REMOVE_SECURITY });
    }
  };
}

export function removeSecurity() {
  return (dispatch, getState) => {
    const { trade: { securityId }, security } = getState();

    if (securityId !== undefined || security !== undefined) {
      dispatch({
        type: types.REMEMBER_SECURITY,
        payload: {
          security,
        },
      });

      dispatch({ type: types.REMOVE_SECURITY });
    }
  };
}

export function changeQuantityForCurrencyTrade(event) {
  return (dispatch, getState) => {
    const { resourceFund: { baseCurrencyIso }, trade, security } = getState();

    dispatch(changeInput(event));
    dispatch(changeCurrencyTradeFxRates());
  };
}

export function changePriceForCurrencyTrade(event) {
  return (dispatch, getState) => {
    const { resourceFund: { baseCurrencyIso }, trade, security } = getState();

    dispatch(changeInput(event));
    dispatch(changeCurrencyTradeFxRates());
  };
}

export function changeCurrencyTradeFxRates() {
  return (dispatch, getState) => {
    const { resourceFund: { baseCurrencyIso }, trade, security } = getState();

    const sellCurrencyFxRate = calculateFxRateForCurrencyTrade(baseCurrencyIso, trade, security, 'sellCurrencyFxRate');
    const buyCurrencyFxRate = calculateFxRateForCurrencyTrade(baseCurrencyIso, trade, security, 'buyCurrencyFxRate');

    dispatch({
      type: types.CHANGE_INPUT,
      payload: {
        value: buyCurrencyFxRate,
        stateKey: 'trade.buyCurrencyFxRate',
      },
    });

    dispatch({
      type: types.CHANGE_INPUT,
      payload: {
        value: sellCurrencyFxRate,
        stateKey: 'trade.sellCurrencyFxRate',
      },
    });
  };
}

export function changeFxRate(event) {
  return (dispatch, getState) => {
    const { trade } = getState();

    const needSettleFxRateUpdate = !!(((trade.tradeFxRate == trade.settleFxRate) || !trade.settleFxRate));

    dispatch(changeInput(event));
    if (needSettleFxRateUpdate) {
      dispatch(makeSettleFxRateEqualToTradeFxRate());
    }
  };
}

export function updateTradeDate(e) {
  return (dispatch, getState) => {
    const { trade, security, resourceFund } = getState();
    const target = e.target ? e.target : e;
    const { value } = target;
    const tradeDate = stringToDate(value);
    const settleDate = isDate(tradeDate) ? (
      moment.utc(tradeDate).add(security.settleDays, 'days').toDate()
    ) : (
      trade.settleDate
    );

    dispatch({
      type: types.UPDATE_TRADE_DATE,
      payload: {
        tradeDate,
        settleDate,
      },
    });

    if (tradeDate) {
      dispatch(fetchCurrentHoldingsRequest());

      if (security && (resourceFund.baseCurrencyIso != security.currencyIso)) {
        dispatch(updateFxRateByCurrencyIso(security.currencyIso, resourceFund.baseCurrencyIso, tradeDate));
      }
    }
  };
}

export const updateHistoricTradeDate = (e) => (dispatch) => {
  const target = e.target ? e.target : e;
  const { value } = target;

  dispatch({
    type: types.UPDATE_HISTORIC_TRADE_DATE,
    payload: {
      date: stringToDate(value),
    },
  });
};

export const changeHistoricTradeCheckbox = (e) => (dispatch) => {
  dispatch(changeInput(e));
  dispatch({
    type: types.UPDATE_HISTORIC_TRADE_DATE,
    payload: {
      date: null,
    },
  });
};

export function changeFeesCurrency(event) {
  return (dispatch) => {
    dispatch(changeInput(event));
    dispatch(convertFeesByFxRate(event.target.value === 'true'));
  };
}

export function clearPreviousSecurity(security) {
  return {
    type: types.CLEAR_PREVIOUS_SECURITY,
    payload: {
      security,
    },
  };
}

export function restorePreviousSecurity(previousSecurity) {
  return (dispatch, getState) => {
    const { resourceFund: { baseCurrencyIso }, security, trade } = getState();

    if (previousSecurity && previousSecurity.id !== trade.securityId) {
      dispatch(updateSecurity(previousSecurity));
      dispatch(fetchCurrentHoldingsRequest());

      if (previousSecurity && (baseCurrencyIso != previousSecurity.currencyIso)) {
        dispatch(updateFxRateByCurrencyIso(previousSecurity.currencyIso, baseCurrencyIso, trade.tradeDate));
        dispatch(updateTradeFx(true));
      } else {
        dispatch(updateTradeFx(false));
      }
    }

    if (security) {
      dispatch({
        type: types.RESTORE_PREVIOUS_SECURITY,
        payload: {
          security,
        },
      });
    }

    if (security && previousSecurity) {
      if (security.id == previousSecurity.id) {
        dispatch({
          type: types.CLEAR_PREVIOUS_SECURITY,
          payload: {
            security,
          },
        });
      }
    } else {
      dispatch({
        type: types.CLEAR_PREVIOUS_SECURITY,
        payload: {
          security,
        },
      });
    }
  };
}

function convertFeesByFxRate(invert = false) {
  return (dispatch, getState) => {
    const { trade: { tradeFxRate, commissionFee, brokerFee, secFee, otherFee, taxes } } = getState();
    const calculate = (fee = 0) => (invert ? fee * tradeFxRate : fee / tradeFxRate);

    dispatch({
      type: types.CONVERT_FEES_BY_FX_RATE,
      payload: {
        commissionFee: calculate(commissionFee),
        brokerFee: calculate(brokerFee),
        secFee: calculate(secFee),
        otherFee: calculate(otherFee),
        taxes: calculate(taxes),
      },
    });
  };
}

function updateSecurity(security) {
  return (dispatch, getState) => {
    const { trade: { tradeDate } } = getState();
    const { id, settleDays } = security;

    dispatch({
      type: types.UPDATE_SECURITY,
      payload: {
        securityId: id,
        settleDate: tradeDate ? moment.utc(tradeDate).add(settleDays, 'days').toDate() : undefined,
        security,
      },
    });
  };
}

function updateTradeFx(fx) {
  return {
    type: types.UPDATE_TRADE_FX,
    payload: {
      fx,
    },
  };
}

function updateFxRateByCurrencyIso(currencyIso, baseCurrencyIso, tradeDate) {
  return (dispatch, getState) => {
    const { batch } = getState();

    const price = fetchPrice(batch.brokerAccount.valuationPriceType, currencyIso, baseCurrencyIso, tradeDate);

    dispatch({ type: types.SET_FX_RATE, payload: { fxRate: price.rate } });
    dispatch(makeSettleFxRateEqualToTradeFxRate());
  };
}

function fetchCurrentHoldings(securityId, currentHoldings, totalSells, totalBuys, withSecurityPrice, pricingDate, securityPrice, holdingsDetails, currenciesBalance) {
  return {
    type: types.FETCH_CURRENT_HOLDINGS,
    payload: {
      currentHoldings,
      totalSells,
      totalBuys,
      withSecurityPrice,
      pricingDate: pricingDate ? moment(pricingDate).format('MM/DD/YYYY') : null,
      securityPrice,
      holdingsDetails,
      currenciesBalance,
    },
  };
}

function fetchCurrentHoldingsRequest() {
  return (dispatch, getState) => {
    const { trade } = getState();

    axios.get(Routes.fund_batch_holdings_path(trade.fundId, trade.batchId, { format: 'json' }), {
      withCredentials: true,
      params: {
        trade_date: trade.tradeDate,
        trade_created_at: trade.tradeCreatedAt,
        trade_id: trade.id,
        security_id: trade.securityId,
      },
    })
      .then(
        (res) => {
          const camelizedData = humps.camelizeKeys(res.data);
          const pricingDate = camelizedData.pricingDate ? stringToDate(camelizedData.pricingDate) : undefined;

          return dispatch(fetchCurrentHoldings(
            trade.securityId,
            camelizedData.currentHoldings,
            camelizedData.totalSells,
            camelizedData.totalBuys,
            camelizedData.withSecurityPrice,
            pricingDate,
            camelizedData.securityPrice,
            camelizedData.holdingsDetails,
            camelizedData.currenciesBalance,
          ));
        },
      );
  };
}

function fetchPrice(priceType, currencyIso, fundBaseCurrencyIso, accountingDate) {
  const result = {
    rate: 1,
    createdAt: '-',
  };

  if (currencyIso === fundBaseCurrencyIso) {
    result.rate = 1;
  } else {
    $.ajax({
      url: Routes.fx_rate_path({
        search: {
          buy_currency_iso: fundBaseCurrencyIso,
          sell_currency_iso: currencyIso,
          date: dateToString(accountingDate),
          price_type: priceType || 'close',
        },
      }),
      type: 'GET',
      dataType: 'json',
      async: false,
      error(callback) {
        return callback;
      },
      success(res) {
        if (res !== undefined) {
          result.rate = res.rate;
          result.createdAt = res.date;
        }
      },
    });
  }

  console.log(result);

  return result;
}

function makeSettleFxRateEqualToTradeFxRate() {
  return { type: types.MAKE_SETTLE_FX_RATE_EQUAL_TO_TRADE_FX_RATE };
}

function calculateFxRateForCurrencyTrade(baseCurrencyIso, trade, security, fxRateType) {
  const securityBuyCurrencyIso = security.buyCurrencyIso;
  const securitySellCurrencyIso = security.sellCurrencyIso;

  switch (fxRateType) {
    case 'buyCurrencyFxRate':
      if ((securityBuyCurrencyIso !== baseCurrencyIso) && (securitySellCurrencyIso !== baseCurrencyIso)) {
        return trade.buyCurrencyFxRate;
      }
      if (securityBuyCurrencyIso !== baseCurrencyIso) {
        return Number(trade.signedQuantity).toFixed(10) / Number(trade.price).toFixed(10);
      }
      if (securityBuyCurrencyIso === baseCurrencyIso) {
        return 1.0;
      }

    case 'sellCurrencyFxRate':
      if ((securityBuyCurrencyIso !== baseCurrencyIso) && (securitySellCurrencyIso !== baseCurrencyIso)) {
        return trade.sellCurrencyFxRate;
      }
      if (securitySellCurrencyIso !== baseCurrencyIso) {
        return Number(trade.price).toFixed(10) / Number(trade.signedQuantity).toFixed(10);
      }
      if (securitySellCurrencyIso === baseCurrencyIso) {
        return 1.0;
      }
  }
}
