import React from 'react';
import PropTypes from 'prop-types';
import { accounting } from 'accounting';
import getSymbolFromCurrency from 'currency-symbol-map';

const propTypes = {
  creditAmount: PropTypes.number.isRequired,
  debitAmount: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
};

function AmountsAlert(props) {
  const { creditAmount, debitAmount, currency, equalCreditAndDebitAmounts, entryNeedBeUpdated } = props;
  const differenceValue = Math.abs((debitAmount || 0) - (creditAmount || 0));
  const differenceMoney = accounting.formatMoney(differenceValue, getSymbolFromCurrency(currency), 2, ',', '.');

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="alert alert-danger">
          <ul className='list list-unstyled'>
            { !equalCreditAndDebitAmounts && <li>Amount of Debit and Credit lines is not equal. There is a difference of {differenceMoney}</li>}
            { entryNeedBeUpdated && <li>Please update entry for this transaction</li> }
          </ul>
        </div>
      </div>
    </div>
  )
}

AmountsAlert.propTypes = propTypes;

export default AmountsAlert;
