import { combineReducers } from 'redux';

import * as types from 'constants/actionTypes';
import { initialState } from 'selectors/funds';

import connectCharts from '../connectCharts';

import accounts from './accounts';
import accruals from './accruals';
import fundInvestorDocumentDirectories from './investorDocumentDirectories';
import portfolios from './portfolios';
import taskItemPatterns from './taskItemPatterns';
import valuation from './valuations';

export function details(state = initialState, action) {
  switch (action.type) {
    case types.FUND_ACCOUNTS_FETCH_SUCCESS:
      return {
        ...state,
        accounts: action.payload.accounts,
      };

    default:
      return state;
  }
}

const rootReducer = combineReducers({
  details,
  accounts,
  valuation,
  charts: connectCharts('fund'),
  accruals,
  taskItemPatterns,
  fundInvestorDocumentDirectories,
  portfolios,
});

export default rootReducer;
