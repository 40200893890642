import { createSelector } from 'reselect';
import { identity } from 'ramda';

import { entityGetter, entityKeysGetter, entityDetailGetter, entityListGetter } from 'utils/entities';

export const initialState = {};

function entitiesSelector(state) {
  return state.entities || initialState;
}

function entityNameSelector(state, { entityName } = {}) {
  return entityName;
}

function entityIdSelector(state, { entityId } = {}) {
  return entityId;
}

function entityIdsSelector(state, { entityIds } = {}) {
  return entityIds;
}

export const getEntities = createSelector(
  entitiesSelector,
  identity,
);

export const getEntity = createSelector(
  [entityNameSelector, getEntities],
  entityGetter,
);

export const getEntityKeys = createSelector(
  [entityNameSelector, getEntities],
  entityKeysGetter,
);

export const getEntityDetail = createSelector(
  [entityNameSelector, entityIdSelector, getEntities],
  entityDetailGetter,
);

export const getEntityList = createSelector(
  [entityNameSelector, entityIdsSelector, getEntities],
  entityListGetter,
);
