import { has, map } from 'ramda';

// Functions for mapping a tree

/* 
  @ Function will apply to an *array* of root nodes and every node's "children" *array*
  @ f(nodes: Array, parent: Object || null) => (newNode: Object)
  @ options: { childrenName }
*/
export function deepMapArray(f, arr, options = {}, parent) {
  const { childrenName = 'children' } = options;
  return f(map((x) => has(childrenName, x) ? { ...x, [childrenName]: deepMapArray(f, x[childrenName], options, x) } : { ...x }, arr), parent);
}

/* 
  @ Function will apply to every node of the tree.
  @ f(node: Object, parent: Object || null) => (newNode: Object)
  @ options: { childrenName }
*/
export default function deepMap(f, arr, options = {}, parent) {
  const { childrenName = 'children' } = options;
  return map((x) => {
    if (has(childrenName, x)) {
      const editedX = { ...x, [childrenName]: deepMap(f, x[childrenName], options, x) };
      return f(editedX, parent);
    }
    return f({ ...x }, parent);
  }, arr);
}