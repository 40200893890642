import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { toString } from 'lodash';

import { FormGroup } from './';

const propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  dataStateKey: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  label: PropTypes.string,
  required: PropTypes.bool,
  inline: PropTypes.bool,
  errors: PropTypes.array,
};

const defaultProps = {
  value: '',
  label: null,
  required: false,
  inline: false,
  errors: null,
};

const RadioGroup = (props) => {
  const { id, name, dataStateKey, options, value, label, required, inline, onChange, errors } = props;

  const radioClasses = classNames({
    form__radio: true,
    'form__radio-inline': inline,
  });

  return (
    <FormGroup label={label} required={required} id={id} errors={errors}>
      {options.map((opt) => {
        return (
          <div key={opt.value} className={radioClasses}>
            <label>
              <input
                name={name}
                onChange={onChange}
                data-statekey={dataStateKey}
                value={opt['value']}
                checked={toString(value) === toString(opt.value)}
                type="radio"
              />
              {opt['label']}
            </label>
          </div>
        );
      })}
    </FormGroup>
  );
};

RadioGroup.propTypes = propTypes;
RadioGroup.defaultProps = defaultProps;

export default RadioGroup;
