import numeral from 'numeral';
import moment from 'moment';
import pluralize from 'pluralize';
import { compose, join, map, split } from 'ramda';

import { decamelize } from 'utils/humps';

export function currencyFormat(value) {
  return numeral(value).format('0,0');
}

export function decimalCurrencyFormat(value) {
  if (value === null) {
    return '';
  }

  return numeral(value).format('0,0.00');
}

export function decimalCurrencySymbolFormat(value, currencySymbol = '$') {
  if (value === null) {
    return '';
  }

  return numeral(value).format(`${currencySymbol}0,0.00`);
}

export function percentFormat(value) {
  return numeral(value / 100).format('0.00%');
}

export function dateFormat(date) {
  if (date) {
    return moment(date).format('MM/DD/YYYY');
  }
  return null;
}

export function dateHumanFormat(date) {
  return moment(new Date(date)).format('DD MMM YYYY');
}

export function dateWithTimeFormat(date) {
  return moment(new Date(date)).format('MM/DD/YYYY h:mm A');
}

export function dateIsValid(date) {
  return moment(new Date(date)).isValid();
}

export function displayTerm(term) {
  return `${term} ${pluralize('month', term)}`;
}

export function currencyWithoutCommaFormat(value) {
  if (value) {
    return value.split(',').join('');
  }
  return null;
}

export const fistSymbolToUpper = (string) => string && string[0].toUpperCase() + string.slice(1);

export const formatName = compose(
  join(' '),
  map(fistSymbolToUpper),
  split(' '),
);

export const makeReadableName = (str) => {
  const separetedStr = decamelize(str, { separator: ' ' });

  return formatName(separetedStr);
};

export const clearSpecSymbols = (str) => str.replace(/[^A-Za-zА-\s-\d]/g, ' ');
