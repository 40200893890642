import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useDrag, useDrop } from 'react-dnd';
import { isEmpty } from 'ramda';
import {
  Drawer,
  Button,
  Icon,
  Col,
  Row,
  Table,
  Tag,
  Popconfirm,
  Popover,
  message,
  Select,
  Avatar,
  Form,
  Input,
  Empty,
  List,
} from 'antd';
import { Field, Form as FinalForm } from 'react-final-form';

import { validateRequired } from 'utils/validates';
import {
  newTaskItemPattern,
  createTaskItemPattern,
  updateTaskItemPattern,
  editTaskItemPattern,
  closeTaskItemPattern,
  deleteTaskItemPattern,
  newTaskItemPatterns,
  importTaskItemPatterns,
  changeTaskItemPatternPosition,
} from '../../../actions/funds/taskItemPatterns';
import {
  collectionSelector as taskItemPatternsSelector,
  getEditable as editableTaskItemPatternSelector,
  dataSource as getTableTaskItemPatterns,
  getDependentOptions as getDependentTaskItemPatterns,
} from '../../../selectors/funds/taskItemPatterns';

import {
  collectionSelector as taskListPatternsSelector,
} from '../../../selectors/taskListPatterns';

import { roles } from '../../../constants/user';

const { Option } = Select;

// eslint-disable-next-line react/prop-types
const DraggableBodyRow = ({ index, id, position, moveRow, className, style, ...restProps }) => {
  const ref = React.useRef();

  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: 'DraggableBodyRow',
    collect: (monitor) => {
      const { index: dragIndex, position: dragPosition } = monitor.getItem() || {};

      if (position === dragPosition) {
        return {};
      }

      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      };
    },
    drop: (item) => (item.position !== position ? moveRow(item.id, item.position, position) : null),
  });

  const [, drag] = useDrag({
    item: { type: 'DraggableBodyRow', index, id, position },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drop(drag(ref));

  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ''}`}
      style={{ cursor: 'grab', ...style }}
      {...restProps}
    />
  );
};

// eslint-disable-next-line react/prop-types
const TaskItemPatternForm = ({ submitting, handleSubmit, initialValues, pristine, dependentItemOptions = [] }) => (
  <Form layout='vertical' onSubmit={handleSubmit}>
    <Field name="title" validate={validateRequired()}>
      {({ input, meta }) => (
        <Form.Item
          label='Title'
          validateStatus={meta.error && meta.touched ? 'error' : 'success'}
          help={meta.error && meta.touched ? meta.error : undefined}
          required
        >
          <Input {...input} />
        </Form.Item>
      )}
    </Field>
    <Field name="fundUserRole" validate={validateRequired()}>
      {({ input, meta }) => (
        <Form.Item
          label='User Role'
          validateStatus={meta.error && meta.touched ? 'error' : 'success'}
          help={meta.error && meta.touched ? meta.error : undefined}
          wrapperCol={{
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 8 },
          }}
          required
        >
          <Select {...input}>
            {roles.map((role) => (
              <Option value={role}>{role}</Option>
            ))}
          </Select>
        </Form.Item>
      )}
    </Field>
    <Field name="dependentFundTaskItemPatternId" parse={(v) => v || null}>
      {({ input }) => (
        <Form.Item label='Dependent Task Pattern'>
          <Select {...input} allowClear={input.value}>
            {dependentItemOptions.map(({ id, title }) => (
              <Option value={id}>{title}</Option>
            ))}
          </Select>
        </Form.Item>
      )}
    </Field>
    <Form.Item>
      <Button type="primary" htmlType="submit" disabled={pristine || submitting}>
        {initialValues ? 'Update' : 'Create'}
      </Button>
    </Form.Item>
  </Form>
);

const TaskItemPatterns = (props) => {
  const {
    // eslint-disable-next-line react/prop-types
    isVisibleEditTaskItemPattern,
    // eslint-disable-next-line react/prop-types
    editableTaskItemPattern,
    // eslint-disable-next-line react/prop-types
    onSubmitTaskItemPattern,
    // eslint-disable-next-line react/prop-types
    onAddTaskItemPattern,
    // eslint-disable-next-line react/prop-types
    onEditTaskItemPattern,
    // eslint-disable-next-line react/prop-types
    onCloseTaskItemPattern,
    // eslint-disable-next-line react/prop-types
    onDeleteTaskItemPattern,
    // eslint-disable-next-line react/prop-types
    tableTaskItemPatterns,
    // eslint-disable-next-line react/prop-types
    onNewTaskItemPatterns,
    // eslint-disable-next-line react/prop-types
    onImportTaskItemPatterns,
    // eslint-disable-next-line react/prop-types
    isVisibleNewTaskItemPatterns,
    // eslint-disable-next-line react/prop-types
    taskListPatterns,
    // eslint-disable-next-line react/prop-types
    dependentTaskItemPatterns,
    // eslint-disable-next-line react/prop-types
    onMoveTaskItemPattern,
  } = props;

  return (
    <>
      <Row gutter={16}>
        <Col span={24} style={{ paddingBottom: 8 }}>
          <div className='pull-left'>
            <Button
              type='primary'
              onClick={onNewTaskItemPatterns}
              size='large'
            >
              Import Task Patterns
            </Button>
          </div>
          <div className='pull-right'>
            <Button
              type='default'
              onClick={onAddTaskItemPattern}
              size='large'
            >
              Add Task Pattern
            </Button>
          </div>
        </Col>
      </Row>
      {
        isEmpty(tableTaskItemPatterns) ? (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ) : (
            <div className='panel panel-clear'>
              <Table
                dataSource={tableTaskItemPatterns}
                onRow={(record, index) => ({
                  index,
                  id: record.id,
                  position: record.position,
                  moveRow: onMoveTaskItemPattern,
                })}
                components={{
                  body: {
                    row: DraggableBodyRow,
                  },
                }}
                columns={[
                  {
                    title: '#',
                    dataIndex: 'position',
                    key: 'position',
                    fixed: 'left',
                    width: 50,
                    sorter: (a, b) => a.key - b.key,
                    ellipsis: true,
                    align: 'center',
                  },
                  {
                    title: 'Title',
                    dataIndex: 'title',
                    key: 'title',
                    render: (title, item) => (
                      <>
                        {title}
                        {item.dependentFundTaskItemPatternId && (
                          <>
                            {' '}
                            <Popover
                              content={(
                                <Button
                                  type="link"
                                >
                                  {/* eslint-disable-next-line max-len */}
                                  {`Dependent on task #${item.dependentFundTaskItemPatternPosition} - ${item.dependentFundTaskItemPatternTitle}`}
                                </Button>
                              )}
                              trigger="hover"
                            >
                              <Icon type='info-circle' theme='twoTone' twoToneColor='#ff4d4f' />
                            </Popover>
                          </>
                        )}
                      </>
                    ),
                  },
                  {
                    title: 'Owner',
                    dataIndex: 'owner',
                    key: 'owner',
                    fixed: 'right',
                    width: 100,
                    render: (owner, item) => (
                      owner ? (
                        <Popover content={item.ownerName} trigger="hover">
                          <Avatar
                            style={{
                              backgroundColor: '#ea763b',
                              verticalAlign: 'middle',
                            }}
                            size='middle'
                          >
                            {owner}
                          </Avatar>
                        </Popover>
                      ) : (
                          <Avatar icon='user' size='middle' />
                        )
                    ),
                    ellipsis: true,
                    align: 'center',
                  },
                  {
                    title: 'Role',
                    dataIndex: 'role',
                    key: 'role',
                    fixed: 'right',
                    width: 120,
                    render: (role) => <Tag color='volcano' key={role}>{role}</Tag>,
                    sorter: (a, b) => a.role.length - b.role.length,
                    ellipsis: true,
                  },
                  {
                    title: undefined,
                    key: 'edit',
                    fixed: 'right',
                    width: 50,
                    align: 'center',
                    render: (item) => <Button type='link' icon='edit' onClick={() => onEditTaskItemPattern(item.id)} />,
                  },
                  {
                    title: undefined,
                    key: 'delete',
                    fixed: 'right',
                    width: 50,
                    align: 'center',
                    render: (item) => (
                      <Popconfirm
                        placement="left"
                        title='Delete Task Pattern?'
                        onConfirm={() => {
                          onDeleteTaskItemPattern(item.id);
                          return message.info('Deleted.');
                        }}
                        okText='Confirm'
                        cancelText='Cancel'
                      >
                        <Button type='link' icon='delete' />
                      </Popconfirm>
                    ),
                  },
                ]}
                scroll={{ x: 1300 }}
                size='small'
                pagination={false}
              />
            </div>
          )
      }
      <Drawer
        title="Task Pattern"
        width='50%'
        placement='left'
        closable={false}
        onClose={onCloseTaskItemPattern}
        visible={isVisibleEditTaskItemPattern}
        bodyStyle={{ paddingBottom: 80 }}
        destroyOnClose
      >
        <FinalForm
          component={TaskItemPatternForm}
          initialValues={editableTaskItemPattern}
          onSubmit={onSubmitTaskItemPattern}
          dependentItemOptions={dependentTaskItemPatterns}
          subscription={{
            pristine: true,
            hasSubmitErrors: true,
            submitting: true,
            submitError: true,
            initialValues: true,
          }}
        />
        <div
          style={{
            position: 'absolute',
            zIndex: 999999,
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
          }}
        >
          <Button onClick={onCloseTaskItemPattern}>
            Close
          </Button>
        </div>
      </Drawer>
      <Drawer
        title="Import Task Patterns"
        width='50%'
        placement='right'
        closable={false}
        onClose={onCloseTaskItemPattern}
        visible={isVisibleNewTaskItemPatterns}
        bodyStyle={{ paddingBottom: 80 }}
        destroyOnClose
      >
        <List
          size="large"
          dataSource={taskListPatterns}
          renderItem={(item) => (
            <List.Item
              actions={[
                <Popconfirm
                  placement="left"
                  title='Import Task Patterns?'
                  onConfirm={() => {
                    onImportTaskItemPatterns(item.id);
                    return message.info('Imported.');
                  }}
                  okText='Confirm'
                  cancelText='Cancel'
                >
                  <Button type='default'>Import</Button>
                </Popconfirm>,
              ]}
            >
              {item.title}
            </List.Item>
          )}
        />
        <div
          style={{
            position: 'absolute',
            zIndex: 999999,
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
          }}
        >
          <Button onClick={onCloseTaskItemPattern}>
            Close
          </Button>
        </div>
      </Drawer>
    </>
  );
};

const mapStateToProps = (state, { match: { path, params } }) => {
  const { id } = params;
  const editableTaskItemPatternId = path === '/funds/:fundId/task_item_patterns/:id/edit' && id;
  const isVisibleNewTaskItemPatterns = path === '/funds/:fundId/task_item_patterns/import/new';
  const isVisibleEditTaskItemPattern = path === '/funds/:fundId/task_item_patterns/:id/edit'
    || path === '/funds/:fundId/task_item_patterns/new';
  const taskItemPatterns = taskItemPatternsSelector(state);
  const taskListPatterns = taskListPatternsSelector(state);
  const editableTaskItemPattern = editableTaskItemPatternSelector(editableTaskItemPatternId, taskItemPatterns);

  return ({
    isVisibleEditTaskItemPattern,
    isVisibleNewTaskItemPatterns,
    taskListPatterns,
    dependentTaskItemPatterns: getDependentTaskItemPatterns(taskItemPatterns, editableTaskItemPattern),
    tableTaskItemPatterns: getTableTaskItemPatterns(state),
    editableTaskItemPattern,
  });
};

const mapDispatchToProps = (dispatch, { match: { path, params } }) => {
  const { id, fundId } = params;
  const editableTaskItemPatternId = path === '/funds/:fundId/task_item_patterns/:id/edit' && id;

  return ({
    onSubmitTaskItemPattern: editableTaskItemPatternId
      ? bindActionCreators(
        (values) => updateTaskItemPattern(values, { fundId, id: editableTaskItemPatternId }),
        dispatch,
      )
      : bindActionCreators((values) => createTaskItemPattern(values, { fundId }), dispatch),
    onEditTaskItemPattern: bindActionCreators(
      (taskItemPatternId) => editTaskItemPattern(fundId, taskItemPatternId),
      dispatch,
    ),
    // eslint-disable-next-line max-len
    onCloseTaskItemPattern: bindActionCreators(() => closeTaskItemPattern(fundId), dispatch),
    onAddTaskItemPattern: bindActionCreators(() => newTaskItemPattern(fundId), dispatch),
    onDeleteTaskItemPattern: bindActionCreators(
      (taskItemPatternId) => deleteTaskItemPattern({ fundId, id: taskItemPatternId }),
      dispatch,
    ),
    onNewTaskItemPatterns: bindActionCreators(() => newTaskItemPatterns(fundId), dispatch),
    onImportTaskItemPatterns: bindActionCreators(
      (taskListPatternId) => importTaskItemPatterns({ taskListPatternId, fundId }),
      dispatch,
    ),
    onMoveTaskItemPattern: bindActionCreators(
      (taskItemPatternId, itemPosition, index) => changeTaskItemPatternPosition(itemPosition, index, {
        fundId,
        id: taskItemPatternId,
      }),
      dispatch,
    ),
  });
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskItemPatterns);
