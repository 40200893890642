import fetch from '../../fetch';
import { getRequest, deleteRequest, postRequest, putRequest } from '../../requests';

export default class FundDirectoriesApi {
  static fetch(fundId, fundAccountId) {
    return fetch(Routes.fund_investor_document_directories_path(fundId), getRequest(), fundAccountId ? { fund_account_id: fundAccountId } : {})
  }

  static create({ title, parentId, fundId, isPrivate }) {
    return fetch(Routes.fund_investor_document_directories_path(fundId), postRequest({ body: { fund_investor_document_directory_params: { title, parentId, isPrivate } } }));
  }

  static rename(fundId, id, title) {
    return fetch(Routes.fund_investor_document_directory_path(fundId, id), putRequest({ body: { fund_investor_document_directory_params: { title } } }))
  }

  static bind(fundId, id, accesses) {
    const payload = {
      body: {
        fund_investor_document_access: {
          targetId: id,
          targetType: 'Fund::InvestorDocumentDirectory',
          owners: accesses.map((key) => ({
            ownerId: Number(key.split('-')[1]),
            ownerType: key.split('-')[0],
          }))
        }
      }
    }
    return fetch(Routes.bind_fund_investor_document_accesses_path(fundId), putRequest(payload));
  }

  static remove(fundId, id) {
    return fetch(Routes.fund_investor_document_directory_path(fundId, id), deleteRequest());
  }
}