import humps from 'humps';
import axios from 'axios';
import { get } from 'lodash';
import numeral from 'numeral';

import scrollToFormAlerts from 'utils/scrollToFormAlerts';
import { stringToDate } from 'utils/dateTransformations';
import { toggleChartOfAccounts } from 'components/shared/ChartOfAccounts/actions';

import * as types from './actionTypes';
import { changeMode, onAddMapping } from '../actions';

export { toggleChartOfAccounts };

export function fillFormMapping(data) {
  return {
    type: types.MAPPING_FORM_FILL,
    payload: {
      data,
    },
  };
}

export function changeNamespace(event) {
  return (dispatch) => {
    dispatch(changeInput(event));

    dispatch({
      type: types.CLEAR_EVENT_AND_TRANSACTION_KEY,
      payload: {
        event: undefined,
        transactionKey: undefined,
      },
    });
  };
}

export function changeEvent(event) {
  return (dispatch) => {
    dispatch(changeInput(event));

    dispatch({
      type: types.CLEAR_TRANSACTION_KEY,
      payload: {
        transactionKey: undefined,
      },
    });
  };
}

export function changeInput(event) {
  const target = event.target ? event.target : event;
  const stateKey =
    target.getAttribute('data-statekey') ||
    target.name
      .replace(/]/g, '')
      .split('[')
      .join('.');
  const type = target.getAttribute('data-type') || target.type;
  const targetValue = target.getAttribute('data-value') || target.value;

  const value = () => {
    switch (type) {
      case 'date':
        return stringToDate(targetValue);

      case 'currency':
        return numeral(targetValue).value();

      case 'checkbox':
        return target.checked;

      case 'number':
        if (target.step === '1') {
          return isNaN(parseInt(targetValue, 10)) ? targetValue : parseInt(targetValue, 10);
        }

        return isNaN(parseFloat(targetValue)) ? targetValue : parseFloat(targetValue);

      default:
        return targetValue;
    }
  };

  return {
    type: types.MAPPING_CHANGE_INPUT,
    payload: {
      stateKey,
      value: value(),
    },
  };
}

export function selectAccount(chartAccountId) {
  return (dispatch, getState) => {
    const { fieldForSelectAccount } = getState().chartOfAccounts;

    dispatch({
      type: types.CHARTS_ACCOUNT_SELECT,
      payload: {
        value: chartAccountId,
        field: fieldForSelectAccount,
      },
    });
    dispatch(toggleChartOfAccounts());
  };
}

export function onSubmit(e) {
  return (dispatch, getState) => {
    const resourceFund = getState().resourceFund;
    const { data } = getState().mappingForm;
    const formData = {
      mapping: humps.decamelizeKeys(data),
    };

    e.preventDefault();

    if (resourceFund) {
      return dispatch(
        submitCreateForm(formData, Routes.fund_charts_mappings_path(resourceFund.id, { format: 'json' }), 'post'),
      );
    }

    return dispatch(submitCreateForm(formData, Routes.charts_mappings_path({ format: 'json' }), 'post'));
  };
}

export function clearMapping() {
  return {
    type: types.MAPPING_CLEAR,
  };
}

function submitCreateForm(data, url, method) {
  return (dispatch) => {
    dispatch(submittingForm(true));

    axios
      .request({
        url,
        method,
        data,
        withCredentials: true,
        xsrfHeaderName: 'X-CSRF-Token',
      })
      .then((res) => {
        dispatch(submittingForm(false));
        dispatch(submittingCreateFormSucceded(humps.camelizeKeys(get(res.data.payload, 'mapping'))));
      })
      .catch((res) => {
        dispatch(submittingForm(false));
        dispatch(submittingFormFailed(humps.camelizeKeys(get(res.response.data.payload, 'errors'))));
        scrollToFormAlerts('dock');
      });
  };
}

function submittingForm(bool) {
  return {
    type: types.MAPPING_SUBMITTING_FORM,
    payload: {
      submitting: bool,
    },
  };
}

function submittingCreateFormSucceded(mapping) {
  return (dispatch) => {
    dispatch({ type: types.MAPPING_SUBMITTING_FORM_SUCCEEDED });
    dispatch(onAddMapping(mapping));
    dispatch(changeMode('table'));
    dispatch(clearMapping());
  };
}

function submittingFormFailed(data = {}) {
  return {
    type: types.MAPPING_SUBMITTING_FORM_FAILED,
    payload: {
      errors: data,
    },
  };
}
