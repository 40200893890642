import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import humps from 'humps';

import { getFirstError } from 'utils/forms';
import FormInput from './FormInput';

const propTypes = {
  autoFocus: PropTypes.bool,
  formLayout: PropTypes.shape({ labelCol: PropTypes.object, wrapperCol: PropTypes.object }),
  horizontal: PropTypes.bool,
  input: PropTypes.objectOf(PropTypes.any).isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  meta: PropTypes.objectOf(PropTypes.any).isRequired,
  customOnChange: PropTypes.func,
};

const defaultProps = {
  autoFocus: false,
  horizontal: false,
  label: true,
  formLayout: {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  },
  customOnChange: undefined,
};

const formItemLayout = (horizontal, formLayout) => {
  if (horizontal === true) {
    return formLayout;
  }
  return null;
};

const formItemErrors = ({ error, touched, submitError }) => ({
  validateStatus: ((error && touched) || submitError) && 'error',
  help: (error && touched) || getFirstError(submitError),
});

const translateLabel = (label, name) => {
  if (label === false) {
    return null;
  }

  if (label === true) {
    const translate = I18n.t(`activerecord.attributes.${humps.decamelize(name)}`);

    return translate;
  }

  return label;
};

function FormGroup({ autoFocus, customOnChange, input, label, horizontal, meta, formLayout, ...rest }) {
  const id = input.name;
  const { required, type } = rest;
  const { error, touched, submitError } = meta;

  const getLabelText = () => {
    if (label) {
      if (required) {
        return (
          <Fragment>
            <abbr className="abbr abbr-required abbr-form" title="Required">
              *
            </abbr>
            {translateLabel(label, input.name)}
          </Fragment>
        );
      }

      return translateLabel(label, input.name);
    }

    return null;
  };

  const formGroupProps = {
    id,
    label: type !== 'checkbox' && getLabelText(),
    ...formItemErrors(meta),
    ...formItemLayout(horizontal, formLayout),
  };

  const inputProps = {
    id,
    autoFocus,
    labelText: getLabelText(),
    ...input,
    ...rest,
    onChange: (e) => {
      input.onChange(e);
      customOnChange && customOnChange(e);
    },
  };

  const metaProps = {
    error,
    touched,
    submitError,
  };

  return (
    <Form.Item {...formGroupProps}>
      <FormInput {...inputProps} meta={metaProps} />
      {(meta.error || meta.submitError) && meta.touched && (
        <span className="form__error-secondary">{meta.error || meta.submitError}</span>
      )}
    </Form.Item>
  );
}

FormGroup.propTypes = propTypes;
FormGroup.defaultProps = defaultProps;

export default FormGroup;
