import * as types from './actionTypes';
import { updateReceivableForItems } from './selectors';
import { stringToDate } from 'utils/dateTransformations';
import { isUpdated, isCreated, getUniqueKey } from './utils';

export * from 'actions/changeInput';

export function updateSchedule(items, manual) {
  return (dispatch, getState) => {
    const { paymentsSchedule } = getState();
    const currentKeys = _.map(paymentsSchedule.items, 'key');
    const newItems = items.map((item) => {
      const newItem = Object.assign({}, item);
      newItem.key = getUniqueKey(currentKeys);

      currentKeys.push(newItem.key);
      newItem.date = item.date ? stringToDate(newItem.date) : null;
      newItem.paymentDate = item.paymentDate ? stringToDate(newItem.paymentDate) : null;
      newItem._updated = isUpdated(newItem) ? newItem._updated : false;
      newItem._created = isCreated(newItem) ? newItem._created : false;
      return newItem;
    });

    dispatch({
      type: types.UPDATE_SCHEDULE,
      payload: {
        items: newItems,
      },
    });

    dispatch(changeManual(manual));
    dispatch(destroyTempItem());
    dispatch(updateReceivableAmounts());
  };
}

export function updateReceivableAmounts() {
  return (dispatch, getState) => {
    dispatch({
      type: types.UPDATE_RECEIVABLE_AMOUNTS,
      payload: {
        items: updateReceivableForItems(getState()),
      },
    });
  };
}

export function changeManual(isManual) {
  return {
    type: types.CHANGE_MANUAL,
    payload: {
      manual: isManual,
    },
  };
}

export function destroyTempItem() {
  return {
    type: types.DESTROY_TEMP_ITEM,
    payload: {
      value: {},
    },
  };
}
