import { deleteRequest, getRequest, postRequest, patchRequest, putRequest } from '../../requests';
import fetch from '../../fetch';

export default class AccrualsApi {
  static submitFundAccrual(body, { fundId, accrualId }) {
    const url = accrualId ? Routes.fund_accrual_path(fundId, accrualId) : Routes.fund_accruals_path(fundId);
    const request = accrualId ? putRequest({ body }) : postRequest({ body });

    return fetch(url, request);
  }

  static updateFundAccrualPaymentItem(body, { fundId, accrualId, paymentItemId }) {
    const url = Routes.fund_accrual_payment_item_path(fundId, accrualId, paymentItemId);
    const request = patchRequest({ body });

    return fetch(url, request);
  }

  static destroyFundAccrualPaymentItem({ fundId, accrualId, paymentItemId }) {
    const url = Routes.fund_accrual_payment_item_path(fundId, accrualId, paymentItemId);

    return fetch(url, deleteRequest());
  }

  static createFundAccrualPaymentItem(body, { fundId, accrualId }) {
    const url = Routes.fund_accrual_payment_items_path(fundId, accrualId);

    return fetch(url, postRequest({ body }));
  }

  static getFundAccrualSchedule(fundId, accrualId) {
    const url = Routes.fund_accrual_schedule_path(fundId, accrualId);

    return fetch(url, getRequest());
  }

  static getFundAccrualPaymentItem(fundId, accrualId, id) {
    const url = Routes.fund_accrual_payment_item_path(fundId, accrualId, id);

    return fetch(url, getRequest());
  }
}
