import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { get } from 'lodash';

import MappingForm from './components/Form';
import * as actions from './actions';

const mapStateToProps = (state, ownProps) => {
  const mapping = state.mappingForm.data;

  const transactionAndFeeTypesByKlassCollection = mapping.transactionKlass
    ? ownProps.refs.transactionAndFeeTypesByKlassCollection[_.lowerFirst(mapping.transactionKlass)]
    : undefined;

  return {
    mappingForm: {
      data: {
        ...mapping,
      },
      meta: {
        errors: get(state.mappingForm.meta, 'errors') || {},
        submitting: get(state.mappingForm.meta, 'submitting') || false,
      },
    },
    chartOfAccountsIsVisible: state.chartOfAccounts.isVisible,
    refs: {
      ...ownProps.refs,
      transactionAndFeeTypesByKlassCollection,
    },
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MappingForm);
