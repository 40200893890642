import PropTypes from 'prop-types';

export const tableItemShape = PropTypes.shape({
  fundUserRole: PropTypes.string,
  id: PropTypes.number,
  key: PropTypes.number,
  owner: PropTypes.string,
  ownerId: PropTypes.number,
  ownerName: PropTypes.string,
  position: PropTypes.number,
  title: PropTypes.string,
  completed: PropTypes.bool,
  dependentFundValuationTaskItemCompleted: PropTypes.bool,
  dependentFundValuationTaskItemId: PropTypes.number,
  dependentFundValuationTaskItemPosition: PropTypes.number,
  dependentFundValuationTaskItemTitle: PropTypes.string,
});

export const ownerOptionsShape = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
});
