import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import FontAwesome from 'react-fontawesome';

import { Input } from 'components/shared';
import * as fundActions from 'components/Funds/actions';

import * as actions from './actions';
import { getChartsOfAccountsTree } from './selectors';
import ChartsCategoryForm from './CategoryForm/Container';
import ChartsAccountForm from './AccountForm/Container';
import ChartItem from './components/ChartItem';

const propTypes = {
  fundId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  chartOfAccounts: PropTypes.array,
  actions: PropTypes.object,
  fundActions: PropTypes.object,
  accountQuery: PropTypes.string,
  refs: PropTypes.object,
  loading: PropTypes.bool,
  isCollapsed: PropTypes.bool,
  mode: PropTypes.string,
  onSelectAccount: PropTypes.func,
};

const defaultProps = {
  fundId: null,
  chartOfAccounts: [],
  actions: null,
  fundActions: null,
  accountQuery: '',
  isCollapsed: true,
  refs: null,
  loading: false,
  mode: 'tree',
  onSelectAccount: null,
};

class ChartOfAccountsTree extends Component {
  componentDidMount() {
    if (this.props.fundId) {
      this.props.fundActions.fetchAccounts(this.props.fundId, this.props.onDate);
    }

    this.props.actions.fetchChartOfAccounts(this.props.fundId);
  }

  render() {
    const {
      fundId,
      chartOfAccounts,
      mode,
      onSelectAccount,
      loading,
      accountQuery,
      actions,
      refs,
      isCollapsed,
    } = this.props;
    let categoriesSize = 0;

    if (loading) {
      return <FontAwesome name="spinner" spin />;
    }

    const ChartItems = chartOfAccounts.map((chart) => {
      categoriesSize += chart.categories.length;
      return <ChartItem key={chart.key} fundId={fundId} chart={chart} actions={actions} isCollapsed={isCollapsed} />;
    });

    return (
      <div>
        {mode === 'tree' && (
          <div>
            <Input
              id="account_search"
              name="account[search]"
              autofocus
              value={accountQuery}
              placeholder="Find account by name or code"
              onChange={actions.changeAccountQuery}
            />

            {accountQuery && categoriesSize === 0 ? (
              <div className="alert alert-warning">Accounts were not found.</div>
            ) : (
              <div>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    actions.toggleAll();
                  }}
                >
                  {isCollapsed ? 'Expand All' : 'Collapse All'}{' '}
                  <FontAwesome name={isCollapsed ? 'angle-up' : 'angle-down'} />
                </a>
                <ul className="tree">{ChartItems}</ul>
              </div>
            )}
          </div>
        )}

        {mode === 'categoryForm' && (
          <ChartsCategoryForm fundId={fundId} onSelectAccount={onSelectAccount} changeMode={actions.changeMode} />
        )}

        {mode === 'accountForm' && (
          <ChartsAccountForm
            fundId={fundId}
            onSelectAccount={onSelectAccount}
            changeMode={actions.changeMode}
            refs={refs}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { accountQuery, loading, mode, isCollapsed } = state.chartOfAccounts;

  return {
    accountQuery,
    chartOfAccounts: getChartsOfAccountsTree(state.chartOfAccounts),
    loading,
    mode,
    isCollapsed,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
  fundActions: bindActionCreators(fundActions, dispatch),
});

ChartOfAccountsTree.propTypes = propTypes;
ChartOfAccountsTree.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(ChartOfAccountsTree);
