import { createStore, applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk';
import humps from 'humps';

import { saveState } from 'utils/localStorage';
import { stringToDate } from 'utils/dateTransformations';

import tradeReducer from './tradeReducer';

const createStoreWithThunk = applyMiddleware(ReduxThunk)(createStore);

export default function configureStore(initialState) {
  const camelizeState = humps.camelizeKeys(initialState);

  const state = {
    ...camelizeState,
    trade: {
      ...camelizeState.trade,
      tradeDate: camelizeState.trade.tradeDate ? stringToDate(camelizeState.trade.tradeDate) : undefined,
      settleDate: camelizeState.trade.settleDate ? stringToDate(camelizeState.trade.settleDate) : undefined,
    }
  };

  const store = createStoreWithThunk(tradeReducer,
    state,
    window.__REDUX_DEVTOOLS_EXTENSION__ &&
    window.__REDUX_DEVTOOLS_EXTENSION__()
  );

  saveState(store.getState());

  return store;
}
