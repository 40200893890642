import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import ChartOfAccountsTree from './Tree/Container';

const childContextTypes = {
  onSelectAccount: PropTypes.func,
  accountQuery: PropTypes.string,
};

const propTypes = {
  fundId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  accountQuery: PropTypes.string,
  onSelectAccount: PropTypes.func,
  refs: PropTypes.object,
};

const defaultProps = {
  fundId: undefined,
  accountQuery: '',
  onSelectAccount: undefined,
  refs: undefined,
};

class ChartOfAccounts extends Component {
  getChildContext() {
    return {
      onSelectAccount: this.props.onSelectAccount,
      accountQuery: this.props.accountQuery,
    };
  }

  render() {
    const { accountQuery, onSelectAccount, fundId, onDate, refs } = this.props;

    return (
      <ChartOfAccountsTree
        accountQuery={accountQuery}
        onSelectAccount={onSelectAccount}
        onDate={onDate}
        fundId={fundId}
        refs={refs}
      />
    );
  }
}

const mapStateToProps = (state) => {
  const { accountQuery } = state.chartOfAccounts;

  return {
    accountQuery,
  };
};

ChartOfAccounts.childContextTypes = childContextTypes;
ChartOfAccounts.propTypes = propTypes;
ChartOfAccounts.defaultProps = defaultProps;

export default connect(mapStateToProps)(ChartOfAccounts);
