import numeral from 'numeral';
import { find, forEach, filter, includes, indexOf } from 'lodash';

import * as types from './actionTypes';

export function toggleAllocatedAmount(allocatedAmount, allocatedAmountType) {
  return (dispatch, getState) => {
    const allocatedAmounts = getState().allocatedAmounts;

    const currentAllocatedAmount = find(allocatedAmounts, {
      _id: allocatedAmount._id,
      parentId: allocatedAmount.parentId,
    });
    const currentAllocatedAmountIndex = indexOf(allocatedAmounts, currentAllocatedAmount);

    if (currentAllocatedAmount) {
      dispatch({
        type: types.ALLOCATED_AMOUNT_TOGGLE,
        payload: {
          allocatedAmount: currentAllocatedAmount,
          index: currentAllocatedAmountIndex,
        },
      });
    } else {
      const isManualSpecialAllocation = allocatedAmountType === 'manual';
      const amount = isManualSpecialAllocation ? 0 : allocatedAmount.amount;
      dispatch({
        type: types.ALLOCATED_AMOUNT_ADD,
        payload: {
          allocatedAmount: {
            _id: allocatedAmount._id,
            amount,
            commitmentAmount: allocatedAmount.commitmentAmount,
            commitmentAmountPercent: allocatedAmount.commitmentAmountPercent,
            targetId: allocatedAmount.id,
            targetType: allocatedAmount.targetType,
            parentId: allocatedAmount.parentId,
            investorClassId: allocatedAmount.investorClassId,
            investorSeriesId: allocatedAmount.investorSeriesId,
            selected: true,
            special: isManualSpecialAllocation,
            _destroy: false,
          },
        },
      });
    }

    dispatch(handleClassOrSeriesAllocatedAmountAdd(allocatedAmount, allocatedAmount.parentId));
  };
}

export function unSelectAllAllocatedAmount(parentId) {
  return (dispatch, getState) => {
    const allocatedAmounts = getState().allocatedAmounts;
    const currentAllocatedAmounts = filter(allocatedAmounts, (allocatedAmount) => {
      return allocatedAmount.parentId === parentId;
    });

    forEach(currentAllocatedAmounts, (allocatedAmount) => {
      const allocatedAmountIndex = indexOf(getState().allocatedAmounts, allocatedAmount);

      dispatch({
        type: types.ALLOCATED_AMOUNT_TOGGLE,
        payload: {
          allocatedAmount: allocatedAmounts[allocatedAmountIndex],
          index: allocatedAmountIndex,
        },
      });
    });
  };
}

export function changeAllocationAmount(event, index) {
  return (dispatch, getState) => {
    const allocatedAmount = getState().allocatedAmounts[`${index}`];

    event.preventDefault();
    const value = event.target.value;

    dispatch({
      type: types.ALLOCATED_AMOUNT_CHANGE,
      payload: {
        stateKey: `${index}.amount`,
        value: numeral(value).value(),
      },
    });

    if (allocatedAmount.special !== true) {
      dispatch({
        type: types.ALLOCATED_AMOUNT_SET_SPECIAL,
        payload: {
          stateKey: `${index}.special`,
        },
      });
    }
  };
}

function handleClassOrSeriesAllocatedAmountAdd(currentAllocatedAmount, parentId) {
  return (dispatch, getState) => {
    const allocatedAmounts = getState().allocatedAmounts;

    if (targetTypeIsInvestorClassOrSeries(currentAllocatedAmount.targetType)) {
      const currentAllocatedAmounts = filter(
        allocatedAmounts,
        (allocatedAmount) =>
          allocatedAmount.parentId === parentId &&
          (allocatedAmount.investorClassId === currentAllocatedAmount.id ||
            allocatedAmount.investorSeriesId === currentAllocatedAmount.id) &&
          allocatedAmount._id !== currentAllocatedAmount._id,
      );

      forEach(currentAllocatedAmounts, (allocatedAmount) => {
        const allocatedAmountIndex = indexOf(getState().allocatedAmounts, allocatedAmount);

        dispatch({
          type: types.ALLOCATED_AMOUNT_TOGGLE,
          payload: {
            allocatedAmount,
            index: allocatedAmountIndex,
          },
        });
      });
    }
  };
}

function targetTypeIsInvestorClassOrSeries(type) {
  return includes(['Fund::InvestorClass', 'Fund::InvestorSeries'], type);
}
