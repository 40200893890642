import React from 'react';
import PropTypes from 'prop-types';

import CategoryItem from './CategoryItem';
import Policy from '../../../Policy';

const contextTypes = {
  accountQuery: PropTypes.string,
};

const propTypes = {
  actions: PropTypes.object.isRequired,
  chart: PropTypes.object.isRequired,
  fundId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const defaultProps = {
  fundId: null,
};

function ChartItem(props, context) {
  const { chart, fundId, isCollapsed } = props;
  const { fillFormCategory, changeMode } = props.actions;

  const formCategoryData = {
    chart: chart.key,
    relatedToFund: true,
    code: chart.newCategoryCode,
  };

  const CategoryItems = chart.categories.map((category) => (
    <CategoryItem
      key={category.id}
      fundId={fundId}
      category={category}
      actions={props.actions}
      isCollapsed={isCollapsed}
    />
  ));

  const showChart = !context.accountQuery || CategoryItems.length > 0;

  return (
    showChart && (
      <li className="tree__item">
        <span className="tree__title">{chart.displayTitle}</span>

        <ul className="tree tree-sub">
          {CategoryItems}
          <Policy name="chart.category.add" type="hide">
            <li className="tree__item tree__item-sub">
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  fillFormCategory(formCategoryData);
                  changeMode('categoryForm');
                }}
              >
                Add Category
              </a>
            </li>
          </Policy>
        </ul>
      </li>
    )
  );
}

ChartItem.contextTypes = contextTypes;
ChartItem.propTypes = propTypes;
ChartItem.defaultProps = defaultProps;

export default ChartItem;
