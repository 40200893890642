import React from 'react';
import PropTypes from 'prop-types';

import { Select } from 'antd';

const propTypes = {
  options: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
};

const defaultProps = {
  options: [],
};

function SelectInput({ options, ...props }) {
  const Options = options.map((option) => (
    <Select.Option key={option.value} value={option.value}>
      {option.label}
    </Select.Option>
  ));

  return <Select {...props}>{Options}</Select>;
}

SelectInput.propTypes = propTypes;
SelectInput.defaultProps = defaultProps;

export default SelectInput;
