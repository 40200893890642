import { propEq, find, filter } from 'ramda';
import { getIn } from 'utils/ramda';

const taskListPatternsSelector = (state) => getIn('taskListPatterns', state) || {};

export const collectionSelector = (state) => getIn('collection', taskListPatternsSelector(state)) || [];

export const getNew = (state) => filter((list) => !list.active, collectionSelector(state));
export const getActive = (state) => filter((list) => list.active, collectionSelector(state));

// eslint-disable-next-line radix
export const getEditable = (id, collection) => find(propEq('id', parseInt(id)))(collection);
