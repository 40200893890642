import React from 'react';
import PropTypes from 'prop-types';

import { Radio } from 'antd';

const propTypes = {
  options: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

const defaultProps = {
  value: null,
  options: [],
};

function RadioGroup({ value, options, ...props }) {
  return (
    <Radio.Group value={value} {...props}>
      {options.map((option) => (
        <Radio key={option.value} value={option.value} checked={option.value === value}>
          {option.label}
        </Radio>
      ))}
    </Radio.Group>
  );
}

RadioGroup.propTypes = propTypes;
RadioGroup.defaultProps = defaultProps;

export default RadioGroup;
