import { schema, normalize } from 'normalizr';
import { camelizeKeys } from 'utils/humps';

const associate = new schema.Entity('associates');

const account = new schema.Entity('accounts', {
  associates: [associate],
});

const toCamelizedArray = (data) => (Array.isArray(data) ? camelizeKeys(data) : camelizeKeys([data]));

export const normalizeAccounts = (data) => normalize(toCamelizedArray(data), [account]);
export const normalizeAssociates = (data) => normalize(toCamelizedArray(data), [associate]);
