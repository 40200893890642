import { createSelector } from 'reselect';

import { includes, identity, map, values } from 'ramda';
import { toTitle } from 'utils/ramda';

import { filter, find, isEmpty } from 'lodash';

import { getEntity, getEntityKeys, getEntityDetail } from '../entities';

export const initialState = {
  collapsedKeys: [],
  securityFilters: [],
  searchValue: '',
  types: [],
};

const stateSelector = (state) => state;
const chartsSelector = (state) => state.charts || initialState;

const categoryPatternsSelector = (state) => getEntity(state, { entityName: 'categoryPatterns' });
const categoryPatternIdsSelector = (state) => getEntityKeys(state, { entityName: 'categoryPatterns' });
const accountPatternsSelector = (state) => getEntity(state, { entityName: 'accountPatterns' });
const accountPatternIdsSelector = (state) => getEntityKeys(state, { entityName: 'accountPatterns' });
const mappingPatternsSelector = (state) => getEntity(state, { entityName: 'mappingPatterns' });

export const getCharts = createSelector(
  chartsSelector,
  identity,
);

export const getChartsTypes = createSelector(
  getCharts,
  (charts) => charts.types || [],
);

export const getChartsCollapsedKeys = createSelector(
  getCharts,
  (charts) => charts.collapsedKeys || [],
);

export const getChartsSearchValue = createSelector(
  getCharts,
  (charts) => charts.searchValue || '',
);

export const getChartsSecurityFilters = createSelector(
  getCharts,
  (charts) => charts.securityFilters || [],
);

export const getChartsCategoryPatternKeys = createSelector(
  categoryPatternIdsSelector,
  (ids) => map((id) => `categoryPattern${id}`, ids),
);

export const getChartsAccountPatternKeys = createSelector(
  accountPatternIdsSelector,
  (ids) => map((id) => `accountPattern${id}`, ids),
);

export const getChartsCategoryAndAccountKeys = createSelector(
  getChartsCategoryPatternKeys,
  getChartsAccountPatternKeys,
  (categoryKeys, accountKeys) => [...categoryKeys, ...accountKeys],
);

export const getChartsMappingPatternsSelector = createSelector(
  mappingPatternsSelector,
  (mappings) => mappings,
);

const getChartsAccountPatternsSelector = createSelector(
  accountPatternsSelector,
  getChartsCollapsedKeys,
  getChartsMappingPatternsSelector,
  getChartsSearchValue,
  (accountPatterns, collapsedKeys, mappingPatterns, searchValue) => {
    const prepareAccount = (accountPattern) => {
      if (accountPattern.displayTitle.toLowerCase().includes(searchValue.toLowerCase())) {
        return {
          ...accountPattern,
          expanded: !includes(`accountPattern${accountPattern.id}`, collapsedKeys),
          mappings: values(mappingPatterns).filter(
            (mappingPattern) => includes(mappingPattern.id, accountPattern.mappingPatterns) && mappingPattern,
          ),
        };
      }

      return {};
    };

    return map(prepareAccount, accountPatterns);
  },
);

const getChartsCategoryPatternsSelector = createSelector(
  categoryPatternsSelector,
  getChartsCollapsedKeys,
  getChartsAccountPatternsSelector,
  getChartsSearchValue,
  (categoryPatterns, collapsedKeys, accountPatterns, searchValue) => {
    const prepareCategory = (categoryPattern) => {
      const categoryAccountPatterns = values(accountPatterns).filter(
        (accountPattern) => includes(accountPattern.id, categoryPattern.accountPatterns) && accountPattern,
      );

      const isAccounts = !searchValue || categoryAccountPatterns.length > 0;

      return (
        isAccounts && {
          ...categoryPattern,
          expanded: !includes(`categoryPattern${categoryPattern.id}`, collapsedKeys),
          accounts: categoryAccountPatterns,
        }
      );
    };

    return map(prepareCategory, categoryPatterns);
  },
);

export const getChartTypesOptions = createSelector(
  getChartsTypes,
  (types) => map((type) => ({ label: toTitle(type), value: type }), types),
);

export const getChartsTree = createSelector(
  getChartsTypes,
  getChartsCategoryPatternsSelector,
  stateSelector,
  (chartTypes, categoryPatterns, state) => {
    const prepareChartType = (chartType) => {
      const chart = getEntityDetail(state, { entityName: 'charts', entityId: chartType });

      const chartCategoryPatterns = values(categoryPatterns).filter(
        (categoryPattern) => includes(categoryPattern.id, chart.categoryPatterns) && categoryPattern,
      );

      return {
        ...chart,
        categories: chartCategoryPatterns,
      };
    };

    return map(prepareChartType, chartTypes);
  },
);

export const getChartsMappingsBySecurityKlassed = createSelector(
  getChartsMappingPatternsSelector,
  getChartsSecurityFilters,
  (mappings, filters) => {
    if (isEmpty(filters)) {
      return mappings;
    }

    // TODO. Discuss with Dmitry, how the filter should work.
    return filter(
      mappings,
      (mapping) => mapping.securityKlass == null || find(filters, { value: mapping.securityKlass }),
    );
  },
);
