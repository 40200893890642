import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Table, Alert, Statistic } from 'antd';
import { equals } from 'ramda';
import { connect } from 'react-redux';

import {
  getAccrualPaymentsBalance,
  getActiveAccrualPaymentItems,
  getPaymentItemsSubmitError,
  getAccrualEditableKeys,
} from 'selectors/funds/accruals';
import * as actions from 'actions/funds/accruals';
import FormStateToRedux from 'components/shared/FormStateToRedux';
import accrualPagination from 'constants/pagination';
import { paymentsItemPropTypes } from 'propTypes';
import { addQueryParamsToCurrent, getQueryParams } from 'utils/queryParams';
import { decimalCurrencyFormat } from 'utils/formats';
import initPaymentsScheduleColumns from './shared/Columns';

import RenderCell from './shared/Cell';
import AccrualsPaymentNew from './shared/New';

const propTypes = {
  editableKeys: PropTypes.objectOf(PropTypes.object),
  isNeedShowError: PropTypes.bool,
  handleDelete: PropTypes.func.isRequired,
  handleCreate: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  paymentItems: PropTypes.arrayOf(paymentsItemPropTypes),
  paymentsScheduleBalance: PropTypes.arrayOf(PropTypes.number),
  paymentsItemsSubmitError: PropTypes.string,
};

const defaultProps = {
  editableKeys: {},
  isNeedShowError: false,
  paymentItems: [],
  paymentsItemsSubmitError: undefined,
  paymentsScheduleBalance: [],
};

function EditableTable(props) {
  const {
    editableKeys,
    isNeedShowError,
    handleCreate,
    handleDelete,
    handleSubmit,
    paymentItems,
    paymentsItemsSubmitError,
    paymentsScheduleBalance,
    // eslint-disable-next-line react/prop-types
    paymentDebitAccountName,
    // eslint-disable-next-line react/prop-types
    paymentDebitAccountBalance,
    // eslint-disable-next-line react/prop-types
    currencySymbol,
  } = props;

  const Cell = (cellProps) => <RenderCell {...cellProps} />;

  const components = {
    body: {
      cell: Cell,
    },
  };
  const paymentsScheduleColumns = initPaymentsScheduleColumns({
    editableKeys,
    paymentItems,
    paymentsScheduleBalance,
    handleSubmit,
    handleDelete,
    currencySymbol,
  });

  const pageParam = Number(getQueryParams('page')) || 1;
  const [pageNumber, setPageNumber] = useState(pageParam);

  const pagination = {
    ...accrualPagination,
    defaultCurrent: pageNumber,
    onChange: (number) => {
      const paginationValue = { page: number };
      addQueryParamsToCurrent(paginationValue);
      setPageNumber(number);
    },
  };

  return (
    <Fragment>
      {isNeedShowError && <Alert message={paymentsItemsSubmitError} type="error" style={{ marginTop: 15 }} showIcon />}
      <div>
        <Statistic title={paymentDebitAccountName} value={paymentDebitAccountBalance} />
        <FormStateToRedux form="paymentSchedule" />
        <AccrualsPaymentNew handleCreate={handleCreate} />
        <Table
          components={components}
          rowClassName={(item) => (item.modified ? 'table__tr-modified editable-row' : 'editable-row')}
          bordered
          dataSource={paymentItems}
          columns={paymentsScheduleColumns}
          className="table-payments_schedule"
          pagination={pagination}
          rowKey="key"
        />
      </div>
    </Fragment>
  );
}

EditableTable.propTypes = propTypes;
EditableTable.defaultProps = defaultProps;

const mapStateToProps = (state, ownProps) => {
  const { dirtySinceLastSubmit } = ownProps;
  const { currencySymbol } = state.resourceFund;
  const { paymentDebitAccountName } = state.fund.accruals;
  // eslint-disable-next-line max-len
  const paymentDebitAccountBalance = `${currencySymbol} ${decimalCurrencyFormat(state.fund.accruals.paymentDebitAccountBalance)}`;
  const editableKeys = getAccrualEditableKeys(state);
  const paymentItems = getActiveAccrualPaymentItems(state);
  const paymentsScheduleBalance = getAccrualPaymentsBalance(state);
  const paymentsItemsSubmitError = getPaymentItemsSubmitError(state);
  const isNeedShowError = paymentsItemsSubmitError && !dirtySinceLastSubmit;

  return {
    editableKeys,
    paymentItems,
    paymentsScheduleBalance,
    paymentsItemsSubmitError,
    paymentDebitAccountName,
    paymentDebitAccountBalance,
    isNeedShowError,
    currencySymbol,
  };
};

const mapDispatchToProps = (dispatch) => ({
  handleDelete: (id) => dispatch(actions.deletePaymentItem(id)),
});

const isTablePropsEqual = (prevProps, nextProps) => {
  const isPaymentItemsEqual = equals(prevProps.paymentItems, nextProps.paymentItems);
  const isEditableKeysEqual = equals(prevProps.editableKeys, nextProps.editableKeys);
  const isPaymentsItemsSubmitErrorEqual = equals(
    prevProps.paymentsItemsSubmitError,
    nextProps.paymentsItemsSubmitError,
  );

  return !(!isPaymentItemsEqual || !isEditableKeysEqual || !isPaymentsItemsSubmitErrorEqual);
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(EditableTable, isTablePropsEqual));
