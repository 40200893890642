import { pipe, mergeDeepRight, reduce, map, __, apply } from 'ramda';
import { stringify } from 'qs';

import { argsToArray } from '../utils/ramda';

function requestsComposition(...args) {
  return pipe(
    argsToArray,
    map(apply(__, args)),
    reduce(mergeDeepRight, {}),
  );
}

function baseRequest() {
  return {
    headers: {
      Accept: 'application/json',
      credentials: 'include',
    },
  };
}

function jsonRequest() {
  return {
    headers: {
      'Content-Type': 'application/json',
    },
  };
}

function getRequestWithMethod(method) {
  return () => ({
    method,
  });
}

function dataJsonRequest({ body }) {
  return {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
    body: pipe(stringify)(body),
  };
}

function getRequest() {
  return {
    ...requestsComposition()(baseRequest, jsonRequest),
  };
}

function postRequest({ body } = { body: {} }) {
  return {
    ...requestsComposition({ body })(baseRequest, getRequestWithMethod('POST'), jsonRequest, dataJsonRequest),
  };
}

function downloadRequest() {
  return {
    method: 'GET',
    responseType: 'blob',
  };
}

export { downloadRequest, getRequest, postRequest };
