import { downloadRequest } from 'services/apiRequests';
import cfetch from 'services/apiHelpers';
import { deleteRequest, getRequest, postRequest, putRequest } from '../requests';
import fetch from '../fetch';

export default class FundsApi {
  static exportData(fundId, valuationId, sourceIds) {
    const url = `/funds/${fundId}/valuations/${valuationId}/override/export`;

    return cfetch(`${url}?sourceIds=${sourceIds}`, downloadRequest());
  }

  static getFunds() {
    const url = Routes.funds_path();

    return fetch(url, getRequest());
  }

  static getFund({ fundId }) {
    const url = Routes.funds_path(fundId);

    return fetch(url, getRequest());
  }

  static getFundCharts({ id }) {
    const url = Routes.fund_charts_path(id);

    return fetch(url, getRequest());
  }

  static getFundAccounts({ fundId }) {
    const url = Routes.fund_accounts_path(fundId);

    return fetch(url, getRequest());
  }

  static getFundChartsCategory({ fundId, id }) {
    const url = fundId ? Routes.fund_charts_category_path(fundId, id) : Routes.charts_category_path(id);
    return fetch(url, getRequest());
  }

  static submitFundChartsCategory(body, { fundId, id }) {
    const url = id ? Routes.fund_charts_category_path(fundId, id) : Routes.fund_charts_categories_path(fundId);
    const request = id ? putRequest({ body }) : postRequest({ body });

    return fetch(url, request);
  }

  static deleteFundChartsCategory({ fundId, id }) {
    const url = fundId ? Routes.fund_charts_category_path(fundId, id) : Routes.charts_category_path(id);
    return fetch(url, deleteRequest());
  }

  static getFundChartsCategoryAccount({ fundId, categoryId, id }) {
    const url = Routes.fund_charts_category_account_path(fundId, categoryId, id);

    return fetch(url, getRequest());
  }

  static submitFundChartsCategoryAccount(body, { fundId, categoryId, id }) {
    const url = id
      ? Routes.fund_charts_category_account_path(fundId, categoryId, id)
      : Routes.fund_charts_category_accounts_path(fundId, categoryId);

    const request = id ? putRequest({ body }) : postRequest({ body });

    return fetch(url, request);
  }

  static deleteFundChartsCategoryAccount({ fundId, categoryId, id }) {
    const url = Routes.fund_charts_category_account_path(fundId, categoryId, id);

    return fetch(url, deleteRequest());
  }

  static submitFundChartsMapping(body, { fundId }) {
    const url = Routes.fund_charts_mappings_path(fundId);
    const request = postRequest({ body });

    return fetch(url, request);
  }

  static deleteFundChartsMapping({ fundId, id }) {
    const url = Routes.fund_charts_mapping_path(fundId, id);

    return fetch(url, deleteRequest());
  }
}
