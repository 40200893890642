import { createSelector } from 'reselect';
import numeral from 'numeral';
import { accounting } from 'accounting';
import { get, upperFirst } from 'lodash';

import { dateToString } from 'utils/dateTransformations';
import { fundSelector } from 'components/Funds/selectors';
import { fetchType, fetchSecondTradeType } from '../Trades/tradeSelectors';

const tradeExerciseSelector = (state) => state.tradeExerciseForm.data;
const securitySelector = (state) => state.security || {};
const realizationsSelector = (state) => state.realizations || {};

export const getTradeExerciseWithType = createSelector(
  tradeExerciseSelector,
  securitySelector,
  (tradeExercise, security) => {
    const signedQuantityToExercise = fetchSignedQuantity(
      tradeExercise,
      numeral(get(security, 'pricingFactor')).value() * numeral(tradeExercise.signedQuantity).value(),
      security,
    );
    const underlyingInvestmentType = fetchType(tradeExercise, signedQuantityToExercise, security.underlyingInvestment);
    const underlyingInvestmentSecondType = fetchSecondTradeType(
      underlyingInvestmentType,
      signedQuantityToExercise,
      security.underlyingInvestment,
    );

    return {
      ...tradeExercise,
      tradeFxRate: tradeExercise.tradeFxRate,
      settleFxRate: tradeExercise.settleFxRate,
      type: upperFirst(tradeExercise.type),
      underlyingInvestmentType,
      underlyingInvestmentSecondType,
    };
  },
);

export const getRealizationsSummary = createSelector(
  tradeExerciseSelector,
  securitySelector,
  realizationsSelector,
  fundSelector,
  (tradeExercise, security, realizations, fund) => {
    const type = fetchType(tradeExercise, tradeExercise.signedQuantity, security);
    const fx = fund.baseCurrencyIso != security.currencyIso;
    const currencySymbol =
      security.holdingsDetails && security.holdingsDetails.sellCurrency
        ? security.holdingsDetails.sellCurrency.symbol
        : security.currencySymbol;

    const price = numeral(realizations.exercisePrice).value() + numeral(realizations.additionalPrice).value();
    const quantityToExercise = numeral(security.pricingFactor).value() * numeral(tradeExercise.signedQuantity).value();

    const totalFeesValue = (() => {
      const { commissionFee, brokerFee, secFee, otherFee, taxes } = tradeExercise;
      return Math.abs(
        [commissionFee, brokerFee, secFee, otherFee, taxes].reduce(
          (a, b) => numeral(a).value() + numeral(b).value(),
          0,
        ),
      );
    })();

    const displayTotalFees = formatMoney(totalFeesValue, currencySymbol);

    const displayTotalFeesTradeAmount = (() => {
      if (fx) {
        if (tradeExercise.baseFundFeesCurrency) {
          return [
            formatMoney(totalFeesValue * (tradeExercise.tradeFxRate || 1), currencySymbol),
            formatMoney(totalFeesValue, fund.baseCurrencySymbol),
          ];
        } else {
          return [
            formatMoney(totalFeesValue, currencySymbol),
            formatMoney(totalFeesValue / (tradeExercise.tradeFxRate || 1), fund.baseCurrencySymbol),
          ];
        }
      }

      return displayTotalFees;
    })();

    const grossAmountValue = Math.abs(quantityToExercise * price);

    const displayGrossAmount = formatMoney(grossAmountValue, currencySymbol);

    const displayGrossTradeAmount = (() => {
      if (fx) {
        return [
          formatMoney(grossAmountValue, currencySymbol),
          formatMoney(grossAmountValue / (tradeExercise.tradeFxRate || 1), fund.baseCurrencySymbol),
        ];
      }

      return displayGrossAmount;
    })();

    const displayGrossSettleAmount = (() => {
      if (fx) {
        return [
          formatMoney(grossAmountValue, currencySymbol),
          formatMoney(grossAmountValue / (tradeExercise.settleFxRate || 1), fund.baseCurrencySymbol),
        ];
      }

      return displayGrossAmount;
    })();

    const displayNetAmount = (() => {
      const accruedInterest = security.type == 'fixed_income' ? Math.abs(tradeExercise.accruedInterest) : 0;

      if (type == 'sell' || type == 'short' || type == 'sell_contract' || type == 'short_contract') {
        const value = grossAmountValue - totalFeesValue + accruedInterest;
        return formatMoney(value, currencySymbol);
      }

      if (type == 'buy' || type == 'cover' || type == 'buy_contract' || type == 'cover_contract') {
        const value = grossAmountValue + totalFeesValue + accruedInterest;
        return formatMoney(value, currencySymbol);
      }

      return formatMoney(0, currencySymbol);
    })();

    const displayNetTradeAmount = (() => {
      const feesValue = tradeExercise.baseFundFeesCurrency
        ? totalFeesValue * (tradeExercise.tradeFxRate || 1)
        : totalFeesValue;
      const accruedInterest = security.type == 'fixed_income' ? Math.abs(tradeExercise.accruedInterest) : 0;

      if (type == 'sell' || type == 'short' || type == 'sell_contract' || type == 'short_contract') {
        const value = grossAmountValue - feesValue + accruedInterest;
        return fx
          ? [
              formatMoney(value, currencySymbol),
              formatMoney(value / (tradeExercise.tradeFxRate || 1), fund.baseCurrencySymbol),
            ]
          : formatMoney(value, currencySymbol);
      }

      if (type == 'buy' || type == 'cover' || type == 'buy_contract' || type == 'cover_contract') {
        const value = grossAmountValue + feesValue + accruedInterest;
        return fx
          ? [
              formatMoney(value, currencySymbol),
              formatMoney(value / (tradeExercise.tradeFxRate || 1), fund.baseCurrencySymbol),
            ]
          : formatMoney(value, currencySymbol);
      }

      return fx
        ? [formatMoney(0, currencySymbol), formatMoney(0, fund.baseCurrencySymbol)]
        : formatMoney(0, currencySymbol);
    })();

    const displayNetSettleAmount = (() => {
      const feesValue = tradeExercise.baseFundFeesCurrency
        ? totalFeesValue * (tradeExercise.settleFxRate || 1)
        : totalFeesValue;
      const accruedInterest = security.type == 'fixed_income' ? Math.abs(tradeExercise.accruedInterest) : 0;

      if (type == 'sell' || type == 'short' || type == 'sell_contract' || type == 'short_contract') {
        const value = grossAmountValue - feesValue + accruedInterest;
        return fx
          ? [
              formatMoney(value, currencySymbol),
              formatMoney(value / (tradeExercise.settleFxRate || 1), fund.baseCurrencySymbol),
            ]
          : formatMoney(value, currencySymbol);
      }

      if (type == 'buy' || type == 'cover' || type == 'buy_contract' || type == 'cover_contract') {
        const value = grossAmountValue + feesValue + accruedInterest;
        return fx
          ? [
              formatMoney(value, currencySymbol),
              formatMoney(value / (tradeExercise.settleFxRate || 1), fund.baseCurrencySymbol),
            ]
          : formatMoney(value, currencySymbol);
      }

      return fx
        ? [formatMoney(0, currencySymbol), formatMoney(0, fund.baseCurrencySymbol)]
        : formatMoney(0, currencySymbol);
    })();

    return {
      displayTotalFees,
      displayTotalFeesTradeAmount,
      displayGrossAmount,
      displayGrossTradeAmount,
      displayGrossSettleAmount,
      displayNetAmount,
      displayNetTradeAmount,
      displayNetSettleAmount,
      displayTradeDate: dateToString(tradeExercise.tradeDate),
    };
  },
);

function fetchSignedQuantity(trade, signedQuantity, security) {
  (() => {
    if (security.putOrCall === 'put') {
      if (trade.type === 'exercise') {
        return -signedQuantity;
      } else if (trade.type === 'assign') {
        return signedQuantity;
      }
    } else if (security.putOrCall === 'call') {
      if (trade.type === 'exercise') {
        return signedQuantity;
      } else if (trade.type === 'assign') {
        return -signedQuantity;
      }
    }
  })();
}

function formatMoney(value, symbol) {
  return accounting.formatMoney(value, symbol, 2, ',', '.');
}
